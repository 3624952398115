import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import { ChartBarVerticalWrapper } from './styles';

const ChartBarVertical = ({ data, secondaryData }) => {
  const { t: translate } = useTranslation();
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const last4Months = [];
  const last4MonthsNumber = [];

  for (let i = 3; i >= 0; i--) {
    const month = new Date(currentYear, currentMonth - i, 1);
    last4MonthsNumber.push({ month: month.getMonth(), year: month.getFullYear() });

    const monthLabel = month.toLocaleString('default', { month: 'short' });
    const yearLabel = month.getFullYear();
    const label = `${monthLabel.charAt(0).toUpperCase() + monthLabel.slice(1)}\n${yearLabel}`;

    last4Months.push(label);
  }

  const dataFiltered = last4MonthsNumber.map((month) => {
    const foundData = data.find(
      (dataItem) => dataItem.month === month.month && dataItem.year === month.year,
    );
    return foundData ? foundData.count : 0;
  });

  const secondaryDataFiltered = last4MonthsNumber.map((month) => {
    const foundData = secondaryData.find(
      (dataItem) => dataItem.month === month.month && dataItem.year === month.year,
    );
    return foundData ? foundData.count : 0;
  });

  const dataChart = {
    labels: last4Months,
    datasets: [
      {
        label: translate('Activities implemented'),
        data: dataFiltered,
        backgroundColor: '#34c38f',
        barThickness: 40,
      },
      {
        label: translate('Activities delivered'),
        data: secondaryDataFiltered,
        backgroundColor: '#778beb',
        barThickness: 40,
      },
    ],
  };

  const options = {
    responsive: true,
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          gridLines: {
            display: false,
          },
          ticks: {
            autoSkip: false,
            maxRotation: 0,
            fontSize: 14,
            callback: function (value) {
              return value.split('\n');
            },
          },
        },
      ],
      yAxes: [
        {
          stacked: false,
          gridLines: {
            display: true,
            borderDash: [2, 2],
            drawBorder: false,
            lineWidth: 2,
          },
          ticks: {
            min: 0,
          },
        },
      ],
    },
  };

  return (
    <ChartBarVerticalWrapper>
      <span className="title">{translate('Activity flow')}</span>
      <div className="d-flex w-100 align-items-center justify-content-center fix-height">
        <Bar data={dataChart} options={options} />
      </div>
      <div className="d-flex w-100 gap-4 align-items-center justify-content-center">
        <div className="legend">
          <span className="dot" style={{ backgroundColor: '#778beb' }} />
          <span>{translate('Activities delivered')}</span>
        </div>
        <div className="legend">
          <span className="dot" style={{ backgroundColor: '#34c38f' }} />
          <span>{translate('Activities implemented')}</span>
        </div>
      </div>
    </ChartBarVerticalWrapper>
  );
};

ChartBarVertical.propTypes = {
  data: PropTypes.array,
  secondaryData: PropTypes.array,
};

export default ChartBarVertical;

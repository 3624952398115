import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { ButtonArchiveStyle } from './styles';
import PropTypes from 'prop-types';

const ButtonArchive = ({ accountId, guidelineId, onClickFunction }) => {
  const { t: translate } = useTranslation();

  return (
    <ButtonArchiveStyle>
      <Button
        className="btn-archive"
        onClick={
          accountId && guidelineId ? () => onClickFunction(accountId, guidelineId) : onClickFunction
        }
      >
        {translate('Archive content')}
      </Button>
    </ButtonArchiveStyle>
  );
};

ButtonArchive.propTypes = {
  accountId: PropTypes.string,
  guidelineId: PropTypes.string,
  onClickFunction: PropTypes.func.isRequired,
};

export default ButtonArchive;

import React, { useEffect, useState } from 'react';
import { MetaTags } from 'react-meta-tags';
import { useTranslation } from 'react-i18next';
import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import { Nav, NavItem, TabContent, TabPane } from 'reactstrap';
import { getDatabase, ref, onValue, off } from 'firebase/database';
import 'boxicons';

import Breadcrumbs from '../../components/Breadcrumb';
import Shimmer from '../../components/Shimmer';
import PageLoading from './components/Page-Loading';
import TableResumeReview from './components/Table-Resume-Review';
import ContainerEditContent from './components/Container-Edit-Content';
import PublishedData from './components/Published-Data';
import { GuidelineLayout } from './styles';
import PerformanceData from './components/Performance-Data';
import TitleGuideline from './components/Title-Guideline';

const ViewPublishedContent = () => {
  const firebaseHelper = getFirebaseBackend();
  const { id } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const currentAccountId = user?.account;
  const { t: translate } = useTranslation();
  const [content, setContent] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [publishedDate, setPublishedDate] = useState(new Date());
  const [publishedUrl, setPublishedUrl] = useState('');
  const [activeNav, setActiveNav] = useState('1');
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isError, setIsError] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    const loadedGuideline = await firebaseHelper.getFirebaseGuideline(currentAccountId, id);

    if (loadedGuideline.column !== 'PUBLISHED') navigate(`/guideline/${id}`);

    if (loadedGuideline.publishedDate) {
      setPublishedDate(new Date(loadedGuideline.publishedDate));
    }

    setIsProcessing(loadedGuideline.processing);
    setIsError(loadedGuideline?.error);
    setContent(loadedGuideline);
    setIsLoading(false);
  };

  async function handleRetry() {
    try {
      if (!id || !currentAccountId) throw new Error('Guideline ID or Account ID is missing');
      const isProduction = process.env.REACT_APP_BUILD_TYPE === 'production';
      const firebaseToken = await firebaseHelper.getIdToken();
      setIsProcessing(true);
      setIsError(false);
      await axios.post(
        `${process.env.REACT_APP_API_URL}/publishContent`,
        {
          accountId,
          guidelineId: id,
          development: !isProduction,
        },
        {
          headers: {
            Authorization: `Bearer ${firebaseToken}`,
          },
        },
      );
    } catch (error) {
      setIsProcessing(false);
      setIsError(true);
      console.log(error);
    }
  }

  useEffect(() => {
    if (!isProcessing && !isError) return;

    const db = getDatabase();
    const guidelineRef = ref(db, `guidelines/${currentAccountId}/${id}`);

    const unsubscribe = onValue(guidelineRef, (snapshot) => {
      const guidelineData = snapshot.val();

      if (!guidelineData) {
        navigate('/404');
        return;
      }

      if (guidelineData?.column !== 'PUBLISHED') {
        navigate(`/guideline/${id}`);
        return;
      }

      if (guidelineData?.processing === false || guidelineData?.error === false) {
        fetchData();
      }
    });

    return () => {
      off(guidelineRef, 'value', unsubscribe);
    };
  }, [isProcessing, isError]);

  useEffect(() => {
    fetchData();
  }, [user]);

  return (
    <GuidelineLayout>
      <div className="page-content">
        <MetaTags>
          <title>{`${translate('Published Content')} | Ectools`}</title>
        </MetaTags>
        <Breadcrumbs title={translate('Content')} breadcrumbItem={translate('Published Content')} />

        {isError && (
          <PageLoading
            title={translate('An error occurred')}
            description={translate('There was an error processing this guideline.')}
            button={translate('Try Again')}
            onClick={handleRetry}
            buttonStyles={{ backgroundColor: '#F56565', color: '#fff' }}
          >
            <box-icon name="x-circle" color="#F56565" size="7rem"></box-icon>
          </PageLoading>
        )}

        {isProcessing && !isError && (
          <PageLoading
            title={translate('This guideline is being processed')}
            description={translate(
              'This may take a few minutes, you can also wait for this process in the background and return later.',
            )}
            button={translate('Go back')}
            onClick={() => navigate('/guidelines')}
          />
        )}

        {!isProcessing && !isError && (
          <>
            {isPageLoading ? (
              <PageLoading
                title={translate('Recreating content')}
                description={translate(
                  'This may take a few minutes, you can also wait for this process in the background and return later.',
                )}
                button={translate('Go back')}
                onClick={() => navigate('/guidelines')}
              />
            ) : (
              <div className="container-row">
                <div className="container-general-information">
                  <div className="header-general-information">
                    <div>
                      <button
                        aria-label="Back"
                        type="button"
                        id="back-button"
                        className="back-button"
                        onClick={() => navigate('/guidelines')}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M5.21875 7.33117H13.3334V8.6645H5.21875L8.79475 12.2405L7.85208 13.1832L2.66675 7.99783L7.85208 2.8125L8.79475 3.75517L5.21875 7.33117Z"
                            fill="#2D3748"
                          />
                        </svg>
                      </button>
                      {content?.primaryKeyword && <TitleGuideline guideline={content} />}
                    </div>
                  </div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={activeNav === '1' ? 'actived' : ''}
                        onClick={() => setActiveNav('1')}
                      >
                        {translate('Summary')}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeNav === '2' ? 'actived' : ''}
                        onClick={() => setActiveNav('2')}
                      >
                        {translate('Content')}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeNav === '3' ? 'actived' : ''}
                        onClick={() => setActiveNav('3')}
                      >
                        {translate('Performance')}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeNav}>
                    {isLoading ? (
                      <>
                        <Shimmer height="22em" style={{ marginBottom: '3em' }} />
                        <Shimmer height="10em" style={{ marginBottom: '3em' }} />
                        <Shimmer height="15em" />
                      </>
                    ) : (
                      <TabPane tabId="1">
                        <TableResumeReview
                          data={content}
                          accountId={currentAccountId}
                          guidelineId={id}
                          noEdit
                        />
                      </TabPane>
                    )}
                  </TabContent>
                  <TabContent activeTab={activeNav}>
                    <TabPane tabId="2">
                      <ContainerEditContent
                        content={content}
                        setIsPageLoading={setIsPageLoading}
                        noIA
                        isDisabled
                      />
                    </TabPane>
                  </TabContent>
                  <TabContent activeTab={activeNav}>
                    <TabPane tabId="3">
                      <PerformanceData
                        primaryKeyword={content?.primaryKeyword}
                        secondaryKeywords={content?.secondaryKeywords}
                        publicationDate={publishedDate}
                      />
                    </TabPane>
                  </TabContent>
                </div>
                <div className="container-side-column">
                  <PublishedData
                    setDate={setPublishedDate}
                    date={publishedDate}
                    setPublishedUrl={setPublishedUrl}
                    publishedUrl={publishedUrl}
                    isDisabled
                    isLoading={isLoading}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </GuidelineLayout>
  );
};

export default ViewPublishedContent;

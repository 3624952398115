import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col, Card, CardBody } from 'reactstrap';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

import { useAuth } from 'hooks/useAuth';
import { getFirebaseBackend } from '../../helpers/firebaseHelper';
import PageStructure from 'components/Page-Structure';
import '../../assets/scss/datatables.scss';
import '../../assets/scss/custom/pages/_users.scss';

const Lessons = () => {
  const { t: translate } = useTranslation();
  const { user } = useAuth();
  const { adminStatus } = user;
  const accountId = user?.account;
  const [lessonData, setLessonData] = useState([]);
  const [lessons, setLessons] = useState([]);
  const firebaseHelper = getFirebaseBackend();

  const archiveLesson = async (id) => {
    await firebaseHelper.updateFirebaseLesson({
      id,
      accountId,
      archived: true,
    });

    fetchLessons();
  };

  const countCourses = async (id) => {
    const courses = await firebaseHelper.getAllFirebaseCourses(accountId);
    if (!courses) return 0;

    let linkedCourses = 0;
    await Promise.all(
      Object?.entries(courses)?.map(([courseId, courseObj]) => {
        courseObj?.modules?.forEach((module) => {
          module?.lessons?.forEach((lessonObj) => {
            if (lessonObj.id === id) linkedCourses++;
          });
        });
      }),
    );
    return linkedCourses;
  };

  const actionButton = ({ id, archived }) => (
    <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
      <Link to={`/edit-lesson?acc=${accountId}&id=${id}`}>
        <Button style={{ border: 'none', backgroundColor: '#E0E0E0' }}>
          <i className="bx bx-pencil" style={{ color: '#495057' }}></i>{' '}
          <span style={{ color: 'rgba(106, 113, 135, 1)' }}>{translate('Edit')}</span>
        </Button>
      </Link>

      <Button
        style={{ border: 'none', backgroundColor: '#FFF3DD' }}
        onClick={() => archiveLesson(id)}
      >
        <i className="bx bx-folder-open" style={{ color: '#AE9365' }}></i>{' '}
        <span style={{ color: '#AE9365' }}>{translate('Archive')}</span>
      </Button>
    </div>
  );

  const titleElem = async ({ name, teachers }) => {
    const teachersName = await Promise.all(
      teachers.map(async (teacherId) => {
        return await firebaseHelper.userName(teacherId);
      }),
    );

    return (
      <div>
        <div style={{ color: '#495057', fontWeight: '600' }}>{name}</div>
        <div style={{ color: '#74788D' }}>
          {teachersName.map((name, index) => {
            if (index === teachersName.length - 1) return `${name}`;
            return `${name}, `;
          })}
        </div>
      </div>
    );
  };

  const fetchLessons = async () => {
    const lessons = await firebaseHelper.getAllFirebaseLessons(accountId, adminStatus);
    setLessons(lessons);
  };

  const processLessons = async () => {
    const newLessonData = await Promise.all(
      Object.entries(lessons)
        .filter(([, lessonObj]) => {
          return !lessonObj.archived;
        })
        .map(async ([lessonId, lessonObj]) => {
          return {
            name: await titleElem(lessonObj),
            nameValue: lessonObj.name,
            linkedCourses: await countCourses(lessonId),
            test: lessonObj.applyTest ? translate('Yes') : translate('No'),
            visibility: lessonObj.public ? translate('Public') : translate('Private'),
            action: actionButton({ ...lessonObj, id: lessonId }),
          };
        }),
    );
    setLessonData(newLessonData);
  };

  useEffect(() => {
    if (lessons === null) return setLessonData([]);

    processLessons();
  }, [lessons]);

  useEffect(() => {
    fetchLessons();
  }, [accountId, adminStatus]);

  const archivedTotal = (acc, cur) => (cur.archived ? ++acc : acc);

  const columns = [
    {
      dataField: 'name',
      type: 'string',
      text: translate('Name'),
      sort: true,
      sortValue: (cell, row) => row.nameValue,
      filterValue: (cell, row) => row.nameValue,
      headerStyle: { width: '35%' },
    },
    {
      dataField: 'linkedCourses',
      text: translate('Courses Linked'),
      sort: true,
      headerStyle: { textAlign: 'center', width: '10%' },
      style: { textAlign: 'center' },
    },
    {
      dataField: 'test',
      text: translate('Test'),
      sort: true,
      headerStyle: { textAlign: 'center', width: '10%' },
      style: { textAlign: 'center' },
    },
    {
      dataField: 'visibility',
      text: translate('Visibility'),
      sort: true,
      headerStyle: { textAlign: 'center', width: '10%' },
      style: { textAlign: 'center' },
    },
    {
      dataField: 'action',
      text: translate('Action'),
      sort: false,
      headerStyle: { textAlign: 'center', width: '20%' },
    },
  ];

  const defaultSorted = [
    {
      dataField: 'nameValue',
      order: 'asc',
    },
  ];

  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: lessonData.length },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: lessonData.length,
    custom: true,
    sizePerPageList,
  };

  const { SearchBar } = Search;

  return (
    <PageStructure metaTitle="Lessons">
      <Row>
        <Col xs="12" className="mb-4">
          <h4 className="mb-0 font-size-18">
            {translate('Lessons')} ({lessons !== null ? Object.values(lessons).length : 0})
          </h4>
        </Col>
      </Row>

      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="id"
                columns={columns}
                data={lessonData}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider keyField="id" columns={columns} data={lessonData} search>
                    {(toolkitProps) => (
                      <React.Fragment>
                        <Row className="mb-2 d-flex justify-content-between">
                          <Col md="4">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar
                                  {...toolkitProps.searchProps}
                                  placeholder={translate('Search')}
                                />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          <Col md="4" className="d-flex flex-row-reverse align-items-center">
                            <Link to="/create-lesson">
                              <Button
                                style={{
                                  backgroundColor: 'rgba(85, 110, 230, 1)',
                                }}
                              >
                                <i className="bx bx-plus-circle"></i>{' '}
                                {translate('Create new Lesson')}
                              </Button>
                            </Link>
                            <Link to="/archived-lessons">
                              <p className="mx-4 mb-0" style={{ color: '#ADB5BD' }}>
                                {translate('Archived Lessons')} (
                                {lessons !== null
                                  ? Object.values(lessons).reduce(archivedTotal, 0)
                                  : 0}
                                )
                              </p>
                            </Link>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={'id'}
                                responsive
                                hover
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                classes={'table align-middle table-nowrap'}
                                headerWrapperClasses={'thead-light'}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row className="align-items-md-center mt-30">
                          <Col className="inner-custom-pagination d-flex">
                            <div className="d-inline">
                              <SizePerPageDropdownStandalone {...paginationProps} />
                            </div>
                            <div className="text-md-right ms-auto">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PageStructure>
  );
};

export default Lessons;

import React, { useState, useEffect } from 'react';
import { Button, Row } from 'reactstrap';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';
import 'boxicons';

import { useAuth } from 'hooks/useAuth';
import LabelGroup from '../../components/Label-Group';
import TextInput from '../../components/Text-Input';
import TextAreaInput from 'components/TextArea-Input';
import SelectInput from 'components/Select-Input';
import CheckboxInput from 'components/Checkbox-Input';
import DisplayErrors from 'components/Display-Errors';
import ShowComponent from 'components/Show-Component';
import { loadLessons } from 'utils';
import { TabWrapper, ModuleBox } from './styles';

const ModulesTab = ({ controlName }) => {
  const { t: translate } = useTranslation();
  const { user } = useAuth();
  const accountId = user?.account;
  const [lessonsKey, setLessonsKey] = useState(0);
  const { control, setValue, getValues, watch, formState, clearErrors } = useFormContext();
  const { fields, append, remove, swap } = useFieldArray({
    control,
    name: controlName,
  });

  const onDragEnd = (result) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    if (result.draggableId.includes('lesson')) {
      const [, moduleIndex] = result.type.split('-');
      const lessonSource = result.source.index;
      const lessonDestination = result.destination.index;
      const modules = watch(controlName);
      const lesson = watch(`${controlName}.${moduleIndex}.lessons`);

      const newModules = _.cloneDeep(modules);
      const temp = _.cloneDeep(lesson[lessonSource]);
      lesson[lessonSource] = lesson[lessonDestination];
      lesson[lessonDestination] = temp;

      newModules[moduleIndex].lessons = lesson;

      setValue(controlName, newModules, { shouldTouch: true });
      setValue(`${controlName}.${moduleIndex}.lessons`, lesson, {
        shouldTouch: true,
      });
    } else {
      swap(result.source.index, result.destination.index);
    }
  };

  const handleAdd = (event) => {
    event.preventDefault();
    append({
      name: '',
      description: '',
      lessons: [{ id: undefined, freeTrial: false }],
    });
  };

  const handleDelete = (event, index) => {
    event.preventDefault();

    if (fields.length > 1) {
      remove(index);
    }
  };

  const addLesson = (moduleIndex) => {
    const arr = getValues(`${controlName}.${moduleIndex}.lessons`);
    if (arr.at(-1).id) {
      arr.push({ id: '', freeTrial: false });
      setValue(`${controlName}.${moduleIndex}.lessons`, arr);
    }
  };

  const removeLesson = (moduleIndex, lessonIndex) => {
    const arr = getValues(`${controlName}.${moduleIndex}.lessons`);
    arr.splice(lessonIndex, 1);
    setValue(`${controlName}.${moduleIndex}.lessons`, arr);
  };

  const clearDraftError = () => {
    if (
      formState?.errors?.modules?.types?.validate ===
      "You can only leave the lessons of a course empty if it's being saved as a draft! Make a change in any module name or description to remove this error."
    )
      clearErrors('modules');
  };

  useEffect(() => {
    setLessonsKey((prevState) => prevState + 1);
  }, [accountId]);

  useEffect(() => {}, [watch('modules')]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <TabWrapper>
        <Button
          className="d-flex justify-content-center align-items-center gap-2"
          onClick={handleAdd}
          color="primary"
        >
          <i className="bx bx-plus-circle" style={{ fontSize: '1rem' }}></i>
          {translate('Add module')}
        </Button>

        <DisplayErrors error={formState.errors?.[controlName]?.types} />

        <Droppable droppableId="modules" type="modules">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {fields.map(({ name }, index) => (
                <Draggable
                  draggableId={`draggable-${index}-${name}`}
                  index={index}
                  key={name + index}
                >
                  {(provided) => (
                    <ModuleBox ref={provided.innerRef} {...provided.draggableProps}>
                      <div>
                        <div {...provided.dragHandleProps}>{index + 1}</div>

                        <ShowComponent condition={fields.length > 1}>
                          <Button onClick={(event) => handleDelete(event, index)}>
                            <box-icon name="trash-alt" color="#F46A6A" size="1.5rem" />
                          </Button>
                        </ShowComponent>
                      </div>

                      <div>
                        <DisplayErrors
                          error={formState.errors?.[controlName]?.[index]?.name?.types}
                          style={{ marginTop: '0' }}
                        />

                        <LabelGroup
                          htmlFor={`${controlName}.${index}.name`}
                          style={{
                            paddingTop: '0',
                            color: '#808080',
                            fontSize: '0.7rem',
                            fontWeight: '300',
                          }}
                          label="Module's Name"
                          size={12}
                          mb={0}
                          column
                          required
                        >
                          <TextInput
                            controlName={`${controlName}.${index}.name`}
                            placeholder="Enter the name"
                            size={12}
                            onChange={() => clearDraftError()}
                            mb={2}
                            required
                          />
                        </LabelGroup>

                        <DisplayErrors
                          error={formState.errors?.[controlName]?.[index]?.description?.types}
                          style={{ marginTop: '0' }}
                        />

                        <LabelGroup
                          htmlFor={`${controlName}.${index}.description`}
                          style={{
                            paddingTop: '0',
                            color: '#808080',
                            fontSize: '0.7rem',
                            fontWeight: '300',
                          }}
                          label="Module's Description"
                          size={12}
                          mb={0}
                          column
                          required
                        >
                          <TextAreaInput
                            controlName={`${controlName}.${index}.description`}
                            placeholder="Enter the description"
                            size={12}
                            onChange={() => clearDraftError()}
                            mb={2}
                            manualResize
                            required
                          />
                        </LabelGroup>

                        <LabelGroup
                          htmlFor={`${controlName}.${index}.lessons`}
                          style={{
                            paddingTop: '0',
                            color: '#808080',
                            fontSize: '0.7rem',
                            fontWeight: '300',
                          }}
                          label="Module's Lessons"
                          size={12}
                          mb={0}
                          column
                        >
                          <Droppable droppableId={`lessons-${index}`} type={`lessons-${index}`}>
                            {(provided) => (
                              <div ref={provided.innerRef} {...provided.droppableProps}>
                                {watch(`modules.${index}.lessons`)?.map(
                                  ({ id: value }, lessonIndex) => {
                                    const isAdd =
                                      lessonIndex === watch('modules')[index].lessons.length - 1;

                                    return (
                                      <Draggable
                                        draggableId={`draggable-lesson-${index}-${value}-${lessonIndex}`}
                                        index={lessonIndex}
                                        key={`draggable-lesson-${index}-${value}-${lessonIndex}`}
                                      >
                                        {(provided) => (
                                          <div ref={provided.innerRef} {...provided.draggableProps}>
                                            <Row className="align-items-center mb-2">
                                              <box-icon
                                                name="menu"
                                                color="#D3D3D3"
                                                style={{ width: 'auto' }}
                                                {...provided.dragHandleProps}
                                              />

                                              <SelectInput
                                                asyncronous
                                                controlName={`${controlName}.${index}.lessons.${lessonIndex}.id`}
                                                key={lessonsKey}
                                                size={watch('freeTrial') ? 9 : 10}
                                                cacheOptions
                                                defaultOptions
                                                loadOptions={(inputValue) =>
                                                  loadLessons(accountId, inputValue)
                                                }
                                                nestedWatch
                                              />

                                              {watch('freeTrial') && (
                                                <CheckboxInput
                                                  controlName={`${controlName}.${index}.lessons.${lessonIndex}.freeTrial`}
                                                  size={1}
                                                  label="Free Trial"
                                                  justifyCenter={false}
                                                />
                                              )}

                                              <Button
                                                onClick={
                                                  isAdd
                                                    ? () => addLesson(index)
                                                    : () => removeLesson(index, lessonIndex)
                                                }
                                                style={{
                                                  width: 'auto',
                                                  backgroundColor: '#7367F0',
                                                  borderColor: '#7367F0',
                                                }}
                                              >
                                                {translate(isAdd ? 'Add' : 'Remove')}
                                              </Button>
                                            </Row>
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  },
                                )}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </LabelGroup>
                      </div>
                    </ModuleBox>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </TabWrapper>
    </DragDropContext>
  );
};

ModulesTab.displayName = 'Modules Tab Component';

ModulesTab.propTypes = {
  controlName: PropTypes.string,
};

export default ModulesTab;

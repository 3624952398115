import styled from 'styled-components';

export const ButtonDetailsStyle = styled.div`
  .btn-details {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    border: none;
    background-color: #f7fafc;
    color: #4a5568 !important;
  }
  .btn-details:hover {
    background-color: #e2e8f0;
  }
`;

import styled from 'styled-components';

export const DragDropTopicsStyle = styled.div`
  .list-group-item {
    border: none !important;
    padding: 0 !important;

    .form-label {
      font-size: 0.75rem;
      font-weight: 600;
      line-height: 1rem;
      color: #4a5568;
      margin: 0;

      svg {
        margin-right: 0.5rem;
      }
    }

    .form-topic {
      height: 2rem;
      /* width: 100% !important; */
    }
  }
  .list-group-item:hover {
    background-color: transparent !important;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #edf2f7;
    border-radius: 4px;
    padding: 0.5rem 0;
  }
  .flex-column:hover {
    background-color: #f7fafc !important;
  }

  .flex-line {
    display: flex;
    justify-content: space-between;
    padding: 0 0.5rem;
  }

  .form-group-prompt {
    display: flex;
    flex-direction: column;
    margin-bottom: 0 !important;
    margin-top: 0.5rem;
    border-top: 1px solid #edf2f7;

    #prompt {
      min-height: 120px;
      resize: none;
    }

    .accordion-button {
      padding: 0.5rem 1rem;
      box-shadow: none;
    }

    .accordion-body {
      padding: 0 0.5rem;
    }
  }

  .form-group-heading {
    margin: 0 !important;

    #heading-select {
      width: 58px;
    }
  }

  .btn-transparent {
    min-width: 148px;
  }

  .bg-light-gray {
    background-color: #f7fafc;
  }

  .collapse-footer {
    border-top: 1px solid #edf2f7;
    padding: 0.5rem 0.5rem 0 0.5rem;
  }
`;

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from 'hooks/useAuth';

const Logout = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const logoutUser = async () => {
    logout()
      .then(() => navigate('/login'))
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    logoutUser();
  }, []);

  return <></>;
};

export default Logout;

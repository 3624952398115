import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { ButtonDenyStyle } from './styles';
import PropTypes from 'prop-types';

const ButtonDeny = ({ accountId, guidelineId, onClickFunction, disabled, inBulk }) => {
  const { t: translate } = useTranslation();

  return (
    <ButtonDenyStyle>
      <Button
        className="btn-deny"
        onClick={
          accountId && guidelineId ? () => onClickFunction(accountId, guidelineId) : onClickFunction
        }
        disabled={disabled}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
        >
          <path
            d="M6.99984 12.8307C3.77809 12.8307 1.1665 10.2191 1.1665 6.9974C1.1665 3.77565 3.77809 1.16406 6.99984 1.16406C10.2216 1.16406 12.8332 3.77565 12.8332 6.9974C12.8332 10.2191 10.2216 12.8307 6.99984 12.8307ZM6.4165 8.7474V9.91406H7.58317V8.7474H6.4165ZM6.4165 4.08073V7.58073H7.58317V4.08073H6.4165Z"
            fill="white"
          />
        </svg>
        {inBulk ? translate('Fail selected') : translate('Deny')}
      </Button>
    </ButtonDenyStyle>
  );
};

ButtonDeny.propTypes = {
  accountId: PropTypes.string,
  guidelineId: PropTypes.string,
  onClickFunction: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  inBulk: PropTypes.bool,
};

export default ButtonDeny;

import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import DatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import enUS from 'date-fns/locale/en-US';
import { useTranslation } from 'react-i18next';
import { Col } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';

import DisplayErrors from '../Display-Errors/';

registerLocale('pt-BR', ptBR);
registerLocale('en-US', enUS);

const TextInput = ({
  autoComplete = 'off',
  controlName,
  dateFormat = 'dd/MM/yyyy',
  timeFormat = 'HH:mm',
  onChange = null,
  placeholder = controlName,
  required = false,
  minDate = null,
  maxDate = null,
  filterTime = false,
  size = 12,
  validation = {},
  withTime = false,
}) => {
  const { t: translate } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const validate = required ? { ...validation, required: 'Required Field!' } : validation;

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <Col lg={size}>
      <Controller
        control={control}
        name={controlName}
        rules={validate}
        render={({ field }) => (
          <DatePicker
            {...field}
            id={controlName}
            selected={field.value}
            customInputRef={field.ref}
            className={`form-control ${errors?.[controlName] ? 'error--input' : ''}`}
            placeholderText={`${translate(placeholder)}...`}
            onChange={onChange ? onChange : field.onChange}
            minDate={minDate}
            maxDate={maxDate}
            locale={translate('en-US')}
            filterTime={filterTime ? filterPassedTime : null}
            excludeOutOfBoundsTimes
            dateFormat={dateFormat}
            timeFormat={timeFormat}
            autoComplete={autoComplete}
            showTimeSelect={withTime}
          />
        )}
      />

      <DisplayErrors error={errors?.[controlName]?.types} />
    </Col>
  );
};

TextInput.propTypes = {
  autoComplete: PropTypes.string,
  controlName: PropTypes.string.isRequired,
  dateFormat: PropTypes.string,
  timeFormat: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  filterTime: PropTypes.bool,
  size: PropTypes.number,
  validation: PropTypes.object,
  withTime: PropTypes.bool,
};

export default TextInput;

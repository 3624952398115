import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { getAuth, signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';
import { getDatabase, ref, update, get } from 'firebase/database';

import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { useLocalStorage } from './useLocalStorage';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const firebaseHelper = getFirebaseBackend();
  const [user, setUser] = useLocalStorage('user', null);

  const register = async (values) => {
    return new Promise(async (resolve, reject) => {
      try {
        await firebaseHelper.registerUser(
          values.email,
          values.password,
          values.username,
          values.code,
        );
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  };

  const login = async (email, password) => {
    return new Promise(async (resolve, reject) => {
      try {
        const auth = getAuth();
        await signInWithEmailAndPassword(auth, email, password);
        const user = auth.currentUser;
        if (user) {
          try {
            const userObj = await isUserActive(user.uid);
            setUser(userObj);
            resolve(user);
          } catch (error) {
            reject(error);
          }
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const logout = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const auth = getAuth();
        await signOut(auth);
        setUser(null);
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  };

  const updateUser = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const auth = getAuth();
        const userId = auth.currentUser?.uid;
        if (userId) {
          const newUser = await firebaseHelper.getUser(userId);
          if (JSON.stringify(newUser) !== JSON.stringify(user)) setUser(newUser);
        }
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  };

  const isUserActive = (userId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = getDatabase();
        const userRef = ref(db, 'users/' + userId);
        const snapshot = await get(userRef);
        const databaseValue = snapshot.val();

        if (databaseValue) {
          if (databaseValue.status === 'Activated') {
            resolve(databaseValue);
          } else {
            await logout();
            if (databaseValue.status === 'Pending') {
              reject("This user wasn't accepted by its company yet. Check Later!");
            } else {
              reject(
                'There is no user record corresponding to this identifier. The user may have been deleted.',
              );
            }
          }
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const forgetPassword = (email) => {
    return new Promise(async (resolve, reject) => {
      try {
        await firebaseHelper.forgetPassword(email);
        resolve('Check the instructions in your email.');
      } catch (error) {
        reject(error);
      }
    });
  };

  const changeAccount = (userId, accountId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = getDatabase();
        const updates = { [`users/${userId}/account`]: accountId };
        await update(ref(db), updates);
        setUser((prevState) => ({ ...prevState, account: accountId }));
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  };

  const value = useMemo(
    () => ({
      user,
      register,
      login,
      logout,
      updateUser,
      forgetPassword,
      changeAccount,
    }),
    [user],
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export const useAuth = () => {
  return useContext(AuthContext);
};

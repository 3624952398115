import React from 'react';
import PropTypes from 'prop-types';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Row, Col, FormGroup, Label, Button } from 'reactstrap';

import SelectInput from '../../components/Select-Input';
import TextInput from '../../components/Text-Input';
import DisplayErrors from '../../components/Display-Errors';

const PhonesInput = ({ mb = 4 }) => {
  const { t: translate } = useTranslation();
  const {
    control,
    getValues,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'phones',
  });

  const handleRemove = (event, index) => {
    event.preventDefault();
    // Remove the row only if it won't leave none to the user
    if (index === 0 && fields.length === 1) {
      update(0, { tag: '', number: '' });
    } else {
      remove(index);
    }
  };

  const handleAdd = (event) => {
    event.preventDefault();
    // Add a row only if the last one is filled
    if (
      getValues(`phones.${fields.length - 1}.tag`) &&
      getValues(`phones.${fields.length - 1}.number`)
    ) {
      append({ tag: '', number: '' });
    }
  };

  const validateAssigned = (index) => {
    const tag = getValues(`phones.${index}.tag`);
    const number = getValues(`phones.${index}.number`);

    // If the field being validated is empty while its complementary field isn't return an error
    if (!tag && number) {
      return 'A tag is required if the number is filled';
    }

    // If the field being validated is clear together with its complementary field clear the error
    if (!tag && !number) {
      clearErrors(`phones.${index}.number`);
    }

    return true;
  };

  const validateStep = (index) => {
    const tag = getValues(`phones.${index}.tag`);
    const number = getValues(`phones.${index}.number`);

    // If the field being validated is empty while its complementary field isn't return an error
    if (!number && tag) {
      return 'A number is required if the tag is selected';
    }

    // If the field being validated is clear together with its complementary field clear the error
    if (!number && !tag) {
      clearErrors(`phones.${index}.tag`);
    }

    return true;
  };

  return (
    <FormGroup className={`mb-${mb} d-flex`} row>
      <Col lg="11">
        <Label>{translate('Phone')}:</Label>
        {fields.map((field, index) => (
          <Row key={field.id} className="mb-2">
            <Col lg={12} xl={8}>
              <Row lg={12}>
                <SelectInput
                  controlName={`phones.${index}.tag`}
                  placeholder="Tag"
                  validation={{
                    validate: () => validateAssigned(index),
                  }}
                  size={4}
                  options={[
                    { value: 0, label: translate('Landline') },
                    { value: 1, label: translate('Cellphone') },
                    { value: 2, label: translate('Whatsapp') },
                  ]}
                />
                <TextInput
                  controlName={`phones.${index}.number`}
                  placeholder="Phone no"
                  size={8}
                  mask="(99) 99999-9999"
                  maskChar=" "
                  validation={{
                    validate: () => validateStep(index),
                  }}
                  style={{ height: '100%' }}
                />
              </Row>

              <Row lg={12} className="mt-1">
                <DisplayErrors size={3} error={errors?.phones?.[index]?.tag?.types} />
                <DisplayErrors size={8} error={errors?.phones?.[index]?.number?.types} />
              </Row>
            </Col>

            <Col lg={12} xl={4}>
              <Button
                color="primary"
                style={{ width: '100%' }}
                onClick={(event) => handleRemove(event, index)}
              >
                {`${translate('Delete')}`}
              </Button>
            </Col>
          </Row>
        ))}
        <Button color="success" className="mt-2" onClick={(event) => handleAdd(event)}>
          {translate('Add')}
        </Button>
      </Col>
    </FormGroup>
  );
};

PhonesInput.propTypes = {
  key: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  mb: PropTypes.number,
};

export default PhonesInput;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { ButtonApproveStyle } from './styles';
import PropTypes from 'prop-types';
import { MoonLoader } from 'react-spinners';

const ButtonApprove = ({
  accountId,
  guidelineId,
  onClickFunction,
  disabled,
  isLoading,
  inBulk,
}) => {
  const { t: translate } = useTranslation();

  return (
    <ButtonApproveStyle>
      <Button
        className="btn-approve"
        onClick={
          accountId && guidelineId ? () => onClickFunction(accountId, guidelineId) : onClickFunction
        }
        disabled={disabled}
      >
        {isLoading ? (
          <MoonLoader size={16} color="white" />
        ) : (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M6.99984 12.8307C3.77809 12.8307 1.1665 10.2191 1.1665 6.9974C1.1665 3.77565 3.77809 1.16406 6.99984 1.16406C10.2216 1.16406 12.8332 3.77565 12.8332 6.9974C12.8332 10.2191 10.2216 12.8307 6.99984 12.8307ZM6.41825 9.33073L10.5424 5.20598L9.71759 4.38115L6.41825 7.68106L4.768 6.03081L3.94317 6.85565L6.41825 9.33073Z"
                fill="white"
              />
            </svg>
            {inBulk ? translate('Approve selected') : translate('Approve')}
          </>
        )}
      </Button>
    </ButtonApproveStyle>
  );
};

ButtonApprove.propTypes = {
  accountId: PropTypes.string,
  guidelineId: PropTypes.string,
  onClickFunction: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  inBulk: PropTypes.bool,
};

export default ButtonApprove;

import { cloneDeep } from 'lodash';

const normalizePhones = (phones) => {
  if (!phones) return null;

  const phonesCopy = cloneDeep(phones);
  const normalizedPhones = phonesCopy
    .filter(({ tag, number }) => {
      return tag && number;
    })
    .map(({ tag, number }) => {
      return { tag: tag.value, number };
    });

  return normalizedPhones;
};

export { normalizePhones };

import styled from 'styled-components';

export const ButtonCancelStyle = styled.div`
  .btn-cancel {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 8px 12px;
    border: none;
    background-color: transparent;
    color: #4a5568 !important;
    font-weight: 500;
    height: 36.5px;
    min-width: 89px;
  }
  .btn-cancel:hover {
    background-color: #e2e8f0;
  }
`;

const simpleHeaderPages = [
  '/create-account',
  '/edit-account',
  '/list-accounts',
  '/create-user',
  '/edit-user',
  '/list-users',
  '/list-pending',
  '/list-deactivated',
  '/edit-profile',
];

export { simpleHeaderPages };

import React, { useState } from 'react';
import {
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { useParams } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import { CardCommentStyle } from './styles';
import { MoonLoader } from 'react-spinners';

const CardComment = ({ comment, fetchData }) => {
  const firebaseHelper = getFirebaseBackend();
  const { id } = useParams();
  const { user } = useAuth();
  const currentAccountId = user?.account;
  const { t: translate } = useTranslation();
  const [idEditComment, setIdEditComment] = useState(null);
  const [newComment, setNewComment] = useState(comment.content);
  const [isLoading, setIsLoading] = useState(false);
  const [isDone, setIsDone] = useState(comment.done);

  const onSubmitEditComment = async (commentId, inputComment) => {
    if (user.id != comment.by || user.adminStatus != 'Super Admin') {
      return;
    }

    try {
      setIsLoading(true);
      await firebaseHelper.editCommentGuideline(currentAccountId, id, commentId, inputComment);

      setIsLoading(false);
      setIdEditComment(null);
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteComment = async (commentId) => {
    if (user.id != comment.by || user.adminStatus != 'Super Admin') {
      return;
    }

    try {
      setIsLoading(true);

      await firebaseHelper.deleteCommentGuideline(currentAccountId, id, commentId);

      setIsLoading(false);
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  const doneComment = async (commentId) => {
    if (user.id !== comment.by && user.adminStatus !== 'Super Admin') {
      return;
    }

    try {
      setIsLoading(true);
      await firebaseHelper.setDoneTaskGuideline(currentAccountId, id, commentId);

      setIsLoading(false);
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <CardCommentStyle>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex">
          {comment.type === 'task' &&
            (isLoading ? (
              <MoonLoader size={12} color="#74788D" />
            ) : (
              <Input
                type="checkbox"
                checked={comment.done}
                disabled={comment.done}
                onChange={() => {
                  doneComment(comment.id);
                }}
              />
            ))}
          <p className={`title ${comment.done ? 'title-done' : 'title-not-done'}`}>
            {comment.name}
          </p>
        </div>
        {isLoading ? (
          <MoonLoader size={12} color="#74788D" />
        ) : (
          (user.id === comment.by || user.adminStatus === 'Super Admin') && (
            <UncontrolledDropdown>
              <DropdownToggle className="dropdown-toggle" tag="span">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9 7.5C8.175 7.5 7.5 8.175 7.5 9C7.5 9.825 8.175 10.5 9 10.5C9.825 10.5 10.5 9.825 10.5 9C10.5 8.175 9.825 7.5 9 7.5ZM9 3C8.175 3 7.5 3.675 7.5 4.5C7.5 5.325 8.175 6 9 6C9.825 6 10.5 5.325 10.5 4.5C10.5 3.675 9.825 3 9 3ZM9 12C8.175 12 7.5 12.675 7.5 13.5C7.5 14.325 8.175 15 9 15C9.825 15 10.5 14.325 10.5 13.5C10.5 12.675 9.825 12 9 12Z"
                    fill="#74788D"
                  />
                </svg>
              </DropdownToggle>
              <DropdownMenu>
                {user.id === comment.by && (
                  <DropdownItem onClick={() => setIdEditComment(comment.id)}>
                    {translate('Edit')}
                  </DropdownItem>
                )}
                <DropdownItem className="text-danger" onClick={() => deleteComment(comment.id)}>
                  {translate('Delete')}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        )}
      </div>
      {idEditComment === comment.id ? (
        <>
          <Input
            type="textarea"
            defaultValue={comment.content}
            onChange={(e) => {
              e.preventDefault();
              setNewComment(e.target.value);
            }}
          />
          <div className="d-flex gap-2">
            <Button
              className="btn-save"
              onClick={(e) => {
                e.preventDefault();
                onSubmitEditComment(comment.id, newComment);
              }}
            >
              {translate('Save')}
            </Button>
            <Button
              className="btn-edit"
              onClick={() => {
                setIdEditComment(null);
              }}
            >
              {translate('Cancel')}
            </Button>
          </div>
        </>
      ) : (
        <p
          className={`${comment.done || comment.done === 'undefined' ? 'comment-content-done' : 'comment-content-not-done'}`}
        >
          {comment.content}
        </p>
      )}

      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="13"
          viewBox="0 0 12 13"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 1.5C3.243 1.5 1 3.743 1 6.5C1 9.257 3.243 11.5 6 11.5C8.757 11.5 11 9.257 11 6.5C11 3.743 8.757 1.5 6 1.5ZM6 10.5C3.7945 10.5 2 8.7055 2 6.5C2 4.2945 3.7945 2.5 6 2.5C8.2055 2.5 10 4.2945 10 6.5C10 8.7055 8.2055 10.5 6 10.5Z"
            fill="#A6A6A6"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.5 4H5.5V6.707L7.1465 8.3535L7.8535 7.6465L6.5 6.293V4Z"
            fill="#A6A6A6"
          />
        </svg>
        <span>
          {new Date(comment.createdAt).toLocaleDateString(translate('en-US'), {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          })}
        </span>
      </div>
    </CardCommentStyle>
  );
};

CardComment.propTypes = {
  comment: PropTypes.object,
  fetchData: PropTypes.func.isRequired,
};

export default CardComment;

import styled from 'styled-components';

export const SendCardLayout = styled.div`
  width: 332px;
  height: fit-content;
  padding: 16px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 6px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
    height: 3rem;
    padding: 10px 24px;
    border-radius: 4px;
    background-color: #556ee6;
    border: none;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    color: #ffffff;
  }
  button:hover {
    background-color: #3b5bfc;
  }
  button:disabled {
    background-color: #a0aec0;
    opacity: 0.5;
    cursor: not-allowed;
  }

  .approve {
    background-color: #48bb78;
  }
  .approve:hover {
    background-color: #25855a;
  }

  .error {
    color: red !important;
    margin-bottom: 0 !important;
    margin-top: 4px;
  }

  .clear {
    background-color: #ffffff;
    color: #343a40 !important;
    border: 1px solid #e2e8f0;
  }
  .clear:hover {
    background-color: #e2e8f0;
  }
  .clear:active {
    border: 1px solid #e2e8f0;
  }

  input {
    display: flex;
    height: 2.75rem;
    padding: 0.5rem 1rem 0.5rem 2.5rem;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
    border-radius: 0.25rem;
    border: 1px solid #e2e8f0;
    background: #fff;
    color: #74788d;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .btn-delete {
    background-color: #f46a6a;
  }
  .btn-delete:hover {
    background-color: #f03e3e;
  }
`;

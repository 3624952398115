import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Input, Label } from 'reactstrap';
import InputMask from 'react-input-mask';

import DisplayErrors from '../Display-Errors';

const formatChars = {
  9: '[0-9]',
  a: '[A-Za-z]',
  '*': '[A-Za-z0-9]',
  '.': '.',
  f: '[gGmMiIyYuUsSdD]',
};

const TextInput = ({
  autoComplete = 'off',
  controlName,
  example,
  mask,
  maskChar,
  noEllipsis = example ? true : false,
  onChange = null,
  placeholder = controlName,
  required = false,
  size = 12,
  type = 'text',
  mb = 0,
  label,
  validation = {},
  minWidth = '',
  maxWidth = '',
  ...other
}) => {
  const { t: translate } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const validate = required ? { ...validation, required: 'Required Field!' } : validation;

  const tag = mask ? InputMask : '';

  const RenderedInput = mask ? InputMask : Input;

  const maskProps = mask ? { mask, maskChar, formatChars } : {};

  return (
    <Col lg={size} className={`mb-${mb}`} style={{ minWidth: minWidth, maxWidth: maxWidth }}>
      {label && (
        <Label htmlFor={controlName} className="col-lg-12 mt-2">
          {`${translate(label)}${required ? ' *' : ''}`}
        </Label>
      )}
      <Controller
        control={control}
        name={controlName}
        rules={validate}
        render={({ field }) => (
          <RenderedInput
            {...field}
            id={controlName}
            value={field.value}
            innerRef={field.ref}
            type={type}
            tag={tag}
            className={`form-control ${errors?.[controlName] ? 'error--input' : ''}`}
            placeholder={`${translate(placeholder)}${
              example ? ' ' + (translate('eg') + '. ' + example) : ''
            }${noEllipsis ? '' : '...'}`}
            onChange={(event) => {
              field.onChange(event);
              if (onChange) onChange(event);
            }}
            autoComplete={autoComplete}
            {...maskProps}
            {...other}
          />
        )}
      />

      <DisplayErrors error={errors?.[controlName]?.types} />
    </Col>
  );
};

TextInput.propTypes = {
  autoComplete: PropTypes.string,
  controlName: PropTypes.string.isRequired,
  example: PropTypes.string,
  mask: PropTypes.string,
  maskChar: PropTypes.string,
  noEllipsis: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  size: PropTypes.number,
  type: PropTypes.string,
  mb: PropTypes.number,
  validation: PropTypes.object || PropTypes.string,
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string,
};

export default TextInput;

import { isDate } from 'lodash';

const getEpochDate = (date) => {
  if (!isDate(date)) return null;

  const parsedDate = Date.parse(date);
  return parsedDate;
};

export { getEpochDate };

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { copyToClipBoard } from '../../utils/';

const TextCurtain = ({ title = null, text = '..........', canCopy = true }) => {
  // If the text is too big, limits the displayed password pattern to 10 characters
  const convertedText =
    text.length > 10 ? '..........'.replace(/./g, '•') : text.replace(/./g, '•');
  const [hidden, setHidden] = useState(true);
  const [hiddenText, setHiddenText] = useState(convertedText);

  return (
    <div className="text-curtain-wrapper">
      {title !== null ? <p className="text-curtain-title">{`${title}: `}</p> : null}

      <div className="text-curtain-field">
        <i
          className={`text-curtain-icon bx ${hidden ? 'bx-show' : 'bx-hide'} me-3`}
          onClick={() => setHidden(!hidden)}
        ></i>
        {hidden ? (
          <p className="text-curtain-text-hidden">{hiddenText}</p>
        ) : (
          <p className={`text-curtain-text ${canCopy ? 'can-select' : 'cant-select'}`}>{text}</p>
        )}
      </div>

      {canCopy ? (
        <i
          className="text-curtain-icon bx bx-copy ms-2"
          onClick={(event) => copyToClipBoard(event, text)}
        ></i>
      ) : null}
    </div>
  );
};

TextCurtain.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  canCopy: PropTypes.bool,
};

export default TextCurtain;

import styled from 'styled-components';

export const SpinnerTopicWordSizeStyle = styled.div`
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    border-color: #ced4da;
    background-color: #f7fafc;
    padding: 0.5rem 0.75rem;
    color: #2d3748 !important;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
  }
  button:hover {
    border-color: #ced4da;
    background-color: #edf2f7;
    color: #2d3748;
  }

  input {
    width: 3.5rem !important;
    height: 2rem;
    border-width: 1px;
    border-color: #ced4da;
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.36);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: center;
  }
`;

import styled from 'styled-components';

export const TextAreaTag = styled.div`
  background-color: ${(props) => props.color};
  color: white;
  width: max-content;
  padding: 0.15rem 1rem;
  margin-left: auto;
  margin-right: 1.5em;
  border-radius: 0 0 4px 4px;
`;

const normalizeString = (
  string,
  configs = {
    normalize: 'NFC',
    replace: false,
    replaceTo: '',
    everyCase: 'normal',
    firstUpper: true,
    allFirstUpper: false,
  },
) => {
  if (typeof string !== 'string') return null;
  let normalizedString = string;

  if (configs.normalize !== 'none') {
    normalizedString = normalizedString.normalize(configs.normalize);
  }

  if (configs.everyCase !== 'normal') {
    switch (configs.everyCase) {
      case 'upper':
        normalizedString = normalizedString.toUpperCase();
        break;
      case 'lower':
        normalizedString = normalizedString.toLowerCase();
        break;
      case 'localUpper':
        normalizedString = normalizedString.toLocaleUpperCase();
        break;
      case 'localLower':
        normalizedString = normalizedString.toLocaleLowerCase();
        break;
    }
  }

  if (configs.replace) {
    normalizedString = normalizedString.replace(configs.replace, configs.replaceTo);
  }

  if (configs.firstUpper) {
    normalizedString = normalizedString.charAt(0).toUpperCase() + normalizedString.slice(1);
  }

  if (configs.allFirstUpper) {
    normalizedString = normalizedString
      .split(' ')
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  }

  return normalizedString;
};

export { normalizeString };

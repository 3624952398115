const kanbanStepNumber = {
  1: 'Backlog',
  2: 'In Progress',
  3: 'Pendings',
  4: 'Delivered',
  5: 'Finished',
};

const contentStepNumber = {
  1: 'Backlog',
  2: 'Prioritized',
  3: 'Under Development',
  4: 'Under Review',
  5: 'Published',
};

const kanbanGuidelineStepNumber = {
  1: 'BACKLOG',
  2: 'GUIDELINE_APPROVAL',
  3: 'CONTENT_STRUCTURE',
  4: 'PRE_REVIEW',
  5: 'CONTENT_REVIEW',
  6: 'INTERNAL_APPROVAL',
  7: 'CONTENT_APPROVAL',
  8: 'APPROVED',
  9: 'PUBLISHED',
};

export { kanbanStepNumber, kanbanGuidelineStepNumber, contentStepNumber };

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useAuth } from 'hooks/useAuth';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { Button } from 'reactstrap';

import ContentTextarea from '../Content-Textarea';
import { ContainerEditContentStyle } from './styles';
import { CircularArrowsIcon } from 'assets/icons';

const ContainerEditContent = ({ content, setIsPageLoading, noAI, isDisabled }) => {
  const firebaseHelper = getFirebaseBackend();
  const { id } = useParams();
  const { user } = useAuth();
  const currentAccountId = user?.account;
  const isProduction = process.env.REACT_APP_BUILD_TYPE === 'production';
  const { t: translate } = useTranslation();
  const [copiedText, setCopiedText] = useState(false);
  const [wordsArray, setWordsArray] = useState([]);

  const noAIValue = noAI !== undefined ? noAI : user.adminStatus === 'Reviewer';

  const recreateAllContent = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/regenerateContent`,
        {
          accountId: currentAccountId,
          guidelineId: id,
          development: !isProduction,
        },
        {
          headers: {
            Authorization: `Bearer ${await firebaseHelper.getIdToken()}`,
          },
        },
      );
      setIsPageLoading(true);
    } catch (error) {
      console.log(error);
    }
  };

  const recreateContent = async (topicIndex) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/regenerateContentTopic`,
        {
          accountId: currentAccountId,
          guidelineId: id,
          topicIndex: topicIndex,
          development: !isProduction,
        },
        {
          headers: {
            Authorization: `Bearer ${await firebaseHelper.getIdToken()}`,
          },
        },
      );
      setIsPageLoading(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCopyTopics = () => {
    const contentToCopy = content.structure.map((item) => item.content).join('\n\n');

    navigator.clipboard.writeText(contentToCopy);

    setCopiedText(true);

    setTimeout(() => {
      setCopiedText(false);
    }, 1500);
  };

  const handleCopyContent = () => {
    const contentToCopy = content.content;

    navigator.clipboard.writeText(contentToCopy);

    setCopiedText(true);

    setTimeout(() => {
      setCopiedText(false);
    }, 1500);
  };

  const countWords = (content) => {
    const words = content.split(' ');
    const filteredWords = words.filter((word) => word !== '');
    return filteredWords.length;
  };

  useEffect(() => {
    if (!content || !content.structure) return;

    if (!content.content) {
      const newWordsArray = content.structure.map((item, index) => ({
        index: index,
        size: countWords(item.content),
      }));

      const totalWords = newWordsArray.reduce((acc, item) => acc + item.size, 0);

      setWordsArray([...newWordsArray, { totalSize: totalWords }]);
    } else {
      const totalWords = countWords(content.content);

      setWordsArray([{ totalSize: totalWords }]);
    }
  }, [content]);

  return (
    <ContainerEditContentStyle>
      <div className="header-content">
        <div className="d-flex align-items-center gap-2 justify-content-end w-100 container-relative">
          {wordsArray && wordsArray.length > 0 && (
            <span className="tag-text-size w-100">{`${wordsArray[wordsArray.length - 1].totalSize} ${translate('words')}`}</span>
          )}
          <button
            className="btn-copy-markdown d-flex gap-2 align-items-center"
            color="secondary"
            onClick={content?.content ? handleCopyContent : handleCopyTopics}
            disabled={copiedText}
          >
            {copiedText ? <i className="bx bx-check" /> : <i className="bx bx-copy" />}
            {copiedText
              ? translate('Copied')
              : content?.content
                ? translate('Copy content')
                : translate('Copy topics')}
          </button>
          {!noAIValue && (
            <Button color="secondary" className="btn-recreate" onClick={recreateAllContent}>
              <CircularArrowsIcon />
              {translate('Recreate') + ' ' + translate('Content').toLowerCase()}
            </Button>
          )}
        </div>
      </div>
      <div className="content-container">
        {content?.content ? (
          <ContentTextarea
            item={content}
            noIA={noAIValue}
            wordsArray={wordsArray}
            setWordsArray={setWordsArray}
            fullContent
          />
        ) : (
          content?.structure &&
          content.structure.map((item, index) => {
            return (
              <ContentTextarea
                key={index}
                item={item}
                index={index}
                recreateFunction={() => recreateContent(index)}
                noIA={noAIValue}
                isDisabled={isDisabled}
                wordsArray={wordsArray}
                setWordsArray={setWordsArray}
              />
            );
          })
        )}
      </div>
    </ContainerEditContentStyle>
  );
};

ContainerEditContent.propTypes = {
  content: PropTypes.object,
  setIsPageLoading: PropTypes.func,
  noAI: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export default ContainerEditContent;

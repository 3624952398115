import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import MarkdownInput from 'components/Markdown-Input';
import { TabWrapper } from './styles';

const DescriptionTab = () => {
  const { t: translate } = useTranslation();

  return (
    <TabWrapper>
      <div className="mb-3">
        {translate(
          'Use this description space to describe in depth the course, what the student will learn and other important details.',
        )}
      </div>

      <MarkdownInput controlName="description" attachId="1" />
    </TabWrapper>
  );
};

DescriptionTab.displayName = 'Description Tab Component';

DescriptionTab.propTypes = {};

export default DescriptionTab;

import i18n from 'i18n';

const experimentChartConfig = {
  chart: {
    id: 'experiments-bar-chart',
    stacked: true,
    zoom: {
      enabled: true,
      type: 'xy',
      autoScaleYaxis: true,
    },
    animation: {
      enabled: false,
    },
    defaultLocale: i18n.t('chart-lang'),
    locales: [
      {
        name: 'en',
        options: {
          months: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ],
          shortMonths: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ],
          days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
          shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          toolbar: {
            download: 'Download SVG',
            selection: 'Selection',
            selectionZoom: 'Selection Zoom',
            zoomIn: 'Zoom In',
            zoomOut: 'Zoom Out',
            pan: 'Panning',
            reset: 'Reset Zoom',
          },
        },
      },
      {
        name: 'pt-br',
        options: {
          months: [
            'Janeiro',
            'Fevereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro',
          ],
          shortMonths: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Out',
            'Nov',
            'Dez',
          ],
          days: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
          shortDays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
          toolbar: {
            download: 'Baixar SVG',
            selection: 'Seleção',
            selectionZoom: 'Seleção de Zoom',
            zoomIn: 'Aproximar',
            zoomOut: 'Afastar',
            pan: 'Mover',
            reset: 'Resetar Zoom',
          },
        },
      },
    ],
  },
  xaxis: {
    type: 'datetime',
    tickPlacement: 'on',
    categories: [],
  },
  yaxis: {
    title: {
      text: i18n.t('Quantity of positioned pages'),
      style: {
        color: '#ADB5BD',
        fontSize: '0.7rem',
        fontWeigth: '300',
      },
    },
  },
  title: {
    text: i18n.t('Experiment'),
    align: 'left',
    offsetX: 5,
  },
  legend: {
    position: 'top',
    horizontalAlign: 'left',
    offsetY: -15,
    markers: {
      fillColors: ['#808080', '#E8B65F', '#63C093'],
    },
  },
  colors: ['#808080', '#E8B65F', '#63C093'],
  plotOptions: {
    bar: {
      columnWidth: '10%',
    },
  },
};

export { experimentChartConfig };

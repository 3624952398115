import styled from 'styled-components';
import { Button } from 'reactstrap';

export const FileButton = styled(Button)`
  border-radius: 50%;
  padding: 0.25rem 0.5rem;
  color: #000;
  background-color: #f2f2f2;
  border: none;

  i {
    color: #000;
  }

  &:hover {
    background-color: ${(props) => (props.blue ? '#50A5F1' : '#F46A6A')};

    i {
      color: #fff;
    }
  }
`;

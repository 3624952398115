import React from 'react';
import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import { Container, Card, CardTitle, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import Breadcrumbs from '../../components/Breadcrumb';

const PageStructure = ({
  card,
  cardTitle,
  children,
  metaTitle,
  breadcrumbTitle,
  breadcrumbItem,
}) => {
  const { t: translate } = useTranslation();

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{`${translate(metaTitle)} | Ectools`}</title>
        </MetaTags>

        <Container fluid>
          {(breadcrumbItem || breadcrumbTitle) && (
            <Breadcrumbs
              title={translate(breadcrumbTitle)}
              breadcrumbItem={translate(breadcrumbItem)}
            />
          )}

          {card ? (
            <Card>
              <CardBody>
                {cardTitle && <CardTitle className="mb-4">{translate(cardTitle)}</CardTitle>}
                {children}
              </CardBody>
            </Card>
          ) : (
            <>{children}</>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

PageStructure.propTypes = {
  card: PropTypes.bool,
  cardTitle: PropTypes.string,
  children: PropTypes.node,
  metaTitle: PropTypes.string,
  breadcrumbTitle: PropTypes.string,
  breadcrumbItem: PropTypes.string,
};

export default PageStructure;

import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { InputGroup, Input, Button } from 'reactstrap';

import { SpinnerTopicWordSizeStyle } from './styles';

const SpinnerTopicWordSize = ({ value, setValue }) => {
  const inputRef = createRef();
  const MAX_VALUE = 500;

  const increaseValue = () => {
    const newValue = value + 100;
    setValue(newValue > MAX_VALUE ? MAX_VALUE : newValue);
  };

  const decreaseValue = () => {
    const newValue = value - 100;
    setValue(newValue < 0 ? 0 : newValue);
  };

  const handleInputChange = (e) => {
    let newValue = parseInt(e.target.value, 10);
    newValue = newValue > MAX_VALUE ? MAX_VALUE : newValue;
    setValue(newValue);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      inputRef.current.blur();
    }
  };

  return (
    <SpinnerTopicWordSizeStyle>
      <InputGroup>
        <Button onClick={decreaseValue}>-</Button>
        <Input
          className="input"
          type="number"
          value={value}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          innerRef={inputRef}
        />
        <Button onClick={increaseValue}>+</Button>
      </InputGroup>
    </SpinnerTopicWordSizeStyle>
  );
};

SpinnerTopicWordSize.propTypes = {
  topicIndex: PropTypes.number,
  topicSize: PropTypes.number,
  value: PropTypes.number,
  setValue: PropTypes.func,
};

export default SpinnerTopicWordSize;

import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Col, Row, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'hooks/useAuth';
import { getFirebaseBackend } from '../../helpers/firebaseHelper';
import PageStructure from '../../components/Page-Structure/';
import SuccessModal from '../../components/Success-Modal';
import LabelGroup from '../../components/Label-Group';
import TextInput from '../../components/Text-Input';
import SelectInput from '../../components/Select-Input';
import ModulesInput from 'components/Modules-Input';
import { loadTeam, getFormValue } from 'utils';

const formConfig = {
  defaultValues: {
    name: '',
    teachers: [],
    isExternal: false,
    modules: [
      {
        name: '',
        description: '',
        lessons: [{ id: undefined, freeTrial: false }],
      },
    ],
    description: '',
    clientFree: false,
    freeTrial: false,
    freeTrialDays: 1,
    showOtherUsers: false,
    availability: 'now',
    releaseDate: '',
    soon: false,
    certificateLock: false,
    certificateDays: 7,
    icon: null,
    status: null,
  },
  shouldFocusError: true,
  criteriaMode: 'all',
  mode: 'onSubmit',
  reValidateMode: 'onChange',
};

const CreateCourse = () => {
  const firebaseHelper = getFirebaseBackend();
  const { t: translate } = useTranslation();
  const form = useForm(formConfig);
  const { formState, watch, setValue, setError, clearErrors } = form;
  const { user } = useAuth();
  const accountId = user?.account;
  const [courseKey, setCourseKey] = useState(null);
  const [successModal, setSuccessModal] = useState(false);
  const [teachersKey, setTeachersKey] = useState(1000);

  const nextLessonKey = async (accountId) => {
    const newLessonKey = await firebaseHelper.newFirebaseKey('courses/', accountId);
    setCourseKey(newLessonKey);
  };

  const closeModalHandle = () => {
    form.reset();
    setSuccessModal(false);
  };

  const onSubmit = async (data) => {
    let moduleWithoutLesson = false;
    const processedModules = data.modules.map((module) => {
      const newLessons = module.lessons.filter((lesson) => lesson.id);
      const lessonEmpty = !newLessons.length;
      if (lessonEmpty) moduleWithoutLesson = true;
      return {
        ...module,
        lessons: lessonEmpty
          ? null
          : module.lessons?.map(({ id, freeTrial }) => {
              return { id: id.value, freeTrial };
            }),
      };
    });

    const course = {
      accountId,
      id: courseKey,
      name: data.name,
      teachers: data.teachers.map(getFormValue),
      modules: processedModules,
      description: data.description,
      clientFree: data.clientFree,
      freeTrial: data.freeTrial,
      freeTrialDays: data.freeTrial ? data.freeTrialDays : null,
      showOtherUsers: data.showOtherUsers,
      availability: data.availability,
      releaseDate: data.availability === 'date' ? new Date(data.releaseDate).valueOf() : null,
      soon: data.availability === 'date' ? data.soon : null,
      certificateLock: data.certificateLock,
      certificateDays: data.certificateLock ? data.certificateDays : null,
      status: data.status,
    };

    if (moduleWithoutLesson && course.status !== 'draft') {
      return setError('modules', {
        types: {
          validate:
            "You can only leave the lessons of a course empty if it's being saved as a draft! Make a change in any module name or description to remove this error.",
        },
      });
    }
    firebaseHelper.setFirebaseCourse(course).then(() => {
      if (data.icon) {
        firebaseHelper.updateCourseBanner(data.icon, accountId, courseKey);
      }
      nextLessonKey(accountId);
      setSuccessModal(true);
    });
  };

  useEffect(() => {
    nextLessonKey(accountId);
  }, []);

  useEffect(() => {
    setTeachersKey((prevState) => prevState + 1);
  }, [accountId]);

  return (
    <PageStructure
      metaTitle="Create Course"
      cardTitle="Create a new Course"
      card
      breadcrumbItem="Create Course"
      breadcrumbTitle="Courses"
    >
      <SuccessModal
        isOpen={successModal}
        onClose={closeModalHandle}
        verb="Created"
        buttonText="See Courses"
        buttonLink="/courses"
      />

      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <LabelGroup htmlFor="name" label="" size={12}>
            <TextInput
              controlName="name"
              placeholder="Course Name"
              label="Course Name"
              size={6}
              required
            />

            <SelectInput
              asyncronous
              isMulti
              controlName="teachers"
              label="Course Teachers"
              required
              key={teachersKey}
              size={6}
              cacheOptions
              defaultOptions
              loadOptions={(inputValue) => loadTeam(accountId, inputValue)}
            />
          </LabelGroup>

          <ModulesInput mb={4} />

          <Row className="gap-2">
            <Col lg="auto">
              <Button
                type="submit"
                style={{ backgroundColor: '#50A5F1', borderColor: '#50A5F1' }}
                disabled={Object.values(formState.errors).length}
                onClick={() => setValue('status', 'draft')}
              >
                {translate('Save as Draft')}
              </Button>
            </Col>

            {watch('availability') === 'date' ? (
              <Col lg="auto">
                <Button
                  type="submit"
                  style={{ backgroundColor: '#7367F0', borderColor: '#7367F0' }}
                  disabled={Object.values(formState.errors).length}
                  onClick={() => setValue('status', 'scheduled')}
                >
                  {translate('Schedule Course')}
                </Button>
              </Col>
            ) : (
              <Col lg="auto">
                <Button
                  type="submit"
                  style={{ backgroundColor: '#34C38F', borderColor: '#34C38F' }}
                  disabled={Object.values(formState.errors).length}
                  onClick={() => setValue('status', 'published')}
                >
                  {translate('Publish Course')}
                </Button>
              </Col>
            )}
          </Row>
        </form>
      </FormProvider>
    </PageStructure>
  );
};

CreateCourse.displayName = 'Create Course Page';

export default CreateCourse;

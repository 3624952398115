import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import axios from 'axios';
import {
  AccordionBody,
  AccordionHeader,
  Button,
  Collapse,
  FormGroup,
  Input,
  Label,
  UncontrolledAccordion,
} from 'reactstrap';
import { MoonLoader } from 'react-spinners';
import _ from 'lodash';

import ButtonRefresh from '../Button-Refresh';
import ButtonMove from '../Button-Move';
import ButtonDelete from '../Button-Delete';
import { DragDropTopicsStyle } from './styles';
import { TargetSize } from 'pages/guideline/styles';
import ButtonEdit from '../Button-Edit';
import ButtonSave from '../Button-Save';
import ModalAddNewTopic from '../Modal-Add-New-Topic';
import ButtonCancel from '../Button-Cancel';
import SpinnerTopicWordSize from '../Spinner-Topic-Word-Size';

const DEFAULT_SIZE_TOPIC = 100;

const DragDropTopics = ({ topicsList, setTopicsList, fetchData }) => {
  const firebaseHelper = getFirebaseBackend();
  const { id } = useParams();
  const { user } = useAuth();
  const currentAccountId = user?.account;
  const isProduction = process.env.REACT_APP_BUILD_TYPE === 'production';
  const { t: translate } = useTranslation();
  const [isLoadingNewTopic, setIsLoadingNewTopic] = useState(false);
  const [isLoadingRecreateStructure, setIsLoadingRecreateStructure] = useState(false);
  const [indexPromptOpen, setIndexPromptOpen] = useState(null);
  const [textTopic, setTextTopic] = useState('');
  const [sizeTopic, setSizeTopic] = useState(DEFAULT_SIZE_TOPIC);
  const [editTopicIndex, setEditTopicIndex] = useState(null);
  const [modal, setModal] = useState(false);

  const HEADING_OPTIONS = [
    {
      label: 'H2',
      value: 'h2',
      marginLeft: '28px',
    },
    {
      label: 'H3',
      value: 'h3',
      marginLeft: '56px',
    },
  ];

  const toggleNewCustomTopic = () => setModal(!modal);

  const handleEditTopic = (index, topic, size) => {
    size ? setSizeTopic(size) : setSizeTopic(DEFAULT_SIZE_TOPIC);
    setTextTopic(topic);
    setEditTopicIndex(index);
  };

  const handleCancelEditTopic = () => {
    setTextTopic('');
    setSizeTopic(DEFAULT_SIZE_TOPIC);
    setEditTopicIndex(null);
    setIndexPromptOpen(null);
  };

  const handleSaveTopic = async (topicIndex) => {
    try {
      await firebaseHelper.editGuidelineTopic(currentAccountId, id, topicIndex, textTopic);
      await firebaseHelper.changeSizeTopic(currentAccountId, id, topicIndex, sizeTopic);

      const updateTopicsList = [...topicsList];
      updateTopicsList[topicIndex].topic = textTopic;
      updateTopicsList[topicIndex].size = sizeTopic;
      setTopicsList(updateTopicsList);

      setEditTopicIndex(null);
      setTextTopic('');
      setSizeTopic(0);
      setIndexPromptOpen(null);
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const newTopicsListOrder = Array.from(topicsList);
    const [reorderedItem] = newTopicsListOrder.splice(result.source.index, 1);
    newTopicsListOrder.splice(result.destination.index, 0, reorderedItem);

    const filteredNewTopicsListOrder = newTopicsListOrder.filter((item) => item !== undefined);

    setTopicsList(filteredNewTopicsListOrder);

    const newOrder = newTopicsListOrder.map((item) => item);

    await firebaseHelper.updateStructureTopicsOrder(currentAccountId, id, newOrder);
  };

  const handleChangeHeading = async (topicId, heading) => {
    try {
      await firebaseHelper.changeHeadingTopic(currentAccountId, id, topicId, heading);
      fetchData();
    } catch {
      console.log('error');
    }
  };

  const regenerateStructuralTopic = async (topicIndex) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/regenerateStructuralTopic`,
        {
          accountId: currentAccountId,
          guidelineId: id,
          topicIndex,
          development: !isProduction,
        },
        {
          headers: {
            Authorization: `Bearer ${await firebaseHelper.getIdToken()}`,
          },
        },
      );
      const newTopic = response.data.subHeading;
      setTopicsList([...topicsList, newTopic]);
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteGuidelineTopic = async (topicIndex) => {
    try {
      await firebaseHelper.deleteGuidelineTopic(currentAccountId, id, topicIndex);

      const updateTopicsList = [...topicsList];
      updateTopicsList.splice(topicIndex, 1);
      const filteredUpdateTopicsList = updateTopicsList.filter((item) => item !== undefined);

      setTopicsList(filteredUpdateTopicsList);
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  const savePromptToFirebase = _.debounce(async (topicIndex, prompt) => {
    try {
      await firebaseHelper.changePromptTopic(currentAccountId, id, topicIndex, prompt);
    } catch (error) {
      console.log(error);
    }
  }, 1500);

  const generateNewTopic = async () => {
    setIsLoadingNewTopic(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/generateNewStructuralTopic`,
        {
          accountId: currentAccountId,
          guidelineId: id,
          development: !isProduction,
        },
        {
          headers: {
            Authorization: `Bearer ${await firebaseHelper.getIdToken()}`,
          },
        },
      );
      const newTopic = response.data.subHeading;

      setTimeout(() => {
        setTopicsList([...topicsList, newTopic]);
        setIsLoadingNewTopic(false);
        fetchData();
      }, 1000);
    } catch (error) {
      console.log(error);
      setIsLoadingNewTopic(false);
    }
  };

  const recreateStructure = async () => {
    setIsLoadingRecreateStructure(true);

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/regenerateStructure`,
        {
          accountId: currentAccountId,
          guidelineId: id,
          development: !isProduction,
        },
        {
          headers: {
            Authorization: `Bearer ${await firebaseHelper.getIdToken()}`,
          },
        },
      );

      setIsLoadingRecreateStructure(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DragDropTopicsStyle>
      {topicsList && (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="contentList">
            {(provided) => {
              return (
                <ul
                  className="list-group contentList"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {topicsList?.map((item, index) => (
                    <Draggable key={item?.topic} draggableId={item?.topic} index={index}>
                      {(provided) => {
                        const headingValue = item?.heading.toLowerCase();
                        const marginLeftOption = HEADING_OPTIONS.find(
                          (h) => h.value === headingValue,
                        );
                        const marginLeft = marginLeftOption ? marginLeftOption.marginLeft : '0';
                        let promptValue = item?.prompt;

                        return (
                          <li
                            className="list-group-item"
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <div
                              className={`${editTopicIndex === index && 'bg-light-gray'} flex-column`}
                              style={{
                                marginLeft: marginLeft,
                              }}
                            >
                              <div className="flex-line">
                                <div className="container-btns">
                                  <ButtonMove />
                                  <FormGroup className="form-group-heading">
                                    <Input
                                      id="heading-select"
                                      name="select"
                                      type="select"
                                      bsSize="sm"
                                      defaultValue={item?.heading.toLowerCase()}
                                      onChange={(e) => handleChangeHeading(index, e.target.value)}
                                    >
                                      <option value="">-</option>
                                      {HEADING_OPTIONS.map((option) => (
                                        <option key={option.value} value={option.value}>
                                          {option.label}
                                        </option>
                                      ))}
                                    </Input>
                                  </FormGroup>
                                  {editTopicIndex === index ? (
                                    <Input
                                      type="text"
                                      className="form-topic"
                                      name={`topic-${index}`}
                                      value={textTopic}
                                      bsSize="md"
                                      style={
                                        item.heading === 'h2'
                                          ? { width: '25.5rem' }
                                          : { width: '24rem' }
                                      }
                                      onChange={(e) => {
                                        setTextTopic(e.target.value);
                                      }}
                                      autoFocus
                                    />
                                  ) : (
                                    <span className="me-2">{item?.topic}</span>
                                  )}
                                </div>
                                {editTopicIndex === index ? (
                                  <SpinnerTopicWordSize value={sizeTopic} setValue={setSizeTopic} />
                                ) : (
                                  <div className="d-flex align-items-center gap-1">
                                    <TargetSize>
                                      {item?.size ? item.size : 100} {translate('words')}
                                    </TargetSize>
                                    <div className="container-btns">
                                      <ButtonEdit
                                        onClickFunction={() => {
                                          handleEditTopic(index, item.topic, item.size);
                                          setIndexPromptOpen((prevState) =>
                                            prevState !== index ? index : null,
                                          );
                                        }}
                                      />
                                      <ButtonRefresh
                                        onClickFunction={() => regenerateStructuralTopic(index)}
                                        index={index}
                                      />
                                      <ButtonDelete
                                        deleteGuidelineTopic={deleteGuidelineTopic}
                                        index={index}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                              <Collapse isOpen={indexPromptOpen === index}>
                                <FormGroup className="form-group-prompt">
                                  <UncontrolledAccordion>
                                    <AccordionHeader targetId="1">
                                      <Label for="prompt">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="12"
                                          height="12"
                                          viewBox="0 0 12 12"
                                          fill="none"
                                        >
                                          <path
                                            d="M6.89035 2L5.11035 10"
                                            stroke="#4A5568"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M9 4L11 6L9 8"
                                            stroke="#4A5568"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M3 8L1 6L3 4"
                                            stroke="#4A5568"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                        {translate('Prompt assistent')}
                                      </Label>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="1">
                                      <Input
                                        type="textarea"
                                        name="prompt"
                                        id="prompt"
                                        defaultValue={promptValue}
                                        onChange={(e) => {
                                          promptValue = e.target.value;
                                          savePromptToFirebase(index, promptValue);
                                        }}
                                      />
                                    </AccordionBody>
                                  </UncontrolledAccordion>
                                  <div className="d-flex justify-content-end gap-1 collapse-footer">
                                    <ButtonCancel onClickFunction={handleCancelEditTopic} />
                                    <ButtonSave onClickFunction={() => handleSaveTopic(index)} />
                                  </div>
                                </FormGroup>
                              </Collapse>
                            </div>
                          </li>
                        );
                      }}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              );
            }}
          </Droppable>
        </DragDropContext>
      )}
      <div className="container-btns-bottom">
        <Button className="btn-new-topic" onClick={toggleNewCustomTopic}>
          {translate('Custom topic')}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              d="M6.41675 6.41406V2.91406H7.58341V6.41406H11.0834V7.58073H7.58341V11.0807H6.41675V7.58073H2.91675V6.41406H6.41675Z"
              fill="white"
            />
          </svg>
        </Button>
        <Button className="btn-transparent" onClick={generateNewTopic}>
          {isLoadingNewTopic ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px',
              }}
            >
              {translate('Loading')}
              <MoonLoader size={16} color="#556ee6" />
            </div>
          ) : (
            translate('New topic by AI')
          )}
        </Button>
        <Button className="btn-transparent" onClick={recreateStructure}>
          {isLoadingRecreateStructure ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px',
              }}
            >
              {translate('Loading')}
              <MoonLoader size={16} color="white" />
            </div>
          ) : (
            translate('Recreate structure')
          )}
        </Button>
      </div>
      <ModalAddNewTopic
        modal={modal}
        toggle={toggleNewCustomTopic}
        topicsList={topicsList}
        setTopicsList={setTopicsList}
        fetchData={fetchData}
      />
    </DragDropTopicsStyle>
  );
};

DragDropTopics.propTypes = {
  topicsList: PropTypes.array,
  setTopicsList: PropTypes.func,
  fetchData: PropTypes.func,
};

export default DragDropTopics;

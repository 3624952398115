import React, { useState, useEffect } from 'react';
import MDEditor from '@uiw/react-md-editor';
import download from 'downloadjs';
import rehypeSanitize from 'rehype-sanitize';
import { Card, CardBody, Col, Row, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getDatabase, ref, onValue, off } from 'firebase/database';
import 'boxicons';

import { useAuth } from 'hooks/useAuth';
import { getFirebaseBackend } from 'helpers/firebaseHelper';
import Shimmer from '../../components/Shimmer';
import EmbeddedVideo from '../../components/Embedded-Video';
import ErrorModal from '../../components/Error-Modal';
import PageStructure from '../../components/Page-Structure/';
import FileTable from '../../components/File-Table';

const Lesson = () => {
  const firebaseHelper = getFirebaseBackend();
  const { t: translate } = useTranslation();
  const { user, changeAccount } = useAuth();
  const userId = user.id;
  const accountId = user.account;
  const { adminStatus } = user;
  const url = new URL(window.location.href);
  const lessonParam = url.searchParams.get('id');
  const accountParam = url.searchParams.get('acc');
  const [lessonData, setLessonData] = useState();
  const [files, setFiles] = useState(null);
  const [menuOpen, setMenuOpen] = useState(true);
  const [loading, setLoading] = useState(true);
  const [errorModal, setErrorModal] = useState(false);

  const toggleMenuOpen = (event) => {
    event.preventDefault();

    setMenuOpen((prevState) => !prevState);
  };

  const deleteFile = (file, index) => {
    firebaseHelper.removeFile(file.fullPath).then(async () => {
      const newFiles = _.cloneDeep(files);
      newFiles.splice(index, 1);
      setFiles(newFiles);
      if (!newFiles.length) {
        const taskPath = {
          clientId: accountId,
          projectId: '1',
          id: taskId,
          taskId,
        };
      }
    });
  };

  const downloadFile = async (file) => {
    const fileObj = await firebaseHelper.getFileObj(file.fullPath);
    download(fileObj, file.name, file.contentType);
  };

  const fetchFiles = async () => {
    const files = await firebaseHelper.listAllFiles(`${accountId}/${lessonParam}`);
    setFiles(files);
  };

  const processLesson = async (data) => {
    let questions, relatedLessons;
    const courseObj = await firebaseHelper.getFirebaseCourse(accountId, data.course);
    const course = courseObj.name;

    const teachersPromise = _.map(data.teachers, async (userId) => {
      const teacherName = await firebaseHelper.userName(userId);
      return teacherName;
    });
    const teachers = await Promise.all(teachersPromise);

    if (data?.questions) {
      const questionsPromise = _.map(data.questions, async (questionId) => {
        const question = await firebaseHelper.getFirebaseQuestion(accountId, questionId);
        return question.answer;
      });
      questions = await Promise.all(questionsPromise);
    }

    if (data?.relatedLessons) {
      const lessonsPromise = _.map(data.relatedLessons, async (lessonId) => {
        const lesson = await firebaseHelper.getFirebaseLessons(accountId, lessonId);
        return lesson.name;
      });
      relatedLessons = await Promise.all(lessonsPromise);
    }

    return { ...data, course, teachers, questions, relatedLessons };
  };

  useEffect(() => {
    if (!accountId) return;

    let allowChangeAccount = false;
    if (accountParam && accountId !== accountParam && adminStatus === 'Super Admin') {
      changeAccount(userId, accountParam);
      allowChangeAccount = true;
    }

    const db = getDatabase();
    const lessonRef = ref(
      db,
      `lessons/${allowChangeAccount ? accountParam : accountId}/${lessonParam}`,
    );

    const handleValueChange = async (snapshot) => {
      const data = snapshot.val();
      if (!data) {
        setErrorModal(true);
        return;
      }
      const processedData = await processLesson(data);
      setLessonData(processedData);
      setLoading(false);
    };

    onValue(lessonRef, handleValueChange);

    return () => {
      off(lessonRef, 'value', handleValueChange);
    };
  }, [accountId, accountParam, lessonParam]);

  useEffect(() => {
    if (!accountId || !accountParam || !lessonParam) return;

    fetchFiles();
  }, [accountId, accountParam, lessonParam]);

  return (
    <PageStructure
      metaTitle="Lesson"
      cardTitle="Lesson"
      breadcrumbItem="Lesson"
      breadcrumbTitle="Courses"
    >
      <ErrorModal isOpen={errorModal} />

      <Row>
        <Col lg={menuOpen ? 9 : 11}>
          <Card>
            <ErrorModal isOpen={errorModal} />

            <CardBody style={{ padding: '2em' }}>
              <section className="meeting-header">
                {adminStatus === 'Super Admin' ||
                  (adminStatus === 'Admin' && (
                    <Link
                      to={`/edit-meeting?id=${lessonData?.id}`}
                      className="d-flex justify-content-center align-items-center mt-2"
                      style={{
                        position: 'absolute',
                        right: '5%',
                        border: '0',
                        backgroundColor: 'transparent',
                        padding: '0',
                        gap: '.3em',
                      }}
                    >
                      <box-icon
                        type="regular"
                        name="edit-alt"
                        size="1.5rem"
                        color="#7367F0"
                      ></box-icon>
                      <span
                        style={{
                          fontSize: '1rem',
                          color: '#7367F0',
                          fontWeight: '500',
                        }}
                      >
                        {translate('Edit')}
                      </span>
                    </Link>
                  ))}
                {loading ? (
                  <Shimmer width="30%" style={{ marginBottom: '.5em' }}></Shimmer>
                ) : (
                  <h4 className="mtheader-title">{lessonData?.name}</h4>
                )}
                <div className="mtheader-info">
                  {loading ? (
                    <Shimmer width="15%" style={{ marginBottom: '.5em' }}></Shimmer>
                  ) : (
                    <div>
                      <box-icon type="regular" name="time" size="1.2rem" color="#484848"></box-icon>
                      <span>
                        {`${lessonData?.durationMins} ${translate('minutes of duration')}`}
                      </span>
                    </div>
                  )}
                </div>
                <div className="mtheader-participants">
                  {loading ? (
                    <Shimmer width="25%" style={{ marginBottom: '.5em' }}></Shimmer>
                  ) : (
                    <>
                      <box-icon
                        type="regular"
                        name="user-voice"
                        size="1.2rem"
                        color="#484848"
                      ></box-icon>
                      <span>
                        {_.map(lessonData?.teachers, (participant, index) => {
                          let item = participant;
                          const lastItem = lessonData?.teachers?.length - 1;

                          if (index !== lastItem) item += ', ';

                          return <span key={participant}>{item}</span>;
                        })}
                      </span>
                    </>
                  )}
                </div>
              </section>

              <hr />

              {loading ? (
                <Shimmer width="100%" height="10em" style={{ marginBottom: '.5em' }}></Shimmer>
              ) : (
                <section className="meeting-description">
                  {lessonData?.videoLink && <EmbeddedVideo link={lessonData.videoLink} />}

                  <MDEditor.Markdown
                    source={lessonData?.description}
                    rehypePlugins={[[rehypeSanitize]]}
                    linkTarget="_blank"
                    style={{ marginTop: '1em' }}
                  />

                  {files && <FileTable title="Lesson Files" data={files} setData={setFiles} />}
                </section>
              )}
            </CardBody>
          </Card>

          {/* <ShowComponent condition={lessonData?.allowQuestions}>
            <LessonQnA>
              <section>
                <span>{translate('Questions and Answers')}</span>
                <textarea placeholder={translate('Ask here your question...')} />
                <button>{translate('Send')}</button>
              </section>

              <section>
                <div>{translate('Answered Questions')}</div>
              </section>
            </LessonQnA>
          </ShowComponent> */}
        </Col>

        {/* <ShowComponent condition={menuOpen}>
          <Col lg={3}>
            <Card>
              <CardBody>
                <div className="d-flex justify-content-between">
                  <span style={{ fontSize: '1rem' }}>{translate('Course Lessons')}</span>
                  <ExpandMenu onClick={toggleMenuOpen}>
                    <box-icon name="chevron-right-square" color="#484848" size="1.5rem" />
                  </ExpandMenu>
                </div>

                <CourseMeter min="0" max="100" value="46" title="Course Completion Percentage"></CourseMeter>

                <LessonMenu>
                  <button className="previous">
                    <box-icon name="chevrons-left" color="#fff" size="1.2rem" />
                    {translate('Previous Lesson')}
                  </button>
                  <button className="next">
                    {translate('Next Lesson')}
                    <box-icon name="chevrons-right" color="#fff" size="1.2rem" />
                  </button>
                </LessonMenu>
              </CardBody>
            </Card>
          </Col>
        </ShowComponent>

        <ShowComponent condition={!menuOpen}>
          <Col lg={1}>
            <Card>
              <CardBody className="d-flex justify-content-center">
                <ExpandMenu onClick={toggleMenuOpen}>
                  <box-icon name="chevron-left-square" color="#C1C1C1" size="1.8rem" />
                  <div>{translate('See Course Lessons')}</div>
                </ExpandMenu>
              </CardBody>
            </Card>

            <LessonMenu column>
              <button className="previous">
                <box-icon name="chevrons-left" color="#fff" size="1.7rem" />
              </button>
              <button className="next">
                <box-icon name="chevrons-right" color="#fff" size="1.7rem" />
              </button>
            </LessonMenu>
          </Col>
        </ShowComponent> */}
      </Row>
    </PageStructure>
  );
};

Lesson.displayName = 'Lesson Page';

export default Lesson;

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { UncontrolledAccordion, Card } from 'reactstrap';
import ReactPaginate from 'react-paginate';

export const CoursePage = styled.div`
  height: calc(100vh - 84px - 94px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow-y: auto;

  .courses-grid {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

export const CourseGridItem = styled.div`
  background-color: white;
  border-radius: 8px;

  .course-image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #556ee6;
    border-radius: 8px 8px 0 0;
    aspect-ratio: 16/9;

    .banner-image.has-image {
      display: block;
      width: 100%;
      aspect-ratio: 16/9;
      border-radius: 8px 8px 0 0;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }

    .banner-image {
      display: none;
    }

    .course-edit-btn {
      position: absolute;
      display: flex;
      opacity: 0%;
      top: 0.5em;
      right: 0.5em;
      justify-content: center;
      align-items: center;
      height: 2.5rem;
      width: 2.5rem;
      aspect-ratio: 1/1;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 0 1px 2px black;
      transition:
        opacity 0.3s ease-in,
        transform 0.3s ease-in-out;

      a {
        display: inherit;
        justify-content: inherit;
        align-items: inherit;
        height: 100%;
        width: 100%;
        padding: 0;
      }

      &:hover {
        transform: scale(1.1);
      }
    }

    .course-progress {
      position: absolute;
      display: flex;
      bottom: 0;
      right: 0;
      justify-content: center;
      align-items: center;
      padding: 0.2em 0.7em;
      border-top-left-radius: 1em;
      background-color: #d9d9d9e5;
      color: #000;
    }

    &:hover {
      div {
        opacity: 100%;
      }
    }
  }

  .course-info {
    display: flex;
    flex-direction: column;
    padding: 1em;

    .course-title {
      width: 100%;
      font-size: 1rem;
      font-weight: 600;
      overflow: hidden;
      word-wrap: break-word;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    .course-teachers {
      /* height: 1.2rem; */
      display: flex;
      gap: 0.5rem;

      div {
        color: #808080;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    .course-description {
      height: 3.25rem;
      font-size: 0.75rem;
      color: #74788d;
      overflow: hidden;
      text-overflow: ellipsis;
      overflow-wrap: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .course-interact {
      display: flex;
      justify-content: space-between;
      align-content: center;
      margin-top: 0.5rem;

      span {
        font-size: 1.5rem;
        color: #50a5f1;
      }

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5em;
        color: #50a5f1;
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        background-color: #50a5f1;
        border-color: #50a5f1;
        font-size: 0.9rem;
      }
    }
  }
`;

export const Pagination = styled(ReactPaginate)`
  display: flex;
  gap: 1em;
  padding: 0;
  list-style: none;

  li {
    a {
      height: 1.5rem;
      width: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #747892;
      background-color: #fff;
      border-radius: 9999px;
      cursor: pointer;
      user-select: none;
    }

    &.disabled {
      a {
        color: #ced4da;
      }
    }

    &.active {
      a {
        background-color: #556ee6;
        color: #fff;
      }

      a:hover {
        background-color: #ced4da;
      }
    }
  }

  li:not(.next):not(.previous):not(.active) {
    a:hover {
      background-color: #ced4da;
      color: #fff;
    }
  }
`;

export const ModulesAccordion = styled(UncontrolledAccordion)`
  .module-accordion-header {
    > button {
      > span:nth-child(2) {
        margin-left: auto;
        margin-right: 1em;
      }

      &::after {
        margin: 0;
      }
    }
  }

  .module-accordion-body {
    .description {
      margin-bottom: 1em;
    }

    .lessons {
      > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1em;
        color: #484848;

        .lock,
        .no-lock {
          margin-left: auto;
        }

        .minutes,
        .lock {
          display: flex;
          justify-content: flex-end;
          width: 5em;
          color: #adb5bd;
        }
      }

      > hr {
        margin: 1em 0;
      }
    }
  }
`;

export const LessonQnA = styled(Card)`
  > section:nth-child(1) {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    background-color: #efefef;
    padding: 2em;
    border-radius: 0.25rem 0.25rem 0 0;

    > span {
      color: #74788d;
      font-size: 1.1rem;
      font-weight: 600;
    }

    > textarea {
      width: 100%;
      height: 5rem;
      padding: 0.5rem 0.7rem;
      border: 2px solid #ced4da;
      color: #74788d;
      border-radius: 4px;
      resize: none;
    }

    > button {
      align-self: flex-end;
      padding: 0.3rem 1rem;
      color: #fff;
      background-color: #50a5f1;
      border: 0;
      border-radius: 1rem;

      &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }
    }
  }

  > section:nth-child(2) {
    padding: 1.5em;
    color: #74788d;
    font-size: 1rem;
    font-weight: 600;
  }
`;

export const CommentSection = styled.section`
  h4 {
    color: #74788d;
    font-size: 1.2em;
    margin-bottom: 1em;
  }

  .course-comment {
    font-size: 0.8em;

    > div {
      display: flex;
      gap: 1em;
    }

    .course-comment-box {
      display: flex;
      flex-direction: column;
      font-weight: 500;
      gap: 0.2em;

      .course-comment-name {
        color: #495057;
      }

      .course-comment-content {
        color: #74788d;
      }
    }
  }
`;

export const LessonUl = styled.ul`
  list-style: none;
  padding: 0;

  li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5em;
    font-size: 1.2em;

    button {
      background-color: transparent;
      color: #50a5f1;
      border: 0;
      padding: 0;
      margin: 0;

      &.lesson-unavailable {
        color: #c1c1c1;
      }

      &.lesson-current {
        color: #7367f0;
      }

      &.lesson-complete {
        color: #6a7187;
      }
    }
  }
`;

import { memoize, debounce, property, wrap } from 'lodash';

import defaultUserPhoto from '../../assets/images/users/default-user-photo.jpg';
import { getFirebaseBackend } from '../../helpers/firebaseHelper';

const setPhoto = async ({ userId, elementSearch, reload = false, restart = false }) => {
  const firebaseHelper = getFirebaseBackend();

  const elems = document.querySelectorAll(elementSearch);
  let allSet = true;
  elems.forEach((elem) => {
    if (!elem.style.backgroundImage) allSet = false;
  });
  if (allSet && !reload) return;

  if (restart) {
    const elems = document.querySelectorAll(elementSearch);
    elems.forEach((elem) => {
      if (elem.style.backgroundImage) elem.style.backgroundImage = null;
    });
  }

  const path = `${userId}/profilePic`;
  const profilePicMeta = await firebaseHelper.listAllFiles(path);
  if (profilePicMeta.length) {
    const profilePicFile = await firebaseHelper.getFileObj(`${path}/${profilePicMeta[0].name}`);
    const profilePicUrl = URL.createObjectURL(profilePicFile);
    const elems = document.querySelectorAll(elementSearch);
    if (elems) {
      elems.forEach((elem) => {
        // Apply the background image to all the same user's photos that aren't set already
        if (elem.style.backgroundImage && !reload) return;
        elem.style.backgroundImage = `url('${profilePicUrl}')`;
      });
    }
  } else {
    const elems = document.querySelectorAll(elementSearch);
    if (elems) {
      elems.forEach((elem) => {
        if (elem.style.backgroundImage && !reload) return;
        elem.style.backgroundImage = `url('${defaultUserPhoto}')`;
      });
    }
  }
};

// Uses debounce to avoid multiple photo fetch from the same user and memoization to return the result faster if needed
const setPFPDebounced = wrap(
  memoize(() => debounce(setPhoto, 1000), property('userId')),
  (getMemoizedFunc, obj) => getMemoizedFunc(obj)(obj),
);

export { setPFPDebounced };

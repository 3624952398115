import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Badge, Button, Input, Progress } from 'reactstrap';
import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { useParams } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import axios from 'axios';

import ButtonSendComment from '../Button-Send-Comment';
import CardComment from '../Card-Comment';
import { ApprovalChatStyle } from './styles';

const ApprovalChat = ({ setComplete = false }) => {
  const firebaseHelper = getFirebaseBackend();
  const { id } = useParams();
  const { user } = useAuth();
  const currentAccountId = user?.account;
  const { t: translate } = useTranslation();
  const [data, setData] = useState([]);
  const [isCreateTaskClicked, setIsCreateTaskClicked] = useState(false);
  const [isCreateCommentClicked, setIsCreateCommentClicked] = useState(false);
  const [newTask, setNewTask] = useState('');
  const [newComment, setNewComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleToggleCreateTask = () => {
    setIsCreateTaskClicked((prevState) => !prevState);
    if (isCreateCommentClicked) {
      setIsCreateCommentClicked(false);
    }
  };

  const handleToggleCreateComment = () => {
    setIsCreateCommentClicked((prevState) => !prevState);
    if (isCreateTaskClicked) {
      setIsCreateTaskClicked(false);
    }
  };

  const onSubmitCreateTask = async (task) => {
    try {
      setIsLoading(true);
      const isProduction = process.env.REACT_APP_BUILD_TYPE === 'production';
      await axios.post(
        `${process.env.REACT_APP_API_URL}/commentReview`,
        {
          accountId: currentAccountId,
          guidelineId: id,
          content: task,
          type: 'task',
          development: !isProduction,
        },
        {
          headers: {
            Authorization: `Bearer ${await firebaseHelper.getIdToken()}`,
          },
        },
      );

      setComplete && setComplete(false);

      setNewTask('');
      setIsCreateTaskClicked(false);
      setData((prevData) => [
        {
          by: user.id,
          content: task,
          createdAt: new Date().getTime(),
          done: false,
          name: user.name,
          type: 'task',
        },
        ...prevData,
      ]);

      setTimeout(() => {
        setIsLoading(false);
        fetchData();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmitCreateComment = async (comment) => {
    try {
      setIsLoading(true);
      const isProduction = process.env.REACT_APP_BUILD_TYPE === 'production';
      await axios.post(
        `${process.env.REACT_APP_API_URL}/commentReview`,
        {
          accountId: currentAccountId,
          guidelineId: id,
          content: comment,
          type: 'comment',
          development: !isProduction,
        },
        {
          headers: {
            Authorization: `Bearer ${await firebaseHelper.getIdToken()}`,
          },
        },
      );

      setNewComment('');
      setIsCreateCommentClicked(false);
      setData((prevData) => [
        {
          by: user.id,
          content: comment,
          createdAt: new Date().getTime(),
          name: user.name,
          type: 'comment',
        },
        ...prevData,
      ]);

      setTimeout(() => {
        setIsLoading(false);
        fetchData();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await firebaseHelper.getCommentsGuideline(currentAccountId, id);
      const dataArray = response
        ? Object.entries(response).map(([key, value]) => ({ id: key, ...value }))
        : [];

      const dataWithNames = await Promise.all(
        dataArray.map(async (item) => {
          const name = await firebaseHelper.getUserName(item.by);
          return { ...item, name };
        }),
      );

      const sortedData = dataWithNames.sort((a, b) => {
        if (a.done === b.done) {
          return b.createdAt - a.createdAt;
        } else {
          return a.done ? 1 : -1;
        }
      });

      setData(sortedData);
      checkTasksCompleted(sortedData);
    } catch (error) {
      console.log(error);
    }
  };

  const checkTasksCompleted = (data) => {
    if (setComplete === undefined) return;

    const allTasksCompleted = data
      .filter((item) => item.type === 'task')
      .every((task) => task.done);

    if (allTasksCompleted) {
      setComplete(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentAccountId]);

  return (
    <ApprovalChatStyle
      isCreateTaskClicked={isCreateTaskClicked}
      isCreateCommentClicked={isCreateCommentClicked}
    >
      <p className="subtitle">{translate('Requests for review')}</p>
      <div className="d-flex flex-column gap-3" style={{ padding: '16px' }}>
        <div className="header-approval-chat">
          <p>
            {data.filter((item) => item.type === 'task' && item.done === false).length === 0 ? (
              translate('No reviews pending')
            ) : (
              <>
                {data.filter((item) => item.type === 'task' && item.done === false).length}{' '}
                {translate('pending reviews')}
              </>
            )}
          </p>
          {data.filter((item) => item.type === 'task' && item.done === false).length !== 0 && (
            <Badge pill>{data.filter((item) => item.type === 'task').length}</Badge>
          )}
        </div>
        <Progress
          color="primary"
          value={
            (data.filter((item) => item.type === 'task' && item.done).length /
              data.filter((item) => item.type === 'task').length) *
            100
          }
        />
      </div>
      <div className="divider" />
      {(!data || data.length === 0) && (
        <div className="container-column-no-messages">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="45"
            viewBox="0 0 44 45"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M25.1373 16.5133L23.4037 14.7797C23.0645 14.4406 22.6045 14.25 22.1248 14.25C21.6451 14.25 21.1851 14.4406 20.8459 14.7797L15.0858 20.5399C14.8176 20.808 14.667 21.1717 14.667 21.5509V24.7917C14.667 25.0449 14.8722 25.2501 15.1253 25.2501H18.3655C18.7451 25.2501 19.1091 25.0993 19.3775 24.8309L25.1373 19.0711C25.4765 18.7319 25.667 18.2719 25.667 17.7922C25.667 17.3125 25.4765 16.8525 25.1373 16.5133V16.5133Z"
              stroke="#CBD5E0"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M29.3337 30.7526H14.667"
              stroke="#CBD5E0"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <rect
              x="5.5"
              y="6"
              width="33"
              height="33"
              rx="16.5"
              stroke="#CBD5E0"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>{translate('No messages found')}</span>
        </div>
      )}
      {data && data.length > 0 && (
        <div className="container-column-messages">
          {data.map((item, index) => (
            <CardComment key={index} comment={item} fetchData={fetchData} />
          ))}
        </div>
      )}
      <div className="divider" />
      <div className="d-flex justify-content-between" style={{ padding: '16px' }}>
        <Button
          color="secondary"
          className="btn-create btn-create-task"
          onClick={handleToggleCreateTask}
        >
          {translate('Create task')}
        </Button>
        <Button
          color="secondary"
          className="btn-create btn-create-comment"
          onClick={handleToggleCreateComment}
        >
          {translate('Create comment')}
        </Button>
      </div>
      {isCreateTaskClicked && (
        <div className="d-flex flex-column gap-3 align-items-end px-3 pb-3">
          <Input
            type="textarea"
            name="task"
            placeholder={translate('Describe the task')}
            style={{ height: '125px' }}
            value={newTask}
            onChange={(e) => {
              e.preventDefault();
              setNewTask(e.target.value);
            }}
          />
          <ButtonSendComment
            onClickFunction={(e) => {
              e.preventDefault();
              onSubmitCreateTask(newTask);
            }}
            isLoading={isLoading}
          />
        </div>
      )}
      {isCreateCommentClicked && (
        <div className="d-flex flex-column gap-3 align-items-end px-3 pb-3">
          <Input
            type="textarea"
            name="comment"
            placeholder={translate('Write a comment')}
            style={{ height: '125px' }}
            value={newComment}
            onChange={(e) => {
              e.preventDefault();
              setNewComment(e.target.value);
            }}
          />
          <ButtonSendComment
            onClickFunction={() => {
              onSubmitCreateComment(newComment);
            }}
            isLoading={isLoading}
          />
        </div>
      )}
    </ApprovalChatStyle>
  );
};

ApprovalChat.propTypes = {
  setComplete: PropTypes.func,
};

export default ApprovalChat;

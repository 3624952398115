import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, UncontrolledAccordion, AccordionItem, AccordionHeader } from 'reactstrap';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { getDatabase, ref, onValue, off } from 'firebase/database';

import { useAuth } from 'hooks/useAuth';
import { getFirebaseBackend } from '../../helpers/firebaseHelper';
import ModalContentContext from 'components/Modal-Content/ModalContent.Context';
import LabelGroup from '../../components/Label-Group';
import TextInput from '../../components/Text-Input';
import DateInput from '../../components/Date-Input';
import SelectInput from '../../components/Select-Input';
import SwitchInput from '../../components/Switch-Input';
import SliderInput from '../../components/Slider-Input';
import TimeInput from '../../components/Time-Input';
import RadioInput from '../../components/Radio-Input';
import TextAreaInput from '../../components/TextArea-Input';
import ShowComponent from '../../components/Show-Component';
import DisplayErrors from '../../components/Display-Errors';
import {
  getFormValue,
  getEpochDate,
  formatKeywords,
  countKeywords,
  formatBatchName,
  normalizeString,
  sortByLabel,
} from '../../utils';
import {
  ExperimentContainer,
  ExperimentForm,
  FormRow,
  SourceConfig,
  CustomAccordionBody,
  ButtonsRow,
  CreditsRow,
  ErrorBox,
} from './styles';

import {
  serpGoogleDomains,
  serpCountries,
  serpLangs,
  serpSearchTypes,
  selectWeekDays,
  numToWeek,
  selectMonthDays,
  experimentAPIs,
  domainToCountry,
  langToCountry,
  codeToCountry,
} from '../../constants/index';

const ExperimentConfig = () => {
  const formConfig = {
    defaultValues: {
      name: '',
      dateBegin: '',
      dateEnd: '',
      deviceType: {
        desktop: true,
        mobile: false,
      },
      dataSource: [],
      serpDomain: '',
      serpGoogleDomain: [],
      serpCountry: [],
      serpLang: [],
      serpResultQuant: 10,
      serpResultType: [],
      serpKeywords: '',
      serpFrequency: '',
      serpMonthDays: [],
      serpWeekDays: [],
      serpHour: '',
    },
    shouldFocusError: true,
    criteriaMode: 'all',
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  };

  const firebaseHelper = getFirebaseBackend();

  const { t: translate } = useTranslation();
  const form = useForm(formConfig);
  const { user } = useAuth();
  const accountId = user.account;
  const { content } = useContext(ModalContentContext);
  const frequencyRef = useRef();
  const dataSourceRef = useRef();
  const configSourceRef = useRef();
  const [accountCredits, setAccountCredits] = useState(0);
  const [limitCredits, setLimitCredits] = useState(0);
  const [creditsToBeConsumed, setCreditsToBeConsumed] = useState(0);
  const [creditsFactors, setCreditFactors] = useState({
    typesNum: 1,
    searches: 1,
    timesThisMonth: 1,
  });
  const [hasExperiment, setHasExperiment] = useState(false);
  const [textLines, setTextLines] = useState('1/50');
  const [loading, setLoading] = useState(true);

  const { formState } = form;
  frequencyRef.current = form.watch('serpFrequency');
  dataSourceRef.current = form.watch('dataSource').map(({ value }) => {
    return value;
  });

  useEffect(() => {
    if (!accountId) return;

    const db = getDatabase();
    const creditRef = ref(db, `accounts/${accountId}/shownCredits`);
    const limitRef = ref(db, `accounts/${accountId}/limits/credits`);

    const handleCreditValueChange = (snapshot) => {
      const data = snapshot.val();
      if (data) setAccountCredits(data);
    };

    const handleLimitValueChange = (snapshot) => {
      const data = snapshot.val();
      if (data) setLimitCredits(data);
    };

    onValue(creditRef, handleCreditValueChange);
    onValue(limitRef, handleLimitValueChange);

    return () => {
      off(creditRef, 'value', handleCreditValueChange);
      off(limitRef, 'value', handleLimitValueChange);
    };
  }, [accountId]);

  useEffect(async () => {
    if (!accountId) return;

    const response = await firebaseHelper.getFirebaseExperiment(accountId, content.id);
    if (response) {
      form.reset({
        name: response.name,
        dateBegin: new Date(response.dateBegin),
        dateEnd: new Date(response.dateEnd),
        deviceType: response.deviceType,
        dataSource: response.dataSource.map((source) => {
          return { value: source, label: experimentAPIs[source] };
        }),
        serpDomain: response.serp.domain,
        serpGoogleDomain: {
          value: response.serp.googleDomain,
          label: domainToCountry[response.serp.googleDomain],
        },
        serpCountry: {
          value: response.serp.country,
          label: codeToCountry[response.serp.country],
        },
        serpLang: {
          value: response.serp.lang,
          label: langToCountry[response.serp.lang],
        },
        serpResultQuant: response.serp.resultQuantity,
        serpResultType: {
          value: response.serp.resultType,
          label: translate(
            normalizeString(response.serp.resultType, {
              replace: /_/g,
              replaceTo: ' ',
              allFirstUpper: true,
            }),
          ),
        },
        serpKeywords: response.serp.keywordsText,
        serpFrequency: response.serp.frequency,
        serpMonthDays: response.serp?.monthDays?.map((monthNum) => {
          return { value: monthNum, label: String(monthNum) };
        }),
        serpWeekDays: response.serp?.weekDays?.map((weekNum) => {
          return { value: weekNum, label: numToWeek[weekNum] };
        }),
        serpHour: response.serp.hours
          ? new Date(new Date().setHours(response.serp.hours[0], 0, 0, 0))
          : '',
      });
      const radioElem = document.querySelector(`input#${response.serp.frequency}`);
      radioElem.checked = true;
      countLines();
      setHasExperiment(true);
    }
    setLoading(false);
  }, [accountId]);

  useEffect(() => {
    setCreditsToBeConsumed(() => {
      return creditsFactors.typesNum * creditsFactors.searches * creditsFactors.timesThisMonth;
    });
  }, [creditsFactors]);

  const onSubmit = async (data) => {
    if (creditsToBeConsumed > accountCredits || !accountCredits) return;
    const [keywords, tags] = formatKeywords(data.serpKeywords);
    const experiment = {
      accountId,
      taskId: content.id,
      name: data.name,
      dateBegin: getEpochDate(data.dateBegin),
      dateEnd: getEpochDate(data.dateEnd),
      deviceType: data.deviceType,
      dataSource: data.dataSource.map(getFormValue),
      serp: {
        remainderCredits: creditsToBeConsumed,
        name: formatBatchName(data.name, accountId, content.id),
        domain: data.serpDomain,
        googleDomain: data.serpGoogleDomain.value,
        country: data.serpCountry.value,
        lang: data.serpLang.value,
        resultQuantity: data.serpResultQuant,
        resultType: data.serpResultType.value,
        keywords,
        tags: _.isEmpty(tags) ? null : tags,
        keywordsText: data.serpKeywords,
        frequency: data.serpFrequency,
        ...(data.serpFrequency !== 'manual' && {
          hours: [data.serpHour.getHours()],
        }),
        ...(data.serpFrequency === 'weekly' && {
          weekDays: data.serpWeekDays.map(getFormValue),
        }),
        ...(data.serpFrequency === 'monthly' && {
          monthDays: data.serpMonthDays.map(getFormValue),
        }),
      },
    };
    if (hasExperiment) {
      const successfull = firebaseHelper.updateExperiment(experiment);
      toast.promise(successfull, {
        pending: translate('Updating Experiment...'),
        success: translate('Updated Successfully!'),
        error: translate('Failed to Update!'),
      });
    } else {
      const successfull = firebaseHelper.registerExperiment(experiment);
      toast.promise(successfull, {
        pending: translate('Creating Experiment...'),
        success: translate('Created Successfully!'),
        error: translate('Failed to Create!'),
      });
      successfull.then(() => {
        setHasExperiment(true);
      });
    }
  };

  const countLines = useCallback(
    _.throttle(
      async () => {
        const searches = countKeywords(form.getValues('serpKeywords'));

        setCreditFactors((prevState) => ({ ...prevState, searches }));
        setTextLines(() => `${searches}/50`);
      },
      500,
      { leading: true, trailing: true },
    ),
  );

  const countTimesThisMonth = useCallback(
    _.debounce(() => {
      const searchType = form.getValues('serpFrequency');

      const hours = form.getValues('serpHour') ? form.getValues('serpHour').getHours() : -1;
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      const monthLastDay = new Date(currentYear, currentMonth + 1, 0).getDate();
      const daysLeft = monthLastDay - currentDate.getDate();
      let timesThisMonth = 1;

      switch (searchType) {
        case '':
          timesThisMonth = 1;
          break;
        case 'manual':
          timesThisMonth = 0;
          break;
        case 'daily':
          timesThisMonth = daysLeft;
          if (hours > currentDate.getHours()) {
            timesThisMonth++;
          }
          break;
        case 'weekly':
          if (_.isEmpty(form.getValues('serpWeekDays'))) {
            timesThisMonth = Math.ceil(daysLeft / 7);
          } else {
            const weekDays = form.getValues('serpWeekDays').map(getFormValue);
            timesThisMonth = 0;
            for (let i = currentDate.getDate(); i <= monthLastDay; i++) {
              // If today's hour has already passed skip to the next day
              if (i === currentDate.getDate() && hours < currentDate.getHours()) continue;
              // If the day matches the batch settings increment the count
              if (weekDays.includes(new Date(currentYear, currentMonth, i).getDay()))
                timesThisMonth++;
            }
          }
          break;
        case 'monthly':
          if (_.isEmpty(form.getValues('serpMonthDays'))) {
            timesThisMonth = 1;
          } else {
            const monthDays = form.getValues('serpMonthDays').map(getFormValue);
            timesThisMonth = 0;
            for (let i = currentDate.getDate(); i <= monthLastDay; i++) {
              // If today's hour has already passed skip to the next day
              if (i === currentDate.getDate() && hours < currentDate.getHours()) continue;
              // If the day matches the batch settings increment the count
              if (monthDays.includes(i)) timesThisMonth++;
            }
          }
          break;
      }

      setCreditFactors((prevState) => ({ ...prevState, timesThisMonth }));
    }, 300),
  );

  const handleFrequencyChange = () => {
    // Clear all the frequency forms errors
    form.clearErrors(['serpMonthDays', 'serpWeekDays', 'serpHour']);

    // Using setTimeout to give the components time to be rendered and change the scrollHeight
    setTimeout(() => {
      const configElem = configSourceRef.current;
      configElem.scrollTop = configElem.scrollHeight;
      countTimesThisMonth();
    }, 0);
  };

  const handleScrollClose = (event) => {
    // Solution to fixed Select Menu no scroll
    // https://github.com/JedWatson/react-select/issues/4088#issuecomment-1073632818
    // Also works for react-datepicker Time Select
    return event.target.contains(configSourceRef.current);
  };

  const handleExperimentDelete = async (event) => {
    event.preventDefault();

    firebaseHelper.unregisterExperiment(accountId, content.id);
  };

  const handleSwitch = (event) => {
    const value = event.target.value;
    const formPath = event.target.name;
    form.setValue(formPath, value);

    // Sets Timeout to 0 to wait values to be setted on the form before updating credits and checking for errors
    setTimeout(() => {
      // Update typesNum to calculated wasted credits
      const typesNum =
        Boolean(form.getValues('deviceType.desktop')) +
        Boolean(form.getValues('deviceType.mobile'));
      setCreditFactors((prevState) => ({ ...prevState, typesNum }));
      // Unified error check
      const desktop = form.getValues('deviceType.desktop');
      const mobile = form.getValues('deviceType.mobile');
      if (!desktop && !mobile) {
        form.setError('deviceType', {
          types: {
            validate: 'You need to choose at least 1 device type!',
          },
        });
      } else {
        form.clearErrors('deviceType');
      }
    }, 0);
  };

  const validateKeywords = (value) =>
    countKeywords(value) <= 50 || 'There must be less than 50 keywords!';

  return (
    <ExperimentContainer id="experiment-config">
      <FormProvider {...form}>
        <ExperimentForm onSubmit={form.handleSubmit(onSubmit)}>
          <FormRow>
            <LabelGroup htmlFor="name" label="Experiment Name" mb={1} size={12} column>
              <TextInput controlName="name" placeholder="Experiment 1" required />
            </LabelGroup>

            <LabelGroup htmlFor="dateBegin" label="Experiment Beginning" mb={1} size={12} column>
              <DateInput controlName="dateBegin" placeholder="Select" required />
            </LabelGroup>

            <LabelGroup htmlFor="dateEnd" label="Experiment Ending" mb={1} size={12} column>
              <DateInput controlName="dateEnd" placeholder="Select" required />
            </LabelGroup>

            <LabelGroup htmlFor="deviceType" label="Traffic Type" mb={0} size={12} column>
              <SwitchInput
                controlName="deviceType.desktop"
                label="Desktop"
                onClick={handleSwitch}
              />
              <SwitchInput controlName="deviceType.mobile" label="Mobile" onClick={handleSwitch} />
              <DisplayErrors error={formState.errors?.deviceType?.types} />
            </LabelGroup>

            <LabelGroup htmlFor="dataSource" label="Data Source" mb={0} size={12} column>
              <SelectInput
                isMulti
                controlName="dataSource"
                required
                options={[{ value: 'serp', label: 'Ecto SERP' }]}
              />
            </LabelGroup>
          </FormRow>

          <FormRow spaceBetween>
            <UncontrolledAccordion className="mt-4">
              <ShowComponent condition={dataSourceRef.current.includes('serp')}>
                <AccordionItem>
                  <AccordionHeader targetId="1">
                    {translate('Ecto SERP') +
                      ` (${accountCredits} ${translate('out of')} ${limitCredits})`}
                  </AccordionHeader>
                  <CustomAccordionBody accordionId="1">
                    <SourceConfig
                      ref={configSourceRef}
                      accordionLength={dataSourceRef.current.length}
                    >
                      <ShowComponent condition={hasExperiment}>
                        <ErrorBox>
                          {translate(
                            'Após a criação de um experimento, não é possível editar as palavras-chave, tags e outros parâmetros da busca, pois afeta a exibição dos resultados. ',
                          )}
                          <strong>
                            {translate('É possível editar apenas a frequência e periodicidade ')}
                          </strong>
                          {translate('do experimento.')}
                        </ErrorBox>
                      </ShowComponent>

                      <LabelGroup
                        htmlFor="serpDomain"
                        label="Domain to be analyzed"
                        mb={2}
                        size={12}
                        column
                      >
                        <TextInput
                          disabled={hasExperiment}
                          controlName="serpDomain"
                          placeholder="https://www.yourdomain.com.br"
                          noEllipsis
                          required
                        />
                      </LabelGroup>

                      <LabelGroup
                        htmlFor="serpGoogleDomain"
                        label="Google Domain"
                        mb={2}
                        size={12}
                        column
                      >
                        <SelectInput
                          isDisabled={hasExperiment}
                          controlName="serpGoogleDomain"
                          required
                          options={serpGoogleDomains.sort(sortByLabel)}
                          menuPosition="fixed"
                          closeMenuOnScroll={handleScrollClose}
                        />
                      </LabelGroup>

                      <LabelGroup mb={2} size={12}>
                        <SelectInput
                          isDisabled={hasExperiment}
                          controlName="serpCountry"
                          label="Country"
                          required
                          size={6}
                          options={serpCountries.sort(sortByLabel)}
                          menuPosition="fixed"
                          closeMenuOnScroll={handleScrollClose}
                        />
                        <SelectInput
                          isDisabled={hasExperiment}
                          controlName="serpLang"
                          label="Language"
                          required
                          size={6}
                          options={serpLangs.sort(sortByLabel)}
                          menuPosition="fixed"
                          closeMenuOnScroll={handleScrollClose}
                        />
                      </LabelGroup>

                      <LabelGroup
                        htmlFor="serpResultQuant"
                        label="Result Quantity"
                        mb={2}
                        size={12}
                        column
                      >
                        <SliderInput
                          disable={hasExperiment}
                          controlName="serpResultQuant"
                          min={10}
                          max={100}
                          step={10}
                          orientation="horizontal"
                          labels={{
                            10: '10',
                            20: '20',
                            30: '30',
                            40: '40',
                            50: '50',
                            60: '60',
                            70: '70',
                            80: '80',
                            90: '90',
                            100: '100',
                          }}
                        />
                      </LabelGroup>

                      <LabelGroup htmlFor="serpKeywords" label="Keywords" mb={2} size={12} column>
                        <TextAreaInput
                          disabled={hasExperiment}
                          controlName="serpKeywords"
                          placeholder={translate(
                            'keyword 1, tag 1, tag 2, ...\nkeyword 2, tag 1, tag 2, ...\nkeyword 3, tag 1, tag 2, ...',
                          )}
                          onChange={countLines}
                          validation={{
                            validate: validateKeywords,
                          }}
                          rows={4}
                          tag
                          tagContent={textLines}
                          required
                        />
                      </LabelGroup>

                      <LabelGroup
                        htmlFor="serpResultType"
                        label="Result Type"
                        mb={2}
                        size={12}
                        column
                      >
                        <SelectInput
                          disable={hasExperiment}
                          controlName="serpResultType"
                          required
                          options={serpSearchTypes}
                          menuPosition="fixed"
                          closeMenuOnScroll={handleScrollClose}
                        />
                      </LabelGroup>

                      <LabelGroup htmlFor="serpFrequency" label="Frequency" mb={2} size={12}>
                        <RadioInput
                          controlName="serpFrequency"
                          id="manual"
                          label="Manual"
                          size="auto"
                          required
                          onChange={handleFrequencyChange}
                        />
                        <RadioInput
                          controlName="serpFrequency"
                          id="daily"
                          label="Daily"
                          size="auto"
                          onChange={handleFrequencyChange}
                        />
                        <RadioInput
                          controlName="serpFrequency"
                          id="weekly"
                          label="Weekly"
                          size="auto"
                          onChange={handleFrequencyChange}
                        />
                        <RadioInput
                          controlName="serpFrequency"
                          id="monthly"
                          label="Monthly"
                          size="auto"
                          onChange={handleFrequencyChange}
                        />
                        <DisplayErrors error={formState.errors?.serpFrequency?.types} />
                      </LabelGroup>

                      <ShowComponent condition={frequencyRef.current === 'monthly'}>
                        <LabelGroup
                          htmlFor="serpMonthDays"
                          label="Month Day(s)"
                          mb={2}
                          size={12}
                          column
                        >
                          <SelectInput
                            controlName="serpMonthDays"
                            required
                            options={selectMonthDays}
                            onChange={() => countTimesThisMonth()}
                            isMulti
                            menuPosition="fixed"
                            closeMenuOnScroll={handleScrollClose}
                          />
                        </LabelGroup>
                      </ShowComponent>

                      <ShowComponent condition={frequencyRef.current === 'weekly'}>
                        <LabelGroup
                          htmlFor="serpWeekDays"
                          label="Weekday(s)"
                          mb={2}
                          size={12}
                          column
                        >
                          <SelectInput
                            controlName="serpWeekDays"
                            required
                            options={selectWeekDays}
                            onChange={() => countTimesThisMonth()}
                            isMulti
                            menuPosition="fixed"
                            closeMenuOnScroll={handleScrollClose}
                          />
                        </LabelGroup>
                      </ShowComponent>

                      <ShowComponent
                        condition={frequencyRef.current !== 'manual' && frequencyRef.current}
                      >
                        <LabelGroup htmlFor="serpHour" label="Hour" mb={2} size={12} column>
                          <TimeInput
                            controlName="serpHour"
                            placeholder="Select"
                            timeIntervals={60}
                            onChange={() => countTimesThisMonth()}
                            portalId="experiment-config"
                            closeOnScroll={handleScrollClose}
                            required
                          />
                        </LabelGroup>
                      </ShowComponent>
                    </SourceConfig>
                  </CustomAccordionBody>
                </AccordionItem>
              </ShowComponent>
            </UncontrolledAccordion>

            <CreditsRow>
              <ShowComponent condition={!hasExperiment}>
                <ShowComponent condition={accountCredits && accountCredits > creditsToBeConsumed}>
                  {translate('This experiment will consume') +
                    ` ${creditsToBeConsumed} ` +
                    translate(
                      `credit${creditsToBeConsumed === 1 ? '' : 's'} automatically this month`,
                    )}
                </ShowComponent>
                <ShowComponent condition={!accountCredits || accountCredits < creditsToBeConsumed}>
                  {translate(
                    'Your amount of credits is not sufficient to create a new experiment!',
                  )}
                </ShowComponent>
              </ShowComponent>
              <ButtonsRow>
                <Button
                  type="submit"
                  color="success"
                  disabled={Boolean(
                    Object.entries(formState.errors).length ||
                      loading ||
                      !accountCredits ||
                      accountCredits < creditsToBeConsumed,
                  )}
                >
                  {translate(hasExperiment ? 'Update Experiment' : 'Create Experiment')}
                </Button>

                <Button
                  type="button"
                  color="danger"
                  onClick={handleExperimentDelete}
                  disabled={loading}
                >
                  {translate('Delete Experiment')}
                </Button>
              </ButtonsRow>
            </CreditsRow>
          </FormRow>
        </ExperimentForm>
      </FormProvider>
    </ExperimentContainer>
  );
};

ExperimentConfig.propTypes = {};

export default ExperimentConfig;

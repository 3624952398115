import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Alert, Card, CardBody, Container, Button } from 'reactstrap';

import { useAuth } from '../../hooks/useAuth';
import { getFirebaseBackend } from '../../helpers/firebaseHelper';
import LanguageDropdown from '../../components/Language-Dropdown';
import logo from '../../assets/images/small-logo.svg';

const DisableNotifications = () => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const auth = JSON.parse(localStorage.getItem('authUser')) ?? null;
  const firebaseHelper = getFirebaseBackend();

  const [alertColor, setAlertColor] = useState('warning');
  const [alertMessage, setAlertMessage] = useState('Disabling All Notifications...');

  useEffect(async () => {
    if (!user) navigate({ pathname: '/login', search: '?goto=/disable-notifications' });

    try {
      await firebaseHelper.disableUserNotifications(auth.uid);

      setAlertColor('success');
      setAlertMessage('Notifications turned off successfully!');
    } catch (error) {
      setAlertColor('danger');
      setAlertMessage('An Error occured turning off the notifications. Try again later!');
    }
  }, []);

  return (
    <>
      <MetaTags>
        <title>{translate('Disable Notifications')} | Ectools</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div style={{ backgroundColor: 'rgba(242, 242, 242, 0.22)' }}>
                  <Row>
                    <Col xs={9}>
                      <div className="text-primary p-4">
                        <h5 style={{ color: '#344792' }}>{translate('Verify Email')}</h5>
                        <p style={{ color: '#344792' }}>
                          {translate("Verify your SEO Dashboard account's email.")}
                        </p>
                      </div>
                    </Col>
                    <Col className="col-3" style={{ paddingLeft: '8%' }}>
                      <LanguageDropdown />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <img src={logo} alt={translate("Ectools' Logo")} height="36" />
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Alert
                      className="d-flex justify-content-center mb-4"
                      color={alertColor}
                      isOpen={true}
                    >
                      {translate(alertMessage)}
                    </Alert>
                    <Row>
                      <Col lg="12">
                        <Link to="/">
                          <Button className="col-lg-12" style={{ backgroundColor: '#344792' }}>
                            {translate('Back to Home')}
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} {translate('Ecto Digital')}.
                  {` ${translate('Created with')} `}
                  <i className="mdi mdi-heart text-danger" />
                  {` ${translate('in Curitiba')}`}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default DisableNotifications;

import usFlag from '../assets/images/flags/us.png';
import brazilFlag from '../assets/images/flags/brazil.png';

const languages = {
  enUS: {
    label: 'English',
    flag: usFlag,
  },
  ptBR: {
    label: 'Português',
    flag: brazilFlag,
  },
};

export default languages;

import React from 'react';
import { Navigate } from 'react-router-dom';

import AuthActions from '../pages/authentication/authActions';
import DisableNotifications from 'pages/authentication/disableNotifications';
import ForgetPwd from '../pages/authentication/forgetPassword';
import Login from '../pages/authentication/login';
import Logout from '../pages/authentication/logout';
import Register from '../pages/authentication/register';
import ResetPwd from '../pages/authentication/resetPassword';
import VerifyEmail from '../pages/authentication/verifyEmail';
import Pages404 from '../pages/utility/page404';
import Pages500 from '../pages/utility/page500';
import PagesMaintenance from '../pages/utility/pageMaintenance';

const publicRoutes = [
  // Root Route
  { path: '/', exact: true, component: () => <Navigate to="/tasks" /> },

  // Authentication
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  { path: '/register', component: Register },
  { path: '/forgot-password', component: ForgetPwd },
  { path: '/auth-actions', component: AuthActions },
  { path: '/disable-notifications', component: DisableNotifications },
  { path: '/verify-email', component: VerifyEmail },
  { path: '/reset-password', component: ResetPwd },

  // Utility pages
  { path: '/page-maintenance', component: PagesMaintenance },
  { path: '/page-500', component: Pages500 },
  { path: '*', component: Pages404 },
];

export { publicRoutes };

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import CourseCard from './CourseCard';
import { CoursePage, Pagination } from './styles';

const CoursesPaginated = ({
  data = [],
  updateData,
  library = [],
  itemsPerPage,
  showProgress = false,
  onZero = null,
}) => {
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, data]);

  return (
    <CoursePage>
      {!data.length && onZero}
      <div className="courses-grid">
        {currentItems?.map((course, index) => (
          <CourseCard
            key={index}
            index={index}
            data={course}
            updateCard={updateData}
            showProgress={showProgress}
            library={library}
          />
        ))}
      </div>

      {data.length > itemsPerPage && (
        <Pagination
          previousLabel="<"
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          renderOnZeroPageCount={null}
          containerClassName="pagination-container"
          activeClassName="active"
        />
      )}
    </CoursePage>
  );
};

CoursesPaginated.displayName = 'Courses Paginated Component';

CoursesPaginated.propTypes = {
  data: PropTypes.array,
  updateData: PropTypes.func,
  library: PropTypes.array,
  itemsPerPage: PropTypes.number,
  showProgress: PropTypes.bool,
  onZero: PropTypes.node,
};

export default CoursesPaginated;

import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { mediaQuery } from '../../constants/index';

export const BreadCrumbBtn = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  color: #50a5f1;

  &:hover {
    color: #50a5f1;
  }
`;

export const NoCourseMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  gap: 1em;
  padding: 0.5em 1em;
  background-color: #fff5e3;
  border: 1px solid #f4e5cc;
  border-radius: 4px;
`;

export const CourseLayout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 1em;
  margin-top: 1em;

  @media ${mediaQuery.less.mds} {
    flex-direction: column-reverse;
  }

  > div:nth-child(1) {
    width: 70% !important;

    @media ${mediaQuery.less.mds} {
      width: 100% !important;
    }
  }

  > div:nth-child(2) {
    width: 30% !important;

    @media ${mediaQuery.less.mds} {
      width: 100% !important;
    }
  }

  .course-home-main {
    padding: 1.5em;

    .teacher-element {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1em;
      padding: 1em;
      background-color: #eef7ff;
      border-radius: 5px;
      margin-bottom: 1em;

      .teacher-avatar {
        height: 5em;
        width: 5em;
        aspect-ratio: 1/1;
        border-radius: 50%;
        background-clip: padding-box;
        background-size: cover;
        background-position: center center;
        animation: loading-img 2s alternate-reverse infinite;
      }

      h3 {
        font-size: 1.2em;
        color: #556ee6;
      }
    }

    .wmde-markdown {
      margin-bottom: 1em;
    }

    h1 {
      font-size: 2em;
      color: #495057;
    }

    h2 {
      font-size: 1.5em;
      color: #6a7187;
    }
  }

  .course-home-interaction {
    > div:nth-child(1) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      aspect-ratio: 16/9;
      border-radius: 5px 5px 0 0;
      background-color: #556ee6;

      > div.has-banner {
        height: 100%;
        width: 100%;
        background-clip: padding-box;
        background-size: cover;
        background-position: center center;
      }
    }

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1em;
      padding: 1em;

      button {
        width: 100%;
        font-size: 1.2em;
        font-weight: 500;
      }
    }

    .course-status {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1em;
      color: #50a5f1;
      font-size: 1.2em;
    }
  }
`;

export const LessonLayout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 1em;

  > div:nth-child(1) {
    width: ${(props) => (props.open ? '70%' : '95%')} !important;

    .lesson-main {
      padding: 1.5em;

      .lesson-main-title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .question-percent {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 1.2em;
          color: #74788d;
          background-color: #f6f6f6;
          border: 1px solid #c1c1c1;
          border-radius: 5px;

          &.reproved {
            color: #f46a6a;
            background-color: #fff6f6;
            border: 1px solid #ffdada;
          }
        }
      }
    }

    h1 {
      font-size: 2em;
    }

    .lesson-stats {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1em;
      color: #7b919e;
    }
  }

  > div:nth-child(2) {
    width: ${(props) => (props.open ? '30%' : '5%')} !important;
  }
`;

export const LessonMenu = styled.div`
  .test-warning {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${(props) => (props.open ? '1.5em' : '0')};
    margin-bottom: 1em;
    gap: 1em;
    border-radius: 9999px;
    background-color: #50a5f1;
    color: #fff;

    .text {
      div:nth-child(1) {
        font-size: 1.7em;
        font-weight: 600;
      }

      div:nth-child(2) {
        font-size: 0.9em;
      }
    }
  }

  .test-reset {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${(props) => (props.open ? '1.5em' : '0')};
    margin-bottom: 1em;
    gap: 1em;
    border: 0;
    border-radius: 9999px;
    background-color: #7367f0;
    color: #fff;
  }

  .card {
    padding: ${(props) => (props.open ? '1.5em' : '1.5em 0.5em')};
    margin-bottom: 1em;

    h4 {
      color: #74788d;
      font-size: 1.2em;
    }

    .download-menu {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1em;
      margin-bottom: 1em;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1em;
        border: 0;
        border-radius: 9999px;
        padding: 0.1em 1em;
        margin: 0;
        color: #fff;

        &.pdf {
          background-color: #50a5f1;
        }

        &.png {
          background-color: #556ee6;
        }
      }
    }

    .social-menu {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1em;
      margin-bottom: 1em;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1em;
        border: 0;
        border-radius: 50%;
        aspect-ratio: 1/1;
        padding: 0.5em;
        margin: 0;
        color: #fff;
        background-color: #495057;
      }
    }

    .link-menu {
      display: flex;
      justify-content: space-between;
      align-items: center;

      input {
        width: 85%;
        border: 1px solid #ced4da;
        border-radius: 4px;
        padding: 0.5em 1em;
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
        background-color: transparent;
        cursor: pointer;
      }
    }

    .open-column {
      .lesson-menu-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h4 {
          font-size: 1.3em;
          color: #74788d;
        }

        button {
          border: 0;
          padding: 0;
          margin: 0;
          background-color: transparent;
        }
      }

      .lesson-menu-meter {
        height: 2rem;
        width: 100%;
        margin: 0.5em 0;
        cursor: pointer;

        &.complete {
          &::-webkit-meter-optimum-value {
            background-color: #7367f0;
          }
        }

        &::-webkit-meter-optimum-value {
          background-color: #34c38f;
          transition: 2s width;
        }

        &::-webkit-meter-bar {
          border: none;
        }
      }

      .lesson-menu-buttons {
        display: flex;
        justify-content: center;
        align-items: stretch;
        flex-direction: row;
        gap: 1em;
        font-size: 0.9rem;

        @media ${mediaQuery.less.md} {
          flex-direction: column;
        }

        > button {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0.25rem 1rem;
          gap: 0.5em;
          border: 0;
          border-radius: 1rem;
          color: #fff;
        }

        > button.previous {
          background-color: #999999;

          &:disabled {
            background-color: #000;
            opacity: 0.1;
            cursor: not-allowed;
          }
        }

        > button.next {
          background-color: #50a5f1;
        }

        > button.test {
          background-color: #7367f0;
        }

        > button.certificate {
          background-color: #34c38f;
        }
      }
    }

    .closed-column {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.3em;

      .sideways {
        color: #c1c1c1;
        font-size: 1rem;
        line-height: 1.8rem;
        text-transform: uppercase;
        writing-mode: vertical-lr;
      }

      button {
        border: 0;
        padding: 0;
        margin: 0;
        background-color: transparent;
      }

      .lesson-menu-buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5em;
      }
    }
  }

  .closed-column {
    padding: 0.3em;

    .lesson-menu-buttons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1em;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.25rem 1rem;
        gap: 0.5em;
        border: 0;
        border-radius: 1rem;
        width: 100%;
        color: #fff;
      }

      button.previous {
        background-color: #999999;

        &:disabled {
          background-color: #000;
          opacity: 0.1;
          cursor: not-allowed;
        }
      }

      button.next {
        background-color: #50a5f1;
      }

      button.test {
        background-color: #7367f0;
      }

      button.certificate {
        background-color: #34c38f;
      }
    }
  }

  .closed-outside {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
    margin-bottom: 1em;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0;
      border-radius: 50%;
      padding: 0.5em;
      margin: 0;
      color: #fff;

      &.pdf {
        background-color: #50a5f1;
      }

      &.png {
        background-color: #556ee6;
      }
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1em;
      border: 0;
      border-radius: 50%;
      aspect-ratio: 1/1;
      padding: 0.5em;
      margin: 0;
      color: #fff;
      background-color: #495057;
    }
  }
`;

export const TestDiv = styled.div`
  margin-bottom: 1em;

  .question-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;
    margin-bottom: 0.4em;

    h4 {
      color: #74788d;
      font-size: 1.2em;
      margin: 0;
    }

    .question-correction {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1em;

      &.right {
        color: #34c38f;
      }

      &.wrong {
        color: #f46a6a;
      }

      &.analysis {
        color: #50a5f1;
      }
    }
  }

  .open-feedback {
    margin-top: 0.3em;
    color: #50a5f1;
  }
`;

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col } from 'reactstrap';

import DisplayErrors from '../Display-Errors';

const FilePicker = ({
  controlName,
  mb,
  placeholder = "Drag 'n' drop some files here, or click to select files",
  required = false,
  isMulti,
  accept,
  maxFiles = 0,
  maxSize = Infinity,
  multiple = false,
  noDrag = false,
  size = 12,
  validation = {},
}) => {
  const { t: translate } = useTranslation();
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useFormContext();
  const [files, setFiles] = useState([]);
  const onDrop = useCallback((acceptedFiles) => {
    if (isMulti) {
      const value = getValues(controlName);
      setValue(controlName, [...value, ...acceptedFiles]);
    } else {
      setValue(controlName, acceptedFiles);
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles,
    multiple,
    accept,
    maxSize,
    noDrag,
  });

  const fileRemoveHandle = (event) => {
    event.preventDefault();

    const newFiles = getValues(controlName);
    newFiles.splice(event.target.id, 1);
    setValue(controlName, newFiles);
  };

  const validate = required ? { ...validation, required: 'Required Field!' } : validation;

  useEffect(() => {
    setFiles(getValues(controlName));
  }, [watch(controlName)]);

  return (
    <Col className={`mb-${mb}`} lg={size}>
      <Controller
        control={control}
        name={controlName}
        rules={validate}
        render={() => (
          <section>
            <div {...getRootProps()} className="drag-n-drop">
              <input {...getInputProps()} />
              <i className="fas fa-cloud-upload-alt fa-3x" style={{ marginBottom: '1%' }}></i>
              <p>{!noDrag && isDragActive ? 'Drop the files here...' : translate(placeholder)}</p>
            </div>
          </section>
        )}
      />

      <DisplayErrors error={errors?.[controlName]?.types} />

      <div className="file-list">
        {files?.map((file, index) => {
          return (
            <div key={`${file.name}-${index}`} className="file">
              <p>{file.name}</p>
              <button
                id={index}
                className="btn btn-sm btn-outline-danger"
                onClick={fileRemoveHandle}
              >
                X
              </button>
            </div>
          );
        })}
      </div>
    </Col>
  );
};

FilePicker.displayName = 'File Picker Component';

FilePicker.propTypes = {
  controlName: PropTypes.string,
  mb: PropTypes.number,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  isMulti: PropTypes.bool,
  accept: PropTypes.string,
  maxFiles: PropTypes.number,
  maxSize: PropTypes.number,
  multiple: PropTypes.bool,
  noDrag: PropTypes.bool,
  size: PropTypes.number,
  validation: PropTypes.object,
};

export default FilePicker;

import styled from 'styled-components';

export const ChartRadarStyles = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  gap: 2rem;
  padding: 2rem;
  border-radius: 0.5rem;
  border: 1px solid #e2e8f0;
  background: #ffffff;

  .title {
    color: #2d3748;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.75rem;
  }

  canvas {
    width: 100% !important;
    max-height: 20rem !important;
    margin: 1.61rem 0;
  }
`;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { UncontrolledAccordion, AccordionItem, Row, Col, Button } from 'reactstrap';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import 'boxicons';

import { useAuth } from '../../../hooks/useAuth';
import {
  AccordionTitle,
  AccordionTable,
  ActionWrapper,
} from '../../../components/Accordion-List/styles';
import { formatDate } from '../../../utils';

const ExperimentAccordionList = ({ column, data }) => {
  const { t: translate } = useTranslation();
  const { user } = useAuth();
  const accountId = user?.account;
  const [experiments, setExperiments] = useState([]);

  useEffect(() => {
    const experimentsData = data ?? [];

    const newData = experimentsData.map((experiment) => {
      const beginDate = experiment.dateBegin
        ? new Date(experiment.dateBegin).toLocaleDateString(translate('en-US'))
        : '-';
      const endDate = experiment.dateEnd
        ? new Date(experiment.dateEnd).toLocaleDateString(translate('en-US'))
        : '-';
      const frequency = experiment.serp?.frequency;
      let creditsPerMonth = 0;
      switch (frequency) {
        case 'daily':
          creditsPerMonth = experiment.serp.keywords.length * experiment.serp.hours.length * 30;
          break;
        case 'weekly':
          creditsPerMonth =
            experiment.serp.keywords.length *
            experiment.serp.weekDays.length *
            experiment.serp.hours.length *
            4;
          break;
        case 'monthly':
          creditsPerMonth =
            experiment.serp.keywords.length *
            experiment.serp.monthDays *
            experiment.serp.hours.length;
          break;
      }
      const improvedKeywords = experiment.serp.keywords.reduce((acc, cur) => {
        if (cur.lastResult > cur.firstResult) return acc++;
        else return acc;
      }, 0);
      let beforeFirst = 0;
      let afterFirst = 0;
      let beforeTenth = 0;
      let afterTenth = 0;
      let beforeLast = 0;
      let afterLast = 0;
      experiment.serp.keywords.forEach((keyword) => {
        if (keyword.firstResult >= 11) beforeLast++;
        else if (keyword.firstResult >= 4) beforeTenth++;
        else if (keyword.firstResult > 0) beforeFirst++;

        if (keyword.lastResult >= 11) afterLast++;
        else if (keyword.lastResult >= 4) afterTenth++;
        else if (keyword.lastResult > 0) afterFirst++;
      });

      return {
        name: nameElement(experiment),
        nameValue: experiment.name,
        improved: improvedElement(improvedKeywords, experiment.serp.keywords.length),
        improvedValue: improvedKeywords,
        firstRanked: rankElement(afterFirst, beforeFirst),
        firstRankedValue: afterFirst - beforeFirst,
        tenthRanked: rankElement(afterTenth, beforeTenth),
        tenthRankedValue: afterTenth - beforeTenth,
        lastRanked: rankElement(afterLast, beforeLast),
        lastRankedValue: afterLast - beforeLast,
        beginDate: beginDate,
        beginDateValue: experiment.dateBegin ?? 0,
        endDate: endDate,
        endDateValue: experiment.dateEnd ?? 0,
        credits: creditsPerMonth,
        action: actionButtons(experiment.id),
      };
    });

    setExperiments(newData);
  }, [data]);

  const rankElement = (before, after) => {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <span
          className="d-flex justify-content-center align-items-center gap-1 px-2 py-1"
          style={{
            backgroundColor: after > before ? '#E0FFF4' : 'transparent',
            borderRadius: '.2rem',
            width: 'fit-content',
          }}
        >
          {before}
          <box-icon name="arrow-from-left" color="#ADB5BD" size="1.2rem"></box-icon>
          {after}
        </span>
      </div>
    );
  };

  const improvedElement = (improvedKeywords, keywords) => {
    return (
      <>
        {improvedKeywords}
        <span style={{ color: '#ADB5BD' }}>{` ${translate('of')} ${keywords}`}</span>
      </>
    );
  };

  const nameElement = (experiment) => {
    return (
      <div>
        <div>{experiment.name}</div>
        <div className="d-flex gap-1">
          {experiment.deviceType.desktop && (
            <span
              className={`status badge rounded-pill font-size-12`}
              style={{ backgroundColor: '#E6E6E6', color: '#707070' }}
            >
              Desktop
            </span>
          )}
          {experiment.deviceType.mobile && (
            <span
              className={`status badge rounded-pill font-size-12`}
              style={{ backgroundColor: '#E6E6E6', color: '#707070' }}
            >
              Mobile
            </span>
          )}
        </div>
      </div>
    );
  };

  const actionButtons = (id) => {
    const actionElem = (
      <ActionWrapper>
        <Link to={`/tasks?id=${id}&acc=${accountId}&expConfig=true`}>
          <Button style={{ backgroundColor: '#50A5F1' }}>
            <box-icon name="pencil" color="#FFF" size="1.2rem"></box-icon>
          </Button>
        </Link>

        <Link to={`/tasks?id=${id}&acc=${accountId}&expGraph=true`}>
          <Button style={{ backgroundColor: '#34C38F' }}>
            <box-icon name="show" color="#FFF" size="1.2rem"></box-icon>
          </Button>
        </Link>
      </ActionWrapper>
    );

    return actionElem;
  };

  const columns = [
    {
      dataField: 'name',
      type: 'string',
      sortValue: (cell, row) => row.nameValue,
      text: translate('Experiment Name'),
      sort: true,
      headerStyle: { backgroundColor: '#F8F9FA', width: '20%' },
    },
    {
      dataField: 'improved',
      type: 'string',
      sortValue: (cell, row) => row.improvedValue,
      text: translate('Improved'),
      sort: true,
      headerStyle: {
        backgroundColor: '#F8F9FA',
        width: '9%',
        textAlign: 'center',
      },
      style: { textAlign: 'center' },
    },
    {
      dataField: 'firstRanked',
      type: 'string',
      sortValue: (cell, row) => row.firstRankedValue,
      text: translate('1 to 3'),
      sort: true,
      headerStyle: {
        backgroundColor: '#F8F9FA',
        width: '9%',
        textAlign: 'center',
      },
    },
    {
      dataField: 'tenthRanked',
      type: 'string',
      sortValue: (cell, row) => row.tenthRankedValue,
      text: translate('4 to 10'),
      sort: true,
      headerStyle: {
        backgroundColor: '#F8F9FA',
        width: '9%',
        textAlign: 'center',
      },
      style: { textAlign: 'center' },
    },
    {
      dataField: 'lastRanked',
      type: 'string',
      sortValue: (cell, row) => row.lastRankedValue,
      text: translate('11 to 100'),
      sort: true,
      headerStyle: {
        backgroundColor: '#F8F9FA',
        width: '9%',
        textAlign: 'center',
      },
      style: { textAlign: 'center' },
    },
    {
      dataField: 'beginDate',
      type: 'date',
      sortValue: (cell, row) => row.beginDateValue,
      text: translate('Begin'),
      sort: true,
      headerStyle: {
        backgroundColor: '#F8F9FA',
        width: '9%',
        textAlign: 'center',
      },
      style: { textAlign: 'center' },
    },
    {
      dataField: 'endDate',
      type: 'date',
      sortValue: (cell, row) => row.endDateValue,
      text: translate('End'),
      sort: true,
      headerStyle: {
        backgroundColor: '#F8F9FA',
        width: '9%',
        textAlign: 'center',
      },
      style: { textAlign: 'center' },
    },
    {
      dataField: 'credits',
      type: 'number',
      text: translate('Credits/month'),
      sort: true,
      headerStyle: {
        backgroundColor: '#F8F9FA',
        width: '5%',
        textAlign: 'center',
      },
      style: { textAlign: 'center' },
    },
    {
      dataField: 'action',
      searchable: false,
      text: translate('Action'),
      headerStyle: {
        backgroundColor: '#F8F9FA',
        width: '10%',
        textAlign: 'center',
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: 'name',
      order: 'asc',
    },
  ];

  const pageOptions = {
    sizePerPage: experiments?.length ?? 0,
    totalSize: experiments?.length ?? 0,
    custom: true,
  };

  return (
    <UncontrolledAccordion className="mb-4">
      <AccordionItem>
        <AccordionTitle targetId="1">
          {`${translate(column)} (${data?.length ?? 0})`}
        </AccordionTitle>

        <AccordionTable>
          <Row>
            <Col lg="12">
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="id"
                columns={columns}
                data={experiments}
              >
                {({ paginationTableProps }) => (
                  <ToolkitProvider keyField="id" columns={columns} data={experiments}>
                    {(toolkitProps) => (
                      <React.Fragment>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField="id"
                                responsive
                                hover
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                classes={'table align-middle table-nowrap'}
                                headerWrapperClasses={'thead-light'}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </Col>
          </Row>
        </AccordionTable>
      </AccordionItem>
    </UncontrolledAccordion>
  );
};

ExperimentAccordionList.displayName = 'Experiment Accordion List Component';

ExperimentAccordionList.propTypes = {
  column: PropTypes.string,
  data: PropTypes.object,
};

export default ExperimentAccordionList;

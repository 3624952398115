import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import 'boxicons';

import { useAuth } from 'hooks/useAuth';
import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { LessonUl } from './styles';

const LessonsList = ({ account, courseId, progress, current, list }) => {
  const { user } = useAuth();
  const firebaseHelper = getFirebaseBackend();
  const [searchParams, setSearchParams] = useSearchParams();
  const [lessons, setLessons] = useState([]);

  const processLessons = async () => {
    let hasPreviousTest = false;
    const libraryObj = await firebaseHelper.getFirebaseLibraryCourse(user.id, courseId);

    const newLessons = await Promise.all(
      list.map(async (lesson) => {
        const lessonObj = await firebaseHelper.getFirebaseLesson(account, lesson.id);
        const passedLesson = progress?.includes(lesson.id);

        if (libraryObj?.status === 'trial') {
          return {
            ...lesson,
            name: lessonObj.name,
            isCompleted: Boolean(passedLesson),
            isCurrent: lesson.index === current,
            isUnavailable: !lesson.freeTrial,
          };
        } else {
          const hasTest = lessonObj.applyTest;
          const returnedLesson = {
            ...lesson,
            name: lessonObj.name,
            isCompleted: Boolean(passedLesson),
            isCurrent: lesson.index === current,
            isUnavailable: false || hasPreviousTest,
          };
          if (!passedLesson && hasTest) hasPreviousTest = true;
          return returnedLesson;
        }
      }),
    );
    setLessons(newLessons);
  };

  const handleClick = (id) => {
    searchParams.set('id', id);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    processLessons();
  }, [list, current]);

  return (
    <LessonUl>
      {lessons.map((lesson) => (
        <li key={lesson.id}>
          <box-icon
            name="chevron-right"
            size="1.2em"
            color={lesson.isCurrent ? '#7367F0' : '#74788D'}
          />
          <button
            onClick={() => handleClick(lesson.id)}
            disabled={lesson.isCurrent || lesson.isUnavailable}
            className={
              lesson.isUnavailable
                ? 'lesson-unavailable'
                : lesson.isCurrent
                  ? 'lesson-current'
                  : lesson.isCompleted
                    ? 'lesson-complete'
                    : ''
            }
          >
            {lesson.name}
          </button>
          {lesson.isCompleted && <box-icon name="check-double" size="1.6em" color="#63C093" />}
          {lesson.isUnavailable && <box-icon name="lock" size="1.6em" color="#C1C1C1" />}
        </li>
      ))}
    </LessonUl>
  );
};

LessonsList.displayName = 'Lessons List Component';

LessonsList.propTypes = {
  account: PropTypes.string,
  courseId: PropTypes.string,
  progress: PropTypes.array,
  current: PropTypes.number,
  list: PropTypes.array,
};

export default LessonsList;

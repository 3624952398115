import i18n from 'i18n';

const serpSearchTypes = [
  {
    value: 'mixed',
    label: i18n.t('Mixed'),
  },
  {
    value: 'web',
    label: i18n.t('Web'),
  },
  {
    value: 'news',
    label: i18n.t('News'),
  },
  {
    value: 'images',
    label: i18n.t('Images'),
  },
  {
    value: 'videos',
    label: i18n.t('Videos'),
  },
  {
    value: 'places',
    label: i18n.t('Places'),
  },
  {
    value: 'shopping',
    label: i18n.t('Shopping'),
  },
];

export { serpSearchTypes };

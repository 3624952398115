import i18n from 'i18n';

const selectWeekDays = [
  {
    value: 0,
    label: i18n.t('Sun'),
  },
  {
    value: 1,
    label: i18n.t('Mon'),
  },
  {
    value: 2,
    label: i18n.t('Tue'),
  },
  {
    value: 3,
    label: i18n.t('Wed'),
  },
  {
    value: 4,
    label: i18n.t('Thu'),
  },
  {
    value: 5,
    label: i18n.t('Fri'),
  },
  {
    value: 6,
    label: i18n.t('Sat'),
  },
];

const numToWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export { selectWeekDays, numToWeek };

import React, { useState, useEffect, useContext } from 'react';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import { useForm, Controller } from 'react-hook-form';
import 'boxicons';

import { loadTeam } from '../../utils';
import HeaderContext from './TasksFilter.Context';

const TasksFilter = ({ toggleFilter, accountId, isContent, show }) => {
  const formConfig = {
    defaultValues: {
      assignedTo: [],
      status: [],
      impact: [],
      complexity: [],
      foundations: [],
      search: '',
    },
  };
  const { t: translate } = useTranslation();
  const { control, reset, getValues } = useForm(formConfig);

  const { setFilter } = useContext(HeaderContext);
  const [assignedKey, setAssignedKey] = useState(0);

  useEffect(() => {
    setAssignedKey((prevState) => prevState + 1);
  }, [accountId]);

  const handleToggle = () => toggleFilter((prevState) => !prevState);

  const cleanFilters = () => {
    reset();
  };

  const applyFilters = () => {
    const dataObj = getValues();
    setFilter(dataObj);
  };

  return (
    <div className="header-wrapper" style={{ display: show ? 'flex' : 'none' }}>
      <div className="filter-menu">
        <form className="filter-menu">
          <div>
            <Controller
              control={control}
              name="assignedTo"
              render={({ field }) => (
                <AsyncSelect
                  {...field}
                  value={field.value}
                  key={assignedKey}
                  placeholder={translate('Assigned To')}
                  className="basic-multi-select filter-select"
                  cacheOptions
                  defaultOptions
                  loadOptions={(inputValue) => loadTeam(accountId, inputValue)}
                  isMulti
                />
              )}
            />

            <Controller
              control={control}
              name="status"
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  value={field.value}
                  placeholder={translate('Status')}
                  className="basic-multi-select filter-select"
                  options={[
                    { value: 0, label: translate('Waiting') },
                    { value: 1, label: translate('Ready to Implement') },
                    { value: 2, label: translate('Technical Impediment') },
                    { value: 3, label: translate('Under Development') },
                    { value: 4, label: translate('Implemented') },
                  ]}
                  isMulti
                />
              )}
            />

            {!isContent && (
              <>
                <Controller
                  control={control}
                  name="impact"
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      value={field.value}
                      placeholder={translate('Impact')}
                      className="basic-multi-select filter-select"
                      options={[
                        { value: 'L', label: translate('Low') },
                        { value: 'M', label: translate('Medium') },
                        { value: 'H', label: translate('High') },
                      ]}
                      isMulti
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="complexity"
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      value={field.value}
                      placeholder={translate('Complexity')}
                      className="basic-multi-select filter-select"
                      options={[
                        { value: 'L', label: translate('Low') },
                        { value: 'M', label: translate('Medium') },
                        { value: 'H', label: translate('High') },
                      ]}
                      isMulti
                    />
                  )}
                />
              </>
            )}
          </div>

          <div>
            {!isContent && (
              <Controller
                control={control}
                name="foundations"
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    value={field.value}
                    placeholder={translate('Foundations')}
                    className="basic-multi-select filter-select"
                    style={{ minWidth: '200px' }}
                    options={[
                      {
                        value: 'Technology',
                        label: translate('Technology'),
                      },
                      { value: 'Content', label: translate('Content') },
                      {
                        value: 'Link Building',
                        label: translate('Link Building'),
                      },
                      { value: 'Report', label: translate('Report') },
                      {
                        value: 'Performance',
                        label: translate('Performance'),
                      },
                      {
                        value: 'Architeture',
                        label: translate('Architeture'),
                      },
                    ]}
                    isMulti
                  />
                )}
              />
            )}
            <Controller
              control={control}
              name="search"
              render={({ field }) => (
                <div className="search">
                  <box-icon name="search" color="#606060"></box-icon>
                  <Input
                    {...field}
                    value={field.value}
                    placeholder={translate('Search')}
                    required
                  />
                </div>
              )}
            />
          </div>
        </form>
      </div>

      <div className="filter-buttons">
        <button
          className="filter-btn"
          style={{ backgroundColor: '#50A5F1' }}
          onClick={applyFilters}
        >
          <box-icon name="check" color="#FFF"></box-icon>
        </button>
        <button
          className="filter-btn"
          style={{ backgroundColor: '#F1B44C' }}
          onClick={cleanFilters}
        >
          <box-icon name="eraser" color="#FFF"></box-icon>
        </button>
        <button
          className="filter-btn"
          style={{ backgroundColor: '#F46A6A' }}
          onClick={handleToggle}
        >
          {translate('Go back')}
        </button>
      </div>
    </div>
  );
};

TasksFilter.propTypes = {
  toggleFilter: PropTypes.func,
  accountId: PropTypes.string,
  isContent: PropTypes.bool,
  show: PropTypes.bool,
};

export default TasksFilter;

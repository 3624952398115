import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col, Card, CardBody } from 'reactstrap';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

import { useAuth } from 'hooks/useAuth';
import { getFirebaseBackend } from '../../helpers/firebaseHelper';
import PageStructure from 'components/Page-Structure';
import '../../assets/scss/datatables.scss';
import '../../assets/scss/custom/pages/_users.scss';

const Tests = () => {
  const { t: translate } = useTranslation();
  const { user } = useAuth();
  const accountId = user?.account;
  const [testData, setTestData] = useState([]);
  const [tests, setTests] = useState([]);
  const firebaseHelper = getFirebaseBackend();

  const deleteTest = (id) => {
    firebaseHelper.removeFirebaseTest(accountId, id);

    fetchTests();
  };

  const actionButton = ({ id }) => (
    <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
      <Link to={`/edit-test?acc=${accountId}&id=${id}`}>
        <Button style={{ border: 'none', backgroundColor: '#E0E0E0' }}>
          <i className="bx bx-pencil" style={{ color: '#495057' }}></i>{' '}
          <span style={{ color: 'rgba(106, 113, 135, 1)' }}>{translate('Edit')}</span>
        </Button>
      </Link>

      <Button style={{ border: 'none', backgroundColor: '#F46A6A' }} onClick={() => deleteTest(id)}>
        <i className="bx bx-trash" style={{ color: '#fff' }}></i>{' '}
        <span style={{ color: '#fff' }}>{translate('Delete')}</span>
      </Button>
    </div>
  );

  const fetchTests = async () => {
    const tests = await firebaseHelper.getAllFirebaseTests(accountId);
    setTests(tests);
  };

  const processTests = async () => {
    const newTestData = await Promise.all(
      Object.entries(tests).map(async ([testId, testObj]) => {
        return {
          name: testObj.name,
          rightPercent: testObj.percent ? `${testObj.percent}%` : '-',
          scramble: testObj.scrambleQuestions ? translate('Yes') : translate('No'),
          answerKey: testObj.answerKey ? translate('Yes') : translate('No'),
          questions: testObj?.questions?.length || 0,
          action: actionButton({ ...testObj, id: testId }),
        };
      }),
    );
    setTestData(newTestData);
  };

  useEffect(() => {
    if (tests === null) return setTestData([]);

    processTests();
  }, [tests]);

  useEffect(() => {
    fetchTests();
  }, []);

  const columns = [
    {
      dataField: 'name',
      type: 'string',
      text: translate('Name'),
      sort: true,
      headerStyle: { width: '35%' },
    },
    {
      dataField: 'rightPercent',
      text: translate('Pass %'),
      type: 'number',
      sort: true,
      headerStyle: { textAlign: 'center', width: '10%' },
      style: { textAlign: 'center' },
    },
    {
      dataField: 'scramble',
      text: translate('Scrambled'),
      sort: true,
      headerStyle: { textAlign: 'center', width: '10%' },
      style: { textAlign: 'center' },
    },
    {
      dataField: 'answerKey',
      text: translate('Answer Key'),
      sort: true,
      headerStyle: { textAlign: 'center', width: '10%' },
      style: { textAlign: 'center' },
    },
    {
      dataField: 'questions',
      text: translate('Questions'),
      type: 'number',
      sort: true,
      headerStyle: { textAlign: 'center', width: '10%' },
      style: { textAlign: 'center' },
    },
    {
      dataField: 'action',
      text: translate('Action'),
      sort: false,
      headerStyle: { textAlign: 'center', width: '20%' },
    },
  ];

  const defaultSorted = [
    {
      dataField: 'name',
      order: 'asc',
    },
  ];

  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: testData.length },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: testData.length,
    custom: true,
    sizePerPageList,
  };

  const { SearchBar } = Search;

  return (
    <PageStructure metaTitle="Tests">
      <Row>
        <Col xs="12" className="mb-4">
          <h4 className="mb-0 font-size-18">
            {translate('Tests')} ({tests !== null ? Object.values(tests).length : 0})
          </h4>
        </Col>
      </Row>

      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="id"
                columns={columns}
                data={testData}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider keyField="id" columns={columns} data={testData} search>
                    {(toolkitProps) => (
                      <React.Fragment>
                        <Row className="mb-2 d-flex justify-content-between">
                          <Col md="4">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar
                                  {...toolkitProps.searchProps}
                                  placeholder={translate('Search')}
                                />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          <Col md="4" className="d-flex flex-row-reverse align-items-center">
                            <Link to="/create-test">
                              <Button
                                style={{
                                  backgroundColor: 'rgba(85, 110, 230, 1)',
                                }}
                              >
                                <i className="bx bx-plus-circle"></i> {translate('Create new Test')}
                              </Button>
                            </Link>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={'id'}
                                responsive
                                hover
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                classes={'table align-middle table-nowrap'}
                                headerWrapperClasses={'thead-light'}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row className="align-items-md-center mt-30">
                          <Col className="inner-custom-pagination d-flex">
                            <div className="d-inline">
                              <SizePerPageDropdownStandalone {...paginationProps} />
                            </div>
                            <div className="text-md-right ms-auto">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PageStructure>
  );
};

export default Tests;

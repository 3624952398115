import React, { useState } from 'react';
import MetaTags from 'react-meta-tags';
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  Input,
  FormGroup,
  Label,
  Button,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { getAuth, verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth';

import logo from '../../assets/images/small-logo.svg';
import LanguageDropdown from '../../components/Language-Dropdown';

const ResetPassword = () => {
  const { t: translate } = useTranslation();
  const url = new URL(window.location.href);
  const code = url.searchParams.get('oobCode');
  const formConfig = {
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    shouldFocusError: true,
  };
  const {
    handleSubmit,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm(formConfig);
  const [alertColor, setAlertColor] = useState('danger');
  const [alertBool, setAlertBool] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showForm, setShowForm] = useState(true);

  const onSubmit = async (data) => {
    setAlertBool(false);
    const auth = getAuth();

    try {
      const isCode = await verifyPasswordResetCode(auth, code);
      if (isCode) {
        await confirmPasswordReset(auth, code, data.password);
        setAlertColor('success');
        setAlertMessage('Password changed successfully!');
        setAlertBool(true);
        setShowForm(false);
      }
    } catch (error) {
      setAlertColor('danger');
      setAlertMessage(error.message);
      setAlertBool(true);
      setShowForm(true);
    }
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>{translate('Reset Password')} | Ectools</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div style={{ backgroundColor: 'rgba(242, 242, 242, 0.22)' }}>
                  <Row>
                    <Col xs={9}>
                      <div className="text-primary p-4">
                        <h5 style={{ color: '#344792' }}>{translate('Reset Password')}</h5>
                        <p style={{ color: '#344792' }}>
                          {translate('Recover your access to the Dashboard.')}
                        </p>
                      </div>
                    </Col>
                    <Col className="col-3" style={{ paddingLeft: '8%' }}>
                      <LanguageDropdown />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <img src={logo} alt={translate("Ectools' Logo")} height="36" />
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Alert color={alertColor} isOpen={alertBool}>
                      {translate(alertMessage)}
                    </Alert>

                    {showForm ? (
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <FormGroup className="mb-2" row>
                          <Col lg="12">
                            <Label className="col-form-label col-lg-12">
                              {translate('Password')}
                            </Label>
                            <Controller
                              control={control}
                              name="password"
                              rules={{
                                required: true,
                                minLength: 6,
                              }}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  value={field.value}
                                  innerRef={field.ref}
                                  type="password"
                                  className={`form-control ${
                                    errors?.password ? 'error--input' : null
                                  }`}
                                  placeholder={translate('Enter your new password')}
                                />
                              )}
                            />
                            {errors?.password && errors?.password?.type === 'required' && (
                              <div className="error--message">{translate('Required Field!')}</div>
                            )}
                            {errors?.password && errors?.password?.type === 'minLength' && (
                              <div className="error--message">
                                {translate('Password must have at least 6 characters!')}
                              </div>
                            )}
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Col lg="12">
                            <Label className="col-form-label col-lg-12">
                              {translate('Confirm Password')}
                            </Label>
                            <Controller
                              control={control}
                              name="confirmPassword"
                              rules={{
                                required: true,
                                validate: (value) => getValues('password') === value,
                              }}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  value={field.value}
                                  innerRef={field.ref}
                                  type="password"
                                  className={`form-control ${
                                    errors?.confirmPassword ? 'error--input' : null
                                  }`}
                                  placeholder={translate('Confirm the entered password')}
                                />
                              )}
                            />
                            {errors?.confirmPassword &&
                              errors?.confirmPassword?.type === 'required' && (
                                <div className="error--message">{translate('Required Field!')}</div>
                              )}
                            {errors?.confirmPassword &&
                              errors?.confirmPassword?.type === 'validate' && (
                                <div className="error--message">
                                  {translate('The Passwords need to match!')}
                                </div>
                              )}
                          </Col>
                        </FormGroup>
                        <Row className="justify-content-end">
                          <Col lg="12">
                            <Button
                              type="submit"
                              className="col-lg-12"
                              style={{ backgroundColor: '#344792' }}
                            >
                              {translate('Reset Password')}
                            </Button>
                          </Col>
                        </Row>
                      </form>
                    ) : null}

                    {!showForm ? (
                      <Row className="justify-content-end">
                        <Col lg="12">
                          <Link to="/login">
                            <Button
                              type="submit"
                              className="col-lg-12"
                              style={{ backgroundColor: '#344792' }}
                            >
                              {translate('Log in')}
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} {translate('Ecto Digital')}.
                  {` ${translate('Created with')} `}
                  <i className="mdi mdi-heart text-danger" />
                  {` ${translate('in Curitiba')}`}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ResetPassword;

import styled from 'styled-components';

export const ButtonFilterStyle = styled.div`
  button {
    display: flex;
    align-items: center;
    gap: 8px;
    border: 1px solid #e2e8f0;
    background-color: transparent;
    color: #2d3748 !important;
    font-size: 13px;
    font-weight: 500;
  }

  button:hover {
    border: 1px solid #e2e8f0;
    background-color: #f7fafc;
  }
`;

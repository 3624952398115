import * as React from 'react';
import PropTypes from 'prop-types';

const CircularArrowsIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M8 13.34a5.336 5.336 0 004.687-2.785M13.336 8A5.336 5.336 0 008 2.664M8 2.664A5.336 5.336 0 003.313 5.45M2.665 8a5.336 5.336 0 005.336 5.336M3.313 5.45A5.336 5.336 0 0113.336 8M2.665 8a5.336 5.336 0 0010.022 2.551"
        stroke={props.color ? props.color : '#4a5568'}
        strokeWidth={props.strokeWidth ? props.strokeWidth : '1.5'}
      />
      <path
        d="M5.364 5.452H3.006V3.094M10.637 10.555h2.357v2.358"
        stroke={props.color ? props.color : '#4a5568'}
      />
    </svg>
  );
};

CircularArrowsIcon.propTypes = {
  color: PropTypes.string,
  strokeWidth: PropTypes.string,
};

export { CircularArrowsIcon };

import React, { useEffect } from 'react';
import { useFieldArray, useFormContext, Controller } from 'react-hook-form';
import { Button, Label, Row, Col } from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import TextInput from '../../components/Text-Input';
import SwitchInput from '../../components/Switch-Input';
import DisplayErrors from 'components/Display-Errors';
import { toQuestionType } from '../../constants';
import { QuestionsCol, QuestionBox } from './styles';

const QuestionsInput = ({ controlName, size = 12, mb = 0 }) => {
  const { t: translate } = useTranslation();
  const {
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
    clearErrors,
  } = useFormContext();
  const { fields, append, remove, swap, update } = useFieldArray({
    control,
    name: controlName,
  });

  const onDragEnd = (result) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    swap(result.source.index, result.destination.index);
  };

  const handleRemove = (event, index) => {
    event.preventDefault();
    if (!event.pageX && !event.pageY) return;
    remove(index);
  };

  const handleAdd = (event, type) => {
    event.preventDefault();
    switch (type) {
      case 'select':
        append({
          type: 'select',
          question: '',
          options: [{ right: false, option: '' }],
        });
        break;
      case 'multi':
        append({
          type: 'multi',
          question: '',
          options: [{ right: false, option: '' }],
        });
        break;
      case 'code':
        append({ type: 'code', question: '', regex: '' });
        break;
      case 'open':
        append({ type: 'open', question: '', feedback: '' });
        break;
    }
  };

  const handleReset = (event) => {
    event.preventDefault();
    remove();
  };

  const addOption = (questionIndex) => {
    const arr = getValues(`${controlName}.${questionIndex}.options`);
    if (arr.at(-1).option) {
      arr.push({ right: false, option: '' });
      setValue(`${controlName}.${questionIndex}.options`, arr);
    }
    update(0, { ...watch(`${controlName}.0`) });
  };

  const removeOption = (questionIndex, optionIndex) => {
    const arr = getValues(`${controlName}.${questionIndex}.options`);
    arr.splice(optionIndex, 1);
    setValue(`${controlName}.${questionIndex}.options`, arr);
  };

  const resetAllSelected = (questionIndex, optionIndex) => {
    const arr = getValues(`${controlName}.${questionIndex}.options`);
    arr.forEach((option, index) => {
      if (index === optionIndex) return;
      setValue(`${controlName}.${questionIndex}.options.${index}`, {
        ...option,
        right: false,
      });
    });
  };

  useEffect(() => {}, [watch()]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <QuestionsCol lg={size} className={`mb-${mb}`}>
        <div className="questions-btn-menu">
          <Button onClick={(e) => handleAdd(e, 'multi')}>
            <box-icon name="plus-circle" color="#fff" size="1.1rem" />
            {translate('Multiple Choice')}
          </Button>

          <Button onClick={(e) => handleAdd(e, 'select')}>
            <box-icon name="plus-circle" color="#fff" size="1.1rem" />
            {translate('Selection Box')}
          </Button>

          <Button onClick={(e) => handleAdd(e, 'code')}>
            <box-icon name="plus-circle" color="#fff" size="1.1rem" />
            {translate('Code')}
          </Button>

          <Button onClick={(e) => handleAdd(e, 'open')}>
            <box-icon name="plus-circle" color="#fff" size="1.1rem" />
            {translate('Open Question')}
          </Button>

          {fields.length > 2 && (
            <Button className="reset-questions-btn" onClick={handleReset}>
              <box-icon name="trash-alt" size="1.1rem" />
              {translate('Delete questions')}
            </Button>
          )}
        </div>

        <DisplayErrors error={errors?.[controlName]?.types} />

        <Droppable droppableId="questions">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {fields.map(({ type, options }, index) => (
                <Draggable draggableId={`draggable-${index}`} index={index} key={type + index}>
                  {(provided) => (
                    <QuestionBox ref={provided.innerRef} {...provided.draggableProps}>
                      <div className="question-header-div">
                        <span>{translate(toQuestionType[type])}</span>

                        <button onClick={(e) => handleRemove(e, index)}>
                          <box-icon name="x" color="#808080" size="1.5rem" />
                        </button>
                      </div>

                      <div className="question-info-div">
                        <div {...provided.dragHandleProps}>{index + 1}</div>

                        <div>
                          <DisplayErrors
                            error={errors?.[controlName]?.[index]?.question?.types}
                            style={{ marginTop: '0', marginBottom: '0.4rem' }}
                          />

                          <TextInput
                            controlName={`${controlName}.${index}.question`}
                            placeholder="Enter the question"
                            size={12}
                            mb={2}
                            required
                          />

                          {type === 'open' && (
                            <Row>
                              <Label lg={3} htmlFor="">
                                {translate('User Feedback:')}
                              </Label>
                              <TextInput
                                controlName={`${controlName}.${index}.feedback`}
                                placeholder="Enter a message to be displayed when the user send the test"
                                size={9}
                              />
                            </Row>
                          )}

                          {type === 'code' && (
                            <>
                              <DisplayErrors
                                error={errors?.[controlName]?.[index]?.regex?.types}
                                style={{
                                  marginTop: '0',
                                  marginBottom: '0.4rem',
                                }}
                              />

                              <Row>
                                <Label lg={3} htmlFor="">
                                  {translate('Validation Regex*:')}
                                </Label>
                                <TextInput
                                  controlName={`${controlName}.${index}.regex`}
                                  mask={`\/${'.'.repeat(1000)}`}
                                  maskChar={null}
                                  placeholder="Enter the regular expression to validate the answer"
                                  size={7}
                                  required
                                />
                                <TextInput
                                  mask="\/fffffff"
                                  maskChar={null}
                                  onChange={() => clearErrors(`${controlName}.${index}.regex`)}
                                  controlName={`${controlName}.${index}.flags`}
                                  placeholder="Regex flags"
                                  size={2}
                                />
                              </Row>
                            </>
                          )}

                          {type === 'select' && (
                            <Row>
                              {options.map((option, optionIndex) => {
                                const isAdd = optionIndex === options.length - 1;

                                return (
                                  <>
                                    <DisplayErrors
                                      error={
                                        errors?.[controlName]?.[index]?.options?.[optionIndex]
                                          ?.option?.types
                                      }
                                      style={{ marginTop: '0.4rem' }}
                                    />

                                    <Row key={optionIndex} className="mt-2 pe-0">
                                      <SwitchInput
                                        controlName={`${controlName}.${index}.options.${optionIndex}.right`}
                                        label=""
                                        round
                                        size={1}
                                      />
                                      <TextInput
                                        controlName={`${controlName}.${index}.options.${optionIndex}.option`}
                                        placeholder="Answer Option"
                                        size={9}
                                        required
                                      />
                                      <Col lg={2} className="px-0">
                                        <Button
                                          onClick={
                                            isAdd
                                              ? () => addOption(index)
                                              : () => removeOption(index, optionIndex)
                                          }
                                          style={{
                                            width: '100%',
                                            backgroundColor: '#7367F0',
                                            borderColor: '#7367F0',
                                          }}
                                        >
                                          {translate(isAdd ? 'Add' : 'Remove')}
                                        </Button>
                                      </Col>
                                    </Row>
                                  </>
                                );
                              })}
                            </Row>
                          )}

                          {type === 'multi' && (
                            <Row>
                              {options.map((option, optionIndex) => {
                                const isAdd = optionIndex === options.length - 1;

                                return (
                                  <>
                                    <DisplayErrors
                                      error={
                                        errors?.[controlName]?.[index]?.options?.[optionIndex]
                                          ?.option?.types
                                      }
                                      style={{ marginTop: '0.4rem' }}
                                    />

                                    <Row key={optionIndex} className="mt-2 pe-0">
                                      <SwitchInput
                                        controlName={`${controlName}.${index}.options.${optionIndex}.right`}
                                        onChange={() => resetAllSelected(index, optionIndex)}
                                        label=""
                                        round
                                        size={1}
                                      />
                                      <TextInput
                                        controlName={`${controlName}.${index}.options.${optionIndex}.option`}
                                        placeholder="Answer Option"
                                        size={9}
                                        required
                                      />
                                      <Col lg={2} className="px-0">
                                        <Button
                                          onClick={
                                            isAdd
                                              ? () => addOption(index)
                                              : () => removeOption(index, optionIndex)
                                          }
                                          style={{
                                            width: '100%',
                                            backgroundColor: '#7367F0',
                                            borderColor: '#7367F0',
                                          }}
                                        >
                                          {translate(isAdd ? 'Add' : 'Remove')}
                                        </Button>
                                      </Col>
                                    </Row>
                                  </>
                                );
                              })}
                            </Row>
                          )}
                        </div>
                      </div>
                    </QuestionBox>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </QuestionsCol>
    </DragDropContext>
  );
};

QuestionsInput.displayName = 'Questions Input Component';

QuestionsInput.propTypes = {
  controlName: PropTypes.string,
  size: PropTypes.number,
  mb: PropTypes.number,
};

export default QuestionsInput;

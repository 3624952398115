import { getFirebaseBackend } from 'helpers/firebaseHelper';

const loadLessons = (accountId, inputValue) => {
  if (!accountId) return;

  const firebaseHelper = getFirebaseBackend();

  return new Promise(async (resolve, reject) => {
    const lessons = await firebaseHelper.getAllFirebaseLessons(accountId, 'Client');

    if (!lessons) return resolve([]);

    // Maps to React Select Object
    let select = Object.entries(lessons)?.map(([lessonId, lessonObj]) => ({
      value: lessonId,
      label: lessonObj.name,
    }));
    // Sorts Alphabetically
    select?.sort(({ label: firstLabel }, { label: secondLabel }) => {
      const normalizedA = firstLabel
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
      const normalizedB = secondLabel
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();

      return normalizedA > normalizedB ? 1 : normalizedB > normalizedA ? -1 : 0;
    });
    // Filters by input
    if (inputValue) {
      select = select?.filter(({ label }) => {
        // Normalize, remove accents and diacretics and lowercase the strings for comparison
        const normalizedTask = label
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase();
        const normalizedLabel = inputValue
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase();

        return normalizedTask.includes(normalizedLabel);
      });
    }

    resolve(select);
  });
};

export { loadLessons };

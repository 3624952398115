import React from 'react';
import PropTypes from 'prop-types';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Row, Col, FormGroup, Label, Button } from 'reactstrap';

import SelectInput from '../../components/Select-Input';
import TextInput from '../../components/Text-Input';
import DisplayErrors from '../../components/Display-Errors';

const NextSteps = ({ key, ...options }) => {
  const { t: translate } = useTranslation();
  const {
    control,
    getValues,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'steps',
  });

  const handleRemove = (event, index) => {
    event.preventDefault();
    // Remove the row only if it won't leave none to the user
    if (index === 0 && fields.length === 1) {
      update(0, { assignedTo: '', step: '' });
    } else {
      remove(index);
    }
  };

  const handleAdd = (event) => {
    event.preventDefault();
    // Add a row only if the last one is filled
    if (
      getValues(`steps.${fields.length - 1}.assignedTo`) &&
      getValues(`steps.${fields.length - 1}.step`)
    ) {
      append({ assignedTo: '', step: '' });
    }
  };

  const validateAssigned = (index) => {
    const assignedTo = getValues(`steps.${index}.assignedTo`);
    const step = getValues(`steps.${index}.step`);

    // If the field being validated is empty while its complementary field isn't return an error
    if (!assignedTo && step) {
      return 'The assigned user is required if the step is filled';
    }

    // If the field being validated is clear together with its complementary field clear the error
    if (!assignedTo && !step) {
      clearErrors(`steps.${index}.step`);
    }

    return true;
  };

  const validateStep = (index) => {
    const assignedTo = getValues(`steps.${index}.assignedTo`);
    const step = getValues(`steps.${index}.step`);

    // If the field being validated is empty while its complementary field isn't return an error
    if (!step && assignedTo) {
      return 'The step is required if the assigned user is selected';
    }

    // If the field being validated is clear together with its complementary field clear the error
    if (!step && !assignedTo) {
      clearErrors(`steps.${index}.assignedTo`);
    }

    return true;
  };

  return (
    <FormGroup className="mb-4 d-flex" row>
      <Col lg="10" className="ms-auto">
        <Label>{translate('Next Steps')}:</Label>
        {fields.map((field, index) => (
          <Row key={field.id} className="mb-2">
            <Col xl={10} lg={12}>
              <Row lg={12}>
                <SelectInput
                  asyncronous
                  controlName={`steps.${index}.assignedTo`}
                  placeholder="Assigned To One"
                  key={key}
                  validation={{
                    validate: () => validateAssigned(index),
                  }}
                  size={4}
                  {...options}
                />
                <TextInput
                  controlName={`steps.${index}.step`}
                  placeholder="Item"
                  size={8}
                  validation={{
                    validate: () => validateStep(index),
                  }}
                  style={{ height: '100%' }}
                />
              </Row>

              <Row lg={12} className="mt-1">
                <DisplayErrors size={3} error={errors?.steps?.[index]?.assignedTo?.types} />
                <DisplayErrors size={8} error={errors?.steps?.[index]?.step?.types} />
              </Row>
            </Col>

            <Col xl={2} lg={12}>
              <Button
                color="primary"
                style={{ width: '100%' }}
                onClick={(event) => handleRemove(event, index)}
              >
                {`${translate('Delete')}`}
              </Button>
            </Col>
          </Row>
        ))}
        <Button color="success" className="mt-2" onClick={(event) => handleAdd(event)}>
          {translate('Add')}
        </Button>
      </Col>
    </FormGroup>
  );
};

NextSteps.propTypes = {
  key: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
};

export default NextSteps;

import MetaTags from 'react-meta-tags';
import React, { useState } from 'react';
import { Row, Col, Alert, Card, CardBody, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import { useAuth } from 'hooks/useAuth';
import logo from '../../assets/images/small-logo.svg';
import LanguageDropdown from '../../components/Language-Dropdown';

const ForgetPasswordPage = () => {
  const { forgetPassword } = useAuth();
  const { t: translate } = useTranslation();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const changePassword = async (email) => {
    try {
      await forgetPassword(email);
      setSuccess(true);
    } catch (error) {
      setError(error);
    }
  };

  const handleValidSubmit = async (event, values) => {
    await changePassword(values.email);

    // Removes form from screen
    document.getElementById('instruction').style.display = 'none';
    document.getElementById('email-field').style.display = 'none';

    // Switches button
    document.getElementById('send-button').style.display = 'none';
    document.getElementById('back-button').style.display = 'block';
    document.getElementById('button-box').classList.add('text-center');
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>{translate('Reset Password')} | Ectools</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div style={{ backgroundColor: 'rgba(242, 242, 242, 0.22)' }}>
                  <Row>
                    <Col xs={9}>
                      <div className="text-primary p-4">
                        <h5 style={{ color: '#344792' }}>{translate('Reset Password')}</h5>
                        <p style={{ color: '#344792' }}>
                          {translate('Recover your access to the Dashboard.')}
                        </p>
                      </div>
                    </Col>
                    <Col className="col-3" style={{ paddingLeft: '8%' }}>
                      <LanguageDropdown />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <img src={logo} alt={translate("Ectools' Logo")} height="36" />
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {error && (
                      <Alert
                        color="danger"
                        style={{
                          marginTop: '13px',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        {translate(error)}
                      </Alert>
                    )}

                    {success && (
                      <Alert
                        color="success"
                        style={{
                          marginTop: '13px',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        {translate('Check the instructions in your email.')}
                      </Alert>
                    )}

                    <div
                      className="alert alert-success"
                      style={{ display: 'flex', justifyContent: 'center' }}
                      id="instruction"
                    >
                      {translate('Enter your email and instructions will be sent.')}
                    </div>

                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    >
                      <div className="mb-3" id="email-field">
                        <AvField
                          name="email"
                          label={translate('Email')}
                          className="form-control"
                          placeholder={translate('Enter email')}
                          type="email"
                          required
                        />
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end" id="button-box">
                          <button
                            className="btn w-md"
                            style={{
                              backgroundColor: '#344792',
                              color: '#FFF',
                            }}
                            type="submit"
                            id="send-button"
                          >
                            {translate('Send')}
                          </button>
                          <Link to="/login" id="back-button" style={{ display: 'none' }}>
                            <button
                              className="btn w-md"
                              style={{
                                backgroundColor: '#344792',
                                color: '#FFF',
                              }}
                              type="submit"
                              id="send-button"
                            >
                              {translate('Go back to Login')}
                            </button>
                          </Link>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} {translate('Ecto Digital')}.
                  {` ${translate('Created with')} `}
                  <i className="mdi mdi-heart text-danger" />
                  {` ${translate('in Curitiba')}`}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ForgetPasswordPage;

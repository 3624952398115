import React from 'react';
import PropTypes from 'prop-types';

import '../../assets/scss/custom/components/_shimmer.scss';

const Shimmer = ({ height = '1.5rem', width = '100%', style = {} }) => {
  return <div style={{ height, width, ...style }} className="shimmer"></div>;
};

Shimmer.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  style: PropTypes.object,
};

export default Shimmer;

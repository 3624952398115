import React, { useEffect, useState } from 'react';
import { Button, Input, Label } from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import { guidelineTypeOptions } from '../../../../constants';
import { useTranslation } from 'react-i18next';
import { loadTeam } from '../../../../utils';

import ContainerLabelDate from '../Container-Label-Date';
import { GuidelinesManageFilterLayout } from './styles';

const GuidelinesManageFilter = ({ accountsId, filter, setFilter, toggleFilter, fetchData }) => {
  const formConfig = {
    defaultValues: {
      assignedTo: [],
      startDate: new Date(),
      endDate: null,
      type: [],
    },
  };
  const { t: translate } = useTranslation();
  const { control, reset, getValues, setValue, watch } = useForm(formConfig);
  const [assignedKey, setAssignedKey] = useState(0);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [date, setDate] = useState('');

  const applyFilters = () => {
    const dataObj = getValues();
    setFilter(dataObj);
    fetchData(dataObj);
  };

  const cleanFilters = () => {
    reset();
    setDate('');
  };

  useEffect(() => {
    setAssignedKey((prevState) => prevState + 1);
  }, [accountsId]);

  return (
    <GuidelinesManageFilterLayout>
      {translate('Filters')}:
      <form>
        <div className="menu-inline">
          <div className="menu-column">
            <Label className="filter-label" for="assignedTo">
              {translate('Assigned To')}
            </Label>
            <Controller
              control={control}
              name="assignedTo"
              render={({ field }) => (
                <AsyncSelect
                  {...field}
                  value={field.value}
                  key={assignedKey}
                  placeholder={translate('Select')}
                  className="basic-multi-select filter-select"
                  cacheOptions
                  defaultOptions
                  loadOptions={(inputValue) => loadTeam(accountsId, inputValue)}
                  isMulti
                />
              )}
            />
          </div>
          <div className="menu-column">
            <Label className="filter-label" for="input-date">
              {translate('Date')}
            </Label>
            <Input
              value={date || ''}
              placeholder={date ? date : translate('Select')}
              className="basic-multi-select filter-select"
              id="input-date"
              style={{ minWidth: '200px' }}
              onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
              onMouseEnter={() => setIsDatePickerOpen(true)}
              readOnly
            />
          </div>
          {isDatePickerOpen && (
            <div className="menu-label-date">
              <ContainerLabelDate
                date={date}
                setDate={setDate}
                watch={watch}
                setValue={setValue}
                getValues={getValues}
                setIsDatePickerOpen={setIsDatePickerOpen}
              />
            </div>
          )}
          <div className="menu-column">
            <Label className="filter-label" for="type">
              {translate('Types of content')}
            </Label>
            <Controller
              control={control}
              name="type"
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  value={field.value}
                  placeholder={translate('Select')}
                  className="basic-multi-select filter-select"
                  options={guidelineTypeOptions}
                  isMulti
                />
              )}
            />
          </div>
          <div className="filter-btn">
            <Button className="filter-btn-apply" onClick={applyFilters}>
              <box-icon name="check" color="#FFF" />
            </Button>
            <Button className="filter-btn-clear" onClick={cleanFilters}>
              <box-icon name="eraser" color="#FFF" style={{ margin: 0 }} />
            </Button>
            <Button className="filter-btn-back" onClick={toggleFilter}>
              {translate('Go back')}
            </Button>
          </div>
        </div>
      </form>
    </GuidelinesManageFilterLayout>
  );
};

GuidelinesManageFilter.propTypes = {
  accountsId: PropTypes.array.isRequired,
  filter: PropTypes.object,
  setFilter: PropTypes.func.isRequired,
  toggleFilter: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
};

export default GuidelinesManageFilter;

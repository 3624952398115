const sizes = {
  xs3: '320px',
  xs2: '475px',
  xs: '625px',
  sm: '768px',
  mds: '1024px',
  md: '1280px',
  mdl: '1360px',
  lg: '1440px',
  xl: '2560px',
};

const mediaQuery = {
  ...sizes,
  more: {
    xs3: `(min-width: ${sizes.xs3})`,
    xs2: `(min-width: ${sizes.xs2})`,
    xs: `(min-width: ${sizes.xs})`,
    sm: `(min-width: ${sizes.sm})`,
    mds: `(min-width: ${sizes.mds})`,
    md: `(min-width: ${sizes.md})`,
    mdl: `(min-width: ${sizes.mdl})`,
    lg: `(min-width: ${sizes.lg})`,
    xl: `(min-width: ${sizes.xl})`,
  },
  less: {
    xs3: `(max-width: ${sizes.xs3})`,
    xs2: `(max-width: ${sizes.xs2})`,
    xs: `(max-width: ${sizes.xs})`,
    sm: `(max-width: ${sizes.sm})`,
    mds: `(max-width: ${sizes.mds})`,
    md: `(max-width: ${sizes.md})`,
    mdl: `(max-width: ${sizes.mdl})`,
    lg: `(max-width: ${sizes.lg})`,
    xl: `(max-width: ${sizes.xl})`,
  },
};

export { mediaQuery };

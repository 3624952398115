import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Input, Label } from 'reactstrap';

import DisplayErrors from '../Display-Errors';
import { TextAreaTag } from './styles';

const TextAreaInput = ({
  controlName,
  cols,
  rows,
  manualResize = false,
  placeholder = controlName,
  required = false,
  size = 12,
  mb = 0,
  label,
  tag = false,
  tagContent = '',
  tagColor = '#65A4EB',
  onChange = null,
  validation = {},
  ...other
}) => {
  const { t: translate } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const validate = required ? { ...validation, required: 'Required Field!' } : validation;

  const canResize = manualResize ? {} : { resize: 'none' };

  return (
    <Col lg={size} className={`mb-${mb}`}>
      {label && (
        <Label htmlFor={controlName} className="col-lg-12 mt-2">
          {`${translate(label)}${required ? ' *' : ''}`}
        </Label>
      )}
      <Controller
        control={control}
        name={controlName}
        rules={validate}
        render={({ field }) => (
          <Input
            {...field}
            id={controlName}
            value={field.value}
            innerRef={field.ref}
            onChange={(event) => {
              field.onChange(event);
              if (onChange) onChange(event);
            }}
            type="textarea"
            cols={cols}
            rows={rows}
            className={`form-control ${errors?.[controlName] ? 'error--input' : ''}`}
            placeholder={translate(placeholder)}
            style={canResize}
            {...other}
          />
        )}
      />

      {tag && <TextAreaTag color={tagColor}>{tagContent}</TextAreaTag>}

      <DisplayErrors error={errors?.[controlName]?.types} />
    </Col>
  );
};

TextAreaInput.propTypes = {
  controlName: PropTypes.string.isRequired,
  cols: PropTypes.number,
  rows: PropTypes.number,
  manualResize: PropTypes.bool,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  size: PropTypes.number,
  mb: PropTypes.number,
  label: PropTypes.string,
  tag: PropTypes.bool,
  tagContent: PropTypes.string,
  tagColor: PropTypes.string,
  onChange: PropTypes.func,
  validation: PropTypes.object,
};

export default TextAreaInput;

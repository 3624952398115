import styled from 'styled-components';

export const ButtonDenyStyle = styled.div`
  .btn-deny {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    border: none;
    background-color: #f56565;
    font-weight: 500;
  }
  .btn-deny:hover {
    background-color: #c53030;
  }
`;

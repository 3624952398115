import * as React from 'react';

const WordpressIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_9954_21615)">
        <path
          d="M32.75 16C32.75 7.184 25.566 0 16.75 0C7.918 0 0.75 7.184 0.75 16C0.75 24.832 7.918 32 16.75 32C25.566 32 32.75 24.832 32.75 16ZM13.198 24.592L7.742 9.952C8.622 9.92 9.614 9.824 9.614 9.824C10.414 9.728 10.318 8.016 9.518 8.048C9.518 8.048 7.198 8.224 5.726 8.224C5.438 8.224 5.134 8.224 4.798 8.208C6.09437 6.22286 7.86743 4.59414 9.95524 3.47057C12.0431 2.34701 14.3791 1.76442 16.75 1.776C20.478 1.776 23.87 3.168 26.43 5.52C25.342 5.344 23.79 6.144 23.79 8.048C23.79 9.232 24.51 10.224 25.23 11.408C25.79 12.384 26.11 13.584 26.11 15.344C26.11 17.728 23.87 23.344 23.87 23.344L19.022 9.952C19.886 9.92 20.334 9.68 20.334 9.68C21.134 9.6 21.038 7.68 20.238 7.728C20.238 7.728 17.934 7.92 16.43 7.92C15.038 7.92 12.702 7.728 12.702 7.728C11.902 7.68 11.806 9.648 12.606 9.68L14.078 9.808L16.094 15.264L13.198 24.592ZM28.606 16C28.99 14.976 29.79 13.008 29.294 9.2C30.414 11.264 30.974 13.536 30.974 16C30.974 21.264 28.206 25.984 23.934 28.448C25.486 24.304 27.038 20.128 28.606 16ZM10.51 28.944C5.742 26.64 2.526 21.648 2.526 16C2.526 13.92 2.894 12.032 3.678 10.256C5.95 16.48 8.222 22.72 10.51 28.944ZM16.958 18.336L21.086 29.504C19.71 29.968 18.27 30.224 16.75 30.224C15.486 30.224 14.238 30.048 13.086 29.696C14.382 25.888 15.678 22.112 16.958 18.336Z"
          fill="#0D5D83"
        />
      </g>
      <defs>
        <clipPath id="clip0_9954_21615">
          <rect width="32" height="32" fill="white" transform="translate(0.75)" />
        </clipPath>
      </defs>
    </svg>
  );
};

const StrapiIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_9954_21618)">
        <path
          d="M0.75 11.0933C0.75 5.86389 0.75 3.24916 2.37458 1.62458C3.99916 0 6.61389 0 11.8433 0H21.6567C26.8861 0 29.5008 0 31.1254 1.62458C32.75 3.24916 32.75 5.86389 32.75 11.0933V20.9067C32.75 26.1361 32.75 28.7508 31.1254 30.3754C29.5008 32 26.8861 32 21.6567 32H11.8433C6.61389 32 3.99916 32 2.37458 30.3754C0.75 28.7508 0.75 26.1361 0.75 20.9067V11.0933Z"
          fill="#4945FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.83 9.70703H12.0566V15.2004H17.55V20.6937H23.0433V9.92036C23.0433 9.80255 22.9478 9.70703 22.83 9.70703Z"
          fill="white"
        />
        <path d="M17.5502 15.2002H17.3369V15.4135H17.5502V15.2002Z" fill="white" />
        <path
          d="M12.0566 15.2002H17.3366C17.4545 15.2002 17.55 15.2957 17.55 15.4135V20.6935H12.27C12.1522 20.6935 12.0566 20.598 12.0566 20.4802V15.2002Z"
          fill="#9593FF"
        />
        <path
          d="M17.5498 20.6934H23.0431L17.7319 26.0046C17.6647 26.0718 17.5498 26.0242 17.5498 25.9292V20.6934Z"
          fill="#9593FF"
        />
        <path
          d="M12.0566 15.2004H6.82074C6.7257 15.2004 6.67813 15.0855 6.74533 15.0183L12.0566 9.70703V15.2004Z"
          fill="#9593FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_9954_21618">
          <rect width="32" height="32" fill="white" transform="translate(0.75)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { WordpressIcon, StrapiIcon };

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import _, { set } from 'lodash';
import axios from 'axios';

import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { CardInputGoogleDriveStyled } from './styles';
import TextInput from 'components/Text-Input';
import LabelGroup from 'components/Label-Group';
import { MoonLoader } from 'react-spinners';

const CardInputGoogleDrive = ({ accountId, watch }) => {
  const { t: translate } = useTranslation();
  const firebaseHelper = getFirebaseBackend();
  const [isSaved, setIsSaved] = useState(false);
  const [isCreatingFolder, setIsCreatingFolder] = useState(false);
  const [gDriveFolder, setGDriveFolder] = useState('');

  const handleCreateNewFolder = async () => {
    setIsCreatingFolder(true);
    const accountName = await getAccountName();

    try {
      const response = await axios.post(
        process.env.REACT_APP_GDOCS_WEBHOOK_CREATE_FOLDER,
        {
          accountName,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_GDOCS_FLOW_KEY}`,
          },
        },
      );

      if (response.data) {
        setGDriveFolder(response.data.root);

        await firebaseHelper.saveGDriveFolderLink(accountId, response.data);

        setIsSaved(true);
      }
      setIsCreatingFolder(false);
    } catch (error) {
      setIsCreatingFolder(false);
      setIsSaved(false);
      console.log(error);
    }
  };

  const getAccountName = async () => {
    const accountName = await firebaseHelper.accountName(accountId);
    return accountName;
  };

  const openInputLink = () => {
    window.open(gDriveFolder, '_blank');
  };

  useEffect(async () => {
    const folderLink = await firebaseHelper.getGDriveFolderLink(accountId);

    if (folderLink) {
      setGDriveFolder(folderLink);
      setIsSaved(true);
    }
  }, [accountId]);

  return (
    <CardInputGoogleDriveStyled>
      <LabelGroup htmlFor="gDriveFolder" label="" mb={0} column>
        <div className="d-flex gap-2 align-items-center mb-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="33"
            viewBox="0 0 32 33"
            fill="none"
          >
            <path
              d="M1.43181 21.043C1.42651 21.513 1.54536 21.9842 1.78836 22.4054L5.03959 28.042C5.5158 28.8676 6.39643 29.3763 7.34953 29.3763H24.6494C25.6025 29.3763 26.4831 28.8676 26.9593 28.042L30.2106 22.4054C30.4536 21.9841 30.5724 21.513 30.5671 21.043H1.43181Z"
              fill="#4285F4"
            />
            <path
              d="M25.994 29.0132L11.4102 3.75331C11.8164 3.51105 12.2867 3.37695 12.776 3.37695H19.2231C20.1758 3.37695 21.0561 3.88522 21.5325 4.71029L30.2101 19.7404C30.6863 20.5651 30.6865 21.5812 30.2107 22.4061L26.9594 28.0427C26.7234 28.4518 26.3882 28.7831 25.994 29.0132Z"
              fill="#FBBC04"
            />
            <path
              d="M20.5889 3.75331C20.1826 3.51105 19.7124 3.37695 19.223 3.37695H12.7759C11.8232 3.37695 10.9429 3.88522 10.4665 4.71029L1.78891 19.7404C1.31275 20.5651 1.31254 21.5812 1.78836 22.4061L5.03959 28.0427C5.27556 28.4518 5.61085 28.7831 6.00506 29.0132L20.5889 3.75331Z"
              fill="#34A853"
            />
            <path
              d="M30.5671 21.043C30.5724 21.513 30.4535 21.9841 30.2105 22.4054L26.9593 28.042C26.7233 28.4511 26.388 28.7824 25.9938 29.0126L21.3926 21.043H30.5671Z"
              fill="#EA4335"
            />
            <path
              d="M20.589 3.75331C20.1827 3.51105 19.7125 3.37695 19.2231 3.37695H12.776C12.2867 3.37695 11.8164 3.51105 11.4102 3.7533L15.9996 11.7024L20.589 3.75331Z"
              fill="#188038"
            />
            <path
              d="M1.43181 21.043C1.42651 21.513 1.54536 21.9842 1.78836 22.4054L5.03959 28.042C5.27556 28.4511 5.61085 28.7824 6.00506 29.0126L10.6063 21.043H1.43181Z"
              fill="#1967D2"
            />
          </svg>

          {translate('File directory')}
        </div>
        {isSaved && (
          <div className="d-flex gap-1">
            <div>
              <TextInput
                id="gDriveFolder"
                controlName="gDriveFolder"
                value={gDriveFolder}
                noEllipsis
              />
            </div>
            {isSaved && (
              <Button className="btn-go-to open-link-button" onClick={openInputLink}>
                <i className="bx bx-link-external" />
              </Button>
            )}
          </div>
        )}
      </LabelGroup>
      {!isSaved && (
        <div className="d-flex gap-2">
          <Button
            className="btn-new-folder"
            onClick={handleCreateNewFolder}
            disabled={isCreatingFolder}
          >
            {isCreatingFolder ? (
              <MoonLoader size={20} color="#000" />
            ) : (
              <>
                <i className="bx bx-plus" />
                {translate('Create new folder')}
              </>
            )}
          </Button>
        </div>
      )}
    </CardInputGoogleDriveStyled>
  );
};

CardInputGoogleDrive.propTypes = {
  accountId: PropTypes.string,
  watch: PropTypes.func,
};

export default CardInputGoogleDrive;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row, Input } from 'reactstrap';

const RadioInput = ({
  controlName,
  className,
  id,
  label,
  onChange = null,
  required = false,
  size = 12,
  validation = {},
  ...other
}) => {
  const { t: translate } = useTranslation();
  const [checked, setChecked] = useState();
  const {
    control,
    formState: { errors },
    getValues,
    watch,
  } = useFormContext();

  const validate = required ? { ...validation, required: 'Required Field!' } : validation;

  useEffect(() => {
    const value = getValues(controlName);
    if (value === id) setChecked(true);
    else setChecked(false);
  }, [watch(controlName)]);

  return (
    <Col lg={size} className={className ? className : 'px-3'}>
      <Controller
        control={control}
        name={controlName}
        rules={validate}
        render={({ field }) => (
          <>
            <Input
              {...field}
              name={controlName}
              type="radio"
              id={id}
              value={id}
              innerRef={field.ref}
              className="form-radio-primary"
              checked={checked ? true : null}
              onChange={(event) => {
                field.onChange(event);
                if (onChange) onChange(event);
              }}
              {...other}
            />
            <label htmlFor={id} className="ms-2">
              {translate(label)}
            </label>
          </>
        )}
      />
    </Col>
  );
};

RadioInput.displayName = 'Radio Input Component';

RadioInput.propTypes = {
  controlName: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  validation: PropTypes.object,
};

export default RadioInput;

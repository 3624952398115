/* eslint-disable react/no-unknown-property */
import * as React from 'react';
import PropTypes from 'prop-types';

export const HomepageNoActivity = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="277"
      height="239"
      viewBox="0 0 277 239"
      fill="none"
      {...props}
    >
      <path
        d="M241.483 78.9894C245.58 78.9894 248.9 75.6689 248.9 71.5727C248.9 67.4766 245.58 64.156 241.483 64.156C237.387 64.156 234.067 67.4766 234.067 71.5727C234.067 75.6689 237.387 78.9894 241.483 78.9894Z"
        fill="#EAEEF9"
      />
      <path
        d="M252.337 50.0464C255.134 50.0464 257.402 47.7787 257.402 44.9813C257.402 42.184 255.134 39.9163 252.337 39.9163C249.539 39.9163 247.272 42.184 247.272 44.9813C247.272 47.7787 249.539 50.0464 252.337 50.0464Z"
        fill="#EAEEF9"
      />
      <path
        d="M50.573 47.8668C53.3704 47.8668 55.6381 45.5991 55.6381 42.8018C55.6381 40.0044 53.3704 37.7367 50.573 37.7367C47.7756 37.7367 45.5079 40.0044 45.5079 42.8018C45.5079 45.5991 47.7756 47.8668 50.573 47.8668Z"
        fill="#F1F3F9"
      />
      <path
        d="M19.64 145.551C24.8351 145.551 29.0465 141.339 29.0465 136.144C29.0465 130.949 24.8351 126.738 19.64 126.738C14.4449 126.738 10.2334 130.949 10.2334 136.144C10.2334 141.339 14.4449 145.551 19.64 145.551Z"
        fill="#EAEEF9"
      />
      <path
        d="M138.127 200C188.777 200 229.841 158.937 229.841 108.105C229.841 57.2735 188.596 16.2102 138.127 16.2102C87.4758 16.2102 46.4125 57.2735 46.4125 108.105C46.4125 158.937 87.4758 200 138.127 200Z"
        fill="#EAEEF9"
      />
      <g filter="url(#filter0_d_10042_4234)">
        <path
          d="M236.551 46.5941V169.703C236.551 175.094 232.185 179.513 226.795 179.513H49.839C44.4489 179.513 40.0291 175.147 40.0291 169.703V46.5941C40.0291 41.204 44.395 36.838 49.839 36.838H226.795C232.185 36.838 236.551 41.204 236.551 46.5941Z"
          fill="url(#paint0_linear_10042_4234)"
        />
      </g>
      <path
        d="M236.551 46.5941V56.7813H40.0291V46.5941C40.0291 41.204 44.395 36.838 49.839 36.838H226.795C232.185 36.838 236.551 41.204 236.551 46.5941Z"
        fill="#D5DDEA"
      />
      <path
        d="M50.6473 49.4506C52.1357 49.4506 53.3424 48.244 53.3424 46.7556C53.3424 45.2672 52.1357 44.0605 50.6473 44.0605C49.1589 44.0605 47.9523 45.2672 47.9523 46.7556C47.9523 48.244 49.1589 49.4506 50.6473 49.4506Z"
        fill="#989FB0"
      />
      <path
        d="M58.7324 49.4506C60.2208 49.4506 61.4274 48.244 61.4274 46.7556C61.4274 45.2672 60.2208 44.0605 58.7324 44.0605C57.244 44.0605 56.0374 45.2672 56.0374 46.7556C56.0374 48.244 57.244 49.4506 58.7324 49.4506Z"
        fill="#989FB0"
      />
      <path
        d="M66.7635 49.4506C68.252 49.4506 69.4586 48.244 69.4586 46.7556C69.4586 45.2672 68.252 44.0605 66.7635 44.0605C65.2751 44.0605 64.0685 45.2672 64.0685 46.7556C64.0685 48.244 65.2751 49.4506 66.7635 49.4506Z"
        fill="#989FB0"
      />
      <path d="M110.477 72.6282H57.3311V125.172H110.477V72.6282Z" fill="#D5DDEA" />
      <path
        d="M183.277 72.3636H219.949"
        stroke="#D6DCE8"
        stroke-width="7.20452"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M125.978 72.3636H167.233"
        stroke="#D6DCE8"
        stroke-width="7.20452"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M125.978 89.5535H219.949"
        stroke="#D6DCE8"
        stroke-width="7.20452"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M163.795 106.743H219.949"
        stroke="#D6DCE8"
        stroke-width="7.20452"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M125.978 106.743H150.044"
        stroke="#D6DCE8"
        stroke-width="7.20452"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M125.978 123.933H177.547"
        stroke="#D6DCE8"
        stroke-width="7.20452"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M167.233 141.123H219.949"
        stroke="#D6DCE8"
        stroke-width="7.20452"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M57.2186 141.123H151.19"
        stroke="#D6DCE8"
        stroke-width="7.20452"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M109.934 158.313H178.693"
        stroke="#D6DCE8"
        stroke-width="7.20452"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M57.2186 158.313H93.8902"
        stroke="#D6DCE8"
        stroke-width="7.20452"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M250.371 195.104H198.695C194.602 195.104 191.299 191.801 191.299 187.708V86.6352C191.299 82.5421 194.602 79.2396 198.695 79.2396H250.371C254.464 79.2396 257.767 82.5421 257.767 86.6352V187.708C257.767 191.801 254.464 195.104 250.371 195.104Z"
        fill="url(#paint1_linear_10042_4234)"
      />
      <path
        d="M248.65 178.685H200.416C198.881 178.685 197.671 177.475 197.671 175.94V88.5422C197.671 87.0073 198.881 85.798 200.416 85.798H248.65C250.185 85.798 251.394 87.0073 251.394 88.5422V175.94C251.394 177.475 250.185 178.685 248.65 178.685Z"
        fill="#F4F6FA"
      />
      <path
        d="M224.556 190.127C226.688 190.127 228.417 188.398 228.417 186.266C228.417 184.134 226.688 182.406 224.556 182.406C222.424 182.406 220.696 184.134 220.696 186.266C220.696 188.398 222.424 190.127 224.556 190.127Z"
        fill="#AAB2C5"
      />
      <path
        d="M138.168 57.163C154.113 57.163 167.04 44.3666 167.04 28.5815C167.04 12.7964 154.113 0 138.168 0C122.222 0 109.296 12.7964 109.296 28.5815C109.296 44.3666 122.222 57.163 138.168 57.163Z"
        fill="url(#paint2_linear_10042_4234)"
      />
      <path
        d="M154.958 27.1806C154.958 25.5474 153.584 25.5474 152.209 25.4113C150.971 25.2752 150.834 24.1864 150.284 23.0976C149.321 21.056 153.034 20.1033 151.109 18.1979C149.184 16.2924 148.222 14.7953 146.709 15.8841C145.747 16.5646 145.059 17.3813 143.96 16.8369C142.86 16.2924 141.76 16.1563 141.622 14.9314C141.21 11.8011 140.935 12.2094 136.673 12.2094C134.06 12.2094 135.848 15.4758 133.786 16.2924C132.548 16.7007 131.723 17.3813 130.761 16.7007C128.149 14.7953 128.424 15.2036 125.261 18.1979C124.161 19.2867 124.986 20.2394 125.949 21.4643C126.636 22.417 125.949 23.2337 125.536 24.4586C124.849 26.5001 121.412 24.7308 121.412 27.3167C121.412 28.5416 121.412 29.7666 121.549 30.9915C122.099 32.4886 123.337 31.8081 124.574 32.3525H124.711C124.711 32.3525 124.711 32.3525 124.849 32.3525C125.124 32.4886 125.399 32.8969 125.536 33.1691C125.536 33.3052 125.674 33.7135 125.811 33.8496C126.224 34.8024 126.636 35.3468 125.949 36.1634C124.161 38.6132 124.436 38.4771 127.461 41.6075C129.248 43.5129 130.486 39.8381 132.411 40.7909C133.236 41.1992 134.335 41.4714 134.61 42.288C134.885 42.8324 134.748 43.7851 135.16 44.4656C135.71 45.6905 137.635 45.2822 139.697 45.2822C142.31 45.2822 140.522 42.0158 142.585 41.1992C145.059 40.3825 144.509 40.1103 146.572 41.6075C148.084 42.6963 149.321 40.927 150.971 39.2937C152.896 37.3883 149.184 36.2995 150.146 34.394C150.284 33.9857 150.559 33.5774 150.696 33.1691C151.109 32.0803 152.071 32.0803 153.309 31.9442C155.371 31.672 154.958 29.7666 154.958 27.1806ZM138.185 34.5301C135.023 34.5301 132.411 31.9442 132.411 28.8139C132.411 25.6835 135.023 23.0976 138.185 23.0976C141.347 23.0976 143.96 25.6835 143.96 28.8139C144.097 31.9442 141.485 34.5301 138.185 34.5301Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_10042_4234"
          x="0.404202"
          y="17.0256"
          width="275.772"
          height="221.925"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="19.8124" />
          <feGaussianBlur stdDeviation="19.8124" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10042_4234" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_10042_4234"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_10042_4234"
          x1="138.226"
          y1="33.5378"
          x2="138.226"
          y2="181.052"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FDFEFF" />
          <stop offset="0.9964" stop-color="#ECF0F5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_10042_4234"
          x1="199.352"
          y1="88.2554"
          x2="244.38"
          y2="188.218"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B0BACC" />
          <stop offset="1" stop-color="#969EAE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_10042_4234"
          x1="109.269"
          y1="28.5877"
          x2="167.05"
          y2="28.5877"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#5CB5FF" />
          <stop offset="1" stop-color="#4299E1" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import 'boxicons';

import { getFirebaseBackend } from '../../helpers/firebaseHelper';
import PageStructure from '../../components/Page-Structure';
import ShowComponent from '../../components/Show-Component';
import VerticalTimeline from '../../components/Vertical-Timeline';
import Shimmer from '../../components/Shimmer';

const Changelog = () => {
  const firebaseHelper = getFirebaseBackend();

  const { t: translate } = useTranslation();
  const [versions, setVersions] = useState();
  const [loading, setLoading] = useState(true);

  const setChangelog = useCallback(async () => {
    const versionsObj = await firebaseHelper.getAllVersions();
    const versionsArr = Object.entries(versionsObj).reverse();
    const versionsFinal = versionsArr.map(([number, logs]) => {
      const released = !number.match(/n/g);
      const title = released ? number.replaceAll('-', '.') : translate('Next Update');
      const description = logs.reduce((acc, cur) => (acc += `- ${translate(cur)}\n`), '');
      return {
        title,
        icon: released ? 'check-square' : 'rotate-right',
        iconColor: released ? '#34C38F' : '#65A4EB',
        description,
      };
    });

    setVersions(versionsFinal);
    setLoading(false);
  }, []);

  useEffect(() => {
    setChangelog();
  }, [setChangelog]);

  return (
    <PageStructure
      card
      metaTitle="What's New"
      breadcrumbItem="What's New"
      breadcrumbTitle="Support"
    >
      <ShowComponent condition={loading}>
        <Shimmer height="5rem" style={{ marginBottom: '1em' }} />
        <Shimmer height="5rem" style={{ marginBottom: '1em' }} />
        <Shimmer height="5rem" style={{ marginBottom: '1em' }} />
        <Shimmer height="5rem" style={{ marginBottom: '1em' }} />
        <Shimmer height="5rem" style={{ marginBottom: '1em' }} />
      </ShowComponent>

      <ShowComponent condition={!loading}>
        <VerticalTimeline data={versions} />
      </ShowComponent>
    </PageStructure>
  );
};

Changelog.propTypes = {};

export default Changelog;

import React from 'react';
import download from 'downloadjs';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { FileButton } from './styles';
import { formatBytes } from 'utils';

const FileTable = ({ title, data, setData }) => {
  const firebaseHelper = getFirebaseBackend();
  const { t: translate } = useTranslation();

  const deleteFile = (file, index) => {
    firebaseHelper.removeFile(file.fullPath).then(async () => {
      const newFiles = _.cloneDeep(data);
      newFiles.splice(index, 1);
      setData(newFiles);
    });
  };

  const downloadFile = async (file) => {
    const fileObj = await firebaseHelper.getFileObj(file.fullPath);
    download(fileObj, file.name, file.contentType);
  };

  return (
    <div className="mt-4">
      <h4 className="d-flex align-items-center">
        <i className="bx bx-paperclip" style={{ color: 'rgba(173, 181, 189, 1)' }}></i>
        <p style={{ margin: '0', marginLeft: '.5em' }}>{translate(title)}</p>
      </h4>
      <hr style={{ margin: '1em 0 1em 0' }}></hr>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">{translate('File Name')}</th>
            <th scope="col">{translate('Uploaded')}</th>
            <th scope="col">{translate('Size')}</th>
            <th scope="col"> </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((file, index) => (
            <tr key={index} style={{ cursor: 'auto' }}>
              <td>{file.name}</td>
              <td>{new Date(file.timeCreated).toLocaleDateString()}</td>
              <td>{formatBytes(file.size)}</td>
              <td>
                {
                  <div className="d-flex gap-1">
                    <FileButton onClick={() => downloadFile(file)} blue>
                      <i className="bx bx-download"></i>
                    </FileButton>

                    <FileButton onClick={() => deleteFile(file, index)}>
                      <i className="bx bx-trash"></i>
                    </FileButton>
                  </div>
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

FileTable.displayName = 'File Table Component';

FileTable.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  setData: PropTypes.func,
};

export default FileTable;

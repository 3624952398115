import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import '../../assets/scss/custom/components/_changelogmodal.scss';
import 'boxicons';

import { getFirebaseBackend } from '../../helpers/firebaseHelper';

const ChangelogModal = () => {
  const firebaseHelper = getFirebaseBackend();

  const { t: translate } = useTranslation();
  const [cookies, setCookies] = useCookies();
  const [changelog, setChangelog] = useState();
  const [changelogDescription, setChangelogDescription] = useState();
  const [version, setVersion] = useState();

  useEffect(async () => {
    const currentVersion = await firebaseHelper.getCurrentVersion();
    const cookieVersion = cookies.version;

    if (isVersionBigger(currentVersion, cookieVersion)) {
      const versionDescription = await firebaseHelper.getVersion(currentVersion);

      setChangelog(true);
      setChangelogDescription(versionDescription);
      setVersion(currentVersion);
    }
  }, []);

  const isVersionBigger = (versionA, versionB) => {
    if (!versionB) return true;

    let isBigger = false;
    const normalizedA = versionA.replace(/[a-zA-Z]/g, '').split('.');
    const normalizedB = versionB.replace(/[a-zA-Z]/g, '').split('.');

    // Used some instead of forEach to be able to break the loop with return true
    // More about: https://stackoverflow.com/questions/6260756/how-to-stop-javascript-foreach
    normalizedA.some((value, index) => {
      if (value > normalizedB[index]) {
        isBigger = true;
        return true;
      } else if (value < normalizedB[index]) {
        return true;
      }
    });

    return isBigger;
  };

  const onChangelogClosed = async () => {
    const currentVersion = await firebaseHelper.getCurrentVersion();
    const monthInSecs = 60 * 60 * 24 * 30;

    setCookies('version', currentVersion, {
      path: '/',
      secure: true,
      maxAge: monthInSecs,
      sameSite: 'none',
    });
  };

  const handleClose = () => {
    setChangelog(false);
  };

  return (
    <Modal
      isOpen={changelog}
      role="dialog"
      contentClassName="log-modal-wrap"
      onClosed={onChangelogClosed}
      keyboard={false}
      autoFocus={true}
      centered={true}
      tabIndex="-1"
    >
      <ModalHeader cssModule={{ 'modal-title': 'w-100 my-0' }}>
        <div className="log-modal-header">
          <div className="log-modal-title">
            <box-icon type="regular" name="bell" color="#50A5F1"></box-icon>
            <p>{translate('Latest Ectools Features')}</p>
          </div>

          <button className="log-modal-close">
            <box-icon
              type="regular"
              name="x"
              color="#484848"
              size="2rem"
              onClick={handleClose}
            ></box-icon>
          </button>
        </div>
      </ModalHeader>
      <ModalBody className="log-modal-body">
        <p className="log-modal-message">
          {translate(
            'A new version of our Dashboard was just released and these are the new features',
          ) + ':'}
        </p>
        <p className="log-modal-version">{version}</p>

        <ul className="log-modal-list">
          {changelogDescription?.map((item, index) => (
            <li className="log-modal-item" key={'item-' + index}>
              <box-icon
                type="regular"
                name="caret-right-square"
                size="1.5rem"
                color="#A6B0CF"
              ></box-icon>
              <span>{translate(item)}</span>
            </li>
          ))}
        </ul>
      </ModalBody>
    </Modal>
  );
};

export default ChangelogModal;

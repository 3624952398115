import { getFirebaseBackend } from 'helpers/firebaseHelper';
import _ from 'lodash';

const loadProjects = (accountId, inputValue) => {
  if (!accountId) return;

  const firebaseHelper = getFirebaseBackend();

  return new Promise(async (resolve, reject) => {
    const projects = await firebaseHelper.getAllProjects(accountId);

    if (!projects) return resolve([]);

    let select;
    // Maps to React Select Object
    if (_.isArray(projects)) {
      const filteredProjects = projects.filter((project) => project);
      select = Object.entries(filteredProjects)?.map(([projectId, projectInfo]) => ({
        value: projectInfo.id,
        label: projectInfo.name,
      }));
    } else {
      select = [{ value: projects.id, label: projects.name }];
    }
    // Sorts Alphabetically
    select?.sort(({ label: firstLabel }, { label: secondLabel }) => {
      const normalizedA = firstLabel
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
      const normalizedB = secondLabel
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();

      return normalizedA > normalizedB ? 1 : normalizedB > normalizedA ? -1 : 0;
    });
    // Filters by input
    if (inputValue) {
      select = select?.filter(({ label }) => {
        // Normalize, remove accents and diacretics and lowercase the strings for comparison
        const normalizedTask = label
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase();
        const normalizedLabel = inputValue
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase();

        return normalizedTask.includes(normalizedLabel);
      });
    }

    resolve(select);
  });
};

export { loadProjects };

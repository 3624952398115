import styled from 'styled-components';

export const DbTBody = styled.tbody`
  border-width: 0;

  td {
    position: relative;
    overflow: visible !important;
    height: 3.5rem;
    padding: 0.75rem 0.625rem;
    vertical-align: middle;
    border-left: 1px solid #e2e8f0;

    .edit-input {
      width: 100%;
      height: 1.875rem;
      border-radius: 0.375rem;
      padding: 0.25rem 0.75rem;
      border: 1px solid #e2e8f0;
    }

    > div {
      display: flex;
      padding-top: 0.625rem;
      gap: 0.5rem;
    }

    button {
      display: flex;
      gap: 0.5rem;
      padding: 0.3rem 0.625rem;
      justify-content: center;
      align-items: center;
      border-radius: 0.25rem;
      border: none;
    }

    .save-button {
      background-color: #48bb78;
      color: #fff;
      &:disabled {
        background-color: #e2e8f0;
        color: #cbd5e0;
      }
    }

    .save-button:hover {
      background-color: #25855a;
    }

    .cancel-button {
      background-color: #f7fafc;
      color: #4a5568;
    }

    .cancel-button:hover {
      background-color: #edf2f7;
    }

    .edit-button-hover {
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto 0;
      background-color: transparent;
      border: none;
      height: 32px;
    }
  }

  td:hover {
    .edit-button-hover {
      display: flex;
    }
  }

  th {
    vertical-align: middle;
    padding: 0.75rem 0.625rem;
  }

  .react-datepicker-wrapper {
    padding: 0 !important;
  }

  .react-datepicker__tab-loop {
    position: absolute !important;
    top: 0;
  }

  .span-late {
    display: flex;
    height: 1.25rem;
    width: fit-content;
    padding: 0.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    border-radius: 0.25rem;
    background: rgba(197, 48, 48, 0.1);
    color: #c53030;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem;
  }

  .dropdown {
    padding: 0;
  }

  .dropdown-menu {
    display: flex;
    padding: 0.5rem;
    justify-content: center;
    align-items: flex-start;
    gap: 0.625rem;
    border-radius: 0.25rem;
    border: 1px solid #e2e8f0;
    background: #fff;
    margin: 0;
  }
`;

export const BorderRound = styled.div`
  border-radius: 0.25rem;
  border: 1px solid #e2e8f0;
  overflow: visible;
`;

export const ContainerAccordion = styled.div`
  display: flex;
  align-items: center;

  .accordion-button {
    border-width: 0 !important;
    box-shadow: none !important;
  }
`;

export const ButtonGoogleDocs = styled.button`
  display: flex;
  height: 2.5rem;
  min-width: max-content;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #e2e8f0;
  background-color: #fff;
  color: #343a40;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  :hover {
    background-color: #e2e8f0;
  }
`;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import 'boxicons';

import { useAuth } from 'hooks/useAuth';
import LanguageDropdown from '../../components/Language-Dropdown';
import TextCurtain from '../../components/Text-Curtain';
import AccountSelect from '../../components/Account-Select';
import { getFirebaseBackend } from '../../helpers/firebaseHelper';
import ProfileMenu from '../../components/Profile-Menu';
import SiteMenu from '../../components/Site-Menu';
import { simpleHeaderPages } from '../../constants';

const Header = () => {
  const firebaseHelper = getFirebaseBackend();
  const { t: translate } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  const { user } = useAuth();
  const { adminStatus } = user;
  const [sidebarIcon, setSidebarIcon] = useState('chevron-left-square');
  const [accountCode, setAccountCode] = useState(undefined);

  const fetchAccountCode = async () => {
    const code = await firebaseHelper.accountCode(user.account);
    setAccountCode(code);
  };

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle('sidebar-enable');
    } else {
      body.classList.toggle('vertical-collpsed');
      body.classList.toggle('sidebar-enable');
    }
  }

  useEffect(() => {
    const observer = new MutationObserver((mutationList) => {
      mutationList.forEach(function (mutation) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          if (mutation.target.className.includes('vertical-collpsed')) {
            setSidebarIcon('chevron-right-square');
          } else {
            setSidebarIcon('chevron-left-square');
          }
        }
      });
    });

    const body = document.body;
    observer.observe(body, { attributes: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!user) return;

    if (adminStatus !== 'Client') {
      fetchAccountCode();
    }
  }, [user]);

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex" style={{ gap: '.5em' }}>
            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item"
              id="vertical-menu-btn"
            >
              <box-icon name={sidebarIcon} color="#555b6d"></box-icon>
            </button>

            {!simpleHeaderPages.includes(pathname) && (
              <AccountSelect user={user} limited={adminStatus !== 'Super Admin'} />
            )}

            {adminStatus !== 'Client' && !simpleHeaderPages.includes(pathname) ? (
              <TextCurtain title={translate('Account Code')} text={accountCode} />
            ) : null}
          </div>

          <div className="d-flex justify-content-center align-items-center gap-4">
            <SiteMenu />

            <LanguageDropdown />

            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;

import React, { useEffect, useState } from 'react';
import { getDatabase, ref, update } from 'firebase/database';
import { useTranslation } from 'react-i18next';
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Dropdown,
  DropdownMenu,
  Table,
  UncontrolledAccordion,
} from 'reactstrap';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { useAuth } from 'hooks/useAuth';

import {
  guidelineDeliveryTypeOptions,
  guidelineTypeOptions,
  monthNumberLabel,
} from '../../../../constants';
import { BorderRound, ButtonGoogleDocs, ContainerAccordion, DbTBody } from './styles';
import { formatDateTimestampNumber } from 'utils';

const TableResumeReview = ({ data, accountId, guidelineId, noEdit, onlyResume }) => {
  const { t: translate } = useTranslation();
  const { user } = useAuth();
  const [searchVolume, setSearchVolume] = useState('');
  const [title, setTitle] = useState('');
  const [slug, setSlug] = useState('');
  const [related, setRelated] = useState('');
  const [synonyms, setSynonyms] = useState('');
  const [tempInput, setTempInput] = useState('');
  const [tempInput2, setTempInput2] = useState('');
  const [editCell, setEditCell] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState('');
  const [publishedDate, setPublishedDate] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [titleSEO, setTitleSEO] = useState('');
  const [descriptionSEO, setDescriptionSEO] = useState('');
  const [productionMonth, setProductionMonth] = useState('');
  const [productionYear, setProductionYear] = useState('');
  const [deliveryType, setDeliveryType] = useState('');
  const [countDescriptionSEO, setCountDescriptionSEO] = useState(0);
  const [internalGoogleDocId, setInternalGoogleDocId] = useState(null);
  const [deliveredGoogleDocId, setDeliveredInternalGoogleDocId] = useState(null);

  const noEditValue = noEdit !== undefined ? noEdit : user.adminStatus === 'Reviewer';

  const PROPERTY_VALUE = {
    searchVolume,
    deliveryDate,
    publishedDate,
    title,
    slug,
    related,
    synonyms,
    titleSEO,
    descriptionSEO,
    productionMonth,
    productionYear,
    deliveryType,
  };

  const PROPERTY_SETTER = {
    searchVolume: setSearchVolume,
    deliveryDate: setDeliveryDate,
    publishedDate: setPublishedDate,
    title: setTitle,
    slug: setSlug,
    related: setRelated,
    synonyms: setSynonyms,
    titleSEO: setTitleSEO,
    descriptionSEO: setDescriptionSEO,
    productionMonth: setProductionMonth,
    productionYear: setProductionYear,
    deliveryType: setDeliveryType,
  };

  const EditInput = (property, isTimestamp, isDropdown, property2) => {
    const setter = PROPERTY_SETTER[property];
    const setter2 = PROPERTY_SETTER[property2];
    const predefinedDate = data[property] ? new Date(data[property]) : null;

    const handleChange = (event) => {
      event.preventDefault();

      setTempInput(event.target.value);
    };

    const handleChange2 = (event) => {
      event.preventDefault();

      setTempInput2(event.target.value);
    };

    const handleDateChange = (newDate) => {
      const timestamp = newDate ? newDate.getTime() : null;

      setTempInput(timestamp);
      setter(timestamp);
    };

    const handleSaveClick = async () => {
      try {
        const db = getDatabase();
        const guidelineRef = ref(db, `guidelines/${accountId}/${guidelineId}`);

        if (property === 'related' || property === 'tags' || property === 'synonyms') {
          const array = tempInput
            .split(',')
            .map((item) => item.trim())
            .filter((item) => item);

          await update(guidelineRef, {
            [property]: array,
          });

          setter(array.join(', '));
        } else if (property === 'productionMonth') {
          await update(ref(db, `guidelines/${accountId}/${guidelineId}/productionDate`), {
            month: tempInput,
            year: tempInput2,
          });

          setter(tempInput);
          setter2(tempInput2);
        } else if (
          property === 'deliveryType' &&
          (tempInput === 'REVIEW' || tempInput === 'REVIEW_AND_DIRECTIONS')
        ) {
          await update(guidelineRef, {
            deliveryType: tempInput,
            reviewLink: tempInput2,
          });

          setter(tempInput);
          window.location.reload();
        } else {
          await update(guidelineRef, {
            [property]: tempInput,
          });

          setter(tempInput);
          property === 'descriptionSEO' && setCountDescriptionSEO(tempInput.length);
          property === 'deliveryType' && window.location.reload();
        }

        setEditCell(null);
      } catch (error) {
        console.error('Error updating guideline:', error);
      }
    };

    const currentYear = new Date().getFullYear();
    const nextYear = currentYear + 1;

    const yearOptions = [
      { label: currentYear.toString(), value: currentYear },
      { label: nextYear.toString(), value: nextYear },
    ];

    return (
      <>
        {isTimestamp === 'isTimestamp' ? (
          <DatePicker
            className="edit-input"
            locale={translate('en-US')}
            dateFormat={translate('en-US') === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
            selected={tempInput ? new Date(tempInput) : predefinedDate}
            onChange={(date) => handleDateChange(date)}
            minDate={property === 'deliveryDate' ? new Date() : ''}
          />
        ) : property2 === 'productionYear' ? (
          <div className="d-flex">
            <select className="edit-input" value={tempInput} onChange={handleChange}>
              <option value="">{translate('Select')}</option>
              {monthNumberLabel.map((month) => (
                <option key={month.value} value={month.value}>
                  {month.label}
                </option>
              ))}
            </select>
            <select className="edit-input" value={tempInput2} onChange={handleChange2}>
              <option value="">{translate('Select')}</option>
              {yearOptions.map((year) => (
                <option key={year.value} value={year.value}>
                  {year.label}
                </option>
              ))}
            </select>
          </div>
        ) : property === 'deliveryType' ? (
          <div className="d-flex flex-column">
            <select className="edit-input" value={tempInput} onChange={handleChange}>
              <option value="">{translate('Select')}</option>
              {guidelineDeliveryTypeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            {(tempInput === 'REVIEW' || tempInput === 'REVIEW_AND_DIRECTIONS') && (
              <input
                className="edit-input"
                value={tempInput2}
                placeholder="URL"
                onChange={handleChange2}
              />
            )}
          </div>
        ) : (
          <input className="edit-input" value={tempInput} onChange={handleChange} />
        )}
        {isDropdown === 'isDropdown' ? (
          <Dropdown
            placement="right"
            isOpen={dropdownOpen}
            toggle={() => setDropdownOpen(!dropdownOpen)}
          >
            <DropdownMenu end>
              <button className="cancel-button" onClick={() => setEditCell(null)}>
                {translate('Cancel')}
              </button>
              <button className="save-button" onClick={handleSaveClick}>
                <i className="bx bxs-check-circle" />
                {translate('Save')}
              </button>
            </DropdownMenu>
          </Dropdown>
        ) : (
          <div>
            <button className="cancel-button" onClick={() => setEditCell(null)}>
              {translate('Cancel')}
            </button>
            <button
              className="save-button"
              onClick={handleSaveClick}
              disabled={
                property === 'deliveryType' &&
                (tempInput === 'REVIEW' || tempInput === 'REVIEW_AND_DIRECTIONS') &&
                tempInput2.length === 0
              }
            >
              <i className="bx bxs-check-circle" />
              {translate('Save')}
            </button>
          </div>
        )}
      </>
    );
  };

  const ContentDisplay = (property, isTimestamp, isDropdown, property2) => {
    const value = PROPERTY_VALUE[property];
    const value2 = PROPERTY_VALUE[property2];

    const month = monthNumberLabel.find(
      (month) => month.value === parseInt(PROPERTY_VALUE['productionMonth']),
    )?.label;

    const valueTimestamp = value
      ? formatDateTimestampNumber(value, translate('date-enUS'))
      : '--/--/----';

    const deliveryType = guidelineDeliveryTypeOptions.find(
      (type) => type.value === PROPERTY_VALUE['deliveryType'],
    )?.label;

    return (
      <span
        className="d-flex gap-2"
        style={{
          color:
            data?.column !== 'PUBLISHED' && property === 'deliveryDate'
              ? deadlineColor()
              : '#495057',
        }}
      >
        {isTimestamp === 'isTimestamp'
          ? valueTimestamp
          : value2
            ? `${month} ${translate('of')} ${value2}`
            : property === 'deliveryType'
              ? deliveryType
              : value}
        {deadlineColor() === '#c53030' &&
          property === 'deliveryDate' &&
          data?.column !== 'PUBLISHED' && <span className="span-late">{translate('Late')}</span>}
        {!noEditValue &&
          (isDropdown === 'isDropdown' ? (
            <button
              className="edit-button-hover"
              onClick={(e) => {
                handleClick(e, property);
                setDropdownOpen(!dropdownOpen);
              }}
            >
              <i className="bx bxs-edit-alt" style={{ color: '#718096' }} />
            </button>
          ) : (
            <button className="edit-button-hover" onClick={(e) => handleClick(e, property)}>
              <i className="bx bxs-edit-alt" style={{ color: '#718096' }} />
            </button>
          ))}
      </span>
    );
  };

  function handleClick(event, property) {
    event.preventDefault();

    setEditCell(property);
    setTempInput(PROPERTY_VALUE[property]);
    if (property === 'productionMonth') setTempInput2(PROPERTY_VALUE['productionYear']);
  }

  const deadlineColor = () => {
    const deliveryDate = data?.deliveryDate;
    if (!deliveryDate) return '#495057';

    let today = new Date();
    today.setHours(0, 0, 0, 0);
    today = today.getTime();

    let oneDayBeforeDeliveryDate = new Date(deliveryDate);
    oneDayBeforeDeliveryDate.setDate(oneDayBeforeDeliveryDate.getDate() - 1);
    oneDayBeforeDeliveryDate.setHours(0, 0, 0, 0);
    oneDayBeforeDeliveryDate = oneDayBeforeDeliveryDate.getTime();

    let oneWeekBeforeDeliveryDate = new Date(deliveryDate);
    oneWeekBeforeDeliveryDate.setDate(oneWeekBeforeDeliveryDate.getDate() - 7);
    oneWeekBeforeDeliveryDate.setHours(0, 0, 0, 0);
    oneWeekBeforeDeliveryDate = oneWeekBeforeDeliveryDate.getTime();

    switch (true) {
      case today < oneWeekBeforeDeliveryDate:
        return '#495057';
      case today >= oneDayBeforeDeliveryDate && today <= deliveryDate:
        return '#f46a6a';
      case today > deliveryDate:
        return '#c53030';
      default:
        return '#f1b44c';
    }
  };

  useEffect(() => {
    if (!data) return;

    setSearchVolume(data?.searchVolume);
    setTitle(data?.title);
    setSlug(data?.slug);
    setRelated(data?.related?.join(', '));
    setSynonyms(data?.synonyms?.join(', '));
    setDeliveryDate(data?.deliveryDate);
    setPublishedDate(data?.publishedDate);
    setTitleSEO(data?.titleSEO);
    setDescriptionSEO(data?.descriptionSEO);
    setProductionMonth(data?.productionDate?.month);
    setProductionYear(data?.productionDate?.year);
    setDeliveryType(data?.deliveryType);
    setCountDescriptionSEO(data?.descriptionSEO?.length || 0);
    setInternalGoogleDocId(data?.googleDocId);
    setDeliveredInternalGoogleDocId(data?.googleDocIdDelivery || '');
  }, [data]);

  return (
    <UncontrolledAccordion defaultOpen={['1', '2', '3']} stayOpen>
      <AccordionItem>
        <ContainerAccordion>
          <AccordionHeader targetId="1" className="w-100">
            {translate('Summary')}
          </AccordionHeader>
          {data?.googleDocId && data?.column !== 'CONTENT_REVIEW' && (
            <ButtonGoogleDocs
              onClick={() =>
                window.open(
                  `https://docs.google.com/document/d/${deliveredGoogleDocId !== '' ? deliveredGoogleDocId : internalGoogleDocId}/edit`,
                )
              }
            >
              <i className="bx bx-file" />
              {translate('Open in Google Docs')}
            </ButtonGoogleDocs>
          )}
        </ContainerAccordion>

        <AccordionBody accordionId="1">
          <BorderRound className="border-round">
            <Table className="m-0">
              <DbTBody>
                <tr>
                  <th scope="row">{translate('Type')}:</th>
                  <td colSpan={2}>
                    {editCell === 'deliveryType'
                      ? EditInput('deliveryType', 'reviewLink')
                      : ContentDisplay('deliveryType')}
                  </td>
                </tr>
                <tr>
                  <th scope="row">{translate('Format')}:</th>
                  <td>
                    {guidelineTypeOptions.map((value) => {
                      if (value.value === data?.type) {
                        return value.label;
                      }
                    })}
                  </td>
                  <td>{data?.totalSize}</td>
                </tr>
                <tr>
                  <th scope="row">{translate('Main word')}:</th>
                  <td>
                    {data?.primaryKeyword.charAt(0).toUpperCase() + data?.primaryKeyword.slice(1)}
                  </td>
                  <td
                    style={{
                      minWidth: '15%',
                      maxWidth: '15%',
                      width: '15%',
                      borderLeft: '1px solid #e2e8f0',
                    }}
                  >
                    {editCell === 'searchVolume'
                      ? EditInput('searchVolume', 'notTimestamp', 'isDropdown')
                      : ContentDisplay('searchVolume', 'notTimestamp', 'isDropdown')}
                  </td>
                </tr>
                <tr>
                  <th scope="row">{translate('Secondary keywords')}:</th>
                  <td colSpan={2}>{data?.secondaryKeywords?.join(', ')}</td>
                </tr>
                <tr>
                  <th scope="row">{translate('Production month')}:</th>
                  <td colSpan={2}>
                    {editCell === 'productionMonth'
                      ? EditInput(
                          'productionMonth',
                          'notTimestamp',
                          'notDropdown',
                          'productionYear',
                        )
                      : ContentDisplay(
                          'productionMonth',
                          'notTimestamp',
                          'notDropdown',
                          'productionYear',
                        )}
                  </td>
                </tr>
                <tr>
                  <th scope="row">{translate('Delivery date')}:</th>
                  <td colSpan="2">
                    {editCell === 'deliveryDate'
                      ? EditInput('deliveryDate', 'isTimestamp')
                      : ContentDisplay('deliveryDate', 'isTimestamp')}
                  </td>
                </tr>
                <tr>
                  <th scope="row" style={{ borderBottomWidth: '0' }}>
                    {translate('Publication date')}:
                  </th>
                  <td colSpan="2" style={{ borderBottomWidth: '0' }}>
                    {editCell === 'publishedDate'
                      ? EditInput('publishedDate', 'isTimestamp')
                      : ContentDisplay('publishedDate', 'isTimestamp')}
                  </td>
                </tr>
              </DbTBody>
            </Table>
          </BorderRound>
        </AccordionBody>
      </AccordionItem>
      {!onlyResume && (
        <AccordionItem>
          <ContainerAccordion>
            <AccordionHeader targetId="2" className="w-100">
              {'SEO'}
            </AccordionHeader>
          </ContainerAccordion>
          <AccordionBody accordionId="2">
            <BorderRound>
              <Table className="m-0">
                <DbTBody>
                  <tr>
                    <th scope="row">H1 {translate('Page')}:</th>
                    <td colSpan={2}>
                      {editCell === 'title' ? EditInput('title') : ContentDisplay('title')}
                    </td>
                  </tr>
                  <tr>
                    <th>{'Slug (SEO)'}:</th>
                    <td colSpan={2}>
                      {editCell === 'slug' ? EditInput('slug') : ContentDisplay('slug')}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">{translate('Title SEO')}:</th>
                    <td colSpan={2}>
                      {editCell === 'titleSEO' ? EditInput('titleSEO') : ContentDisplay('titleSEO')}
                    </td>
                  </tr>
                  <tr style={{ borderBottomWidth: '0' }}>
                    <th scope="row" style={{ borderBottomWidth: '0' }}>
                      {translate('Description SEO')}:
                    </th>
                    <td style={{ borderBottomWidth: '0' }}>
                      {editCell === 'descriptionSEO'
                        ? EditInput('descriptionSEO')
                        : ContentDisplay('descriptionSEO')}
                    </td>
                    <td
                      style={{
                        minWidth: '10%',
                        maxWidth: '10%',
                        width: '10%',
                        borderLeft: '1px solid #e2e8f0',
                        borderBottomWidth: '0',
                      }}
                    >
                      {countDescriptionSEO}
                    </td>
                  </tr>
                </DbTBody>
              </Table>
            </BorderRound>
          </AccordionBody>
        </AccordionItem>
      )}
      {!onlyResume && (
        <AccordionItem>
          <ContainerAccordion>
            <AccordionHeader targetId="3" className="w-100">
              {translate('Key words')}
            </AccordionHeader>
          </ContainerAccordion>
          <AccordionBody accordionId="3">
            <BorderRound>
              <Table className="m-0">
                <DbTBody>
                  <tr>
                    <th scope="row">{translate('Related')}:</th>
                    <td>
                      {editCell === 'related' ? EditInput('related') : ContentDisplay('related')}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" style={{ borderBottomWidth: '0' }}>
                      {translate('Synonyms')}:
                    </th>
                    <td style={{ borderBottomWidth: '0' }}>
                      {editCell === 'synonyms' ? EditInput('synonyms') : ContentDisplay('synonyms')}
                    </td>
                  </tr>
                </DbTBody>
              </Table>
            </BorderRound>
          </AccordionBody>
        </AccordionItem>
      )}
    </UncontrolledAccordion>
  );
};

TableResumeReview.propTypes = {
  data: PropTypes.object,
  accountId: PropTypes.string,
  guidelineId: PropTypes.string,
  noEdit: PropTypes.bool,
  onlyResume: PropTypes.bool,
};

export default TableResumeReview;

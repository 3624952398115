import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row, Card, Button } from 'reactstrap';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';
import _ from 'lodash';

import { useAuth } from 'hooks/useAuth';
import { getFirebaseBackend } from 'helpers/firebaseHelper';
import PageStructure from 'components/Page-Structure';
import ShowComponent from 'components/Show-Component';
import Shimmer from 'components/Shimmer';
import ErrorModal from 'components/Error-Modal';
import CheckboxInput from 'components/Checkbox-Input';
import RadioInput from 'components/Radio-Input';
import TextAreaInput from 'components/TextArea-Input';
import DisplayErrors from 'components/Display-Errors';
import EmbeddedVideo from '../../components/Embedded-Video';
import FileTable from '../../components/File-Table';
import LessonComments from './components/LessonComments';
import LessonsList from './components/LessonsList';
import { LessonLayout, TestDiv, LessonMenu } from './styles';

const formConfig = {
  defaultValues: {},
  shouldFocusError: true,
  criteriaMode: 'all',
  mode: 'onSubmit',
  reValidateMode: 'onChange',
};

const Test = () => {
  const { user } = useAuth();
  const firebaseHelper = getFirebaseBackend();
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const { id: courseId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const testId = searchParams.get('id');
  const lessonId = searchParams.get('lss');
  const form = useForm(formConfig);
  const { formState, watch, reset, setValue, handleSubmit } = form;
  const [test, setTest] = useState(null);
  const [lesson, setLesson] = useState(null);
  const [course, setCourse] = useState(null);
  const [library, setLibrary] = useState(null);
  const [answered, setAnswered] = useState(false);
  const [passed, setPassed] = useState(false);
  const [progress, setProgress] = useState([]);
  const [current, setCurrent] = useState();
  const [changeLoading, setChangeLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [menuOpen, setMenuOpen] = useState(true);
  const [errorModal, setErrorModal] = useState(false);

  const courseProgress = (courseObj) => {
    const lastLesson = library?.lastLesson;

    const lessons = [];
    let index = 0;
    let lastLessonId = null;
    let currentLessonId = null;

    courseObj.modules.forEach((module) => {
      module.lessons.forEach((lesson) => {
        if (lesson.id === lastLesson) lastLessonId = index;
        if (lesson.id === lessonId) currentLessonId = index;
        lessons.push({
          id: lesson.id,
          index,
        });
        index++;
      });
    });

    if (currentLessonId > lastLessonId) {
      firebaseHelper.updateFirebaseLibraryCourse({
        userId: user.id,
        courseId,
        lastLesson: lessonId,
      });
    }

    return [lessons, currentLessonId];
  };

  const progressPercent = () => {
    const progress = library?.progress;

    if (!progress) {
      return 0;
    } else {
      const currentProgress = progress.length;
      let courseLength = 0;

      Object.values(course?.modules || {}).forEach((module) => {
        module.lessons.forEach(() => {
          courseLength++;
        });
      });

      const percent = Math.floor((currentProgress / courseLength) * 100);
      return percent;
    }
  };

  const onSubmit = async (data) => {
    let rightAnswers = 0;
    let totalAnswers = 0;
    let questionKey = {};

    Object.entries(data).forEach(([answerKey, answer]) => {
      if (answer === undefined) data[answerKey] = false;
    });

    test.questions.forEach((question) => {
      switch (question.type) {
        case 'select':
          totalAnswers++;
          const optionsNum = question.options.length;
          const pointPerRight = 1 / optionsNum;
          let points = 0;

          question.options.forEach((option, optionIndex) => {
            if (Boolean(data[`question-${question.id}-${optionIndex}`]) == option.right)
              points += pointPerRight;
          });
          rightAnswers += points;
          questionKey[question.id] = points === 1 ? 'right' : rightAnswers === 0 ? 'wrong' : 'half';
          break;
        case 'multi':
          totalAnswers++;
          const [, selectAnswer] = data[`question-${question.id}`].split('-');
          if (question.options[selectAnswer].right) {
            questionKey[question.id] = 'right';
            rightAnswers++;
          } else {
            questionKey[question.id] = 'wrong';
          }
          break;
        case 'code':
          totalAnswers++;
          const pattern = question.regex.slice(1);
          const flags = question.flags.slice(1);
          const regex = new RegExp(pattern, flags);
          if (regex.test(data[`question-${question.id}`])) {
            questionKey[question.id] = 'right';
            rightAnswers++;
          } else {
            questionKey[question.id] = 'wrong';
          }
          break;
        case 'open':
          totalAnswers++;
          if (!test.demandCorrection) {
            questionKey[question.id] = 'right';
            rightAnswers++;
          } else {
            questionKey[question.id] = 'analysis';
          }
          break;
      }
    });

    const passPercent = Math.floor((rightAnswers / totalAnswers) * 100);

    if (!test?.passPercent || passPercent > test?.percent) {
      const libProgress = library?.progress || [];
      if (!libProgress.includes(lessonId)) {
        libProgress.push(lessonId);
        firebaseHelper.updateFirebaseLibraryCourse({
          userId: user.id,
          courseId,
          progress: libProgress,
        });
      }
    }

    const testProgress = library?.tests || {};
    testProgress[testId] = {
      grade: passPercent,
      correction: questionKey,
      answers: data,
    };
    await firebaseHelper.updateFirebaseLibraryCourse({
      userId: user.id,
      courseId,
      tests: testProgress,
    });

    setAnswered(true);
    fetchCourseLibrary();
  };

  const handleBack = () => {
    navigate(`/course/${courseId}/lesson?id=${lessonId}`);
  };

  const handleNext = () => {
    const libProgress = library?.progress || [];
    if (!libProgress.includes(lessonId)) {
      libProgress.push(lessonId);
      firebaseHelper.updateFirebaseLibraryCourse({
        userId: user.id,
        courseId,
        progress: libProgress,
      });
    }

    navigate(`/course/${courseId}/lesson?id=${progress[current + 1].id}`);

    setChangeLoading(true);
  };

  const handleCertificate = () => {
    const libProgress = library?.progress || [];
    if (!libProgress.includes(lessonId)) {
      libProgress.push(lessonId);
      firebaseHelper.updateFirebaseLibraryCourse({
        userId: user.id,
        courseId,
        progress: libProgress,
      });
    }

    navigate(`/course/${courseId}/certificate`);
  };

  const handleReset = () => {
    setAnswered(false);
    setPassed(false);
    reset({});
  };

  const fetchLessonData = async (accountId) => {
    if (!accountId) return;

    const lessonObj = await firebaseHelper.getFirebaseLesson(accountId, lessonId);

    const teacherNames = await Promise.all(
      lessonObj.teachers.map(async (teacherId) => {
        const teacherName = await firebaseHelper.userName(teacherId);

        return { name: teacherName, id: teacherId };
      }),
    );

    setLesson({ ...lessonObj, teachers: teacherNames });
  };

  const fetchTestData = async (accountId) => {
    if (!accountId) return;

    const testObj = await firebaseHelper.getFirebaseTest(accountId, testId);

    if (testObj?.scrambleQuestions) {
      testObj.originalQuestions = testObj.questions;
      testObj.questions = _.shuffle(testObj.questions);
    }

    setPassed(
      (library?.tests?.[testId]?.grade >= (testObj?.percent || 0) && !test?.demandCorrection) ||
        test?.passPercent,
    );
    setTest(testObj);
    setChangeLoading(false);
    setLoading(false);
  };

  const fetchCourseData = async (accountId) => {
    if (!accountId) return;

    const courseObj = await firebaseHelper.getFirebaseCourse(accountId, courseId);
    const [progress, currentIndex] = courseProgress(courseObj);

    setProgress(progress);
    setCurrent(currentIndex);
    setCourse(courseObj);
  };

  const fetchCourseLibrary = async () => {
    const libraryObj = await firebaseHelper.getFirebaseLibraryCourse(user.id, courseId);

    if (libraryObj?.tests?.[testId]) {
      setAnswered(true);
      reset(libraryObj.tests[testId].answers);
    }

    if (!libraryObj) navigate('/page-404');
    setLibrary(libraryObj);
  };

  useEffect(() => {
    if (!testId) return;

    setChangeLoading(true);
    fetchCourseLibrary();
  }, [testId]);

  useEffect(() => {
    try {
      fetchCourseData(library?.account);
      fetchTestData(library?.account);
      fetchLessonData(library?.account);
    } catch (error) {
      console.error(error);
      setErrorModal(true);
    }
  }, [library]);

  return (
    <PageStructure
      metaTitle={test?.name || 'Test'}
      breadcrumbItem={test?.name || 'Test'}
      breadcrumbTitle="Test"
    >
      <LessonLayout open={menuOpen}>
        <ErrorModal isOpen={errorModal} />

        <ShowComponent condition={loading}>
          <Shimmer height="30rem" />
          <Shimmer height="20rem" />
        </ShowComponent>

        <ShowComponent condition={!loading}>
          <ShowComponent condition={changeLoading}>
            <Shimmer height="30rem" />
          </ShowComponent>

          <ShowComponent condition={!changeLoading}>
            <div>
              <Card className="lesson-main">
                <section>
                  <div className="lesson-main-title">
                    <div>
                      <h1>{test?.name}</h1>

                      <div className="lesson-stats">
                        <box-icon
                          type="regular"
                          name="user-voice"
                          size="1.2rem"
                          color="#484848"
                        ></box-icon>
                        <span>
                          {_.map(lesson?.teachers, ({ name, id }, index) => {
                            let item = name;
                            const lastItem = lesson?.teachers?.length - 1;

                            if (index !== lastItem) item += ', ';

                            return <span key={id}>{item}</span>;
                          })}
                        </span>
                      </div>
                    </div>

                    {answered && (
                      <div
                        className={`question-percent ${
                          library?.tests?.[testId]?.grade < test?.percent ? 'reproved' : ''
                        }`}
                      >
                        <span>
                          {translate('You got')} <strong>{library?.tests?.[testId]?.grade}%</strong>{' '}
                          {translate('of the questions right')}
                        </span>
                      </div>
                    )}
                  </div>

                  <hr />
                </section>

                <FormProvider {...form}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    {test?.questions?.map((question) => (
                      <TestDiv key={question.id} className={question.type}>
                        <div className="question-title">
                          <h4>{question.question}</h4>
                          {answered &&
                            test?.answerKey &&
                            library?.tests?.[testId]?.correction[question.id] === 'right' && (
                              <div className="question-correction right">
                                <box-icon
                                  type="solid"
                                  name="check-circle"
                                  size="1.5em"
                                  color="#34C38F"
                                />
                                {translate('correct')}
                              </div>
                            )}

                          {answered &&
                            test?.answerKey &&
                            (library?.tests?.[testId]?.correction[question.id] === 'wrong' ||
                              library?.tests?.[testId]?.correction[question.id] === 'half') && (
                              <div className="question-correction wrong">
                                <box-icon
                                  type="solid"
                                  name="x-circle"
                                  size="1.5em"
                                  color="#F46A6A"
                                />
                                {translate('incorrect')}
                              </div>
                            )}

                          {answered &&
                            test?.answerKey &&
                            library?.tests?.[testId]?.correction[question.id] === 'analysis' && (
                              <div className="question-correction analysis">
                                <box-icon name="search-alt" size="1.5em" color="#50A5F1" />
                                {translate('under analysis from teacher')}
                              </div>
                            )}
                        </div>

                        <ShowComponent condition={question.type === 'select'}>
                          {question?.options?.map((option, optionIndex) => (
                            <CheckboxInput
                              key={`question-${question.id}-${optionIndex}`}
                              controlName={`question-${question.id}-${optionIndex}`}
                              label={option.option}
                              justifyCenter={false}
                              disabled={answered}
                            />
                          ))}
                        </ShowComponent>

                        <ShowComponent condition={question.type === 'multi'}>
                          {question?.options?.map((option, optionIndex) => (
                            <RadioInput
                              key={`question-${question.id}-${optionIndex}`}
                              controlName={`question-${question.id}`}
                              className="px-0"
                              id={`answer-${optionIndex}`}
                              disabled={answered}
                              label={option.option}
                              size="auto"
                              required
                            />
                          ))}
                          <DisplayErrors
                            error={formState?.errors?.[`question-${question.id}`]?.types}
                          />
                        </ShowComponent>

                        <ShowComponent condition={question.type === 'code'}>
                          <TextAreaInput
                            controlName={`question-${question.id}`}
                            placeholder="Enter your answer..."
                            rows={4}
                            disabled={answered}
                            required
                          />
                        </ShowComponent>

                        <ShowComponent condition={question.type === 'open'}>
                          <TextAreaInput
                            controlName={`question-${question.id}`}
                            placeholder="Enter your answer..."
                            rows={4}
                            disabled={answered}
                            required
                          />

                          {answered && <div className="open-feedback">{question.feedback}</div>}
                        </ShowComponent>
                      </TestDiv>
                    ))}

                    {!answered && (
                      <Row>
                        <Col lg="10">
                          <Button
                            className="d-flex justify-content-center align-items-center gap-2 px-4 py-1"
                            type="submit"
                            style={{
                              backgroundColor: '#7367F0',
                              borderColor: '#7367F0',
                              borderRadius: '2em',
                            }}
                            disabled={Object.values(formState.errors).length}
                          >
                            {translate('Send Answers')}
                            <box-icon name="log-in-circle" color="#fff" size="1em" />
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </form>
                </FormProvider>
              </Card>
            </div>
          </ShowComponent>

          <LessonMenu open={menuOpen}>
            <ShowComponent condition={answered && test?.demandCorrection && passed}>
              <ShowComponent condition={menuOpen}>
                <div className="test-warning">
                  <div>
                    <box-icon name="timer" color="#234B6F" size="2.5em" />
                  </div>
                  <div className="text">
                    <div>{translate('Waiting for correction')}</div>
                    <div>
                      {translate(
                        'There are open questions in this test, teacher correction is necessary to proceed.',
                      )}
                    </div>
                  </div>
                </div>
              </ShowComponent>

              <ShowComponent condition={!menuOpen}>
                <div className="test-warning">
                  <box-icon name="timer" color="#234B6F" size="2.5em" />
                </div>
              </ShowComponent>
            </ShowComponent>

            <ShowComponent condition={answered && !passed}>
              <ShowComponent condition={menuOpen}>
                <button className="test-reset" onClick={handleReset}>
                  <div>
                    <box-icon name="rotate-left" color="#291EA5" size="2.5em" />
                  </div>
                  <div className="text">
                    <div>{translate('Redo test')}</div>
                    <div>
                      {translate("It's necessary to get at least") +
                        ` ${test?.percent}% ` +
                        translate('of the questions right to proceed.')}
                    </div>
                  </div>
                </button>
              </ShowComponent>

              <ShowComponent condition={!menuOpen}>
                <button className="test-reset" onClick={handleReset}>
                  <box-icon name="rotate-left" color="#291EA5" size="2.5em" />
                </button>
              </ShowComponent>
            </ShowComponent>

            <Card>
              <ShowComponent condition={menuOpen}>
                <div className="open-column">
                  <div className="lesson-menu-header">
                    <h4>{translate('Course Lessons')}</h4>
                    <button onClick={() => setMenuOpen(false)}>
                      <box-icon name="chevron-right-square" color="#484848" size="1.5em" />
                    </button>
                  </div>

                  <meter
                    min="0"
                    max="100"
                    value={progressPercent()}
                    title={translate('Course Completion Percentage')}
                    className={`lesson-menu-meter ${progressPercent() === 100 ? 'complete' : ''}`}
                  />

                  <div>
                    <LessonsList
                      list={progress}
                      progress={library?.progress}
                      current={current}
                      account={library?.account}
                    />
                  </div>

                  <div className="lesson-menu-buttons">
                    <button className="previous" onClick={handleBack}>
                      <box-icon name="chevrons-left" color="#fff" size="1.2rem" />
                      {translate('Go Back to Lesson')}
                    </button>

                    <ShowComponent
                      condition={
                        current === progress.length - 1 && passed && !test?.demandCorrection
                      }
                    >
                      <button className="next" onClick={handleCertificate}>
                        {translate('Certificate')}
                        <box-icon name="medal" color="#fff" size="1.2rem" />
                      </button>
                    </ShowComponent>

                    <ShowComponent
                      condition={current < progress.length - 1 && passed && !test?.demandCorrection}
                    >
                      <button className="next" onClick={handleNext}>
                        {translate('Next Lesson')}
                        <box-icon name="chevrons-right" color="#fff" size="1.2rem" />
                      </button>
                    </ShowComponent>
                  </div>
                </div>
              </ShowComponent>

              <ShowComponent condition={!menuOpen}>
                <div className="closed-column">
                  <button onClick={() => setMenuOpen(true)}>
                    <box-icon name="chevron-left-square" color="#C1C1C1" size="1.5em" />
                  </button>
                  <div className="sideways">{translate('See Course Lessons')}</div>
                </div>
              </ShowComponent>
            </Card>

            <ShowComponent condition={!menuOpen}>
              <div className="closed-column">
                <div className="lesson-menu-buttons">
                  <button className="previous" onClick={handleBack}>
                    <box-icon name="chevrons-left" color="#fff" size="1.2rem" />
                  </button>

                  <ShowComponent
                    condition={current === progress.length - 1 && passed && !test?.demandCorrection}
                  >
                    <button className="next" onClick={handleCertificate}>
                      <box-icon name="medal" color="#fff" size="1.2rem" />
                    </button>
                  </ShowComponent>

                  <ShowComponent
                    condition={current < progress.length - 1 && passed && !test?.demandCorrection}
                  >
                    <button className="next" onClick={handleNext}>
                      <box-icon name="chevrons-right" color="#fff" size="1.2rem" />
                    </button>
                  </ShowComponent>
                </div>
              </div>
            </ShowComponent>
          </LessonMenu>
        </ShowComponent>
      </LessonLayout>
    </PageStructure>
  );
};

Test.displayName = 'Test Page';

export default Test;

import React from 'react';
import { Navigate } from 'react-router-dom';

const AuthActions = () => {
  const url = new URL(window.location.href);
  const mode = url.searchParams.get('mode');
  const params = url.searchParams.toString();

  let returnRender = <Navigate to={{ pathname: '/' }} />;

  if (mode === 'resetPassword') {
    returnRender = <Navigate to={{ pathname: '/reset-password', search: `?${params}` }} />;
  }
  if (mode === 'verifyEmail') {
    returnRender = <Navigate to={{ pathname: '/verify-email', search: `?${params}` }} />;
  }

  if (mode === 'disableAllNotifications') {
    returnRender = <Navigate to={{ pathname: '/disable-notifications', search: `?${params}` }} />;
  }

  return returnRender;
};

export default AuthActions;

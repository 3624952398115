import React from 'react';
import Proptypes from 'prop-types';
import { Button } from 'reactstrap';

import { ButtonSendCommentStyle } from './styles';
import { ClipLoader } from 'react-spinners';

const ButtonSendComment = ({ onClickFunction, isLoading }) => {
  return (
    <ButtonSendCommentStyle>
      <Button onClick={onClickFunction}>
        {isLoading ? (
          <ClipLoader size={18} color="white" cssOverride={{ marginTop: '3.5px' }} />
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              d="M7.23177 5.78906L4.89844 7.0618"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.89869 7.05841L5.79487 11.1304C5.8978 11.598 6.27597 11.9553 6.74865 12.0314C7.22134 12.1076 7.69258 11.8873 7.93718 11.4757L12.2697 4.18456C12.484 3.82399 12.4883 3.37614 12.2808 3.01159C12.0734 2.64703 11.6863 2.42188 11.2668 2.42188H3.10423C2.63337 2.42186 2.20865 2.7049 2.02741 3.13949C1.84618 3.57408 1.94396 4.07502 2.27532 4.40955L4.89869 7.05841Z"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </Button>
    </ButtonSendCommentStyle>
  );
};

ButtonSendComment.propTypes = {
  onClickFunction: Proptypes.func,
  isLoading: Proptypes.bool,
};

export default ButtonSendComment;

import i18n from 'i18n';

const serpCountries = [
  {
    value: 'af',
    label: i18n.t('Afghanistan'),
  },
  {
    value: 'al',
    label: i18n.t('Albania'),
  },
  {
    value: 'dz',
    label: i18n.t('Algeria'),
  },
  {
    value: 'as',
    label: i18n.t('American Samoa'),
  },
  {
    value: 'ad',
    label: i18n.t('Andorra'),
  },
  {
    value: 'ao',
    label: i18n.t('Angola'),
  },
  {
    value: 'ai',
    label: i18n.t('Anguilla'),
  },
  {
    value: 'aq',
    label: i18n.t('Antarctica'),
  },
  {
    value: 'ag',
    label: i18n.t('Antigua and Barbuda'),
  },
  {
    value: 'ar',
    label: i18n.t('Argentina'),
  },
  {
    value: 'am',
    label: i18n.t('Armenia'),
  },
  {
    value: 'aw',
    label: i18n.t('Aruba'),
  },
  {
    value: 'au',
    label: i18n.t('Australia'),
  },
  {
    value: 'at',
    label: i18n.t('Austria'),
  },
  {
    value: 'az',
    label: i18n.t('Azerbaijan'),
  },
  {
    value: 'bs',
    label: i18n.t('Bahamas'),
  },
  {
    value: 'bh',
    label: i18n.t('Bahrain'),
  },
  {
    value: 'bd',
    label: i18n.t('Bangladesh'),
  },
  {
    value: 'bb',
    label: i18n.t('Barbados'),
  },
  {
    value: 'by',
    label: i18n.t('Belarus'),
  },
  {
    value: 'be',
    label: i18n.t('Belgium'),
  },
  {
    value: 'bz',
    label: i18n.t('Belize'),
  },
  {
    value: 'bj',
    label: i18n.t('Benin'),
  },
  {
    value: 'bm',
    label: i18n.t('Bermuda'),
  },
  {
    value: 'bt',
    label: i18n.t('Bhutan'),
  },
  {
    value: 'bo',
    label: i18n.t('Bolivia'),
  },
  {
    value: 'ba',
    label: i18n.t('Bosnia and Herzegovina'),
  },
  {
    value: 'bw',
    label: i18n.t('Botswana'),
  },
  {
    value: 'bv',
    label: i18n.t('Bouvet Island'),
  },
  {
    value: 'br',
    label: i18n.t('Brazil'),
  },
  {
    value: 'io',
    label: i18n.t('British Indian Ocean Territory'),
  },
  {
    value: 'bn',
    label: i18n.t('Brunei Darussalam'),
  },
  {
    value: 'bg',
    label: i18n.t('Bulgaria'),
  },
  {
    value: 'bf',
    label: i18n.t('Burkina Faso'),
  },
  {
    value: 'bi',
    label: i18n.t('Burundi'),
  },
  {
    value: 'kh',
    label: i18n.t('Cambodia'),
  },
  {
    value: 'cm',
    label: i18n.t('Cameroon'),
  },
  {
    value: 'ca',
    label: i18n.t('Canada'),
  },
  {
    value: 'cv',
    label: i18n.t('Cape Verde'),
  },
  {
    value: 'ky',
    label: i18n.t('Cayman Islands'),
  },
  {
    value: 'cf',
    label: i18n.t('Central African Republic'),
  },
  {
    value: 'td',
    label: i18n.t('Chad'),
  },
  {
    value: 'cl',
    label: i18n.t('Chile'),
  },
  {
    value: 'cn',
    label: i18n.t('China'),
  },
  {
    value: 'cx',
    label: i18n.t('Christmas Island'),
  },
  {
    value: 'cc',
    label: i18n.t('Cocos (Keeling) Islands'),
  },
  {
    value: 'co',
    label: i18n.t('Colombia'),
  },
  {
    value: 'km',
    label: i18n.t('Comoros'),
  },
  {
    value: 'cg',
    label: i18n.t('Congo'),
  },
  {
    value: 'cd',
    label: i18n.t('Congo, the Democratic Republic of the'),
  },
  {
    value: 'ck',
    label: i18n.t('Cook Islands'),
  },
  {
    value: 'cr',
    label: i18n.t('Costa Rica'),
  },
  {
    value: 'ci',
    label: i18n.t('Ivory Coast'),
  },
  {
    value: 'hr',
    label: i18n.t('Croatia'),
  },
  {
    value: 'cu',
    label: i18n.t('Cuba'),
  },
  {
    value: 'cy',
    label: i18n.t('Cyprus'),
  },
  {
    value: 'cz',
    label: i18n.t('Czech Republic'),
  },
  {
    value: 'dk',
    label: i18n.t('Denmark'),
  },
  {
    value: 'dj',
    label: i18n.t('Djibouti'),
  },
  {
    value: 'dm',
    label: i18n.t('Dominica'),
  },
  {
    value: 'do',
    label: i18n.t('Dominican Republic'),
  },
  {
    value: 'ec',
    label: i18n.t('Ecuador'),
  },
  {
    value: 'eg',
    label: i18n.t('Egypt'),
  },
  {
    value: 'sv',
    label: i18n.t('El Salvador'),
  },
  {
    value: 'gq',
    label: i18n.t('Equatorial Guinea'),
  },
  {
    value: 'er',
    label: i18n.t('Eritrea'),
  },
  {
    value: 'ee',
    label: i18n.t('Estonia'),
  },
  {
    value: 'et',
    label: i18n.t('Ethiopia'),
  },
  {
    value: 'fk',
    label: i18n.t('Falkland Islands (Malvinas)'),
  },
  {
    value: 'fo',
    label: i18n.t('Faroe Islands'),
  },
  {
    value: 'fj',
    label: i18n.t('Fiji'),
  },
  {
    value: 'fi',
    label: i18n.t('Finland'),
  },
  {
    value: 'fr',
    label: i18n.t('France'),
  },
  {
    value: 'gf',
    label: i18n.t('French Guiana'),
  },
  {
    value: 'pf',
    label: i18n.t('French Polynesia'),
  },
  {
    value: 'tf',
    label: i18n.t('French Southern Territories'),
  },
  {
    value: 'ga',
    label: i18n.t('Gabon'),
  },
  {
    value: 'gm',
    label: i18n.t('Gambia'),
  },
  {
    value: 'ge',
    label: i18n.t('Georgia'),
  },
  {
    value: 'de',
    label: i18n.t('Germany'),
  },
  {
    value: 'gh',
    label: i18n.t('Ghana'),
  },
  {
    value: 'gi',
    label: i18n.t('Gibraltar'),
  },
  {
    value: 'gr',
    label: i18n.t('Greece'),
  },
  {
    value: 'gl',
    label: i18n.t('Greenland'),
  },
  {
    value: 'gd',
    label: i18n.t('Grenada'),
  },
  {
    value: 'gp',
    label: i18n.t('Guadeloupe'),
  },
  {
    value: 'gu',
    label: i18n.t('Guam'),
  },
  {
    value: 'gt',
    label: i18n.t('Guatemala'),
  },
  {
    value: 'gn',
    label: i18n.t('Guinea'),
  },
  {
    value: 'gw',
    label: i18n.t('Guinea-Bissau'),
  },
  {
    value: 'gy',
    label: i18n.t('Guyana'),
  },
  {
    value: 'ht',
    label: i18n.t('Haiti'),
  },
  {
    value: 'hm',
    label: i18n.t('Heard Island and Mcdonald Islands'),
  },
  {
    value: 'va',
    label: i18n.t('Holy See (Vatican City State)'),
  },
  {
    value: 'hn',
    label: i18n.t('Honduras'),
  },
  {
    value: 'hk',
    label: i18n.t('Hong Kong'),
  },
  {
    value: 'hu',
    label: i18n.t('Hungary'),
  },
  {
    value: 'is',
    label: i18n.t('Iceland'),
  },
  {
    value: 'in',
    label: i18n.t('India'),
  },
  {
    value: 'id',
    label: i18n.t('Indonesia'),
  },
  {
    value: 'ir',
    label: i18n.t('Iran'),
  },
  {
    value: 'iq',
    label: i18n.t('Iraq'),
  },
  {
    value: 'ie',
    label: i18n.t('Ireland'),
  },
  {
    value: 'il',
    label: i18n.t('Israel'),
  },
  {
    value: 'it',
    label: i18n.t('Italy'),
  },
  {
    value: 'jm',
    label: i18n.t('Jamaica'),
  },
  {
    value: 'jp',
    label: i18n.t('Japan'),
  },
  {
    value: 'jo',
    label: i18n.t('Jordan'),
  },
  {
    value: 'kz',
    label: i18n.t('Kazakhstan'),
  },
  {
    value: 'ke',
    label: i18n.t('Kenya'),
  },
  {
    value: 'ki',
    label: i18n.t('Kiribati'),
  },
  {
    value: 'kp',
    label: i18n.t('North Korea'),
  },
  {
    value: 'kr',
    label: i18n.t('South Korea'),
  },
  {
    value: 'kw',
    label: i18n.t('Kuwait'),
  },
  {
    value: 'kg',
    label: i18n.t('Kyrgyzstan'),
  },
  {
    value: 'la',
    label: i18n.t('Laos'),
  },
  {
    value: 'lv',
    label: i18n.t('Latvia'),
  },
  {
    value: 'lb',
    label: i18n.t('Lebanon'),
  },
  {
    value: 'ls',
    label: i18n.t('Lesotho'),
  },
  {
    value: 'lr',
    label: i18n.t('Liberia'),
  },
  {
    value: 'ly',
    label: i18n.t('Libya'),
  },
  {
    value: 'li',
    label: i18n.t('Liechtenstein'),
  },
  {
    value: 'lt',
    label: i18n.t('Lithuania'),
  },
  {
    value: 'lu',
    label: i18n.t('Luxembourg'),
  },
  {
    value: 'mo',
    label: i18n.t('Macao'),
  },
  {
    value: 'mk',
    label: i18n.t('Macedonia'),
  },
  {
    value: 'mg',
    label: i18n.t('Madagascar'),
  },
  {
    value: 'mw',
    label: i18n.t('Malawi'),
  },
  {
    value: 'my',
    label: i18n.t('Malaysia'),
  },
  {
    value: 'mv',
    label: i18n.t('Maldives'),
  },
  {
    value: 'ml',
    label: i18n.t('Mali'),
  },
  {
    value: 'mt',
    label: i18n.t('Malta'),
  },
  {
    value: 'mh',
    label: i18n.t('Marshall Islands'),
  },
  {
    value: 'mq',
    label: i18n.t('Martinique'),
  },
  {
    value: 'mr',
    label: i18n.t('Mauritania'),
  },
  {
    value: 'mu',
    label: i18n.t('Mauritius'),
  },
  {
    value: 'yt',
    label: i18n.t('Mayotte'),
  },
  {
    value: 'mx',
    label: i18n.t('Mexico'),
  },
  {
    value: 'fm',
    label: i18n.t('Micronesia'),
  },
  {
    value: 'md',
    label: i18n.t('Moldova'),
  },
  {
    value: 'mc',
    label: i18n.t('Monaco'),
  },
  {
    value: 'mn',
    label: i18n.t('Mongolia'),
  },
  {
    value: 'ms',
    label: i18n.t('Montserrat'),
  },
  {
    value: 'ma',
    label: i18n.t('Morocco'),
  },
  {
    value: 'mz',
    label: i18n.t('Mozambique'),
  },
  {
    value: 'mm',
    label: i18n.t('Myanmar'),
  },
  {
    value: 'na',
    label: i18n.t('Namibia'),
  },
  {
    value: 'nr',
    label: i18n.t('Nauru'),
  },
  {
    value: 'np',
    label: i18n.t('Nepal'),
  },
  {
    value: 'nl',
    label: i18n.t('Netherlands'),
  },
  {
    value: 'an',
    label: i18n.t('Netherlands Antilles'),
  },
  {
    value: 'nc',
    label: i18n.t('New Caledonia'),
  },
  {
    value: 'nz',
    label: i18n.t('New Zealand'),
  },
  {
    value: 'ni',
    label: i18n.t('Nicaragua'),
  },
  {
    value: 'ne',
    label: i18n.t('Niger'),
  },
  {
    value: 'ng',
    label: i18n.t('Nigeria'),
  },
  {
    value: 'nu',
    label: i18n.t('Niue'),
  },
  {
    value: 'nf',
    label: i18n.t('Norfolk Island'),
  },
  {
    value: 'mp',
    label: i18n.t('Northern Mariana Islands'),
  },
  {
    value: 'no',
    label: i18n.t('Norway'),
  },
  {
    value: 'om',
    label: i18n.t('Oman'),
  },
  {
    value: 'pk',
    label: i18n.t('Pakistan'),
  },
  {
    value: 'pw',
    label: i18n.t('Palau'),
  },
  {
    value: 'ps',
    label: i18n.t('Palestine'),
  },
  {
    value: 'pa',
    label: i18n.t('Panama'),
  },
  {
    value: 'pg',
    label: i18n.t('Papua New Guinea'),
  },
  {
    value: 'py',
    label: i18n.t('Paraguay'),
  },
  {
    value: 'pe',
    label: i18n.t('Peru'),
  },
  {
    value: 'ph',
    label: i18n.t('Philippines'),
  },
  {
    value: 'pn',
    label: i18n.t('Pitcairn'),
  },
  {
    value: 'pl',
    label: i18n.t('Poland'),
  },
  {
    value: 'pt',
    label: i18n.t('Portugal'),
  },
  {
    value: 'pr',
    label: i18n.t('Puerto Rico'),
  },
  {
    value: 'qa',
    label: i18n.t('Qatar'),
  },
  {
    value: 're',
    label: i18n.t('Reunion'),
  },
  {
    value: 'ro',
    label: i18n.t('Romania'),
  },
  {
    value: 'ru',
    label: i18n.t('Russia'),
  },
  {
    value: 'rw',
    label: i18n.t('Rwanda'),
  },
  {
    value: 'sh',
    label: i18n.t('Saint Helena, Ascension and Tristan da Cunha'),
  },
  {
    value: 'kn',
    label: i18n.t('Saint Kitts and Nevis'),
  },
  {
    value: 'lc',
    label: i18n.t('Saint Lucia'),
  },
  {
    value: 'pm',
    label: i18n.t('Saint Pierre and Miquelon'),
  },
  {
    value: 'vc',
    label: i18n.t('Saint Vincent and the Grenadines'),
  },
  {
    value: 'ws',
    label: i18n.t('Samoa'),
  },
  {
    value: 'sm',
    label: i18n.t('San Marino'),
  },
  {
    value: 'st',
    label: i18n.t('Sao Tome and Principe'),
  },
  {
    value: 'sa',
    label: i18n.t('Saudi Arabia'),
  },
  {
    value: 'sn',
    label: i18n.t('Senegal'),
  },
  {
    value: 'rs',
    label: i18n.t('Serbia and Montenegro'),
  },
  {
    value: 'sc',
    label: i18n.t('Seychelles'),
  },
  {
    value: 'sl',
    label: i18n.t('Sierra Leone'),
  },
  {
    value: 'sg',
    label: i18n.t('Singapore'),
  },
  {
    value: 'sk',
    label: i18n.t('Slovakia'),
  },
  {
    value: 'si',
    label: i18n.t('Slovenia'),
  },
  {
    value: 'sb',
    label: i18n.t('Solomon Islands'),
  },
  {
    value: 'so',
    label: i18n.t('Somalia'),
  },
  {
    value: 'za',
    label: i18n.t('South Africa'),
  },
  {
    value: 'gs',
    label: i18n.t('South Georgia and the South Sandwich Islands'),
  },
  {
    value: 'es',
    label: i18n.t('Spain'),
  },
  {
    value: 'lk',
    label: i18n.t('Sri Lanka'),
  },
  {
    value: 'sd',
    label: i18n.t('Sudan'),
  },
  {
    value: 'sr',
    label: i18n.t('Suriname'),
  },
  {
    value: 'sj',
    label: i18n.t('Svalbard and Jan Mayen'),
  },
  {
    value: 'sz',
    label: i18n.t('Swaziland'),
  },
  {
    value: 'se',
    label: i18n.t('Sweden'),
  },
  {
    value: 'ch',
    label: i18n.t('Switzerland'),
  },
  {
    value: 'sy',
    label: i18n.t('Syria'),
  },
  {
    value: 'tw',
    label: i18n.t('Taiwan'),
  },
  {
    value: 'tj',
    label: i18n.t('Tajikistan'),
  },
  {
    value: 'tz',
    label: i18n.t('Tanzania'),
  },
  {
    value: 'th',
    label: i18n.t('Thailand'),
  },
  {
    value: 'tl',
    label: i18n.t('Timor-Leste'),
  },
  {
    value: 'tg',
    label: i18n.t('Togo'),
  },
  {
    value: 'tk',
    label: i18n.t('Tokelau'),
  },
  {
    value: 'to',
    label: i18n.t('Tonga'),
  },
  {
    value: 'tt',
    label: i18n.t('Trinidad and Tobago'),
  },
  {
    value: 'tn',
    label: i18n.t('Tunisia'),
  },
  {
    value: 'tr',
    label: i18n.t('Turkey'),
  },
  {
    value: 'tm',
    label: i18n.t('Turkmenistan'),
  },
  {
    value: 'tc',
    label: i18n.t('Turks and Caicos Islands'),
  },
  {
    value: 'tv',
    label: i18n.t('Tuvalu'),
  },
  {
    value: 'ug',
    label: i18n.t('Uganda'),
  },
  {
    value: 'ua',
    label: i18n.t('Ukraine'),
  },
  {
    value: 'ae',
    label: i18n.t('United Arab Emirates'),
  },
  {
    value: 'uk',
    label: i18n.t('United Kingdom'),
  },
  {
    value: 'gb',
    label: i18n.t('United Kingdom'),
  },
  {
    value: 'us',
    label: i18n.t('United States'),
  },
  {
    value: 'um',
    label: i18n.t('United States Minor Outlying Islands'),
  },
  {
    value: 'uy',
    label: i18n.t('Uruguay'),
  },
  {
    value: 'uz',
    label: i18n.t('Uzbekistan'),
  },
  {
    value: 'vu',
    label: i18n.t('Vanuatu'),
  },
  {
    value: 've',
    label: i18n.t('Venezuela'),
  },
  {
    value: 'vn',
    label: i18n.t('Vietnam'),
  },
  {
    value: 'vg',
    label: i18n.t('Virgin Islands, British'),
  },
  {
    value: 'vi',
    label: i18n.t('Virgin Islands, U.S.'),
  },
  {
    value: 'wf',
    label: i18n.t('Wallis and Futuna'),
  },
  {
    value: 'eh',
    label: i18n.t('Western Sahara'),
  },
  {
    value: 'ye',
    label: i18n.t('Yemen'),
  },
  {
    value: 'zm',
    label: i18n.t('Zambia'),
  },
  {
    value: 'zw',
    label: i18n.t('Zimbabwe'),
  },
];

export { serpCountries };

const selectHours = [
  {
    value: 8,
    label: '08:00',
  },
  {
    value: 9,
    label: '09:00',
  },
  {
    value: 10,
    label: '10:00',
  },
  {
    value: 11,
    label: '11:00',
  },
  {
    value: 12,
    label: '12:00',
  },
  {
    value: 13,
    label: '13:00',
  },
  {
    value: 14,
    label: '14:00',
  },
  {
    value: 15,
    label: '15:00',
  },
  {
    value: 16,
    label: '16:00',
  },
  {
    value: 17,
    label: '17:00',
  },
  {
    value: 18,
    label: '18:00',
  },
  {
    value: 19,
    label: '19:00',
  },
  {
    value: 20,
    label: '20:00',
  },
  {
    value: 21,
    label: '21:00',
  },
  {
    value: 22,
    label: '22:00',
  },
  {
    value: 23,
    label: '23:00',
  },
];

export { selectHours };

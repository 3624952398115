import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { ButtonDeleteStyled, CardInputCMSStyled } from './styles';
import { StrapiIcon, WordpressIcon } from 'assets/icons/Cms';
import TextInput from 'components/Text-Input';
import LabelGroup from 'components/Label-Group';
import DisplayErrors from 'components/Display-Errors';

const CardInputCMS = ({
  accountId,
  watch,
  setValue,
  getValues,
  cmsType,
  setCmsType,
  errors,
  clearErrors,
  isSaved,
  setIsSaved,
}) => {
  const { t: translate } = useTranslation();
  const firebaseHelper = getFirebaseBackend();
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const deleteCmsAuth = async () => {
    try {
      await firebaseHelper.deleteCmsAuth(accountId);
      setValue('cmsAuth.link', '');
      setValue('cmsAuth.login', '');
      setValue('cmsAuth.password', '');
      setValue('cmsAuth.base64', '');
      setValue('cmsAuth.key', '');
      setValue('cmsAuth.type', '');
      setIsSaved(false);
      setCmsType(null);
      toggle();
    } catch (error) {
      console.log(error);
    }
  };

  const normalizeUrl = (url) => {
    const hasHttpPrefix = url.startsWith('http://') || url.startsWith('https://');
    return hasHttpPrefix ? url : `http://${url}`;
  };

  const openInputLink = () => {
    window.open(normalizeUrl(watch('cmsAuth.link')), '_blank');
  };

  const handleErrors = (errors) => {
    if (!errors || Object.keys(errors).length === 0) {
      return {};
    }

    if (
      typeof errors === 'object' &&
      Object.values(errors).every((error) => typeof error === 'string')
    ) {
      return errors;
    }

    const formattedErrors = {};

    if (errors) {
      formattedErrors.required = errors.message || errors;
    }

    return formattedErrors;
  };

  useEffect(async () => {
    watch('cmsAuth.type') === 'STRAPI' && setCmsType('STRAPI');
    watch('cmsAuth.type') === 'WORDPRESS' && setCmsType('WORDPRESS');
  }, [accountId]);

  return (
    <div className="d-flex flex-column gap-4">
      {!isSaved && !watch('cmsAuth.type') && (
        <CardInputCMSStyled>
          {translate('Choose which CMS you want to integrate')}
          <div className="d-flex w-100 gap-2">
            <Button
              className="btn-cms"
              onClick={() => {
                setCmsType('WORDPRESS');
                clearErrors('cmsAuth');
              }}
            >
              <WordpressIcon />
              {translate('WordPress Credentials')}
            </Button>
            <Button
              className="btn-cms"
              onClick={() => {
                setCmsType('STRAPI');
                clearErrors('cmsAuth');
              }}
            >
              <StrapiIcon />
              {translate('Strapi Credentials')}
            </Button>
          </div>
        </CardInputCMSStyled>
      )}
      {cmsType === 'WORDPRESS' && (
        <CardInputCMSStyled>
          <div className="d-flex w-100 gap-2">
            <WordpressIcon />
            <div className="w-100 flex-column gap-2">
              <div className="d-flex w-100 gap-2">
                <div className="w-100">
                  <LabelGroup
                    className="w-100 m-0"
                    style={{ paddingTop: '0px' }}
                    htmlFor="cmsAuth.link"
                    label="Link"
                    mb={0}
                    column
                    required
                  >
                    <TextInput
                      id="cmsAuth.link"
                      controlName="cmsAuth.link"
                      placeholder={translate('Insert link here')}
                      noEllipsis
                      required
                      validation={
                        watch('cmsAuth.link') !== '' && !watch('cmsAuth.link').includes('http')
                          ? translate('Invalid link')
                          : ''
                      }
                    />
                    <DisplayErrors error={handleErrors(errors?.cmsAuth?.link)} />
                  </LabelGroup>
                </div>
                {getValues('cmsAuth.link') !== '' && (
                  <Button
                    className="btn-go-to open-link-button"
                    onClick={openInputLink}
                    style={{
                      minHeight: '2.3rem',
                      alignSelf: 'end',
                    }}
                  >
                    <i className="bx bx-link-external" />
                  </Button>
                )}
              </div>
              <div className="d-flex w-100 gap-2 mt-1">
                <LabelGroup
                  className="w-100 m-0"
                  htmlFor="cmsAuth.login"
                  label={`${translate('Login')}`}
                  mb={0}
                  column
                  required
                >
                  <TextInput
                    id="cmsAuth.login"
                    controlName="cmsAuth.login"
                    placeholder={translate('E-mail')}
                    noEllipsis
                    minWidth="100%"
                    type="email"
                    required
                  />
                  <DisplayErrors error={handleErrors(errors?.cmsAuth?.login)} />
                </LabelGroup>
                {!isSaved && watch('cmsAuth.base64') === '' && (
                  <LabelGroup
                    className="w-100 m-0"
                    htmlFor="cmsAuth.password"
                    label={`${translate('Password')}`}
                    mb={0}
                    column
                    required
                  >
                    <TextInput
                      id="cmsAuth.password"
                      controlName="cmsAuth.password"
                      placeholder={translate('Password')}
                      noEllipsis
                      minWidth="100%"
                      type="password"
                      required
                    />
                    <DisplayErrors error={handleErrors(errors?.cmsAuth?.password)} />

                    <TextInput
                      id="cmsAuth.base64"
                      controlName="cmsAuth.base64"
                      noEllipsis
                      minWidth="100%"
                      type="hidden"
                    />
                    <TextInput
                      id="cmsAuth.type"
                      controlName="cmsAuth.type"
                      noEllipsis
                      minWidth="100%"
                      type="hidden"
                    />
                  </LabelGroup>
                )}
              </div>
              {isSaved && cmsType === 'WORDPRESS' && (
                <div className="d-flex w-100 justify-content-end">
                  <Button className="btn-delete mt-3" onClick={toggle}>
                    <i className="bx bxs-trash" />
                    {translate('Delete credential')}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </CardInputCMSStyled>
      )}
      {cmsType === 'STRAPI' && (
        <CardInputCMSStyled>
          <div className="d-flex w-100 gap-2">
            <StrapiIcon />
            <div className="w-100 flex-column gap-2">
              <div className="d-flex w-100 gap-2">
                <div className="w-100">
                  <LabelGroup
                    className="w-100 m-0"
                    style={{ paddingTop: '0px' }}
                    htmlFor="cmsAuth.link"
                    label="Link"
                    mb={0}
                    column
                    required
                  >
                    <TextInput
                      id="cmsAuth.link"
                      controlName="cmsAuth.link"
                      placeholder={translate('Insert link here')}
                      noEllipsis
                      required
                      validation={
                        watch('cmsAuth.link') !== '' && !watch('cmsAuth.link').includes('http')
                          ? translate('Invalid link')
                          : ''
                      }
                    />
                    <DisplayErrors error={handleErrors(errors?.cmsAuth?.link)} />
                  </LabelGroup>
                  <LabelGroup
                    className="w-100 m-0 mt-1"
                    htmlFor="cmsAuth.key"
                    label={`${translate('API key')}`}
                    mb={0}
                    column
                    required
                  >
                    <TextInput
                      id="cmsAuth.key"
                      controlName="cmsAuth.key"
                      placeholder={translate('Enter Strapi API key here')}
                      noEllipsis
                      required
                    />
                    <DisplayErrors error={handleErrors(errors?.cmsAuth?.key)} />
                  </LabelGroup>
                  <TextInput
                    id="cmsAuth.type"
                    controlName="cmsAuth.type"
                    noEllipsis
                    minWidth="100%"
                    type="hidden"
                  />
                </div>
              </div>
              {isSaved && cmsType === 'STRAPI' && (
                <div className="d-flex w-100 justify-content-end">
                  <Button className="btn-delete mt-3" onClick={toggle}>
                    <i className="bx bxs-trash" />
                    {translate('Delete credential')}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </CardInputCMSStyled>
      )}
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>{translate('Delete accreditation')}</ModalHeader>
        <ModalBody>
          {cmsType === 'WORDPRESS' &&
            translate('Are you sure you want to delete integration with this WordPress directory?')}
          {cmsType === 'STRAPI' &&
            translate('Are you sure you want to delete integration with this Strapi directory?')}
        </ModalBody>
        <ModalFooter style={{ border: 'none' }}>
          <Button onClick={toggle}>{translate('Cancel')}</Button>
          <ButtonDeleteStyled onClick={deleteCmsAuth}>{translate('Delete')}</ButtonDeleteStyled>
        </ModalFooter>
      </Modal>
    </div>
  );
};

CardInputCMS.propTypes = {
  accountId: PropTypes.string,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  cmsType: PropTypes.string,
  setCmsType: PropTypes.func,
  errors: PropTypes.object,
  clearErrors: PropTypes.func,
  isSaved: PropTypes.bool,
  setIsSaved: PropTypes.func,
};

export default CardInputCMS;

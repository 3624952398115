import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Col, Row, Button } from 'reactstrap';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getFirebaseBackend } from '../../helpers/firebaseHelper';
import PageStructure from '../../components/Page-Structure/';
import SuccessModal from '../../components/Success-Modal';
import ErrorModal from '../../components/Error-Modal';
import LabelGroup from '../../components/Label-Group';
import TextInput from '../../components/Text-Input';
import SelectInput from '../../components/Select-Input';
import ShowComponent from 'components/Show-Component';
import ModulesInput from 'components/Modules-Input';
import { loadTeam, getFormValue } from 'utils';

const formConfig = {
  defaultValues: {
    name: '',
    teachers: [],
    modules: [
      {
        name: '',
        description: '',
        lessons: [{ id: undefined, freeTrial: false }],
      },
    ],
    description: '',
    clientFree: false,
    freeTrial: false,
    freeTrialDays: 1,
    showOtherUsers: false,
    availability: null,
    releaseDate: '',
    soon: false,
    certificateLock: false,
    certificateDays: 7,
    icon: null,
    status: null,
  },
  shouldFocusError: true,
  criteriaMode: 'all',
  mode: 'onSubmit',
  reValidateMode: 'onChange',
};

const EditCourse = () => {
  const firebaseHelper = getFirebaseBackend();
  const { t: translate } = useTranslation();
  const form = useForm(formConfig);
  const { formState, watch, reset, setValue, handleSubmit, setError } = form;
  const [params] = useSearchParams();
  const accountParam = params.get('acc');
  const courseParam = params.get('id');
  const [fetchSuccess, setFetchSuccess] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [teachersKey, setTeachersKey] = useState(1000);

  const closeModalHandle = () => {
    reset();
    setSuccessModal(false);
    getCourse();
  };

  const fetchCourseBanner = async () => {
    const path = `${accountParam}/courses/${courseParam}`;
    const bannerMeta = await firebaseHelper.listAllFiles(path);
    if (bannerMeta.length) {
      const bannerBlob = await firebaseHelper.getFileObj(`${path}/${bannerMeta[0].name}`);
      const bannerFile = new File([bannerBlob], bannerMeta[0].name);
      setValue('icon', bannerFile);
    }
  };

  const getCourse = async () => {
    try {
      fetchCourseBanner();
      const course = await firebaseHelper.getFirebaseCourse(accountParam, courseParam);
      const processedTeachers = await Promise.all(
        course.teachers.map(async (teacher) => {
          const label = await firebaseHelper.userName(teacher);
          return { value: teacher, label };
        }),
      );
      const processedModules = [];
      await Promise.all(
        course.modules.map(async (module) => {
          const newLessons = await Promise.all(
            module.lessons.map(async (lessonObj) => {
              const { name } = await firebaseHelper.getFirebaseLesson(accountParam, lessonObj.id);
              return { ...lessonObj, id: { value: lessonObj.id, label: name } };
            }),
          );
          processedModules.push({ ...module, lessons: newLessons });
        }),
      );
      form.reset({
        id: course.id,
        name: course.name,
        teachers: processedTeachers,
        modules: processedModules,
        description: course.description,
        clientFree: course.clientFree,
        freeTrial: course.freeTrial,
        freeTrialDays: course.freeTrialDays,
        showOtherUsers: course.showOtherUsers,
        availability: course.availability,
        releaseDate: course.releaseDate,
        soon: course.soon,
        certificateLock: course.certificateLock,
        certificateDays: course.certificateDays,
        status: course.status,
      });
      setFetchSuccess(true);
    } catch (error) {
      setErrorModal(true);
      console.error(error);
    }
  };

  const onSubmit = async (data) => {
    let moduleWithoutLesson = false;
    const processedModules = data.modules.map((module) => {
      const newLessons = module.lessons.filter((lesson) => lesson.id);
      const lessonEmpty = !newLessons.length;
      if (lessonEmpty) moduleWithoutLesson = true;
      return {
        ...module,
        lessons: lessonEmpty
          ? null
          : module.lessons?.map(({ id, freeTrial }) => {
              return { id: id.value, freeTrial };
            }),
      };
    });

    const course = {
      accountParam,
      id: courseParam,
      name: data.name,
      teachers: data.teachers.map(getFormValue),
      modules: processedModules,
      description: data.description,
      clientFree: data.clientFree,
      freeTrial: data.freeTrial,
      freeTrialDays: data.freeTrial ? data.freeTrialDays : null,
      showOtherUsers: data.showOtherUsers,
      availability: data.availability,
      releaseDate: data.availability === 'date' ? new Date(data.releaseDate).valueOf() : null,
      soon: data.availability === 'date' ? data.soon : null,
      certificateLock: data.certificateLock,
      certificateDays: data.certificateLock ? data.certificateDays : null,
      status: data.status,
    };

    if (moduleWithoutLesson && course.status !== 'draft') {
      return setError('modules', {
        types: {
          validate:
            "You can only leave the lessons of a course empty if it's being saved as a draft! Make a change in any module name or description to remove this error.",
        },
      });
    }
    firebaseHelper.updateFirebaseCourse(course).then(() => {
      if (data.icon) {
        firebaseHelper.updateCourseBanner(data.icon, accountParam, courseParam);
      }
      getCourse();
      setSuccessModal(true);
    });
  };

  const handleClick = (event, status) => {
    event.preventDefault();

    setValue('status', status);
    handleSubmit(onSubmit)();
  };

  useEffect(() => {
    getCourse();
  }, []);

  useEffect(() => {
    setTeachersKey((prevState) => prevState + 1);
  }, [accountParam]);

  return (
    <PageStructure
      metaTitle="Edit Course"
      cardTitle="Edit an existing Course"
      card
      breadcrumbItem="Edit Course"
      breadcrumbTitle="Courses"
    >
      <SuccessModal
        isOpen={successModal}
        onClose={closeModalHandle}
        verb="Updated"
        buttonText="See Courses"
        buttonLink="/courses"
      />

      <ErrorModal isOpen={errorModal} />

      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <LabelGroup htmlFor="name" label="" size={12}>
            <TextInput
              controlName="name"
              placeholder="Course Name"
              label="Course Name"
              s
              size={6}
              required
            />

            <SelectInput
              asyncronous
              isMulti
              controlName="teachers"
              label="Course Teachers"
              required
              key={teachersKey}
              size={6}
              cacheOptions
              defaultOptions
              loadOptions={(inputValue) => loadTeam(accountParam, inputValue)}
            />
          </LabelGroup>

          <ModulesInput mb={4} />

          <Row className="gap-2">
            <Col lg="auto">
              <Button
                type="submit"
                style={{ backgroundColor: '#50A5F1', borderColor: '#50A5F1' }}
                disabled={Object.values(formState.errors).length || !fetchSuccess}
              >
                {translate(watch('status') === 'draft' ? 'Save as Draft' : 'Save Course')}
              </Button>
            </Col>

            <ShowComponent condition={watch('status') === 'published'}>
              <Col lg="auto">
                <Button
                  type="submit"
                  style={{ backgroundColor: '#F1B44C', borderColor: '#F1B44C' }}
                  disabled={Object.values(formState.errors).length || !fetchSuccess}
                  onClick={(event) => handleClick(event, 'archived')}
                >
                  {translate('Archive Course')}
                </Button>
              </Col>
            </ShowComponent>

            <ShowComponent condition={watch('status') === 'scheduled'}>
              <Col lg="auto">
                <Button
                  type="submit"
                  style={{ backgroundColor: '#E83E8C', borderColor: '#E83E8C' }}
                  disabled={Object.values(formState.errors).length || !fetchSuccess}
                  onClick={(event) => handleClick(event, 'draft')}
                >
                  {translate('Cancel Scheduling')}
                </Button>
              </Col>

              <Col lg="auto">
                <Button
                  type="submit"
                  style={{ backgroundColor: '#34C38F', borderColor: '#34C38F' }}
                  disabled={Object.values(formState.errors).length || !fetchSuccess}
                  onClick={(event) => handleClick(event, 'published')}
                >
                  {translate('Publish Now')}
                </Button>
              </Col>
            </ShowComponent>

            <ShowComponent
              condition={watch('status') === 'draft' || watch('status') === 'archived'}
            >
              <ShowComponent condition={watch('availability') === 'date'}>
                <Col lg="auto">
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: '#7367F0',
                      borderColor: '#7367F0',
                    }}
                    disabled={Object.values(formState.errors).length || !fetchSuccess}
                    onClick={(event) => handleClick(event, 'scheduled')}
                  >
                    {translate('Schedule Course')}
                  </Button>
                </Col>
              </ShowComponent>

              <ShowComponent condition={watch('availability') !== 'date'}>
                <Col lg="auto">
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: '#34C38F',
                      borderColor: '#34C38F',
                    }}
                    disabled={Object.values(formState.errors).length || !fetchSuccess}
                    onClick={(event) => handleClick(event, 'published')}
                  >
                    {translate('Publish Course')}
                  </Button>
                </Col>
              </ShowComponent>
            </ShowComponent>
          </Row>
        </form>
      </FormProvider>
    </PageStructure>
  );
};

EditCourse.displayName = 'Edit Course Page';

export default EditCourse;

import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { getDatabase, ref, onValue, off } from 'firebase/database';
import 'boxicons';

import { useAuth } from 'hooks/useAuth';
import { getFirebaseBackend } from '../../helpers/firebaseHelper';
import ModalContentContext from 'components/Modal-Content/ModalContent.Context';
import ExperimentsDashboard from './ExperimentsDashboard';
import ShowComponent from 'components/Show-Component';
import { formatDate, translateArray } from '../../utils/';

import { ContentHeader, ContentBody, RequestAPIButton } from './styles';

const ExperimentsContent = () => {
  const firebaseHelper = getFirebaseBackend();

  const { t: translate } = useTranslation();
  const { content, adminStatus } = useContext(ModalContentContext);
  const { user } = useAuth();
  const accountId = user.account;
  const [hasExperiment, setHasExperiment] = useState(false);
  const [accountCredits, setAccountCredits] = useState(0);

  useEffect(() => {
    if (!accountId) return;

    const db = getDatabase();
    const creditRef = ref(db, `accounts/${accountId}/shownCredits`);
    const experimentRef = ref(db, `experiments/${accountId}/${content?.id}/serp/batchId`);

    const handleCreditValueChange = (snapshot) => {
      const data = snapshot.val();
      if (data) setAccountCredits(data);
    };

    const handleExperimentValueChange = (snapshot) => {
      const data = snapshot.val();
      if (data) setHasExperiment(true);
      else setHasExperiment(false);
    };

    onValue(creditRef, handleCreditValueChange);
    onValue(experimentRef, handleExperimentValueChange);

    return () => {
      off(creditRef, 'value', handleCreditValueChange);
      off(experimentRef, 'value', handleExperimentValueChange);
    };
  }, [accountId]);

  const handleAPIRequest = async (event) => {
    event.preventDefault();

    const {
      serp: { batchId },
    } = await firebaseHelper.getFirebaseExperiment(accountId, content.id);
    await firebaseHelper.runValueSerpBatch(batchId);
  };

  return (
    <ContentBody>
      <ContentHeader>
        <div>
          <h3>{content?.name}</h3>
        </div>
        <div className="d-flex gap-3 mt-2" style={{ color: 'rgba(173, 181, 189, 1)' }}>
          <div className="d-flex align-items-center">
            <i className="bx bx-calendar-alt fs-4"></i>
            <span className="mx-2">
              {content?.dateBegin
                ? formatDate(new Date(content?.dateBegin), translate('date-enUS'))
                : 'N/A'}
            </span>
          </div>
          <div className="d-flex align-items-center">
            <i className="bx bx-calendar-check fs-4"></i>
            <span className="mx-2">
              {content?.dateDelivery
                ? formatDate(new Date(content?.dateDelivery), translate('date-enUS'))
                : 'N/A'}
            </span>
          </div>
          <div className="d-flex align-items-center">
            <i className="bx bx-purchase-tag-alt fs-4"></i>
            <span className="mx-2">
              {content?.foundations?.map(translateArray)?.toLocaleString()}
            </span>
          </div>
          <ShowComponent condition={hasExperiment && adminStatus !== 'Client' && accountCredits}>
            <RequestAPIButton onClick={handleAPIRequest}>
              <box-icon
                type="regular"
                name="caret-right-circle"
                color="#50A5F1"
                size="1.5rem"
              ></box-icon>
              {translate('Request API data')}
            </RequestAPIButton>
          </ShowComponent>
        </div>
      </ContentHeader>

      <ExperimentsDashboard />
    </ContentBody>
  );
};

export default ExperimentsContent;

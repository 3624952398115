import styled from 'styled-components';

export const TabWrapper = styled.div`
  background-color: #f0f2f6;
  padding: 1rem;

  h4 {
    font-size: 0.8rem;
    color: #7367f0;
  }
`;

export const FreeTrialDisclaimer = styled.div`
  margin-left: 85px;

  color: #808080;
  font-style: italic;
  font-size: 0.7rem;
`;

export const AvailabilityConfig = styled.div`
  display: flex;
  gap: 1em;
  align-items: center;

  label {
    color: #808080;
    font-style: italic;
    font-size: 0.7rem;
    margin: 0;
  }
`;

export const ModuleBox = styled.div`
  display: flex;
  gap: 1em;
  margin-top: 1em;

  > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;

    button {
      background-color: transparent;
      padding: 0;
      margin: 0;
      border: 0;

      &:hover {
        background-color: transparent;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }

    > div:nth-child(1) {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 3rem;
      width: 3rem;
      font-size: 1.5rem;
      background-color: #fff;
      border: 1px solid #d3d3d3;
      padding: 1rem;
      user-select: none;
      cursor: move;
    }
  }

  > div:nth-child(2) {
    width: 100%;
  }
`;

import styled from 'styled-components';

export const TableLastTasksStyled = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  gap: 2rem;
  padding: 2rem;
  border-radius: 0.5rem;
  border: 1px solid #e2e8f0;
  background: #ffffff;

  .title {
    color: #2d3748;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.75rem;
  }

  .div-bordered {
    border: 1px solid #eff2f7;
    border-radius: 0.5rem;
    overflow: hidden;
  }

  table {
    margin: 0;
  }
  th {
    border-width: 0 !important;
    vertical-align: middle;
    padding: 0.375rem 1.5rem;
    height: 2.5rem;
  }
  td {
    background-color: #ffffff !important;
    border-width: 0 !important;
    vertical-align: middle;
    border-top: 1px solid #eff2f7 !important;
  }

  .task-name {
    height: 4.5rem;
    padding: 0.5rem 1.5rem;
    align-items: center;
    gap: 0.5rem;
    flex: 1 0 0;
    color: #495057;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .badge-column {
    display: flex;
    width: 120px;
    padding: 2px 0px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid #eff2f7;
    background: #f8f9fa;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: #4a5568;
  }

  .badge-status {
    display: flex;
    width: 144px;
    padding: 2px 0px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 4px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    color: #4a5568;
  }
  .badge-status-0 {
    background: #f7fafc;
  }
  .badge-status-1 {
    background: #fcefdc;
  }
  .badge-status-2 {
    background: #fcdce2;
  }
  .badge-status-3 {
    background: #eadcfc;
  }
  .badge-status-4 {
    background: #f0fff4;
  }

  .button-task {
    display: flex;
    height: 2rem;
    padding: 0.625rem 0.75rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    background-color: transparent;
    border: none;
    color: #2d3748 !important;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;

    i {
      color: #a0aec0;
    }
  }
  .button-task:hover {
    background-color: #f7fafc;
  }
`;

import { getFirebaseBackend } from 'helpers/firebaseHelper';

const loadTeam = (accountId, inputValue = '') => {
  if (!accountId) return;

  const firebaseHelper = getFirebaseBackend();

  return new Promise(async (resolve, reject) => {
    const team = await firebaseHelper.getTeam(accountId);
    const internalTeam = await firebaseHelper.getInternalTeam(accountId);

    // Filter out deactivated users
    for (const user of Object.values(team)) {
      let isActivated;
      if (user.id === 'initialTeamState') {
        isActivated = false;
      } else {
        const userData = await firebaseHelper.getUser(user.id);
        isActivated = userData.status === 'Activated';
      }

      if (!isActivated) delete team[user.id];
    }
    // Gets the account's team and the account's internal team, process its data and filter the initialTeamState
    const select1 = Object.entries(team)
      ?.filter(([memberId]) => memberId !== 'initialTeamState')
      ?.map(([accountId, memberInfo]) => ({
        value: memberInfo.id,
        label: memberInfo.name,
      }));
    const select2 = await Promise.all(
      Object.entries(internalTeam)?.map(async ([index, memberId]) => ({
        value: memberId,
        label: await firebaseHelper.userName(memberId),
      })),
    );
    // Unify the two arrays and filters the duplicate objects
    const unifiedSelect = [...select1, ...select2];
    let uniqueSelect = unifiedSelect?.reduce((uniqueArr, object) => {
      if (!uniqueArr.some((obj) => obj.label === object.label && obj.value === object.value)) {
        uniqueArr.push(object);
      }
      return uniqueArr;
    }, []);
    // Sorts Alphabetically
    uniqueSelect?.sort(({ label: firstLabel }, { label: secondLabel }) => {
      const normalizedA = firstLabel
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
      const normalizedB = secondLabel
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();

      return normalizedA > normalizedB ? 1 : normalizedB > normalizedA ? -1 : 0;
    });
    // Filters by input
    if (inputValue) {
      uniqueSelect = uniqueSelect?.filter(({ label }) => {
        // Normalize, remove accents and diacretics and lowercase the strings for comparison
        const normalizedTask = label
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase();
        const normalizedLabel = inputValue
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase();

        return normalizedTask.includes(normalizedLabel);
      });
    }

    resolve(uniqueSelect);
  });
};

export { loadTeam };

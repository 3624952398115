export const initialGuidelineKanbanState = [
  {
    id: 1,
    title: 'Backlog',
    cards: [],
  },
  {
    id: 2,
    title: 'Guideline approval',
    cards: [],
  },
  {
    id: 3,
    title: 'Content Structure',
    cards: [],
  },
  {
    id: 4,
    title: 'Pre review',
    cards: [],
  },
  {
    id: 5,
    title: 'Content review',
    cards: [],
  },
  {
    id: 6,
    title: 'Internal approval',
    cards: [],
  },
  {
    id: 7,
    title: 'Content approval',
    cards: [],
  },
  {
    id: 8,
    title: 'Approved',
    cards: [],
  },
  {
    id: 9,
    title: 'Published',
    cards: [],
  },
];

export const initialGuidelineClientState = [
  {
    id: 2,
    title: 'Guideline approval',
    cards: [],
  },
  {
    id: 7,
    title: 'Content approval',
    cards: [],
  },
  {
    id: 8,
    title: 'Approved',
    cards: [],
  },
  {
    id: 9,
    title: 'Published',
    cards: [],
  },
];

export const initialGuidelineReviewerState = [
  {
    id: 4,
    title: 'Pre review',
    cards: [],
  },
  {
    id: 5,
    title: 'Content review',
    cards: [],
  },
];

import styled from 'styled-components';

export const HeaderDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
`;

export const BodyDiv = styled.div`
  color: #656b7c;
  font-weight: 600;
`;

export const CancelButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eff2f6;
  color: #000000;
  font-weight: 600;
  padding: 0.5rem 1rem;
  margin: 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

export const DeleteButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f46a6a;
  color: #ffffff;
  font-weight: 600;
  padding: 0.5rem 1rem;
  margin: 0;
  border: none;
  border-radius: 5px;
  margin-left: 1.5em;
  cursor: pointer;
`;

export const SelectAssignedToDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5em;

  > div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5em;
  }

  span {
    background-color: transparent;
    margin: 0;
    border: none;
    font-weight: 600;

    &.remove {
      color: #f56565;
    }

    &.add {
      color: #718096;
    }
  }
`;

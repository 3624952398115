import styled from 'styled-components';

export const PerformanceTableStyles = styled.div`
  position: relative;

  ::after,
  ::before {
    content: '';
    position: absolute;
    top: 4.5rem;
    bottom: 3.9rem;
    background-color: #e2e8f0;
  }
  ::after {
    right: 0;
    width: 1px;
  }
  ::before {
    left: 0;
    width: 1px;
  }

  .table-header {
    color: #2d3748;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    padding: 1.5rem;
    background-color: #f7fafc;
    border: 1px solid #e2e8f0;
    border-radius: 0.8rem 0.8rem 0 0;
  }

  .performance-table {
    overflow-x: scroll;
    width: 100%;
  }

  table {
    thead {
      height: 2.75rem;
    }

    thead th {
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 1.125rem;
      color: #475467;
      white-space: nowrap;
    }

    thead th:first-child {
      min-width: 16.875rem;
      background-color: #f2f2f5;
      border-width: 0 1px 1px 1px;
      border-color: #e2e8f0;
      border-style: solid;
    }

    thead th:last-child,
    tbody td:last-child {
      border-width: 0 1px 1px 0;
      border-color: #e2e8f0;
      border-style: solid;
    }

    th {
      background-color: #ffffff;
      border-width: 0 0 1px 0;
      border-color: #e2e8f0;
      border-style: solid;
      vertical-align: middle;
    }

    .header-label {
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 1.125rem;
      color: #475467;
    }

    .header-value {
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1rem;
      color: #718096;
    }

    .body-search-keyword {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      color: #1a202c;
      padding: 0.75rem 1rem;
      border-width: 0 1px 1px 1px;
      border-color: #e2e8f0;
      border-style: solid;
      vertical-align: middle;
      background-color: #f9f9f9;
    }

    .body-search-tag {
      width: max-content;
      height: 1.25rem;
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 1rem;
      color: #718096;
      background-color: #f7fafc;
      padding: 0 0.5rem;
      border: 1px solid #edf2f7;
      border-radius: 0.25rem;
      margin-bottom: 0.25rem;
    }

    .body-search-value {
      min-width: 10rem;
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.5rem;
      color: #2d3748;
      padding: 0.75rem 1rem;
      border-width: 0 0 1px 0;
      border-color: #e2e8f0;
      border-style: solid;
      vertical-align: middle;
      background-color: #ffffff;
    }

    .total-growth {
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 1rem;
      gap: 0.25rem;
    }

    .total-growth-negative {
      color: #38a169;
    }

    .total-growth-positive {
      color: #e53e3e;
    }
  }

  .table-footer {
    padding: 0.75rem;
    background-color: #ffffff;
    border-width: 0 1px 1px 1px;
    border-color: #e2e8f0;
    border-style: solid;
    border-radius: 0 0 0.8rem 0.8rem;

    span {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      color: #718096;
    }
  }

  .btn-card {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    min-width: 9.375rem;
    padding: 0.625rem 1rem;
    border-radius: 0.25rem;
    border: none;
    font-size: 1rem;
    line-height: 1.5px;
    font-weight: 600;
    color: #fff;
    align-self: flex-end;
    gap: 0.5rem;
  }

  .next {
    background-color: #556ee6;
  }
  .next:hover {
    background-color: #3b5bfc;
  }
  .next:disabled {
    background-color: #a0aec0;
  }
  .previous {
    background-color: #ffffff;
    border: 1px solid #e2e8f0;
    color: #2d3748;
  }
  .previous:hover {
    background-color: #edf2f7;
    border: 1px solid #e2e8f0;
    color: #2d3748;
  }
  .previous:disabled {
    background-color: #ffffff;
    border: 1px solid #e2e8f0;
    color: #a0aec0;
  }

  .download {
    font-size: 0.875rem;
    line-height: 1.25px;
    height: 2rem;
    padding: 0.625rem 0.75rem;
  }
`;

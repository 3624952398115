import styled from 'styled-components';

export const ApprovalChatStyle = styled.div`
  width: 332px;
  height: fit-content;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  position: relative;

  .subtitle {
    padding: 16px;
    margin: 0 !important;
  }

  .header-approval-chat {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: 16px;
      line-height: normal;
      font-weight: 300;
      margin: 0 !important;
      color: #7367f0;
    }

    .badge {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      background-color: #fff !important;
      border: 1px solid #edf2f7;
      color: #4a5568 !important;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .divider {
    border: 1px solid #edf2f7;
    width: 100%;
    height: 1px;
  }

  .container-column-no-messages {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 16px;
    height: 219px;

    span {
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      margin: 0 !important;
      color: #a0aec0;
    }
  }

  .container-column-messages {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 16px;
    max-height: 525px;
    overflow-y: scroll;
  }

  .btn-create {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f7fafc;
    border: none;
    height: ${(props) =>
      props.isCreateTaskClicked || props.isCreateCommentClicked ? '36px' : '68px'};
    width: 146px;
    padding: 16px;
    color: #718096 !important;
  }

  .btn-create:hover {
    background-color: #faf5ff;
    color: #556ee6 !important;
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { ButtonUploadCSVLayout } from './styles';
import { useTranslation } from 'react-i18next';

const ButtonUploadCSV = ({ changeHandler }) => {
  const { t: translate } = useTranslation();

  return (
    <ButtonUploadCSVLayout>
      <label htmlFor="csv-upload">
        {translate('Upload CSV file')}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="14"
          viewBox="0 0 15 14"
          fill="none"
        >
          <path
            d="M3.78772 10.7123C2.28623 9.21082 1.83706 6.9527 2.64966 4.99091C3.46226 3.02912 5.3766 1.75 7.50003 1.75C9.62346 1.75 11.5378 3.02912 12.3504 4.99091C13.163 6.9527 12.7138 9.21082 11.2123 10.7123"
            stroke="#A0AEC0"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.50004 11.6641V6.41406"
            stroke="#A0AEC0"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.25 8.16406L7.5 6.41406L5.75 8.16406"
            stroke="#A0AEC0"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <input
          type="file"
          id="csv-upload"
          name="csv-upload"
          accept=".csv"
          onChange={changeHandler}
        ></input>
      </label>
      <p>
        {translate('See')}{' '}
        <a href="/GuidelinesTemplate.csv" download="guideline-CSV-template.csv">
          {translate('spreadsheet model.')}
        </a>{' '}
        {translate('Upload only .csv files')}
      </p>
    </ButtonUploadCSVLayout>
  );
};

ButtonUploadCSV.propTypes = {
  changeHandler: PropTypes.func,
};

export default ButtonUploadCSV;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  GraphGrid,
  GraphCard,
  Circle,
  CardTitle,
  CardThen,
  CardNow,
  CardComparison,
} from './styles';

const GraphHeader = ({ stats }) => {
  const { t: translate } = useTranslation();

  return (
    <GraphGrid>
      <GraphCard>
        <div>
          <CardTitle>{translate('Improved Keywords')}</CardTitle>

          <div>
            <CardThen>{stats.improved}</CardThen>
            <CardNow>{` ${translate('of')} ${stats.total}`}</CardNow>
          </div>
        </div>

        <div>
          <Circle size="4em" color="#EFF2F7">
            <box-icon name="trending-up" size="2em" color="#484848"></box-icon>
          </Circle>
        </div>
      </GraphCard>

      <GraphCard>
        <div>
          <CardTitle>{translate('1 to 3')}</CardTitle>

          <CardComparison>
            {stats.oldFirst}
            <box-icon name="arrow-from-left" size="1.5rem" color="#ADB5BD"></box-icon>
            {stats.newFirst}
          </CardComparison>
        </div>

        <div>
          <Circle size="4em" color="#EFF2F7">
            <box-icon name="trophy" size="2em" color="#34C38F"></box-icon>
          </Circle>
        </div>
      </GraphCard>

      <GraphCard>
        <div>
          <CardTitle>{translate('4 to 10')}</CardTitle>

          <CardComparison>
            {stats.oldTenth}
            <box-icon name="arrow-from-left" size="1.5rem" color="#ADB5BD"></box-icon>
            {stats.newTenth}
          </CardComparison>
        </div>

        <div>
          <Circle size="4em" color="#EFF2F7">
            <box-icon name="trophy" size="2em" color="#E8B65F"></box-icon>
          </Circle>
        </div>
      </GraphCard>

      <GraphCard>
        <div>
          <CardTitle>{translate('11 to 100')}</CardTitle>

          <CardComparison>
            {stats.oldLast}
            <box-icon name="arrow-from-left" size="1.5rem" color="#ADB5BD"></box-icon>
            {stats.newLast}
          </CardComparison>
        </div>

        <div>
          <Circle size="4em" color="#EFF2F7">
            <box-icon name="trophy" size="2em" color="#808080"></box-icon>
          </Circle>
        </div>
      </GraphCard>

      <GraphCard>
        <div>
          <CardTitle>{translate('Unranked')}</CardTitle>

          <CardComparison>
            {stats.oldUnranked}
            <box-icon name="arrow-from-left" size="1.5rem" color="#ADB5BD"></box-icon>
            {stats.newUnranked}
          </CardComparison>
        </div>

        <div>
          <Circle size="4em" color="#EFF2F7">
            <box-icon name="traffic-cone" size="2em" color="#484848"></box-icon>
          </Circle>
        </div>
      </GraphCard>
    </GraphGrid>
  );
};

GraphHeader.propTypes = {
  stats: PropTypes.object,
};

export default GraphHeader;

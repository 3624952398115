import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue, off } from 'firebase/database';
import { MetaTags } from 'react-meta-tags';
import { useTranslation } from 'react-i18next';
import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  UncontrolledAccordion,
  UncontrolledTooltip,
} from 'reactstrap';
import 'boxicons';
import { MoonLoader } from 'react-spinners';
import { selectWeekDays, selectHours } from '../../constants';
import axios from 'axios';

import Breadcrumbs from '../../components/Breadcrumb';
import { SendCardLayout } from './components/Send-Card/styles';
import Shimmer from '../../components/Shimmer';
import SERPData from './components/SERP-Data';
import DragDropTopics from './components/Drag-Drop-Topics';
import ButtonEdit from './components/Button-Edit';
import ButtonRefresh from './components/Button-Refresh';
import ButtonSave from './components/Button-Save';
import { GuidelineLayout, TargetSize } from './styles';
import PageLoading from './components/Page-Loading';
import PerformanceData from './components/Performance-Data';
import TableResumeReview from './components/Table-Resume-Review';
import TitleGuideline from './components/Title-Guideline';

const CreateContentStructure = () => {
  const firebaseHelper = getFirebaseBackend();
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useAuth();
  const currentAccountId = user?.account;
  const isProduction = process.env.REACT_APP_BUILD_TYPE === 'production';
  const { t: translate } = useTranslation();
  const [content, setContent] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [editTitle, setEditTitle] = useState(false);
  const [title, setTitle] = useState('');
  const [topicsList, setTopicsList] = useState([]);
  const [activeNav, setActiveNav] = useState('1');
  const [error, setError] = useState(null);
  const [trackingDay, setTrackingDay] = useState(1);
  const [trackingTime, setTrackingTime] = useState(8);
  const [totalWordSize, setTotalWordSize] = useState(0);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isError, setIsError] = useState(false);

  const onSubmit = async () => {
    if (trackingDay === '') {
      setError('no-select-day');
      return;
    }
    if (trackingTime === '') {
      setError('no-select-hour');
      return;
    }
    if (!title) {
      setError('no-title');
      return;
    }

    try {
      setIsSending(true);
      await axios.post(
        `${process.env.REACT_APP_API_URL}/createContent`,
        {
          accountId: currentAccountId,
          guidelineId: id,
          development: !isProduction,
          tracking: {
            day: parseInt(trackingDay),
            hour: parseInt(trackingTime),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${await firebaseHelper.getIdToken()}`,
          },
        },
      );

      setError(null);
      setIsSending(false);
      setIsPageLoading(true);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    const loadedGuideline = await firebaseHelper.getFirebaseGuideline(currentAccountId, id);

    if (loadedGuideline.column !== 'CONTENT_STRUCTURE') {
      navigate(`/guideline/${id}`);
    }

    setIsProcessing(loadedGuideline.processing);
    setIsError(loadedGuideline?.error);
    setContent(loadedGuideline);

    if (loadedGuideline?.structure) {
      setTopicsList(loadedGuideline.structure.map((item) => item));
    }

    if (!loadedGuideline?.title) {
      setError('no-title');
    }

    let totalWords = 0;
    loadedGuideline?.structure.forEach((topic) => {
      totalWords += topic?.size || 100;
    });
    setTotalWordSize(totalWords);

    setTitle(loadedGuideline?.title);
    setIsPageLoading(loadedGuideline?.processing);
    setIsLoading(false);
  };

  const toggleEditTitle = () => {
    setEditTitle(!editTitle);
  };

  const handleEditTitle = async () => {
    await firebaseHelper.updateGuidelineTitle(currentAccountId, id, title);
    setEditTitle(!editTitle);

    !title ? setError('no-title') : setError(null);
  };

  const handleRefreshTitle = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/generateNewTitle`,
        {
          accountId: currentAccountId,
          guidelineId: id,
          development: !isProduction,
        },
        {
          headers: {
            Authorization: `Bearer ${await firebaseHelper.getIdToken()}`,
          },
        },
      );
      !response ? setError('no-title') : setError(null);
      setTitle(response.data.title);
    } catch (error) {
      console.log(error);
    }
  };

  async function handleRetry() {
    try {
      if (!id || !currentAccountId) throw new Error('Guideline ID or Account ID is missing');
      const isProduction = process.env.REACT_APP_BUILD_TYPE === 'production';
      const firebaseToken = await firebaseHelper.getIdToken();
      setIsProcessing(true);
      setIsError(false);
      await axios.post(
        `${process.env.REACT_APP_API_URL}/approveGuideline`,
        {
          accountId,
          guidelineId: id,
          development: !isProduction,
        },
        {
          headers: {
            Authorization: `Bearer ${firebaseToken}`,
          },
        },
      );
    } catch (error) {
      setIsProcessing(false);
      setIsError(true);
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, [user]);

  useEffect(() => {
    if (!isProcessing && !isError) return;

    const db = getDatabase();
    const guidelineRef = ref(db, `guidelines/${currentAccountId}/${id}`);

    const unsubscribe = onValue(guidelineRef, (snapshot) => {
      const guidelineData = snapshot.val();

      if (!guidelineData) {
        navigate('/404');
        return;
      }

      if (guidelineData?.column !== 'CONTENT_STRUCTURE') {
        navigate(`/guideline/${id}`);
        return;
      }

      if (guidelineData?.processing === false || guidelineData?.error === false) {
        fetchData();
      }
    });

    return () => {
      off(guidelineRef, 'value', unsubscribe);
    };
  }, [isProcessing, isError]);

  useEffect(() => {
    if (!isPageLoading) return;

    const db = getDatabase();
    const guidelineRef = ref(db, `guidelines/${currentAccountId}/${id}`);

    const handleValueChange = async (snapshot) => {
      const data = snapshot.val();

      if (!data?.processing) navigate(`/guideline/${id}`);
    };

    onValue(guidelineRef, handleValueChange);

    return () => {
      off(guidelineRef, 'value', handleValueChange);
    };
  }, [isPageLoading]);

  return (
    <GuidelineLayout>
      <div className="page-content">
        <MetaTags>
          <title>{`${translate('Create new content')} | Ectools`}</title>
        </MetaTags>
        <Breadcrumbs
          title={translate('Content')}
          breadcrumbItem={translate('Create new content')}
        />

        {isError && (
          <PageLoading
            title={translate('An error occurred')}
            description={translate('There was an error processing this guideline.')}
            button={translate('Try Again')}
            onClick={handleRetry}
            buttonStyles={{ backgroundColor: '#F56565', color: '#fff' }}
          >
            <box-icon name="x-circle" color="#F56565" size="7rem"></box-icon>
          </PageLoading>
        )}

        {isProcessing && !isError && (
          <PageLoading
            title={translate('This guideline is being processed')}
            description={translate(
              'This may take a few minutes, you can also wait for this process in the background and return later.',
            )}
            button={translate('Go back')}
            onClick={() => navigate('/guidelines')}
          />
        )}

        {!isProcessing && !isError && (
          <>
            {isPageLoading ? (
              <PageLoading
                title={translate('Creating content')}
                description={translate(
                  'This may take a few minutes, you can also wait for this process in the background and return later.',
                )}
                button={translate('Go back')}
                onClick={() => navigate('/guidelines')}
              />
            ) : (
              <div className="container-row">
                <div className="container-general-information">
                  <div className="header-general-information">
                    <div>
                      <button
                        aria-label="Back"
                        type="button"
                        id="back-button"
                        className="back-button"
                        onClick={() => navigate('/guidelines')}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M5.21875 7.33117H13.3334V8.6645H5.21875L8.79475 12.2405L7.85208 13.1832L2.66675 7.99783L7.85208 2.8125L8.79475 3.75517L5.21875 7.33117Z"
                            fill="#2D3748"
                          />
                        </svg>
                      </button>
                      {content?.primaryKeyword && <TitleGuideline guideline={content} />}
                    </div>
                  </div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={activeNav === '1' ? 'actived' : ''}
                        onClick={() => setActiveNav('1')}
                      >
                        {translate('Complete structure')}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeNav === '2' ? 'actived' : ''}
                        onClick={() => setActiveNav('2')}
                      >
                        {translate('SERP data')}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeNav === '3' ? 'actived' : ''}
                        onClick={() => setActiveNav('3')}
                      >
                        {translate('Performance')}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeNav}>
                    {isLoading ? (
                      <>
                        <Shimmer height="25em" style={{ marginBottom: '3em' }} />
                        <Shimmer height="60em" />
                      </>
                    ) : (
                      <TabPane tabId="1">
                        <UncontrolledAccordion defaultOpen={['1', '2']} stayOpen>
                          <TableResumeReview
                            data={content}
                            accountId={currentAccountId}
                            guidelineId={id}
                            onlyResume
                          />

                          <AccordionItem>
                            <AccordionHeader targetId="2" style={{ position: 'relative' }}>
                              {translate('Content Structure')}
                              <TargetSize style={{ position: 'absolute', right: '50px' }}>
                                {totalWordSize} {translate('Words')}
                              </TargetSize>
                            </AccordionHeader>
                            <AccordionBody accordionId="2">
                              <ul
                                className="list-group contentList"
                                style={{ marginBottom: '32px' }}
                              >
                                <li
                                  className={`${error === 'no-title' ? 'border-red' : ''} list-group-item`}
                                  style={{ height: '58px', fontWeight: 500 }}
                                >
                                  <div className="container-btns">
                                    <Input
                                      type="text"
                                      className="heading-h1"
                                      name="title"
                                      value="H1"
                                      bsSize="sm"
                                      readOnly
                                      style={{
                                        width: '31px',
                                      }}
                                    />
                                    {editTitle ? (
                                      <Input
                                        type="text"
                                        className="title"
                                        name="title"
                                        value={title}
                                        bsSize="md"
                                        style={{
                                          width: '525px',
                                        }}
                                        onChange={(e) => {
                                          setTitle(e.target.value);
                                        }}
                                        autoFocus
                                      />
                                    ) : (
                                      title
                                    )}
                                  </div>

                                  {editTitle ? (
                                    <ButtonSave onClickFunction={handleEditTitle} />
                                  ) : (
                                    <div className="container-btns">
                                      <ButtonEdit onClickFunction={toggleEditTitle} />
                                      <ButtonRefresh onClickFunction={handleRefreshTitle} />
                                    </div>
                                  )}
                                </li>
                                {error === 'no-title' && (
                                  <p className="error m-0">{translate('Please, enter a title')}</p>
                                )}
                              </ul>
                              <DragDropTopics
                                topicsList={topicsList}
                                setTopicsList={setTopicsList}
                                fetchData={fetchData}
                              />
                            </AccordionBody>
                          </AccordionItem>
                        </UncontrolledAccordion>
                      </TabPane>
                    )}
                  </TabContent>
                  <TabContent activeTab={activeNav}>
                    <TabPane tabId="2">
                      <SERPData
                        data={content?.headers}
                        googleQuestionsData={content?.googleQuestions}
                        primaryKeyword={content?.primaryKeyword}
                      />
                    </TabPane>
                  </TabContent>
                  <TabContent activeTab={activeNav}>
                    <TabPane tabId="3">
                      <PerformanceData
                        primaryKeyword={content?.primaryKeyword}
                        secondaryKeywords={content?.secondaryKeywords}
                      />
                    </TabPane>
                  </TabContent>
                </div>
                <div className="container-side-column">
                  <SendCardLayout>
                    <p className="subtitle">{translate('Action')}</p>
                    <Button onClick={onSubmit}>
                      {isSending ? (
                        <MoonLoader size={20} color="white" />
                      ) : (
                        translate('Create Content')
                      )}
                    </Button>
                    <p className="text-observation" style={{ margin: '0px' }}>
                      {translate(
                        'The content will be created according to the structure and prompts on this page.',
                      )}
                    </p>
                  </SendCardLayout>
                  <SendCardLayout>
                    <div
                      className="d-flex align-items-center justify-content-between"
                      style={{ marginBottom: '16px' }}
                    >
                      <p className="subtitle" style={{ margin: '0px' }}>
                        {translate('Position tracking')}
                      </p>
                      <svg
                        id="tooltip-info-tracking"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                      >
                        <circle
                          cx="12"
                          cy="12.5"
                          r="9.5"
                          stroke="#718096"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M13.125 16H12V11.75H10.875"
                          stroke="#718096"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.673 8.57383L11.6734 8.57339C11.7709 8.47614 11.9288 8.47629 12.0261 8.57372C12.1234 8.67116 12.1234 8.82901 12.026 8.92639C11.9286 9.02376 11.7708 9.02381 11.6733 8.9265C11.5759 8.82919 11.5757 8.67133 11.673 8.57383"
                          stroke="#718096"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <UncontrolledTooltip
                        placement="top"
                        target="tooltip-info-tracking"
                        className="tooltip"
                        style={{ textAlign: 'left' }}
                      >
                        {translate(
                          'After approval of this agenda, we began a process of monitoring primary and secondary keywords. Tracking is done weekly by simulating a mobile search on Google in the defined country and with the chosen language. When content is published, we start monitoring the specific URL and not just the domain.',
                        )}
                      </UncontrolledTooltip>
                    </div>
                    <FormGroup>
                      <Label className="form-label" for="day">
                        {translate('Tracking day')}
                      </Label>
                      <Input
                        type="select"
                        name="day"
                        id="day-tracking"
                        onChange={(e) => setTrackingDay(e.target.value)}
                        value={trackingDay}
                        disabled={true}
                      >
                        <option value="">{translate('Select')}</option>
                        {selectWeekDays.map((day) => (
                          <option key={day.value} value={day.value}>
                            {day.label}
                          </option>
                        ))}
                      </Input>
                      {error === 'no-select-day' && (
                        <p className="error">{translate('Please select a day')}</p>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label className="form-label" for="time">
                        {translate('Tracking time')}
                      </Label>
                      <Input
                        type="select"
                        name="time"
                        id="time-tracking"
                        onChange={(e) => setTrackingTime(e.target.value)}
                        value={trackingTime}
                        disabled={true}
                      >
                        <option value="">{translate('Select')}</option>
                        {selectHours.map((hour) => (
                          <option key={hour.value} value={hour.value}>
                            {hour.label}
                          </option>
                        ))}
                      </Input>
                      {error === 'no-select-hour' && (
                        <p className="error">{translate('Please select an hour')}</p>
                      )}
                    </FormGroup>
                  </SendCardLayout>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </GuidelineLayout>
  );
};

export default CreateContentStructure;

import styled from 'styled-components';

export const ModalAddNewTopicStyle = styled.div`
  .container-input-prompt {
    margin-top: 8px;
    border-radius: 4px;
    border: 1px solid #edf2f7;
    padding: 8px;
    background-color: #f7fafc;

    .form-label {
      font-size: 12px;
      font-weight: 500;
      color: #4a5568;
    }
  }

  .btn-transparent {
    margin-top: 8px;
    min-width: 131px;
    background-color: transparent !important;
    border: none;
    color: #2d3748 !important;
  }

  .btn-transparent:hover {
    background-color: #f7fafc !important;
  }
`;

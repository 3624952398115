import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'reactstrap';

const SubmitButton = ({
  backgroundColor = '#344792',
  color,
  justifyLeft = false,
  disabled,
  text,
  ...other
}) => {
  const { t: translate } = useTranslation();

  return (
    <Row className={`${justifyLeft ? '' : 'justify-content-end'}`}>
      <Col lg="10">
        <Button
          type="submit"
          color={color}
          style={{ backgroundColor }}
          disabled={disabled}
          {...other}
        >
          {translate(text)}
        </Button>
      </Col>
    </Row>
  );
};

SubmitButton.propTypes = {
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  justifyLeft: PropTypes.bool,
  disabled: PropTypes.bool,
  text: PropTypes.string,
};

export default SubmitButton;

import styled from 'styled-components';

export const ContainerLabelDateStyle = styled.div`
  .container-menu-date {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 9999;
    top: 65px;
    left: 182px;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #e2e8f0;
    box-shadow:
      0px 1px 2px 0px rgba(0, 0, 0, 0.06),
      0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    padding: 16px;
    gap: 16px;
  }

  .menu-date {
    display: flex;
    gap: 16px;
  }

  .radio-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 253px;
    height: 250px;
    overflow-y: scroll;

    .radio-item {
      display: flex;
      width: 100%;
      align-items: center;
      gap: 8px;
      padding: 10px;

      label {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: #1a202c;
        margin: 0;
      }
    }
  }

  .date-selected {
    display: flex;
    align-items: center;
    border-top: 1px solid #e2e8f0;
    padding: 12px;
    gap: 8px;

    p {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: #171923;
      margin: 0;
    }
  }
`;

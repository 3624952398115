import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getFirebaseBackend } from 'helpers/firebaseHelper';

import ButtonApprove from '../Button-Approve';

const ModalApprove = ({
  modalFunction,
  modalState,
  dataMany,
  dataIndividual,
  redirectToGuideline,
}) => {
  const firebaseHelper = getFirebaseBackend();
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const [reason, setReason] = useState('');
  const [customReason, setCustomReason] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const REASONS_TO_APPROVE = [
    {
      label: translate('Client approved via email'),
      value: 'CLIENT_APPROVED_VIA_EMAIL',
    },
    {
      label: translate('Client approved in a meeting'),
      value: 'CLIENT_APPROVED_IN_MEETING',
    },
    {
      label: translate('No approval required'),
      value: 'NO_APPROVAL_REQUIRED',
    },
    {
      label: translate('Other'),
      value: 'OTHER',
    },
  ];

  const approveGuideline = async () => {
    setIsLoading(true);
    let statusReason = reason;

    if (!reason) {
      setError('no-select');
      setIsLoading(false);
      return;
    }

    if (reason === 'OTHER') {
      statusReason = customReason;

      if (!statusReason) {
        setError('no-text');
        setIsLoading(false);
        return;
      }

      if (statusReason.length < 2) {
        setError('small-text');
        setIsLoading(false);
        return;
      }
    }

    const firebaseToken = await firebaseHelper.getIdToken();

    if (dataMany?.length > 0) {
      for (const guideline of dataMany) {
        const body = {
          accountId: guideline.accountId,
          guidelineId: guideline.guidelineId,
          statusReason,
          development: process.env.REACT_APP_BUILD_TYPE !== 'production',
        };
        const config = {
          headers: {
            Authorization: `Bearer ${firebaseToken}`,
          },
        };
        if (guideline.column === 'GUIDELINE_APPROVAL') {
          await axios.post(`${process.env.REACT_APP_API_URL}/approveGuideline`, body, config);
        } else if (guideline.column === 'CONTENT_APPROVAL') {
          await axios.post(
            `${process.env.REACT_APP_API_URL}/approveContent`,
            {
              guidelines: [
                {
                  guidelineId: guideline.guidelineId,
                  accountId: guideline.accountId,
                },
              ],
              statusReason,
              development: process.env.REACT_APP_BUILD_TYPE !== 'production',
            },
            config,
          );
        }
      }
    }
    if (dataIndividual?.length > 0) {
      for (const guideline of dataIndividual) {
        const body = {
          accountId: guideline.accountId,
          guidelineId: guideline.guidelineId,
          statusReason,
          development: process.env.REACT_APP_BUILD_TYPE !== 'production',
        };
        const config = {
          headers: {
            Authorization: `Bearer ${firebaseToken}`,
          },
        };
        if (guideline.column === 'GUIDELINE_APPROVAL') {
          await axios.post(`${process.env.REACT_APP_API_URL}/approveGuideline`, body, config);
        } else if (guideline.column === 'CONTENT_APPROVAL') {
          await axios.post(
            `${process.env.REACT_APP_API_URL}/approveContent`,
            {
              guidelines: [
                {
                  guidelineId: guideline.guidelineId,
                  accountId: guideline.accountId,
                },
              ],
              statusReason,
              development: process.env.REACT_APP_BUILD_TYPE !== 'production',
            },
            config,
          );
        }
      }
    }

    setError(null);

    setTimeout(() => {
      setReason('');
      setCustomReason('');
      setIsLoading(false);
      modalFunction();
      redirectToGuideline && navigate('/guidelines');
    }, 2000);
  };

  return (
    <Modal isOpen={modalState} toggle={modalFunction} centered>
      <ModalHeader toggle={modalFunction}>{translate('Approve guideline')}</ModalHeader>
      <ModalBody>
        <Label>{translate('Provide the reason')}*</Label>
        <Input
          type="select"
          name="reason"
          value={reason}
          onChange={(e) => {
            setReason(e.target.value);
            setError(null);
          }}
        >
          <option value="">{translate('Select')}</option>
          {REASONS_TO_APPROVE.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Input>
        {error === 'no-select' && (
          <p className="text-danger">{translate('Please, select a reason')}</p>
        )}
        {reason === 'OTHER' && (
          <>
            <Input
              type="text"
              name="reason"
              placeholder={translate('Provide the reason')}
              value={customReason}
              onChange={(e) => {
                setCustomReason(e.target.value);
                setError(null);
              }}
              style={{ marginTop: '8px' }}
            />
            {error === 'no-text' && (
              <p className="text-danger">{translate('Please, write a reason')}</p>
            )}
            {error === 'small-text' && (
              <p className="text-danger">{translate('Write at least 2 characters')}</p>
            )}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={modalFunction}>
          {translate('Cancel')}
        </Button>
        <ButtonApprove onClickFunction={approveGuideline} isLoading={isLoading} />
      </ModalFooter>
    </Modal>
  );
};

ModalApprove.propTypes = {
  modalFunction: PropTypes.func.isRequired,
  modalState: PropTypes.bool.isRequired,
  accountId: PropTypes.string,
  guidelineId: PropTypes.string,
  dataMany: PropTypes.array,
  dataIndividual: PropTypes.array,
  redirectToGuideline: PropTypes.bool,
};

export default ModalApprove;

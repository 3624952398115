import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';

import { useAuth } from 'hooks/useAuth';
import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { normalizePhones, loadAccounts, getFormValue } from 'utils/';
import SuccessModal from '../../components/Success-Modal';
import ErrorModal from '../../components/Error-Modal';
import Breadcrumbs from '../../components/Breadcrumb';
import LabelGroup from '../../components/Label-Group';
import TextInput from '../../components/Text-Input';
import TextAreaInput from '../../components/TextArea-Input';
import SelectInput from '../../components/Select-Input';
import DateInput from '../../components/Date-Input';
import SwitchInput from '../../components/Switch-Input';
import PhonesInput from '../../components/Phones-Input';
import SubmitButton from '../../components/Submit-Button';
import '../../assets/scss/custom/pages/_users.scss';

import { ufToState, numberTag } from '../../constants/index';

const EditUser = () => {
  const formConfig = {
    defaultValues: {
      accountType: [],
      status: '',
      oldStatus: '',
      account: [],
      unchangedEmail: '',
      email: '',
      name: '',
      tasks: true,
      content: false,
      occupation: '',
      description: '',
      birthdate: '',
      phones: [{ tag: '', number: '' }],
      zipCode: '',
      addressLine1: '',
      addressLine2: '',
      state: [],
      city: '',
      emailNotification: {
        newTask: false,
        assignedToTask: false,
        newComment: false,
        newMeeting: false,
      },
    },
    shouldFocusError: true,
    criteriaMode: 'all',
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  };
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const userParam = searchParams.get('id');
  const form = useForm(formConfig);
  const { formState, getValues } = form;
  const userAccount = getValues('fixedAccount');
  const { t: translate } = useTranslation();
  const { user, updateUser } = useAuth();
  const superAdminStatus = user.adminStatus === 'Super Admin';
  const [accountModules, setAccountModules] = useState(null);
  const [file, setFile] = useState(null);
  const [fetchedFile, setFetchedFile] = useState(false);
  const [updateSuccessfull, setUpdateSuccessfull] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const firebaseHelper = getFirebaseBackend();

  const onSubmit = async (data) => {
    const newFile = file !== null;
    const userAccounts = data.account.map(getFormValue);
    const loggedAccount = userAccounts.includes(data.fixedAccount)
      ? data.fixedAccount
      : userAccounts[0];
    const user = {
      id: userParam,
      status: data.oldStatus,
      account: loggedAccount,
      accounts: userAccounts,
      email: data.email,
      name: data.name,
      adminStatus: data.accountType.value,
      hasPhoto: data.hasPhoto ? data.hasPhoto : newFile,
      modules: {
        tasks: data.tasks,
        content: data.content,
        courses: data.courses,
      },
      occupation: data.occupation ? data.occupation : null,
      description: data.description ? data.description : null,
      birthDate: data.birthdate ? Date.parse(data.birthdate) : null,
      phones: normalizePhones(data.phones),
      zipCode: data.zipCode ? data.zipCode : null,
      addressLine1: data.addressLine1 ? data.addressLine1 : null,
      addressLine2: data.addressLine2 ? data.addressLine2 : null,
      state: data?.state?.value ? data.state.value : null,
      city: data.city ? data.city : null,
      emailNotification: data.emailNotification ?? {
        newTask: false,
        assignedToTask: false,
        newComment: false,
        newMeeting: false,
      },
      createdAt: data.createdAt,
    };
    await firebaseHelper.updateUser(user);
    if (data.status.value === 'Activated') {
      await firebaseHelper.activateUser(user.id);
    }
    if (data.status.value === 'Deactivated') {
      await firebaseHelper.deactivateUser(user.id);
    }
    if (newFile) {
      await firebaseHelper.updateUserPhoto(file, user.id);
    }
    setSuccessModal(true);
    updateUser();
  };

  const closeModalHandle = () => {
    setFile(null);
    setSuccessModal(false);
    getUser();
  };

  const handleFileSubmit = (photoFile, substitute = true) => {
    const loader = document.getElementById('loading-icon');
    const ghost = document.getElementById('ghost-icon');
    const image = document.getElementById('file-display');

    // Checks if a file was uploaded and if it matches the valid extensions and puts it as source for the img tag
    if (photoFile) {
      if (
        !(
          photoFile.type === 'image/png' ||
          photoFile.type === 'image/jpg' ||
          photoFile.type === 'image/jpeg'
        )
      ) {
        alert(
          translate('The profile photo file extesion needs to be one of the following') +
            ': png, jpg, jpeg.',
        );
        document.getElementById('file-input').value = null;
      } else {
        if (substitute) {
          setFile(photoFile);
        } else {
          setFetchedFile(true);
        }
        image.style.display = 'inline';
        image.src = URL.createObjectURL(photoFile);
        ghost.style.display = 'none';
        loader.style.display = 'none';
      }
    } else {
      setFile(null);
      ghost.style.display = 'inline';
      image.style.display = 'none';
      loader.style.display = 'none';
    }
  };

  const activateLoader = () => {
    document.getElementById('loading-icon').style.display = 'inline';
    document.getElementById('ghost-icon').style.display = 'none';
    document.getElementById('file-display').style.display = 'none';
  };

  const getAccountModules = async () => {
    const account = await firebaseHelper.getAccount(userAccount);
    const accountModules = account.modules;
    setAccountModules(accountModules);
  };

  const getUser = async () => {
    try {
      const user = await firebaseHelper.getUser(userParam);
      let processedAccount;
      if (user.accounts) {
        processedAccount = await Promise.all(
          user.accounts.map(async (account) => ({
            value: account,
            label: await firebaseHelper.accountName(account),
          })),
        );
      } else {
        processedAccount = [
          {
            value: user.account,
            label: await firebaseHelper.accountName(user.account),
          },
        ];
      }
      const processedPhones = user.phones
        ? user.phones.map(({ tag, number }) => ({
            number,
            tag: {
              value: tag,
              label: translate(numberTag[tag]),
            },
          }))
        : [{ tag: '', number: '' }];
      form.reset({
        accountType: {
          value: user.adminStatus,
          label: translate(user.adminStatus),
        },
        fixedAccount: user.account,
        account: processedAccount,
        email: user.email,
        name: user.name,
        tasks: user.modules?.tasks === true,
        content: user.modules?.content === true,
        courses: user.modules?.courses === true,
        occupation: user?.occupation,
        description: user?.description,
        birthdate: user.birthDate ? new Date(user.birthDate) : '',
        zipCode: user?.zipCode,
        addressLine1: user?.addressLine1,
        addressLine2: user?.addressLine2,
        state: user.state ? { value: user.state, label: ufToState[user.state] } : null,
        city: user?.city,
        phones: processedPhones,
        oldStatus: user.status,
        status: { value: user.status, label: translate(user.status) },
        hasPhoto: user.hasPhoto,
        emailNotification: user.emailNotification ?? {
          newTask: false,
          assignedToTask: false,
          newComment: false,
          newMeeting: false,
        },
        createdAt: user.createdAt,
      });
      if (user.hasPhoto) {
        activateLoader();
        const path = `${user.id}/profilePic`;
        const profilePicMeta = await firebaseHelper.listAllFiles(path);
        if (profilePicMeta) {
          const profilePicFile = await firebaseHelper.getFileObj(
            `${path}/${profilePicMeta[0].name}`,
          );
          handleFileSubmit(profilePicFile, false);
        }
      }
      setUpdateSuccessfull(true);
    } catch (error) {
      console.error(error);
      setErrorModal(true);
    }
  };

  useEffect(async () => {
    getUser();
  }, []);

  useEffect(() => {
    if (!userAccount) return;

    getAccountModules();
  }, [userAccount]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{translate('Edit User')} | Ectools</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title={translate('Clients')} breadcrumbItem={translate('Edit')} />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <SuccessModal
                    isOpen={successModal}
                    onClose={closeModalHandle}
                    verb="Updated"
                    buttonText="See Users"
                    buttonLink="/list-users"
                  />

                  <ErrorModal isOpen={errorModal} />

                  <CardTitle className="mb-2">{translate('Edit Existing User')}</CardTitle>

                  <FormProvider {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div style={{ width: '49%' }}>
                          <LabelGroup htmlFor="status" label="Status" mb={1} column required>
                            <SelectInput
                              controlName="status"
                              required
                              options={[
                                {
                                  value: 'Activated',
                                  label: translate('Activated'),
                                },
                                {
                                  value: 'Deactivated',
                                  label: translate('Deactivated'),
                                },
                              ]}
                            />
                          </LabelGroup>

                          {superAdminStatus && (
                            <>
                              <LabelGroup
                                htmlFor="accountType"
                                label="Account Type"
                                mb={1}
                                column
                                required
                              >
                                <SelectInput
                                  controlName="accountType"
                                  required
                                  options={[
                                    { value: 'Client', label: 'Client' },
                                    {
                                      value: 'Client Admin',
                                      label: 'Client Admin',
                                    },
                                    { value: 'Admin', label: 'Admin' },
                                    {
                                      value: 'Super Admin',
                                      label: 'Super Admin',
                                    },
                                    {
                                      value: 'Reviewer',
                                      label: translate('Reviewer'),
                                    },
                                  ]}
                                />
                              </LabelGroup>

                              <LabelGroup htmlFor="account" label="Account" mb={1} column required>
                                <SelectInput
                                  asyncronous
                                  isMulti
                                  controlName="account"
                                  required
                                  cacheOptions
                                  defaultOptions
                                  loadOptions={(inputValue) => loadAccounts(inputValue)}
                                />
                              </LabelGroup>
                            </>
                          )}

                          <LabelGroup htmlFor="email" label="Email" mb={1} column required>
                            <TextInput
                              controlName="email"
                              placeholder="Email"
                              required
                              disabled
                              validation={{
                                pattern: {
                                  value: RegExp(/^\S+@\S+.+\S+$/),
                                  message: 'Invalid Email!',
                                },
                              }}
                            />
                          </LabelGroup>

                          <LabelGroup htmlFor="name" label="Name" mb={1} column required>
                            <TextInput controlName="name" placeholder="Name" required />
                          </LabelGroup>

                          <FormGroup className="mb-1 mt-3" row>
                            <Col lg="2">
                              <div
                                style={{
                                  height: '6rem',
                                  width: '6rem',
                                  border: '1px solid #CED4DA',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  borderRadius: '4px',
                                }}
                              >
                                <i
                                  id="ghost-icon"
                                  className="bx bx-ghost"
                                  style={{ fontSize: '4rem', color: '#D2D2D2' }}
                                ></i>

                                <i
                                  id="loading-icon"
                                  className="bx bx-loader-alt loading-animation"
                                  style={{
                                    fontSize: '2rem',
                                    color: '#D2D2D2',
                                    display: 'none',
                                  }}
                                ></i>

                                <img
                                  id="file-display"
                                  width="90%"
                                  style={
                                    file === null && !fetchedFile
                                      ? { display: 'none' }
                                      : { display: 'inline', maxHeight: '90%' }
                                  }
                                ></img>
                              </div>
                            </Col>
                            <Col lg="9">
                              <Label className="col-form-label col-lg-12">
                                {translate('Profile Photo')}
                              </Label>
                              <Input
                                id="file-input"
                                className="form-control"
                                type="file"
                                onChange={(event) => handleFileSubmit(event.target.files[0])}
                                accept="image/png, image/jpg, image/jpeg"
                              />
                            </Col>
                          </FormGroup>

                          <LabelGroup htmlFor="tasks" label="Access Modules" mb={1} column>
                            {accountModules?.tasks && (
                              <SwitchInput controlName="tasks" label="Tasks" />
                            )}
                            {accountModules?.content && (
                              <SwitchInput controlName="content" label="Content" />
                            )}
                            {accountModules?.courses && (
                              <SwitchInput controlName="courses" label="Courses" />
                            )}
                          </LabelGroup>
                        </div>

                        <div
                          style={{
                            height: 'auto',
                            width: '1px',
                            backgroundColor: '#E2E2E2',
                          }}
                        ></div>

                        <div style={{ width: '49%' }}>
                          <LabelGroup htmlFor="occupation" label="Occupation" mb={1} column>
                            <TextInput controlName="occupation" placeholder="Occupation" />
                          </LabelGroup>

                          <LabelGroup htmlFor="description" label="Description" mb={1} column>
                            <TextAreaInput
                              controlName="description"
                              placeholder="Enter the description"
                              size={12}
                              mb={2}
                              manualResize
                            />
                          </LabelGroup>

                          <LabelGroup htmlFor="birthdate" label="Birthdate" mb={1} column>
                            <DateInput controlName="birthdate" placeholder="Birthdate" />
                          </LabelGroup>

                          <PhonesInput mb={1} />

                          <LabelGroup htmlFor="zipCode" label="Zip Code" mb={1} column>
                            <TextInput
                              controlName="zipCode"
                              placeholder="Zip Code"
                              mask="99999-999"
                              maskChar=" "
                            />
                          </LabelGroup>

                          <LabelGroup htmlFor="addressLine1" label="Address Line 1" mb={1} column>
                            <TextInput controlName="addressLine1" placeholder="Address Line 1" />
                          </LabelGroup>

                          <LabelGroup htmlFor="addressLine2" label="Address Line 2" mb={1} column>
                            <TextInput controlName="addressLine2" placeholder="Address Line 2" />
                          </LabelGroup>

                          <LabelGroup htmlFor="state" label="State and City" mb={1} column>
                            <Row>
                              <SelectInput
                                size={3}
                                controlName="state"
                                options={[
                                  { value: 'AC', label: 'Acre' },
                                  { value: 'AL', label: 'Alagoas' },
                                  { value: 'AP', label: 'Amapá' },
                                  { value: 'AM,', label: 'Amazonas' },
                                  { value: 'BA', label: 'Bahia' },
                                  { value: 'CE', label: 'Ceará' },
                                  { value: 'ES', label: 'Espírito Santo' },
                                  { value: 'GO', label: 'Goiás' },
                                  { value: 'MA', label: 'Maranhão' },
                                  { value: 'MT', label: 'Mato Grosso' },
                                  { value: 'MS', label: 'Mato Grosso do Sul' },
                                  { value: 'MG', label: 'Minas Gerais' },
                                  { value: 'PA', label: 'Pará' },
                                  { value: 'PB', label: 'Paraíba' },
                                  { value: 'PR', label: 'Paraná' },
                                  { value: 'PE', label: 'Pernambuco' },
                                  { value: 'PI', label: 'Piauí' },
                                  { value: 'RJ', label: 'Rio de Janeiro' },
                                  { value: 'RN', label: 'Rio Grande do Norte' },
                                  { value: 'RS', label: 'Rio Grande do Sul' },
                                  { value: 'RO', label: 'Rondônia' },
                                  { value: 'RR', label: 'Roraima' },
                                  { value: 'SC', label: 'Santa Catarina' },
                                  { value: 'SP', label: 'São Paulo' },
                                  { value: 'SE', label: 'Sergipe' },
                                  { value: 'TO', label: 'Tocantins' },
                                  { value: 'DF', label: 'Distrito Federal' },
                                ]}
                              />
                              <TextInput size={9} controlName="city" placeholder="City" />
                            </Row>
                          </LabelGroup>

                          <LabelGroup
                            htmlFor="emailNotification"
                            label="Email Notifications"
                            mb={1}
                            column
                          >
                            <SwitchInput
                              controlName="emailNotification.newTask"
                              label="New task created on the project"
                            />
                            <SwitchInput
                              controlName="emailNotification.assignedToTask"
                              label="Tasks assigned to me"
                            />
                            <SwitchInput
                              controlName="emailNotification.newComment"
                              label="New comments on tasks"
                            />
                            <SwitchInput
                              controlName="emailNotification.newMeeting"
                              label="New meeting created on the project"
                            />
                          </LabelGroup>
                        </div>
                      </div>

                      <SubmitButton
                        text="Edit User"
                        justifyLeft
                        disabled={Boolean(
                          Object.values(formState.errors).length || !updateSuccessfull,
                        )}
                      />
                    </form>
                  </FormProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

EditUser.propTypes = {
  location: PropTypes.object,
  search: PropTypes.string,
};

export default EditUser;

import React, { useState } from 'react';
import MetaTags from 'react-meta-tags';
import { Row, Col, CardBody, Card, Alert, Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getDatabase, ref, get } from 'firebase/database';
import { FormProvider, useForm } from 'react-hook-form';

import { useAuth } from 'hooks/useAuth';
import logo from 'assets/images/small-logo.svg';
import LanguageDropdown from 'components/Language-Dropdown';
import LabelGroup from 'components/Label-Group';
import TextInput from 'components/Text-Input';
import DisplayErrors from 'components/Display-Errors';

const Register = () => {
  const formConfig = {
    defaultValues: {
      email: '',
      username: '',
      code: '',
      password: '',
    },
    shouldFocusError: true,
  };
  const form = useForm(formConfig);
  const {
    handleSubmit,
    setError,
    formState: { errors },
  } = form;
  const { register } = useAuth();
  const { t: translate } = useTranslation();
  const [success, setSuccess] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const handleValidSubmit = async (data) => {
    try {
      setIsSending(true);
      const success = await register(data);
      setSuccess(success);
      setIsSending(false);
    } catch (error) {
      setIsSending(false);
      console.error('Registration error:', error);
      if (error === 'Firebase: Error (auth/email-already-in-use).') {
        setError('email', {
          type: 'manual',
          message: 'This email is already in use.',
        });
      } else {
        setError('submit', {
          type: 'manual',
          message: error.message,
        });
      }
    }
  };

  const handleErrors = (errors) => {
    if (!errors || Object.keys(errors).length === 0) {
      return {};
    }

    if (
      typeof errors === 'object' &&
      Object.values(errors).every((error) => typeof error === 'string')
    ) {
      return errors;
    }

    const formattedErrors = {};

    if (errors) {
      formattedErrors.required = errors.message || errors;
    }

    return formattedErrors;
  };

  const validateAccountCode = async (value, ctx, input, cb) => {
    if (value) {
      try {
        // Removes . # $ [ and ] from the string because they throw errors on firebase searches
        const unverifiedCode = value.replace(/[\.#$\[\]]/g, '');
        const db = getDatabase();
        const codeVerifierRef = ref(db, `codes/${unverifiedCode}`);

        const snapshot = await get(codeVerifierRef);
        const codeInfo = snapshot.val();

        if (!codeInfo) {
          return translate('This code is not valid!');
        }

        return true;
      } catch (error) {
        console.error('Error validating account code:', error);
        return 'Error validating code. Please try again.';
      }
    }
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>{translate('Register')} | Ectools</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div style={{ backgroundColor: 'rgba(242, 242, 242, 0.22)' }}>
                  <Row>
                    <Col xs={9}>
                      <div className="text-primary p-4">
                        <h5 style={{ color: '#344792' }}>{translate('Register')}</h5>
                        <p style={{ color: '#344792' }}>{translate('Your SEO Dashboard.')}</p>
                      </div>
                    </Col>
                    <Col className="col-3" style={{ paddingLeft: '8%' }}>
                      <LanguageDropdown />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <img src={logo} alt={translate("Ectools' Logo")} height="36" />
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <FormProvider {...form}>
                      <form className="form-horizontal" onSubmit={handleSubmit(handleValidSubmit)}>
                        {success && (
                          <>
                            <Alert color="success" className="mb-4">
                              {translate(
                                'Registered Successfully, wait for the approval of your user.',
                              )}
                            </Alert>
                            <div className="mt-4 d-flex justify-content-center">
                              <Link to="/" className="text-primary">
                                <button
                                  className="btn btn-block"
                                  type="submit"
                                  style={{
                                    backgroundColor: '#344792',
                                    color: '#FFF',
                                  }}
                                >
                                  {translate('Back to Home')}
                                </button>
                              </Link>
                            </div>
                          </>
                        )}

                        {!success && (
                          <>
                            <LabelGroup column mb={2} htmlFor="email" label="Email" required>
                              <TextInput
                                controlName="email"
                                type="email"
                                placeholder="Enter email"
                                required
                                validation={{
                                  pattern: {
                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                    message: translate('Invalid email address'),
                                  },
                                }}
                              />
                              <DisplayErrors error={handleErrors(errors?.email)} />
                            </LabelGroup>

                            <LabelGroup column mb={2} htmlFor="username" label="Name" required>
                              <TextInput
                                controlName="username"
                                type="text"
                                placeholder="Enter name"
                                required
                              />
                              <DisplayErrors error={handleErrors(errors?.username)} />
                            </LabelGroup>

                            <LabelGroup column mb={2} htmlFor="code" label="Company Code" required>
                              <TextInput
                                controlName="code"
                                type="text"
                                placeholder="Enter code"
                                required
                                validation={{
                                  validate: validateAccountCode,
                                }}
                              />
                              <DisplayErrors error={handleErrors(errors?.code)} />
                            </LabelGroup>

                            <LabelGroup column mb={2} htmlFor="password" label="Password" required>
                              <TextInput
                                controlName="password"
                                type="password"
                                placeholder="Enter password"
                                required
                                validation={{
                                  minLength: {
                                    value: 6,
                                    message: 'Password must be at least 6 characters long',
                                  },
                                }}
                              />
                              <DisplayErrors error={handleErrors(errors?.password)} />
                            </LabelGroup>

                            <div className="mt-4">
                              <button
                                className="btn btn-block"
                                type="submit"
                                style={{
                                  backgroundColor: '#344792',
                                  color: '#FFF',
                                  width: '7.5rem',
                                  height: '2.2rem',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  padding: 0,
                                  position: 'relative',
                                }}
                              >
                                {isSending ? (
                                  <div
                                    className="spinner-border text-light"
                                    role="status"
                                    style={{
                                      width: '1.5rem',
                                      height: '1.5rem',
                                      position: 'absolute',
                                    }}
                                  >
                                    <span className="visually-hidden">Loading...</span>
                                  </div>
                                ) : (
                                  translate('Register button')
                                )}
                              </button>
                            </div>
                          </>
                        )}

                        <div className="mt-4 text-center">
                          <p className="mb-0">
                            {`${translate('By registering you agree to the Ectools')} `}
                            <Link to="#" className="text-primary">
                              {translate('Terms of Use')}
                            </Link>
                          </p>
                        </div>
                      </form>
                    </FormProvider>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  {`${translate('Already have an account?')} `}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {` ${translate('Login')}`}
                  </Link>{' '}
                </p>
                <p>
                  © {new Date().getFullYear()} {translate('Ecto Digital')}.
                  {` ${translate('Created with')} `}
                  <i className="mdi mdi-heart text-danger" />
                  {` ${translate('in Curitiba')}`}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Register;

import React, { useState, useEffect, useCallback } from 'react';
import { getDatabase, ref, onValue, off } from 'firebase/database';

import { useAuth } from '../../hooks/useAuth';
import PageStructure from '../../components/Page-Structure';
import ShowComponent from '../../components/Show-Component';
import ExperimentAccordionList from './components/Experiment-Accordion-List';
import Shimmer from '../../components/Shimmer';
import { LoadingAccordions } from '../../components/Accordion-List/styles';

const ExperimentsList = () => {
  const { user } = useAuth();
  const accountId = user?.account;
  const [experiments, setExperiments] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!accountId) return;
    setLoading(true);

    const db = getDatabase();
    const experimentsRef = ref(db, `experiments/${accountId}`);

    const handleValueChange = (snapshot) => {
      const data = snapshot.val();
      if (data) {
        processExperiments(data);
      }
      setLoading(false);
    };

    onValue(experimentsRef, handleValueChange);

    return () => {
      off(experimentsRef, 'value', handleValueChange);
    };
  }, [accountId]);

  const processExperiments = useCallback((receivedExperiments) => {
    if (!receivedExperiments) {
      return setLoading(false);
    }

    const newExperimentState = [[], []];

    const sortedTasks = Object.entries(receivedExperiments).reduce(
      (sortedObj, [key, value]) => ({
        ...sortedObj,
        [key]: value,
      }),
      {},
    );

    for (const [key, value] of Object.entries(sortedTasks)) {
      const now = Date.parse(new Date());
      if (value.dateEnd > now) {
        newExperimentState[0].push({ ...value, id: key });
      } else {
        newExperimentState[1].push({ ...value, id: key });
      }
    }

    setExperiments(newExperimentState);
  }, []);

  return (
    <PageStructure
      metaTitle="List Experiments"
      breadcrumbItem="Experiment List"
      breadcrumbTitle="Tasks"
    >
      <ShowComponent condition={loading}>
        <LoadingAccordions>
          <Shimmer height="10rem" />
          <Shimmer height="10rem" />
        </LoadingAccordions>
      </ShowComponent>
      <ShowComponent condition={!loading}>
        <ExperimentAccordionList column="Ongoing Experiments" data={experiments?.[0]} />
        <ExperimentAccordionList column="Finished Experiments" data={experiments?.[1]} />
      </ShowComponent>
    </PageStructure>
  );
};

ExperimentsList.displayName = 'Experiments List Page';

export default ExperimentsList;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { ButtonCancelStyle } from './styles';

const ButtonCancel = ({ onClickFunction }) => {
  const { t: translate } = useTranslation();

  return (
    <ButtonCancelStyle>
      <Button className="btn-cancel" onClick={onClickFunction}>
        {translate('Cancel')}
      </Button>
    </ButtonCancelStyle>
  );
};

ButtonCancel.propTypes = {
  onClickFunction: PropTypes.func.isRequired,
};

export default ButtonCancel;

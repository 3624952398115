import React, { useEffect, useState } from 'react';
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import axios from 'axios';
import { MoonLoader } from 'react-spinners';
import { getFirebaseBackend } from 'helpers/firebaseHelper';

import { ButtonApproveStyle } from '../Button-Approve/styles';
import { ModalAddNewTopicStyle } from './styles';

const ModalAddNewTopic = ({ modal, toggle, topicsList, setTopicsList, fetchData }) => {
  const firebaseHelper = getFirebaseBackend();
  const { id } = useParams();
  const { user } = useAuth();
  const currentAccountId = user?.account;
  const isProduction = process.env.REACT_APP_BUILD_TYPE === 'production';
  const { t: translate } = useTranslation();
  const [textTopic, setTextTopic] = useState('');
  const [textPrompt, setTextPrompt] = useState('');
  const [error, setError] = useState(false);
  const [customPrompt, setCustomPrompt] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sucess, setSucess] = useState(false);

  const onSubmit = async () => {
    if (!textTopic) {
      setError(true);
      return;
    }

    try {
      setIsLoading(true);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/generatePersonalizedTopic`,
        {
          accountId: currentAccountId,
          guidelineId: id,
          development: !isProduction,
          topic: textTopic,
          prompt: textPrompt === '' ? null : textPrompt,
        },
        {
          headers: {
            Authorization: `Bearer ${await firebaseHelper.getIdToken()}`,
          },
        },
      );
      const newTopic = response.data;
      setTopicsList([...topicsList, newTopic]);
      setIsLoading(false);
      setSucess(true);

      setTimeout(() => {
        setSucess(false);
        setCustomPrompt(false);
        setTextTopic('');
        setTextPrompt('');
        fetchData();
        toggle();
      }, 1000);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setSucess(false);
    }
  };

  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      <ModalAddNewTopicStyle>
        <ModalHeader toggle={toggle}>{translate('Create new topic')}</ModalHeader>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center py-4">
            <MoonLoader color="#556ee6" size={100} />
          </div>
        ) : sucess ? (
          <div className="d-flex justify-content-center align-items-center py-4">
            <i className="bx bxs-check-circle " style={{ fontSize: '120px', color: '#556ee6' }} />
          </div>
        ) : (
          <ModalBody>
            <Label>{translate('Topic title')}*</Label>
            <Input
              type="text"
              className={`topic-custom`}
              name={`topic-custom`}
              value={textTopic}
              bsSize="md"
              style={{ width: '100%' }}
              placeholder={translate('Write a title for the topic')}
              onChange={(e) => {
                setTextTopic(e.target.value);
              }}
              autoFocus
            />
            {error && <p className="text-danger">{translate('Please, write a title')}</p>}
            {!customPrompt ? (
              <Button className="btn-transparent" onClick={() => setCustomPrompt(true)}>
                {translate('Add prompt manually')}
              </Button>
            ) : (
              <div className="container-input-prompt d-flex flex-column">
                <Label>{translate('Prompt Wizard')}</Label>
                <Input
                  type="text"
                  className={`topic-custom`}
                  name={`topic-custom`}
                  value={textPrompt}
                  bsSize="md"
                  style={{ width: '100%' }}
                  placeholder={translate('Write a prompt for the topic')}
                  onChange={(e) => {
                    setTextPrompt(e.target.value);
                  }}
                  autoFocus
                />
              </div>
            )}
          </ModalBody>
        )}
        <ModalFooter style={{ border: 'none' }}>
          <Button
            onClick={() => {
              setCustomPrompt(false);
              toggle();
            }}
            disabled={isLoading}
          >
            {translate('Cancel')}
          </Button>
          <ButtonApproveStyle>
            <Button className="btn-approve" onClick={onSubmit} disabled={isLoading | sucess}>
              {translate('To add')}
            </Button>
          </ButtonApproveStyle>
        </ModalFooter>
      </ModalAddNewTopicStyle>
    </Modal>
  );
};

ModalAddNewTopic.propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  text: PropTypes.string,
  topicsList: PropTypes.array,
  setTopicsList: PropTypes.func,
  fetchData: PropTypes.func,
};

export default ModalAddNewTopic;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

const CheckboxInput = ({
  controlName,
  className = '',
  disabled = false,
  onChange = null,
  required = false,
  justifyCenter = true,
  size = 12,
  label,
  validation = {},
}) => {
  const { t: translate } = useTranslation();
  const [checked, setChecked] = useState();
  const {
    control,
    formState: { errors },
    watch,
    getValues,
  } = useFormContext();

  const validate = required ? { ...validation, required: 'Required Field!' } : validation;

  useEffect(() => {
    const value = getValues(controlName);
    if (value === true) setChecked(true);
    else setChecked(false);
  }, [watch(controlName)]);

  return (
    <Col
      lg={size}
      className={`d-flex ${
        justifyCenter ? 'justify-content-center' : ''
      } align-items-center gap-2 ${className}`}
    >
      <Controller
        control={control}
        name={controlName}
        rules={validate}
        render={({ field }) => (
          <input
            {...field}
            value={field.value}
            ref={field.ref}
            checked={checked ? true : null}
            disabled={disabled}
            onChange={(event) => {
              field.onChange(event);
              if (onChange) onChange(event);
            }}
            type="checkbox"
            id={controlName}
            name={controlName}
          />
        )}
      />

      <label htmlFor={controlName} className="m-0">
        {translate(label) + (required ? ' *' : '')}
      </label>
    </Col>
  );
};

CheckboxInput.propTypes = {
  controlName: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  justifyCenter: PropTypes.bool,
  size: PropTypes.number,
  label: PropTypes.string,
  validation: PropTypes.object,
};

export default CheckboxInput;

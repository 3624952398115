import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import CheckboxInput from 'components/Checkbox-Input';
import { MoonLoader } from 'react-spinners';
import { SendCardLayout } from './styles';

const SendCard = ({ isLoading }) => {
  const { t: translate } = useTranslation();

  return (
    <SendCardLayout>
      <p className="subtitle">{translate('Action')}</p>
      <button type="submit">
        {isLoading ? (
          <MoonLoader size={20} color={'white'} />
        ) : (
          <React.Fragment>
            {translate('Create Guideline')}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="19"
              viewBox="0 0 20 19"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.49808 2.25C5.49808 3.90754 4.15437 5.25125 2.49683 5.25125C4.15437 5.25125 5.49808 6.59496 5.49808 8.2525C5.49808 6.59496 6.84178 5.25125 8.49933 5.25125C6.84178 5.25125 5.49808 3.90754 5.49808 2.25Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.7516 11.2531C13.7516 9.18119 12.0719 7.50156 10 7.50156C12.0719 7.50156 13.7516 5.82193 13.7516 3.75C13.7516 5.82193 15.4312 7.50156 17.5031 7.50156C15.4312 7.50156 13.7516 9.18119 13.7516 11.2531Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.4993 10.1328C8.4993 11.7904 7.15559 13.1341 5.49805 13.1341C7.15559 13.1341 8.4993 14.4778 8.4993 16.1353C8.4993 14.4778 9.843 13.1341 11.5005 13.1341C9.843 13.1341 8.4993 11.7904 8.4993 10.1328Z"
                fill="white"
              />
            </svg>
          </React.Fragment>
        )}
      </button>
      <CheckboxInput
        label={translate('Notify responsible parties by email')}
        controlName="notification"
        size={12}
        justifyCenter={false}
      />
    </SendCardLayout>
  );
};

SendCard.propTypes = {
  isLoading: PropTypes.bool,
};

export default SendCard;

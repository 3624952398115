import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getFirebaseBackend } from '../../helpers/firebaseHelper.js';
import TasksSummary from '../../components/Tasks-Summary';
import TasksFilter from '../../components/Tasks-Filter';
import GuidelinesFilter from '../../pages/guideline/components/Guidelines-Filter';

const KanbanHeader = ({ board, accountId, filterTotal, isContent, isGuideline, userStatus }) => {
  const firebaseHelper = getFirebaseBackend();

  const [filterSide, setFilterSide] = useState(false);

  // Handles the logo element
  const getLogo = async () => {
    const logoElem = document.getElementById('account-photo');
    const loadingElem = document.getElementById('loading-icon');
    if (logoElem && loadingElem) {
      logoElem.style.display = 'none';
      loadingElem.style.display = 'block';
    }

    const path = `${accountId}/logo`;
    const logoMeta = await firebaseHelper.listAllFiles(path);
    if (logoMeta.length !== 0) {
      const logoFile = await firebaseHelper.getFileObj(`${path}/${logoMeta[0].name}`);
      const logoUrl = URL.createObjectURL(logoFile);
      logoElem.style.display = 'block';
      loadingElem.style.display = 'none';
      logoElem.style.backgroundImage = `url('${logoUrl}')`;
    } else {
      if (logoElem && loadingElem) {
        logoElem.style.display = 'none';
        loadingElem.style.display = 'none';
      }
    }
  };

  const filterTotalReturn = () => {
    return filterTotal;
  };

  useEffect(() => {
    getLogo();
  }, [accountId]);

  return (
    <TaskSummmaryLayout className="task-summary-wrapper m-0 p-4">
      <div className="task-summary-photo">
        <div id="account-photo" className="task-summary-logo" />
        <i id="loading-icon" className="bx bx-loader-alt task-summary-loading-icon" />
      </div>

      {isGuideline ? (
        <GuidelinesFilter toggleFilter={setFilterSide} accountId={accountId} show={filterSide} />
      ) : (
        <TasksFilter
          isContent={isContent}
          toggleFilter={setFilterSide}
          accountId={accountId}
          show={filterSide}
        />
      )}

      <TasksSummary
        isContent={isContent}
        isGuideline={isGuideline}
        board={board}
        toggleFilter={setFilterSide}
        filterTotal={filterTotalReturn}
        userStatus={userStatus}
        show={!filterSide}
      />
    </TaskSummmaryLayout>
  );
};

const TaskSummmaryLayout = styled.div`
  .task-summary-logo {
    margin-left: 0 !important;
  }
`;

KanbanHeader.propTypes = {
  board: PropTypes.object,
  accountId: PropTypes.string,
  filterTotal: PropTypes.number,
  isContent: PropTypes.bool,
  isGuideline: PropTypes.bool,
  userStatus: PropTypes.string,
  t: PropTypes.func,
};

export default KanbanHeader;

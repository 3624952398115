const initialKanbanState = [
  {
    id: 1,
    title: 'Backlog',
    cards: [],
  },
  {
    id: 2,
    title: 'In Progress',
    cards: [],
  },
  {
    id: 3,
    title: 'Pendings',
    cards: [],
  },
  {
    id: 4,
    title: 'Delivered',
    cards: [],
  },
  {
    id: 5,
    title: 'Finished',
    cards: [],
  },
];

export { initialKanbanState };

import styled from 'styled-components';

import { mediaQuery } from '../../../../constants';

export const ContentCardLayout = styled.div`
  .card-form {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: space-between;
    padding: 24px;
    border: 1px solid #edf2f7;
    border-radius: 8px;

    @media ${mediaQuery.less.sm} {
      flex-direction: column;
      padding: 16px;
    }
  }

  .full-width {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .text-detail {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    padding: 0;
    margin: 8px 0 0 0;
    color: #718096;
  }

  .btn-delete {
    background-color: transparent;
    border: none;
    padding: 0;
    width: 32px;
    height: 32px;
    padding: 8px;
    display: flex;
    justify-content: center;
  }
  .btn-delete:hover {
    background-color: #edf2f7;
  }
`;

import styled from 'styled-components';

export const ButtonApproveStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .btn-approve {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    border: none;
    background-color: #48bb78;
    font-weight: 500;
  }
  .btn-approve:hover {
    background-color: #25855a;
  }
`;

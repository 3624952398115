import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import 'boxicons';

import { useAuth } from 'hooks/useAuth';
import { getFirebaseBackend } from '../../helpers/firebaseHelper';
import defaultUserPhoto from '../../assets/images/users/default-user-photo.jpg';

const ProfileMenu = () => {
  const { t: translate } = useTranslation();
  const [menu, setMenu] = useState(false);
  const [userName, setUserName] = useState('');
  const [userHasPhoto, setUserHasPhoto] = useState();
  const { user } = useAuth();
  const { adminStatus } = user;
  const firebaseHelper = getFirebaseBackend();

  useEffect(() => {
    if (!user) return;

    setUserName(user.name);
    setUserHasPhoto(user.hasPhoto);
  }, [user]);

  useEffect(async () => {
    if (userHasPhoto === null || userHasPhoto === undefined) return;

    const picElem = document.getElementById('user-photo');
    const path = `${user.id}/profilePic`;
    const profilePicMeta = await firebaseHelper.listAllFiles(path);
    if (profilePicMeta.length) {
      const profilePicFile = await firebaseHelper.getFileObj(`${path}/${profilePicMeta[0].name}`);
      const profilePicUrl = URL.createObjectURL(profilePicFile);
      picElem.style.backgroundImage = `url('${profilePicUrl}')`;
    } else {
      picElem.style.backgroundImage = `url('${defaultUserPhoto}')`;
    }
  }, [userHasPhoto]);

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
        <DropdownToggle
          className="btn header-item d-flex align-items-center ps-0"
          id="page-header-user-dropdown"
          tag="button"
        >
          <span className="d-none d-xl-inline-block ms-2 me-1">{userName}</span>
          <div id="user-photo" className="profile-user-pic"></div>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {adminStatus === 'Super Admin' ? (
            <>
              <DropdownItem
                tag="a"
                href="/create-account"
                className="d-flex justify-content-start align-items-center"
              >
                {' '}
                <box-icon
                  type="regular"
                  name="add-to-queue"
                  size="16px"
                  style={{ marginRight: '.3em' }}
                ></box-icon>
                {translate('Create Account')}{' '}
              </DropdownItem>
              <DropdownItem
                tag="a"
                href="/list-accounts"
                className="d-flex justify-content-start align-items-center"
              >
                {' '}
                <box-icon
                  type="regular"
                  name="align-justify"
                  size="16px"
                  style={{ marginRight: '.3em' }}
                ></box-icon>
                {translate('List Accounts')}{' '}
              </DropdownItem>
              <div className="dropdown-divider" />
            </>
          ) : null}
          {adminStatus === 'Super Admin' || adminStatus === 'Admin' ? (
            <>
              <DropdownItem
                tag="a"
                href="/create-user"
                className="d-flex justify-content-start align-items-center"
              >
                {' '}
                <box-icon
                  type="solid"
                  name="user-plus"
                  size="16px"
                  style={{ marginRight: '.3em' }}
                ></box-icon>
                {translate('Create User')}{' '}
              </DropdownItem>
              <DropdownItem
                tag="a"
                href="/list-users"
                className="d-flex justify-content-start align-items-center"
              >
                {' '}
                <box-icon
                  type="solid"
                  name="user-detail"
                  size="16px"
                  style={{ marginRight: '.3em' }}
                ></box-icon>
                {translate('List Users')}{' '}
              </DropdownItem>
              <div className="dropdown-divider" />
            </>
          ) : null}
          <Link
            to="/edit-profile"
            className="dropdown-item d-flex justify-content-start align-items-center"
          >
            <box-icon
              type="regular"
              name="user"
              size="16px"
              style={{ marginRight: '.3em' }}
            ></box-icon>
            <span>{translate('Edit Profile')}</span>
          </Link>
          <Link
            to="/logout"
            className="dropdown-item d-flex justify-content-start align-items-center"
          >
            <box-icon
              type="regular"
              name="power-off"
              color="red"
              size="16px"
              style={{ marginRight: '.3em' }}
            ></box-icon>
            <span>{translate('Logout')}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
};

export default ProfileMenu;

import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import 'boxicons';
import axios from 'axios';
import _ from 'lodash';
import Chart from 'react-apexcharts';
import { useForm, FormProvider } from 'react-hook-form';

import { useAuth } from 'hooks/useAuth';
import { getFirebaseBackend } from '../../helpers/firebaseHelper';
import ModalContentContext from '../../components/Modal-Content/ModalContent.Context';
import LabelGroup from '../../components/Label-Group';
import SelectInput from '../../components/Select-Input';
import GraphHeader from './GraphHeader';
import KeywordTable from './KeywordTable';
import ShowComponent from '../../components/Show-Component';
import { loadTags, loadKeywords } from '../../utils/';

import { experimentChartConfig } from '../../constants';
import { ExperimentContainer, Circle, GraphForm, GraphResponsive, ChartDiv } from './styles';

const ExperimentGraphs = () => {
  const formConfig = {
    defaultValues: {
      device: [],
      tags: [],
      keywords: [],
    },
    shouldFocusError: true,
    criteriaMode: 'all',
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  };
  const firebaseHelper = getFirebaseBackend();

  const { t: translate } = useTranslation();
  const form = useForm(formConfig);
  const { user } = useAuth();
  const accountId = user.account;
  const { content } = useContext(ModalContentContext);
  const [loading, setLoading] = useState(true);
  const [hasData, setHasData] = useState(false);
  const [queryData, setQueryData] = useState(null);
  const [batchId, setBatchId] = useState(null);
  const [stats, setStats] = useState();
  const [options, setOptions] = useState(experimentChartConfig);
  const [series, setSeries] = useState();
  const [filterOn, setFilterOn] = useState(false);
  const [filteredData, setFilteredData] = useState(false);
  const formRef = useRef();

  const setExperimentVars = useCallback(async () => {
    firebaseHelper.getFirebaseExperiment(accountId, content.id).then((experimentData) => {
      const name = experimentData?.name;
      const batchId = experimentData?.serp?.batchId;
      setBatchId(() => batchId);
      setOptions((prevState) => ({
        ...prevState,
        title: {
          ...prevState.title,
          text: name,
        },
      }));
    });
  }, [accountId, content.id]);

  useEffect(() => {
    setExperimentVars();
  }, [setExperimentVars]);

  useEffect(() => {
    if (!accountId || !content.id || !batchId) return;

    const params = `?acc=${accountId}&tsk=${content.id}&bch=${batchId}`;
    axios
      .get(`https://us-central1-ectools-9318d.cloudfunctions.net/selectBatchValues${params}`)
      .then(
        (res) => {
          const { data } = res.data;
          const parsedData = JSON.parse(data);
          setQueryData(parsedData);
          processData(parsedData);
          setLoading(false);
        },
        (res) => {
          console.error(res.data);
          setLoading(false);
        },
      );
  }, [accountId, content, batchId]);

  const processData = (data, filter = false) => {
    if (_.isEmpty(data)) return setHasData(false);

    const filteredData = data.filter((positionedPage) => {
      let deviceBool = 1;
      let tagBool = 1;
      let keywordBool = 1;
      const deviceValue = form.getValues('device');
      const tagValue = form.getValues('tags');
      const keywordValue = form.getValues('keywords');

      if (!_.isEmpty(deviceValue)) {
        deviceBool = 0;
        deviceValue.forEach(({ value }) => {
          if (positionedPage.device === value) deviceBool++;
        });
      }

      if (!_.isEmpty(tagValue)) {
        tagBool = 0;
        tagValue.forEach(({ value }) => {
          const [, tags] = positionedPage.custom_id.split('|');
          const tagArr = tags.replace('t_', '').split('_');
          if (tagArr.includes(value)) tagBool++;
        });
      }

      if (!_.isEmpty(keywordValue)) {
        keywordBool = 0;
        keywordValue.forEach(({ value }) => {
          const [keyword] = positionedPage.custom_id.split('|');
          if (keyword === value) keywordBool++;
        });
      }

      return deviceBool && tagBool && keywordBool;
    });

    setFilteredData(filteredData);
    setFilterOn(filter);

    const dateSet = new Set();
    const firstData = [];
    const tenthData = [];
    const lastData = [];
    const unrankedData = [];
    const oldestSet = {};
    let newestSet = {};
    let keywordNumber = 0;
    let improvedKeywords = 0;

    let batchIndex = -1;

    filteredData.forEach((result) => {
      const dateCategory = new Date(result.date.value).toString();
      if (!dateSet.has(dateCategory)) {
        batchIndex++;
        keywordNumber = 0;
        newestSet = {};
      }
      dateSet.add(dateCategory);

      keywordNumber++;
      let firstThree = 0;
      let placedTenth = 0;
      let placedLast = 0;
      let unranked = 0;
      if (_.isEmpty(result.matched_searches)) {
        unranked++;
      } else {
        result.matched_searches.forEach((search) => {
          if (search.position < 4) firstThree++;
          else if (search.position < 11) placedTenth++;
          else placedLast++;
        });
      }

      const keywordCalc = firstThree * 2 + placedTenth + placedLast * 0.5;
      if (!batchIndex) {
        oldestSet[result.query + result.device] = keywordCalc;
      } else {
        newestSet[result.query + result.device] = keywordCalc;
      }

      firstData[batchIndex] = (firstData[batchIndex] ?? 0) + firstThree;
      tenthData[batchIndex] = (tenthData[batchIndex] ?? 0) + placedTenth;
      lastData[batchIndex] = (lastData[batchIndex] ?? 0) + placedLast;
      unrankedData[batchIndex] = (unrankedData[batchIndex] ?? 0) + unranked;
    });

    Object.entries(newestSet).forEach(([key, value]) => {
      if (oldestSet[key] === null || oldestSet[key] === undefined) return;
      if (value > oldestSet[key]) improvedKeywords++;
    });

    setStats({
      oldFirst: firstData.at(0),
      newFirst: firstData.at(-1),
      oldTenth: tenthData.at(0),
      newTenth: tenthData.at(-1),
      oldLast: lastData.at(0),
      newLast: lastData.at(-1),
      oldUnranked: unrankedData.at(0),
      newUnranked: unrankedData.at(-1),
      improved: improvedKeywords,
      total: keywordNumber,
    });

    setOptions((prevState) => ({
      ...prevState,
      xaxis: {
        ...prevState.xaxis,
        categories: Array.from(dateSet).reverse(),
      },
    }));

    setSeries([
      {
        name: '20 a 100',
        data: Object.values(lastData).reverse(),
      },
      {
        name: '4 a 10',
        data: Object.values(tenthData).reverse(),
      },
      {
        name: '1 a 3',
        data: Object.values(firstData).reverse(),
      },
    ]);

    setHasData(true);
  };

  const handleChange = () => {
    processData(queryData, true);
  };

  const handleScrollClose = (event) => {
    // Solution to fixed Select Menu no scroll
    // https://github.com/JedWatson/react-select/issues/4088#issuecomment-1073632818
    // Also works for react-datepicker Time Select
    return event.target.contains(formRef.current);
  };

  // Style the container to avoid the graph toolbar z-index
  const customStyles = {
    container: (provided) => ({
      ...provided,
      zIndex: 12,
    }),
  };

  return (
    <ExperimentContainer padding gap>
      {loading ? (
        <>
          <box-icon name="loader-alt" animation="spin" size="4em" color="#50A5F1"></box-icon>
        </>
      ) : (
        <>
          <ShowComponent condition={hasData}>
            <GraphResponsive>
              <FormProvider {...form}>
                <GraphForm ref={formRef}>
                  <LabelGroup htmlFor="device" label="" mb={0} size={12} column>
                    <SelectInput
                      controlName="device"
                      isMulti
                      required
                      placeholder="Device"
                      onChange={handleChange}
                      options={[
                        { value: 'desktop', label: 'Desktop' },
                        { value: 'mobile', label: 'Mobile' },
                      ]}
                      menuPosition="fixed"
                      styles={customStyles}
                      closeMenuOnScroll={handleScrollClose}
                    />
                  </LabelGroup>

                  <LabelGroup htmlFor="tags" label="" mb={0} size={12} column>
                    <SelectInput
                      asyncronous
                      isMulti
                      controlName="tags"
                      required
                      placeholder="Tags"
                      onChange={handleChange}
                      menuPosition="fixed"
                      styles={customStyles}
                      closeMenuOnScroll={handleScrollClose}
                      cacheOptions
                      defaultOptions
                      loadOptions={(inputValue) => loadTags(accountId, content.id, inputValue)}
                    />
                  </LabelGroup>

                  <LabelGroup htmlFor="keywords" label="" mb={0} size={12} column>
                    <SelectInput
                      asyncronous
                      isMulti
                      controlName="keywords"
                      required
                      placeholder="All Keywords"
                      onChange={handleChange}
                      menuPosition="fixed"
                      styles={customStyles}
                      closeMenuOnScroll={handleScrollClose}
                      cacheOptions
                      defaultOptions
                      loadOptions={(inputValue) => loadKeywords(accountId, content.id, inputValue)}
                    />
                  </LabelGroup>
                </GraphForm>
              </FormProvider>

              <GraphHeader stats={stats} />

              <ChartDiv>
                <Chart options={options} series={series} type="bar" width="100%" height="290px" />
              </ChartDiv>

              <KeywordTable
                categories={options.xaxis.categories}
                data={filterOn ? filteredData : queryData}
              />
            </GraphResponsive>
          </ShowComponent>

          <ShowComponent condition={!hasData}>
            <Circle size="12em" color="#50A5F1" margin>
              <box-icon type="regular" name="hdd" size="6.5em" color="white" />
            </Circle>
            <h2>
              {translate('The Experiment has been created but no data has been collected yet.')}
            </h2>
          </ShowComponent>
        </>
      )}
    </ExperimentContainer>
  );
};

export default ExperimentGraphs;

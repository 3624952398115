import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import 'boxicons';
import { Button } from 'reactstrap';
import { useAuth } from 'hooks/useAuth';
import { getDatabase, onValue, ref } from 'firebase/database';
import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { MoonLoader } from 'react-spinners';

import { TaskSummmaryLayout } from './styles';
import ButtonFilter from 'pages/guideline/components/Button-Filter';

const TasksSummary = ({
  board,
  toggleFilter,
  filterTotal,
  isContent,
  isGuideline,
  show,
  userStatus,
}) => {
  const { t: translate } = useTranslation();
  const { parse } = require('json2csv');
  const navigate = useNavigate();
  const { user } = useAuth();
  const accountId = user?.account;
  const firebaseHelper = getFirebaseBackend();
  const [isLoading, setIsLoading] = useState(false);

  let tasksTotal = 0;

  let waitingTotal = 0;
  let readyTotal = 0;
  let impedimentTotal = 0;
  let developmentTotal = 0;
  let implementedTotal = 0;

  const countTotals = [
    () => {
      waitingTotal++;
    },
    () => {
      readyTotal++;
    },
    () => {
      impedimentTotal++;
    },
    () => {
      developmentTotal++;
    },
    () => {
      implementedTotal++;
    },
  ];

  // Counts tasks totals
  board?.columns.forEach((column) => {
    column.cards.forEach((task) => {
      if (task.status) {
        countTotals[task.status]();
      }
      tasksTotal++;
    });
  });

  const handleToggle = () => toggleFilter((prevState) => !prevState);

  const handleDownloadCSV = async () => {
    try {
      setIsLoading(true);
      const db = getDatabase();
      const guidelinesRef = ref(db, `guidelines/${accountId}`);
      let allGuidelines = [];

      onValue(guidelinesRef, async (snapshot) => {
        const data = snapshot.val();

        const guidelinesWithIds = Object.entries(data ?? {}).flatMap(([key, value]) => {
          if (key !== 'metadata') {
            return {
              ...value,
              id: key,
            };
          } else {
            return [];
          }
        });

        allGuidelines = guidelinesWithIds;
      });

      await new Promise((resolve) => setTimeout(resolve, 1000));

      const guidelinesPromises = Object.values(allGuidelines).map(async (guideline) => {
        let assignedToNames = '';
        let responsibleToApprovalNames = '';

        if (guideline.assignedTo && guideline.assignedTo.length > 0) {
          const userPromises = guideline.assignedTo.map(
            async (userId) => await firebaseHelper.getUserName(userId),
          );
          const resolvedUserNames = await Promise.all(userPromises);
          assignedToNames = resolvedUserNames.join(',');
        }
        if (guideline.responsibleToApproval && guideline.responsibleToApproval.length > 0) {
          const userPromises = guideline.responsibleToApproval.map(
            async (userId) => await firebaseHelper.getUserName(userId),
          );
          const resolvedUserNames = await Promise.all(userPromises);
          responsibleToApprovalNames = resolvedUserNames.join(',');
        }

        return {
          id: guideline.id,
          column: guideline.column,
          assignedTo: assignedToNames,
          responsibleToApproval: responsibleToApprovalNames,
          primaryKeyword: guideline.primaryKeyword,
          secondaryKeywords: guideline.secondaryKeywords
            ? guideline.secondaryKeywords.join(',')
            : '',
          title: guideline.title || '',
          createdAt: guideline.createdAt
            ? new Date(guideline.createdAt).toISOString().substring(0, 10)
            : '',
          deliveryDate: guideline.deliveryDate
            ? new Date(guideline.deliveryDate).toISOString().substring(0, 10)
            : '',
          productionDateMonth: guideline.productionDate?.month || '',
          productionDateYear: guideline.productionDate?.year || '',
          searchVolume: guideline.searchVolume || '',
          content: guideline.content || '',
        };
      });

      const resolvedGuidelines = await Promise.all(guidelinesPromises);

      const csvOptions = {
        fields: [
          'id',
          'column',
          'assignedTo',
          'responsibleToApproval',
          'primaryKeyword',
          'secondaryKeywords',
          'title',
          'createdAt',
          'deliveryDate',
          'productionDateMonth',
          'productionDateYear',
          'searchVolume',
          'content',
        ],
        header: true,
      };

      const csvContent = parse(resolvedGuidelines, csvOptions);

      const blob = new Blob([csvContent], { type: 'text/csv' });

      const url = URL.createObjectURL(blob);
      const accountName = await firebaseHelper.accountName(accountId);
      const dateNumber = new Date().toISOString().substring(0, 10);

      const link = document.createElement('a');
      link.href = url;
      link.download = `${accountName}_${dateNumber}_guidelines_data.csv`;

      link.click();

      URL.revokeObjectURL(url);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error downloading CSV', error);
    }
  };

  return (
    <TaskSummmaryLayout className="header-wrapper" style={{ display: show ? 'flex' : 'none' }}>
      {isGuideline ? (
        <div className="task-summary-guideline">
          <div className="task-summary-stats">
            <div className="task-summary-stat">
              <p className="task-summary-title">{translate('Total Guidelines')}</p>
              <p className="task-summary-number">{tasksTotal}</p>
            </div>
            {board.columns.slice(1, 8).map((column) => {
              return (
                <div className="task-summary-stat" key={column.id}>
                  <p className="task-summary-title">{translate(column.title)}</p>
                  <p className="task-summary-number">{column.cards.length}</p>
                </div>
              );
            })}
          </div>
          <div className="task-summary-btns">
            <ButtonFilter handleToggle={handleToggle} filterTotal={filterTotal} />
            {(userStatus === 'Admin' || userStatus === 'Super Admin') && (
              <Button className="btn-download-csv" onClick={handleDownloadCSV}>
                {isLoading ? (
                  <MoonLoader size={20} color="#2d3748" loading={isLoading} />
                ) : (
                  <>
                    <i className="bx bx-download" />
                    {translate('Export CSV')}
                  </>
                )}
              </Button>
            )}
            {(userStatus === 'Admin' || userStatus === 'Super Admin') && (
              <button
                className="btn-guideline-create"
                onClick={() => navigate('/create-guideline')}
              >
                {translate('Create Guideline')}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.99834 2C3.99834 3.47337 2.80394 4.66778 1.33057 4.66778C2.80394 4.66778 3.99834 5.86218 3.99834 7.33556C3.99834 5.86218 5.19275 4.66778 6.66612 4.66778C5.19275 4.66778 3.99834 3.47337 3.99834 2Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.3347 10.0054C11.3347 8.16367 9.84172 6.67066 8 6.67066C9.84172 6.67066 11.3347 5.17765 11.3347 3.33594C11.3347 5.17765 12.8277 6.67066 14.6694 6.67066C12.8277 6.67066 11.3347 8.16367 11.3347 10.0054Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.66607 9C6.66607 10.4734 5.47166 11.6678 3.99829 11.6678C5.47166 11.6678 6.66607 12.8622 6.66607 14.3356C6.66607 12.8622 7.86047 11.6678 9.33385 11.6678C7.86047 11.6678 6.66607 10.4734 6.66607 9Z"
                    fill="white"
                  />
                </svg>
              </button>
            )}
          </div>
        </div>
      ) : (
        <>
          <div className="task-summary-stats">
            <div className="task-summary-stat">
              <p className="task-summary-title">
                {translate(isContent ? 'Total Guidelines' : 'Total Tasks')}
              </p>
              <p className="task-summary-number">{tasksTotal}</p>
            </div>

            {waitingTotal > 0 ? (
              <div className="task-summary-stat">
                <p className="task-summary-title">{translate('Waiting')}</p>
                <p className="task-summary-number">{waitingTotal}</p>
              </div>
            ) : null}

            {readyTotal > 0 ? (
              <div className="task-summary-stat">
                <p className="task-summary-title">{translate('Ready to Implement')}</p>
                <p className="task-summary-number">{readyTotal}</p>
              </div>
            ) : null}

            {impedimentTotal > 0 ? (
              <div className="task-summary-stat">
                <p className="task-summary-title">{translate('Technical Impediment')}</p>
                <p className="task-summary-number">{impedimentTotal}</p>
              </div>
            ) : null}

            {developmentTotal > 0 ? (
              <div className="task-summary-stat">
                <p className="task-summary-title">{translate('Under Development')}</p>
                <p className="task-summary-number">{developmentTotal}</p>
              </div>
            ) : null}

            {implementedTotal > 0 ? (
              <div className="task-summary-stat">
                <p className="task-summary-title">{translate('Implemented')}</p>
                <p className="task-summary-number">{implementedTotal}</p>
              </div>
            ) : null}
          </div>
          <div className="filter-icon" onClick={handleToggle}>
            <box-icon
              name="filter-alt"
              size="1.3em"
              color="#556EE6"
              type={filterTotal > 0 ? 'solid' : 'regular'}
            />
            <p>{translate('Filters') + (filterTotal() > 0 ? ` (${filterTotal()})` : '')}</p>
          </div>
        </>
      )}
    </TaskSummmaryLayout>
  );
};

TasksSummary.propTypes = {
  board: PropTypes.object,
  toggleFilter: PropTypes.func,
  show: PropTypes.bool,
  isContent: PropTypes.bool,
  isGuideline: PropTypes.bool,
  filterTotal: PropTypes.func,
  userStatus: PropTypes.string,
};

export default TasksSummary;

import React, { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AccordionItem, AccordionHeader, AccordionBody } from 'reactstrap';
import PropTypes from 'prop-types';

import 'boxicons';
import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { ModulesAccordion } from './styles';

const ModulesTable = ({ data, library }) => {
  const firebaseHelper = getFirebaseBackend();
  const { t: translate } = useTranslation();
  const [searchParams] = useSearchParams();
  const accountParam = searchParams.get('acc');
  const courseParam = searchParams.get('id');
  const [modules, setModules] = useState([]);

  const minutesToHours = (totalMinutes) => {
    if (totalMinutes < 60) {
      return `${String(totalMinutes).padStart(2, '0')}:00`;
    } else {
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00`;
    }
  };

  const fetchModules = async () => {
    let hasPreviousTest = false;

    const newModules = await Promise.all(
      data.map(async (module) => {
        const newLessons = await Promise.all(
          module.lessons.map(async (lesson) => {
            const lessonObj = await firebaseHelper.getFirebaseLesson(accountParam, lesson.id);

            if (!library?.status) {
              return {
                ...lesson,
                name: lessonObj.name,
                minutes: lessonObj.durationMins,
                normalizedMins: minutesToHours(lessonObj.durationMins),
                hasVideo: Boolean(lessonObj.videoLink),
                isLinkable: false,
                isLocked: !lesson.freeTrial,
              };
            } else if (library?.status === 'trial') {
              return {
                ...lesson,
                name: lessonObj.name,
                minutes: lessonObj.durationMins,
                normalizedMins: minutesToHours(lessonObj.durationMins),
                hasVideo: Boolean(lessonObj.videoLink),
                isLinkable: lesson.freeTrial,
                isLocked: !lesson.freeTrial,
              };
            } else {
              const passedLesson = library?.progress?.includes(lesson.id);
              const hasTest = lessonObj.applyTest;

              const returnedLesson = {
                ...lesson,
                name: lessonObj.name,
                minutes: lessonObj.durationMins,
                normalizedMins: minutesToHours(lessonObj.durationMins),
                hasVideo: Boolean(lessonObj.videoLink),
                isLinkable: passedLesson || !hasPreviousTest,
                isLocked: false || hasPreviousTest,
              };

              if (!passedLesson && hasTest) hasPreviousTest = true;
              return returnedLesson;
            }
          }),
        );

        const moduleLength = newLessons.reduce((acc, cur) => {
          return (acc += cur.minutes);
        }, 0);

        return { ...module, lessons: newLessons, moduleLength };
      }),
    );

    setModules(newModules);
  };

  useEffect(() => {
    fetchModules();
  }, [data, library]);

  return (
    <ModulesAccordion stayOpen>
      {modules.map((module, index) => (
        <AccordionItem key={module.name}>
          <AccordionHeader className="module-accordion-header" targetId={index.toString()}>
            <span>{module.name}</span>
            <span>{`${module.lessons.length} ${translate('lessons')} - ${
              module.moduleLength
            } ${translate('minutes')}`}</span>
            <span></span>
          </AccordionHeader>

          <AccordionBody className="module-accordion-body" accordionId={index.toString()}>
            <div className="description">{module.description}</div>

            <div className="lessons">
              {module.lessons.map((lessonObj, index) => (
                <>
                  <div>
                    <box-icon
                      name={lessonObj.hasVideo ? 'caret-right-circle' : 'file'}
                      size="1.5em"
                      color="#484848"
                    />
                    <span>
                      {lessonObj.isLinkable ? (
                        <Link to={`${courseParam}/lesson?id=${lessonObj.id}`}>
                          {lessonObj.name}
                        </Link>
                      ) : (
                        lessonObj.name
                      )}
                    </span>
                    {lessonObj.isLocked && (
                      <span className="lock">
                        <box-icon name="lock-alt" size="1.5em" color="#495057" />
                      </span>
                    )}
                    <span
                      className={`minutes ${!lessonObj.isLocked ? 'no-lock' : ''}`}
                    >{`${lessonObj.normalizedMins}`}</span>
                  </div>
                  {index < module.lessons.length - 1 && <hr />}
                </>
              ))}
            </div>
          </AccordionBody>
        </AccordionItem>
      ))}
    </ModulesAccordion>
  );
};

ModulesTable.displayName = 'Modules Table Component';

ModulesTable.propTypes = {
  data: PropTypes.object,
  library: PropTypes.object,
};

export default ModulesTable;

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { ToastContainer } from 'react-toastify';

import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import { AuthProvider } from './hooks/useAuth';

const app = (
  <AuthProvider>
    <CookiesProvider>
      <BrowserRouter>
        <ToastContainer />
        <App />
      </BrowserRouter>
    </CookiesProvider>
  </AuthProvider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();

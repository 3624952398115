const formatKeywords = (rawKeywordText) => {
  if (!rawKeywordText) return [[], []];

  const keywordArr = [];
  const tagsArr = new Set();

  rawKeywordText.split('\n').forEach((line) => {
    let formattedKeyword = '';
    const [keyword, ...tags] = line.split(',').map((word) => word.trim());
    if (!keyword) return;
    formattedKeyword += `${keyword}`;

    if (tags.length) {
      formattedKeyword += '|t_';
      tags.forEach((tag, index) => {
        const formattedTag = tag.replaceAll('_', '-');
        tagsArr.add(formattedTag);
        if (index === tags.length - 1) {
          formattedKeyword += `${formattedTag}`;
        } else {
          formattedKeyword += `${formattedTag}_`;
        }
      });
    }
    keywordArr.push({
      keyword,
      formattedKeyword,
    });
  });

  return [keywordArr, Array.from(tagsArr)];
};

export { formatKeywords };

import React from 'react';
import PropTypes from 'prop-types';
import { Radar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { ChartRadarStyles } from './styles';

const ChartRadar = ({ data }) => {
  const { t: translate } = useTranslation();

  const labels = ['Content', 'Technology', 'Architeture', 'Report', 'Link Building'];

  const dataChart = {
    labels: labels.map((item) => translate(item)),
    datasets: [
      {
        label: '',
        data: data.map((item) => item.percent),
        backgroundColor: 'rgba(85, 110, 230, 0.8)',
        borderColor: '#556EE6',
        borderWidth: 1,
      },
    ],
  };

  return (
    <ChartRadarStyles>
      <span className="title">{translate('Pillars of action')}</span>
      <Radar
        data={dataChart}
        height={400}
        options={{
          maintainAspectRatio: false,
          scale: {
            pointLabels: {
              fontSize: 16,
              fontColor: '#718096',
            },
            ticks: {
              stepSize: 20,
              callback: function (value) {
                return value + '%';
              },
              max: 100,
            },
          },
          legend: {
            display: false,
          },
          tooltips: {
            callbacks: {
              title: function () {
                return '';
              },
              label: function (tooltipItem, data) {
                return (
                  data.labels[tooltipItem.index] +
                  ': ' +
                  data.datasets[0].data[tooltipItem.index] +
                  '%'
                );
              },
            },
          },
        }}
      />
    </ChartRadarStyles>
  );
};

ChartRadar.propTypes = {
  data: PropTypes.array,
};

export default ChartRadar;

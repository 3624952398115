import React from 'react';
import { getDatabase, ref, update } from 'firebase/database';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { useAuth } from 'hooks/useAuth';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const MenuDropdown = ({ guidelineColumn, guidelineId, isProcessing, googleDocId }) => {
  const { t: translate } = useTranslation();
  const { user } = useAuth();
  const accountId = user.account;

  const isAuthorized = user?.adminStatus === 'Super Admin' || user?.adminStatus === 'Admin';

  const handleCopyURL = () => {
    const url = `${window.location.origin}/guideline/${guidelineId}`;
    navigator.clipboard.writeText(url);
  };

  const handleReturnPreviousStep = async () => {
    const db = getDatabase();
    const guidelineRef = ref(db, `guidelines/${accountId}/${guidelineId}`);

    switch (guidelineColumn) {
      case 'GUIDELINE_APPROVAL':
        await update(guidelineRef, {
          column: 'BACKLOG',
          error: false,
        });
        break;
      case 'CONTENT_STRUCTURE':
        await update(guidelineRef, {
          column: 'GUIDELINE_APPROVAL',
          error: false,
        });
        break;
      case 'PRE_REVIEW':
        await update(guidelineRef, {
          column: 'CONTENT_STRUCTURE',
          error: false,
        });
        break;
      case 'CONTENT_REVIEW':
        await update(guidelineRef, {
          column: 'PRE_REVIEW',
          error: false,
        });
        break;
      case 'INTERNAL_APPROVAL':
        await update(guidelineRef, {
          column: 'CONTENT_REVIEW',
          error: false,
        });
        break;
      case 'CONTENT_APPROVAL':
        await update(guidelineRef, {
          column: 'INTERNAL_APPROVAL',
          error: false,
        });
        break;
      case 'APPROVED':
        await update(guidelineRef, {
          column: 'CONTENT_APPROVAL',
          error: false,
        });
        break;
      case 'PUBLISHED':
        await update(guidelineRef, {
          column: 'APPROVED',
          error: false,
        });
        break;
      default:
        break;
    }
  };

  return (
    <UncontrolledDropdown>
      <DropdownToggle className="btn-menu d-flex align-items-center justify-content-center">
        <i className="bx bx-dots-vertical-rounded" />
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem className="d-flex align-items-center gap-1" onClick={handleCopyURL}>
          <i className="bx bx-copy" /> {translate('Copy URL')}
        </DropdownItem>
        {googleDocId && (
          <DropdownItem
            className="d-flex align-items-center gap-1"
            onClick={() =>
              window.open(`https://docs.google.com/document/d/${googleDocId}`, '_blank')
            }
          >
            <i className="bx bx-file" /> {translate('Open in Google Docs')}
          </DropdownItem>
        )}
        {guidelineColumn !== 'BACKLOG' && isAuthorized && (
          <>
            <DropdownItem divider />
            <DropdownItem
              className="d-flex align-items-center gap-1"
              onClick={handleReturnPreviousStep}
              disabled={isProcessing}
            >
              <i className="bx bx-arrow-back" /> {translate('Return previous step')}
            </DropdownItem>
          </>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

MenuDropdown.propTypes = {
  guidelineColumn: PropTypes.string,
  guidelineId: PropTypes.string,
  isProcessing: PropTypes.bool,
  googleDocId: PropTypes.string,
};

export default MenuDropdown;

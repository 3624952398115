import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const BetaTag = styled.span`
  margin-left: 1em;
  padding: 0.2rem;
  font-size: 0.7rem;
  background-color: #f1b44c;
  color: #865400;
  text-transform: uppercase;
`;

export const QuickMenuLink = styled(Link)`
  padding: 0.2rem 1.5rem !important;
`;

export const SidebarContentStyles = styled.div`
  .link-bold-white {
    font-weight: 500;
    color: #ffffff !important;
  }
`;

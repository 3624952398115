import i18n from 'i18n';

const langToCountry = {
  af: i18n.t('Afrikaans'),
  ak: i18n.t('Akan'),
  sq: i18n.t('Albanian'),
  am: i18n.t('Amharic'),
  ar: i18n.t('Arabic'),
  hy: i18n.t('Armenian'),
  az: i18n.t('Azerbaijani'),
  eu: i18n.t('Basque'),
  be: i18n.t('Belarusian'),
  bem: i18n.t('Bemba'),
  bn: i18n.t('Bengali'),
  bh: i18n.t('Bihari'),
  bs: i18n.t('Bosnian'),
  br: i18n.t('Breton'),
  bg: i18n.t('Bulgarian'),
  km: i18n.t('Cambodian'),
  ca: i18n.t('Catalan'),
  chr: i18n.t('Cherokee'),
  ny: i18n.t('Chichewa'),
  'zh-cn': i18n.t('Chinese (Simplified)'),
  'zh-tw': i18n.t('Chinese (Traditional)'),
  co: i18n.t('Corsican'),
  hr: i18n.t('Croatian'),
  cs: i18n.t('Czech'),
  da: i18n.t('Danish'),
  nl: i18n.t('Dutch'),
  en: i18n.t('English'),
  eo: i18n.t('Esperanto'),
  et: i18n.t('Estonian'),
  ee: i18n.t('Ewe'),
  fo: i18n.t('Faroese'),
  tl: i18n.t('Filipino'),
  fi: i18n.t('Finnish'),
  fr: i18n.t('French'),
  fy: i18n.t('Frisian'),
  gaa: i18n.t('Ga'),
  gl: i18n.t('Galician'),
  ka: i18n.t('Georgian'),
  de: i18n.t('German'),
  el: i18n.t('Greek'),
  gn: i18n.t('Guarani'),
  gu: i18n.t('Gujarati'),
  ht: i18n.t('Haitian Creole'),
  ha: i18n.t('Hausa'),
  haw: i18n.t('Hawaiian'),
  iw: i18n.t('Hebrew'),
  hi: i18n.t('Hindi'),
  hu: i18n.t('Hungarian'),
  is: i18n.t('Icelandic'),
  ig: i18n.t('Igbo'),
  id: i18n.t('Indonesian'),
  ia: i18n.t('Interlingua'),
  ga: i18n.t('Irish'),
  it: i18n.t('Italian'),
  ja: i18n.t('Japanese'),
  jw: i18n.t('Javanese'),
  kn: i18n.t('Kannada'),
  kk: i18n.t('Kazakh'),
  rw: i18n.t('Kinyarwanda'),
  rn: i18n.t('Kirundi'),
  kg: i18n.t('Kongo'),
  ko: i18n.t('Korean'),
  kri: i18n.t('Krio (Sierra Leone)'),
  ku: i18n.t('Kurdish'),
  ckb: i18n.t('Kurdish (Soranî)'),
  ky: i18n.t('Kyrgyz'),
  lo: i18n.t('Laothian'),
  la: i18n.t('Latin'),
  lv: i18n.t('Latvian'),
  ln: i18n.t('Lingala'),
  lt: i18n.t('Lithuanian'),
  loz: i18n.t('Lozi'),
  lg: i18n.t('Luganda'),
  ach: i18n.t('Luo'),
  mk: i18n.t('Macedonian'),
  mg: i18n.t('Malagasy'),
  ms: i18n.t('Malay'),
  ml: i18n.t('Malayalam'),
  mt: i18n.t('Maltese'),
  mi: i18n.t('Maori'),
  mr: i18n.t('Marathi'),
  mfe: i18n.t('Mauritian Creole'),
  mo: i18n.t('Moldavian'),
  mn: i18n.t('Mongolian'),
  'sr-ME': i18n.t('Montenegrin'),
  ne: i18n.t('Nepali'),
  pcm: i18n.t('Nigerian Pidgin'),
  nso: i18n.t('Northern Sotho'),
  no: i18n.t('Norwegian'),
  nn: i18n.t('Norwegian (Nynorsk)'),
  oc: i18n.t('Occitan'),
  or: i18n.t('Oriya'),
  om: i18n.t('Oromo'),
  ps: i18n.t('Pashto'),
  fa: i18n.t('Persian'),
  pl: i18n.t('Polish'),
  pt: i18n.t('Portuguese'),
  'pt-br': i18n.t('Portuguese (Brazil)'),
  'pt-pt': i18n.t('Portuguese (Portugal)'),
  pa: i18n.t('Punjabi'),
  qu: i18n.t('Quechua'),
  ro: i18n.t('Romanian'),
  rm: i18n.t('Romansh'),
  nyn: i18n.t('Runyakitara'),
  ru: i18n.t('Russian'),
  gd: i18n.t('Scots Gaelic'),
  sr: i18n.t('Serbian'),
  sh: i18n.t('Serbo-Croatian'),
  st: i18n.t('Sesotho'),
  tn: i18n.t('Setswana'),
  crs: i18n.t('Seychellois Creole'),
  sn: i18n.t('Shona'),
  sd: i18n.t('Sindhi'),
  si: i18n.t('Sinhalese'),
  sk: i18n.t('Slovak'),
  sl: i18n.t('Slovenian'),
  so: i18n.t('Somali'),
  es: i18n.t('Spanish'),
  'es-419': i18n.t('Spanish (Latin American)'),
  su: i18n.t('Sundanese'),
  sw: i18n.t('Swahili'),
  sv: i18n.t('Swedish'),
  tg: i18n.t('Tajik'),
  ta: i18n.t('Tamil'),
  tt: i18n.t('Tatar'),
  te: i18n.t('Telugu'),
  th: i18n.t('Thai'),
  ti: i18n.t('Tigrinya'),
  to: i18n.t('Tonga'),
  lua: i18n.t('Tshiluba'),
  tum: i18n.t('Tumbuka'),
  tr: i18n.t('Turkish'),
  tk: i18n.t('Turkmen'),
  tw: i18n.t('Twi'),
  ug: i18n.t('Uighur'),
  uk: i18n.t('Ukrainian'),
  ur: i18n.t('Urdu'),
  uz: i18n.t('Uzbek'),
  vi: i18n.t('Vietnamese'),
  cy: i18n.t('Welsh'),
  wo: i18n.t('Wolof'),
  xh: i18n.t('Xhosa'),
  yi: i18n.t('Yiddish'),
  yo: i18n.t('Yoruba'),
  zu: i18n.t('Zulu'),
};

export { langToCountry };

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col, Row, FormGroup, Label } from 'reactstrap';

const LabelGroup = ({
  children,
  htmlFor,
  size,
  column = false,
  mb = 4,
  label = htmlFor,
  required = false,
  style,
  className,
}) => {
  const { t: translate } = useTranslation();

  return (
    <FormGroup cssModule={{ 'mb-3': `mb-${mb}` }} className={className} row>
      {column ? (
        <>
          <Col lg={size ?? 11}>
            {label && (
              <Label htmlFor={htmlFor} className="col-form-label col-lg-12" style={style ?? {}}>
                {`${translate(label)}${required ? ' *' : ''}`}
              </Label>
            )}
            {children}
          </Col>
        </>
      ) : (
        <>
          {label && (
            <Label htmlFor={htmlFor} className="col-form-label col-lg-2" style={style ?? {}}>
              {`${translate(label)}${required ? ' *' : ''}`}
            </Label>
          )}
          <Col lg={size ?? 10}>
            <Row>{children}</Row>
          </Col>
        </>
      )}
    </FormGroup>
  );
};

LabelGroup.propTypes = {
  children: PropTypes.node,
  htmlFor: PropTypes.string.isRequired,
  size: PropTypes.number,
  column: PropTypes.bool,
  mb: PropTypes.number,
  label: PropTypes.string,
  required: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
};

export default LabelGroup;

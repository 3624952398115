import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t: translate } = useTranslation();

  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>
              {new Date().getFullYear()} © {translate('Ecto Digital')}.
            </Col>
            <Col md={6}>
              <p style={{ textAlign: 'right' }}>
                {` ${translate('Created with')} `}
                <i className="mdi mdi-heart text-danger" />
                {` ${translate('in Curitiba')}`}
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;

import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { useAuth } from 'hooks/useAuth';
import { getFirebaseBackend } from 'helpers/firebaseHelper';

const CourseRedirect = () => {
  const { user } = useAuth();
  const firebaseHelper = getFirebaseBackend();
  const navigate = useNavigate();
  const { id: courseId } = useParams();

  const redirectFirstLesson = async (accountId) => {
    const courseObj = await firebaseHelper.getFirebaseCourse(accountId, courseId);

    navigate(`/course/${courseId}/lesson?id=${courseObj.modules[0].lessons[0].id}`);
  };

  const fetchCourseLibrary = async () => {
    const libraryObj = await firebaseHelper.getFirebaseLibraryCourse(user.id, courseId);

    if (!libraryObj) return navigate('/page-404');

    const lastLesson = libraryObj?.lastLesson;

    if (lastLesson) {
      navigate(`/course/${courseId}/lesson?id=${lastLesson}`);
    } else {
      redirectFirstLesson(libraryObj?.account);
    }
  };

  useEffect(() => {
    fetchCourseLibrary();
  }, []);

  return <></>;
};

CourseRedirect.displayName = 'Course Redirect Page';

export default CourseRedirect;

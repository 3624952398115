import React, { useState } from 'react';
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getFirebaseBackend } from 'helpers/firebaseHelper';
import ButtonArchive from '../Button-Archive';

const ModalArchive = ({ modalFunction, modalState, dataMany, dataIndividual }) => {
  const firebaseHelper = getFirebaseBackend();
  const { t: translate } = useTranslation();
  const [reason, setReason] = useState('');

  const archiveGuideline = async (reason) => {
    if (reason) {
      const archive = async (guidelines) => {
        for (const guideline of guidelines) {
          await firebaseHelper.archiveGuideline(guideline.accountId, guideline.guidelineId, reason);
        }
      };
      if (dataMany?.length > 0) {
        await archive(dataMany);
      }
      if (dataIndividual?.length > 0) {
        await archive(dataIndividual);
      }
    } else {
      const archive = async (guidelines) => {
        for (const guideline of guidelines) {
          await firebaseHelper.archiveGuideline(guideline.accountId, guideline.guidelineId);
        }
      };
      if (dataMany?.length > 0) {
        await archive(dataMany);
      }
      if (dataIndividual?.length > 0) {
        await archive(dataIndividual);
      }
    }

    modalFunction();
    setReason('');
  };

  return (
    <Modal isOpen={modalState} toggle={modalFunction} centered>
      <ModalHeader toggle={modalFunction}>{translate('Archive content')}</ModalHeader>
      <ModalBody>
        <Label>
          {translate('Add a comment')} {'('}
          {translate('optional')}
          {')'}
        </Label>
        <Input
          type="textarea"
          name="reason"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          placeholder={translate('Write a comment')}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={modalFunction}>
          {translate('Cancel')}
        </Button>
        <ButtonArchive onClickFunction={() => archiveGuideline(reason)} />
      </ModalFooter>
    </Modal>
  );
};

ModalArchive.propTypes = {
  modalFunction: PropTypes.func.isRequired,
  modalState: PropTypes.bool.isRequired,
  dataMany: PropTypes.array,
  dataIndividual: PropTypes.array,
};

export default ModalArchive;

import { cloneDeep } from 'lodash';

const normalizeSteps = (steps) => {
  if (!steps) return null;

  const stepsCopy = cloneDeep(steps);
  const normalizedSteps = stepsCopy
    .filter(({ step, assignedTo }) => {
      return step && assignedTo;
    })
    .map(({ step, assignedTo }) => {
      return { step, assignedTo: assignedTo.value };
    });

  return normalizedSteps;
};

export { normalizeSteps };

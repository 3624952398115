import React, { useState } from 'react';
import { ContainerLabelDateStyle } from './styles';
import { ButtonGroup, Input } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { formatDate, formatDateInput } from '../../../../utils';

const ContainerLabelDate = ({ date, setDate, watch, setValue, getValues, setIsDatePickerOpen }) => {
  const { t: translate } = useTranslation();
  const [selectedRadio, setSelectedRadio] = useState(null);

  const radioDates = [
    {
      label: translate('Last 7 days'),
      value: '7',
    },
    {
      label: translate('Last 30 days'),
      value: '30',
    },
    {
      label: translate('Last 90 days'),
      value: '90',
    },
    {
      label: translate('This week'),
      value: 'thisWeek',
    },
    {
      label: translate('This month'),
      value: 'thisMonth',
    },
    {
      label: translate('This year'),
      value: 'thisYear',
    },
  ];

  const onChangeRadioDates = (value) => {
    const currentDate = new Date();
    setValue('endDate', currentDate);

    switch (value) {
      case '7':
        setValue('startDate', new Date(currentDate - 7 * 24 * 60 * 60 * 1000));
        break;
      case '30':
        setValue('startDate', new Date(currentDate - 30 * 24 * 60 * 60 * 1000));
        break;
      case '90':
        setValue('startDate', new Date(currentDate - 90 * 24 * 60 * 60 * 1000));
        break;
      case 'thisWeek':
        setValue(
          'startDate',
          new Date(currentDate - (currentDate.getDay() - 1) * 24 * 60 * 60 * 1000),
        );
        break;
      case 'thisMonth':
        const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        setValue('startDate', firstDayOfMonth);
        break;
      case 'thisYear':
        const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1);
        setValue('startDate', firstDayOfYear);
        break;
      default:
        break;
    }

    const formattedDateRange = formatDateInput(getValues('startDate'), getValues('endDate'), '');
    setDate(formattedDateRange);
    setSelectedRadio(value);
  };

  const onChangeCalendarDate = (dates) => {
    const [start, end] = dates;
    const formattedDateRange = formatDateInput(start, end, '');

    setValue('startDate', start);
    setValue('endDate', end);
    setDate(formattedDateRange);
    setSelectedRadio(null);
  };

  return (
    <ContainerLabelDateStyle>
      <div className="container-menu-date" onMouseLeave={() => setIsDatePickerOpen(false)}>
        <div className="menu-date">
          <ButtonGroup className="radio-group">
            {radioDates.map((dateOption, index) => (
              <div
                key={index}
                className="radio-item"
                onClick={() => onChangeRadioDates(dateOption.value)}
              >
                <Input
                  id={dateOption.label}
                  type="radio"
                  name="radioDates"
                  value={dateOption.value}
                  checked={selectedRadio === dateOption.value}
                  onChange={() => onChangeRadioDates(dateOption.value)}
                />
                <label htmlFor={date.label} className="ms-2">
                  {translate(`${dateOption.label}`)}
                </label>
              </div>
            ))}
          </ButtonGroup>

          <DatePicker
            id="date"
            selected={watch('startDate')}
            endDate={watch('endDate')}
            startDate={watch('startDate')}
            selectsRange
            inline
            locale={translate('en-US')}
            onChange={onChangeCalendarDate}
          />
        </div>
        <div className="date-selected">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M7.49826 2.99219V5.99344"
              stroke="#556EE6"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.5019 2.99219V5.99344"
              stroke="#556EE6"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.49826 2.99219V5.99344"
              stroke="#556EE6"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.5019 2.99219V5.99344"
              stroke="#556EE6"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.99925 20.9991H5.99759C4.34004 20.9991 2.99634 19.6554 2.99634 17.9978V7.49344C2.99634 5.83589 4.34004 4.49219 5.99759 4.49219H18.0026C19.6601 4.49219 21.0038 5.83589 21.0038 7.49344V9.99448"
              stroke="#556EE6"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle
              cx="16.5019"
              cy="16.5019"
              r="4.50187"
              stroke="#556EE6"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.2009 14.5156V16.7996H18.0967"
              stroke="#556EE6"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {watch('startDate') && watch('endDate') && (
            <p>{formatDate(new Date(watch('startDate')), translate('date-enUS'))}</p>
          )}
          <p>-</p>
          {watch('endDate') && (
            <p>{formatDate(new Date(watch('endDate')), translate('date-enUS'))}</p>
          )}
        </div>
      </div>
    </ContainerLabelDateStyle>
  );
};

ContainerLabelDate.propTypes = {
  date: PropTypes.string,
  setDate: PropTypes.func.isRequired,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  setIsDatePickerOpen: PropTypes.func,
};

export default ContainerLabelDate;

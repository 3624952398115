import styled from 'styled-components';

export const CardCommentStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  background-color: #f7fafc;

  p {
    margin: 0 !important;
  }

  .title {
    font-size: 13px;
    line-height: normal;
    font-weight: 500;
  }

  .title-done {
    color: #a0aec0;
  }

  .title-not-done {
    color: #7367f0;
  }

  .comment-content-done {
    color: #a0aec0 !important;
  }

  .comment-content-not-done {
    color: #4a5568 !important;
  }

  span {
    font-size: 10px;
    line-height: normal;
    font-weight: 300;
    color: #a6a6a6;
    margin-left: 6px;
  }

  .btn-save {
    width: fit-content;
    height: 36px;
    border: none;
    background-color: #48bb78;
  }

  .btn-save:hover {
    background-color: #25855a;
    border: none;
  }
`;

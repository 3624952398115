import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { Col, Row, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import 'boxicons';

import { useAuth } from 'hooks/useAuth';
import { getFirebaseBackend } from '../../helpers/firebaseHelper';
import PageStructure from '../../components/Page-Structure';
import SuccessModal from '../../components/Success-Modal';
import ErrorModal from '../../components/Error-Modal';
import LabelGroup from '../../components/Label-Group';
import TextInput from '../../components/Text-Input';
import SwitchInput from '../../components/Switch-Input';
import SliderInput from '../../components/Slider-Input';
import ShowComponent from '../../components/Show-Component';
import QuestionsInput from 'components/Questions-Input';

const formConfig = {
  defaultValues: {
    name: '',
    passPercent: false,
    percent: 0,
    scrambleQuestions: false,
    answerKey: false,
    demandCorrection: false,
    questions: [],
  },
  shouldFocusError: true,
  criteriaMode: 'all',
  mode: 'onSubmit',
  reValidateMode: 'onChange',
};

const EditTest = () => {
  const firebaseHelper = getFirebaseBackend();
  const { t: translate } = useTranslation();
  const form = useForm(formConfig);
  const { formState, watch, getValues, setError } = form;
  const { user } = useAuth();
  const accountId = user?.account;
  const [params] = useSearchParams();
  const accountParam = params.get('acc');
  const testParam = params.get('id');
  const [fetchSuccess, setFetchSuccess] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [testKey, setTestKey] = useState(null);

  const closeModalHandle = () => {
    form.reset();
    setSuccessModal(false);
    getTest();
  };

  const getTest = async () => {
    try {
      const test = await firebaseHelper.getFirebaseTest(accountId, testParam);
      form.reset({
        name: test.name,
        passPercent: test.passPercent,
        percent: test.percent,
        scrambleQuestions: test.scrambleQuestions,
        answerKey: test.answerKey,
        demandCorrection: test.demandCorrection,
        questions: test.questions,
      });
      setFetchSuccess(true);
    } catch (error) {
      setErrorModal(true);
      console.error(error);
    }
  };

  const onSubmit = async (data) => {
    if (!getValues('questions').length) {
      return setError('questions', {
        types: {
          required: 'At least one question is required!',
        },
      });
    }

    const processedQuestions = await Promise.all(
      data.questions.map(async (question) => {
        let questionKey = question?.id;
        if (!questionKey) {
          questionKey = await firebaseHelper.newKey(`tests/${accountId}/${testKey}/questions`);
        }
        return { ...question, id: questionKey };
      }),
    );

    const test = {
      accountId,
      id: testParam,
      name: data.name,
      passPercent: data.passPercent,
      percent: data.passPercent ? data.percent : null,
      scrambleQuestions: data.scrambleQuestions,
      answerKey: data.answerKey,
      demandCorrection: data.demandCorrection,
      questions: processedQuestions,
    };
    firebaseHelper.updateFirebaseTest(test).then(() => {
      setSuccessModal(true);
    });
  };

  useEffect(() => {
    getTest();
  }, []);

  return (
    <PageStructure
      metaTitle="Edit Test"
      cardTitle="Edit an existing Test"
      card
      breadcrumbItem="Edit Test"
      breadcrumbTitle="Tests"
    >
      <SuccessModal
        isOpen={successModal}
        onClose={closeModalHandle}
        verb="Created"
        buttonText="See Tests"
        buttonLink="/tests"
      />

      <ErrorModal isOpen={errorModal} />

      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <LabelGroup htmlFor="name" label="Test Information" required>
            <TextInput controlName="name" placeholder="Test Name" mb={4} required />

            <SwitchInput controlName="passPercent" label="Minimum % to pass the test" />
            <ShowComponent condition={watch('passPercent')}>
              <LabelGroup htmlFor="percent" label="Define the minimum %" column>
                <SliderInput
                  controlName="percent"
                  min={0}
                  max={100}
                  step={10}
                  orientation="horizontal"
                  labels={{
                    0: '0',
                    10: '10',
                    20: '20',
                    30: '30',
                    40: '40',
                    50: '50',
                    60: '60',
                    70: '70',
                    80: '80',
                    90: '90',
                    100: '100',
                  }}
                />
              </LabelGroup>
            </ShowComponent>
            <SwitchInput controlName="scrambleQuestions" label="Scramble test questions" />
            <SwitchInput controlName="answerKey" label="Show answer key when user finishes test" />
            <SwitchInput
              controlName="demandCorrection"
              label="Allow user to advance test only when the teacher corrects (Open Questions)"
            />
          </LabelGroup>

          <LabelGroup htmlFor="questions" label="Questions" required>
            <QuestionsInput controlName="questions" />
          </LabelGroup>

          <Row className="justify-content-end">
            <Col lg="10">
              <Button
                type="submit"
                color="success"
                disabled={Object.values(formState.errors).length || !fetchSuccess}
              >
                {translate('Edit Test')}
              </Button>
            </Col>
          </Row>
        </form>
      </FormProvider>
    </PageStructure>
  );
};

EditTest.displayName = 'Edit Test Page';

export default EditTest;

import styled from 'styled-components';

export const ContentTextareaStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  button {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    background-color: #f7fafc;
    border: none;
    color: #4a5568 !important;
    min-width: 218px;
    font-weight: 500;
    height: 40px;
  }
  button:hover {
    background-color: #fff;
    border: 1px solid #e2e8f0;
    color: #4a5568 !important;
  }

  .content-item {
    min-height: 100% !important;
    height: 100% !important;
    width: 100%;
    position: relative;
    z-index: 1;
  }

  .editor-container {
    min-height: 100% !important;
    height: 100% !important;
  }

  .rc-md-editor {
    min-height: 400px !important;
    height: 100% !important;
  }

  .sec-md {
    min-height: 100% !important;
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: #edf2f7;
    margin: 16px 0;
  }

  .header-content-markdown {
    width: 100%;
    height: 56px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #d9d8d9;
    border-left: 1px solid #d9d8d9;
    border-right: 1px solid #d9d8d9;
    background-color: #f2f2f2;
    padding: 0 16px;
    overflow: visible;
  }

  .title-topic {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .btn-recreate-markdown {
    display: flex;
    padding: 0.625rem 0.75rem;
    justify-content: center;
    align-items: center;
    min-width: fit-content;
    gap: 0.5rem;
    border-radius: 0.25rem;
    background: #556ee6;
    color: #fff !important;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
  }
  .btn-recreate-markdown:hover {
    background-color: #3b5bfc;
    color: #fff !important;
    border: none !important;
  }
  .btn-recreate-markdown:active {
    border: none !important;
  }

  .btn-copy-markdown {
    min-width: fit-content;
    display: flex;
    padding: 0.625rem 0.75rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    background-color: transparent;
    color: #4a5568 !important;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
  }
  .btn-copy-markdown:hover {
    background-color: #f7fafc;
    border: 1px solid #f7fafc !important;
  }
  .btn-copy-markdown:active {
    color: #4a5568 !important;
    border: none !important;
  }
  .btn-copy-markdown:focus {
    outline: none !important;
  }

  .tag-text-size {
    position: absolute;
    z-index: 2;
    top: 65px;
    right: 42px;
    color: #343a40;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'hooks/useAuth';
import { getFirebaseBackend } from 'helpers/firebaseHelper';

const ContainerDatePicker = ({
  date,
  id,
  anyDate,
  isDateBegin,
  isDateDelivery,
  isDateImplementation,
}) => {
  const { t: translate } = useTranslation();
  const { user } = useAuth();
  const accountId = user?.account;
  const firebaseHelper = getFirebaseBackend();
  const [newDate, setNewDate] = useState(date);

  const handleDateSave = async (newDate) => {
    try {
      if (isDateBegin) {
        await firebaseHelper.updateTaskDateBegin(accountId, id, newDate);
      }

      if (isDateDelivery) {
        await firebaseHelper.updateTaskDateDelivery(accountId, id, newDate);
      }

      if (isDateImplementation) {
        await firebaseHelper.updateTaskDateImplementation(accountId, id, newDate);
      }
    } catch (error) {
      console.error('Error updating task date:', error);
    }
  };

  const handleDateChange = (newDate) => {
    const timestamp = newDate ? newDate.getTime() : null;
    setNewDate(timestamp);
    handleDateSave(timestamp);
  };

  return (
    <div>
      <DatePicker
        id="date"
        selected={newDate ? new Date(newDate) : null}
        inline
        locale={translate('en-US')}
        onChange={handleDateChange}
        minDate={anyDate ? '' : new Date()}
      />
    </div>
  );
};

ContainerDatePicker.propTypes = {
  date: PropTypes.instanceOf(Date),
  id: PropTypes.string,
  isDateBegin: PropTypes.bool,
  isDateDelivery: PropTypes.bool,
  isDateImplementation: PropTypes.bool,
  anyDate: PropTypes.bool,
};

export default ContainerDatePicker;

import { getFirebaseBackend } from 'helpers/firebaseHelper';

const loadAccounts = (inputValue) => {
  const firebaseHelper = getFirebaseBackend();

  return new Promise(async (resolve, reject) => {
    const accounts = await firebaseHelper.getAllAccounts();

    // Maps to React Select Object
    let select = Object.entries(accounts)?.map(([accountId, accountInfo]) => ({
      value: accountId,
      label: accountInfo.name,
    }));
    // Sorts Alphabetically
    select?.sort(({ label: firstLabel }, { label: secondLabel }) => {
      const normalizedA = firstLabel
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
      const normalizedB = secondLabel
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();

      return normalizedA > normalizedB ? 1 : normalizedB > normalizedA ? -1 : 0;
    });
    // Filters by input
    if (inputValue) {
      select = select?.filter(({ label }) => {
        // Normalize, remove accents and diacretics and lowercase the strings for comparison
        const normalizedTask = label
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase();
        const normalizedLabel = inputValue
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase();

        return normalizedTask.includes(normalizedLabel);
      });
    }

    resolve(select);
  });
};

export { loadAccounts };

const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const kiloByte = 1024;
  decimals = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const magnitude = Math.floor(Math.log(bytes) / Math.log(kiloByte));

  return (
    parseFloat((bytes / Math.pow(kiloByte, magnitude)).toFixed(decimals)) + ' ' + sizes[magnitude]
  );
};

export { formatBytes };

import MetaTags from 'react-meta-tags';
import React, { useState } from 'react';
import { Row, Col, CardBody, Card, Alert, Container } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import { useAuth } from 'hooks/useAuth';
import logo from '../../assets/images/small-logo.svg';
import LanguageDropdown from '../../components/Language-Dropdown';

const Login = () => {
  const { login } = useAuth();
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const url = new URL(window.location.href);
  const goto = url.searchParams.get('goto') ?? '/';

  const handleValidSubmit = async (event, values) => {
    setError(false);
    login(values.email, values.password, goto)
      .then(() => navigate(goto))
      .catch((error) => setError(error));
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>{translate('Login')} | Ectools</title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div style={{ backgroundColor: 'rgba(242, 242, 242, 0.22)' }}>
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 style={{ color: '#344792' }}>Ectools</h5>
                        <p style={{ color: '#344792' }}>{translate('Your SEO Dashboard.')}</p>
                      </div>
                    </Col>
                    <Col className="col-5" style={{ paddingLeft: '25%' }}>
                      <LanguageDropdown />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <img src={logo} alt={translate("Ectools' Logo")} height="36" />
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      {error && <Alert color="danger">{translate(error)}</Alert>}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label={translate('Email')}
                          className="form-control"
                          placeholder={translate('Enter email')}
                          type="email"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label={translate('Password')}
                          type="password"
                          required
                          placeholder={translate('Enter password')}
                        />
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-block"
                          style={{ backgroundColor: '#344792', color: '#FFF' }}
                          type="submit"
                        >
                          {translate('Log In')}
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          {translate('Forgot your password?')}
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  {`${translate("Don't have an account?")} `}
                  <Link to="/register" className="fw-medium text-primary">
                    {` ${translate('Signup now')} `}
                  </Link>{' '}
                </p>
                <p>
                  © {new Date().getFullYear()} {translate('Ecto Digital')}.
                  {` ${translate('Created with')} `}
                  <i className="mdi mdi-heart text-danger" />
                  {` ${translate('in Curitiba')}`}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;

import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

import Layout from '../layouts/Auth-Layout/';
import { useAuth } from 'hooks/useAuth';

export const AdminRoute = ({ children, role }) => {
  const { user, updateUser } = useAuth();
  const { adminStatus } = user;

  updateUser();

  return adminStatus === role ? <Layout>{children}</Layout> : <Navigate to={-1} />;
};

AdminRoute.propTypes = {
  children: PropTypes.node,
  role: PropTypes.string,
};

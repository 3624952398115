import styled from 'styled-components';
import { Col } from 'reactstrap';

export const QuestionsCol = styled(Col)`
  .questions-btn-menu {
    display: flex;
    gap: 1em;

    > button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      color: #fff;
      background-color: #7367f0;
      border-color: #7367f0;
      font-size: 0.9rem;

      &:hover {
        background-color: #140b74;
        border-color: #140b74;
      }
    }

    .reset-questions-btn {
      color: #f46a6a !important;
      background-color: transparent;
      border-color: transparent;
      fill: #f46a6a !important;

      &:hover {
        color: #a60c0c !important;
        background-color: transparent;
        border-color: transparent;
        fill: #a60c0c !important;
      }
    }
  }
`;

export const QuestionBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1em;
  background-color: #fafafa;
  border-radius: 5px;
  padding: 1.5rem;

  .question-header-div {
    display: flex;
    justify-content: center;
    align-items: center;

    > span {
      width: max-content;
      color: #808080;
      font-size: 0.85rem;
    }

    > button {
      display: inline;
      width: max-content;
      margin-left: auto;
      padding: 0;
      border: 0;
      background-color: transparent;
    }
  }

  label {
    color: #808080;
    font-size: 0.85rem;
  }

  .question-info-div {
    display: flex;
    gap: 1em;

    > div:nth-child(1) {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 3rem;
      width: 3rem;
      font-size: 1.5rem;
      background-color: #fff;
      border: 1px solid #d3d3d3;
      padding: 1rem;
      user-select: none;
      cursor: move;
    }

    > div:nth-child(2) {
      width: 100%;
    }
  }
`;

import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Label, Input } from 'reactstrap';
import { useFormContext, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

const PhotoPicker = ({
  controlName,
  mb = 0,
  label,
  required = false,
  size = 12,
  validation = {},
}) => {
  const { t: translate } = useTranslation();
  const { control, setValue, getValues, watch } = useFormContext();
  const [file, setFile] = useState(null);
  const imageRef = useRef();
  const ghostRef = useRef();

  const validate = required ? { ...validation, required: 'Required Field!' } : validation;

  const switchOffImage = () => {
    document.getElementById('file-input').value = null;
    setValue(controlName, null);
    ghostRef.current.style.display = 'inline';
    imageRef.current.style.display = 'none';
  };

  const switchOnImage = (photoFile) => {
    setValue(controlName, photoFile);
    imageRef.current.style.display = 'inline';
    imageRef.current.src = URL.createObjectURL(photoFile);
    ghostRef.current.style.display = 'none';
  };

  const handleFileSubmit = (photoFile) => {
    if (photoFile) {
      const isExtensionRight =
        photoFile.type === 'image/png' ||
        photoFile.type === 'image/jpg' ||
        photoFile.type === 'image/jpeg';
      isExtensionRight
        ? switchOnImage(photoFile)
        : (document.getElementById('file-input').value = null);
    } else {
      switchOffImage();
    }
  };

  useEffect(() => {
    const file = getValues(controlName);
    if (file) {
      switchOnImage(file);
    } else {
      switchOffImage();
    }
  }, [watch(controlName)]);

  return (
    <Col lg={size} className={`d-flex mb-${mb} gap-4`}>
      <div
        style={{
          aspectRatio: '1/1',
          width: '7rem',
          border: '1px solid #CED4DA',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '4px',
        }}
      >
        <i
          ref={ghostRef}
          id="ghost-icon"
          className="bx bx-ghost"
          style={{ fontSize: '4rem', color: '#D2D2D2' }}
        ></i>

        <img
          ref={imageRef}
          id="file-display"
          width="90%"
          style={file === null ? { display: 'none' } : { display: 'inline', maxHeight: '90%' }}
        ></img>
      </div>

      <Col style={{ width: '100%' }} className="d-flex justify-content-center align-items-center">
        {label && <Label className="col-form-label col-lg-12">{translate(label)}</Label>}

        <Controller
          control={control}
          name={controlName}
          rules={validate}
          render={() => (
            <Input
              id="file-input"
              className="form-control"
              type="file"
              onChange={(event) => handleFileSubmit(event.target.files[0])}
              accept="image/png, image/jpg, image/jpeg"
            />
          )}
        />
      </Col>
    </Col>
  );
};

PhotoPicker.displayName = 'Photo Picker Component';

PhotoPicker.propTypes = {
  controlName: PropTypes.string,
  label: PropTypes.string,
  mb: PropTypes.number,
  required: PropTypes.bool,
  size: PropTypes.number,
  validation: PropTypes.object,
};

export default PhotoPicker;

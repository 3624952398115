import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import SwitchInput from 'components/Switch-Input';
import RadioInput from 'components/Radio-Input';
import CheckboxInput from 'components/Checkbox-Input';
import DateInput from 'components/Date-Input';
import SpinnerInput from 'components/Spinner-Input';
import { TabWrapper, AvailabilityConfig } from './styles';

const AvailabilityTab = () => {
  const { t: translate } = useTranslation();
  const { watch } = useFormContext();
  const nowRadioRef = useRef();
  const dateRadioRef = useRef();

  return (
    <TabWrapper>
      <div className="mb-2">
        {translate(
          'As you checked this course available for a external public, the course home page can be acessed by users that are not on your account. On the below configuration, define the display rules.',
        )}
      </div>

      <h4>{translate('Visibility')}</h4>

      <SwitchInput
        controlName="showOtherUsers"
        label="Display the course for other users of the dashboard"
        round
      />

      <h4 className="mt-2">{translate('When the course is available')}</h4>

      <RadioInput ref={nowRadioRef} controlName="availability" id="now" label="Available Now" />
      <RadioInput ref={dateRadioRef} controlName="availability" id="date" label="On date" />

      {watch('availability') === 'date' && (
        <>
          <AvailabilityConfig className="ms-4 mb-2">
            <label style={{ width: 'auto' }}>{translate('Make available on')}</label>
            <DateInput
              controlName="releaseDate"
              placeholder="Select Date"
              minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
              filterTime
              dateFormat="dd/MM/yyyy HH:mm"
              size={5}
              withTime
            />
          </AvailabilityConfig>

          <CheckboxInput
            controlName="soon"
            className="ms-4"
            label="Display the course as 'Coming soon' on the course homepage"
            justifyCenter={false}
          />
        </>
      )}

      <h4 className="mt-2">{translate('Certificate')}</h4>

      <CheckboxInput
        controlName="certificateLock"
        className="ms-4"
        label="Issue certificate only some days after course start"
        justifyCenter={false}
      />

      {watch('certificateLock') && (
        <div className="ms-5 mt-2">
          <SpinnerInput
            controlName="certificateDays"
            min={1}
            step={1}
            max={30}
            label="Days after the course start in which the student is able to get his certificate."
          />
        </div>
      )}
    </TabWrapper>
  );
};

AvailabilityTab.displayName = 'Availability Tab Component';

AvailabilityTab.propTypes = {};

export default AvailabilityTab;

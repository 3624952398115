import styled from 'styled-components';

export const KanbanCardGuidelineLayout = styled.div`
  background-color: #e2e8f0;

  .card-responsive {
    margin: 0;
    width: 100%;
    border-bottom: 1px solid #e2e8f0;
    border-radius: 0;
  }

  .border-top-color-PRODUCTION {
    border-top: 0.25rem solid #f2994a;
  }
  .border-top-color-PRODUCTION_AND_UNIFICATION {
    border-top: 0.25rem solid #f2c94c;
  }
  .border-top-color-REVIEW {
    border-top: 0.25rem solid #2d9cdb;
  }
  .border-top-color-REVIEW_AND_DIRECTIONS {
    border-top: 0.25rem solid #bb6bd9;
  }

  .card-body {
    max-width: 350px;
  }

  .card-title-guideline {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #1a202c;
    margin: 0;
  }

  .avatar-group-item {
    margin-top: 16px;
  }

  .btn-card {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    padding: 10px 12px;
    border-radius: 4px;
    border: none;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    color: #fff;
    align-self: flex-end;
  }

  .btn-menu {
    width: 1.5rem;
    height: 1.5rem;
    background-color: transparent;
    color: #74788d !important;
    font-size: 1rem;
    font-weight: 600;
    border: none;
  }
  .btn-menu:hover {
    background-color: #f8f8fb;
  }

  .continue {
    background-color: #556ee6;
  }
  .continue:hover {
    background-color: #3b5bfc;
  }

  .loading {
    background-color: #556ee6;
    opacity: 0.5;
  }

  .try-again {
    background-color: #f56565;
  }
  .try-again:hover {
    background-color: #c53030;
  }

  .approval {
    background-color: #48bb78;
  }
  .approval:hover {
    background-color: #25855a;
  }

  .badge-number {
    display: flex;
    height: 1.5rem;
    padding: 0.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    border-radius: 0.25rem;
    background-color: #f8f8fb !important;
    max-width: max-content;
    color: #343a40;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .badge-type {
    display: flex;
    height: 1.5rem;
    padding: 0.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    border-radius: 0.25rem;
    color: #fff;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .badge-type-PRODUCTION {
    background-color: #f2994a !important;
  }
  .badge-type-PRODUCTION_AND_UNIFICATION {
    background-color: #f2c94c !important;
  }
  .badge-type-REVIEW {
    background-color: #2d9cdb !important;
  }
  .badge-type-REVIEW_AND_DIRECTIONS {
    background-color: #bb6bd9 !important;
  }

  .dropdown-menu {
    border: 1px solid #e2e8f0;
  }
`;

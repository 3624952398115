import i18n from 'i18n';

const domainToCountry = {
  'google.ad': i18n.t('Andorra') + ' - google.ad',
  'google.ae': i18n.t('United Arab Emirates') + ' - google.ae',
  'google.al': i18n.t('Albania') + ' - google.al',
  'google.am': i18n.t('Armenia') + ' - google.am',
  'google.as': i18n.t('American Samoa') + ' - google.as',
  'google.at': i18n.t('Austria') + ' - google.at',
  'google.az': i18n.t('Azerbaijan') + ' - google.az',
  'google.ba': i18n.t('Bosnia and Herzegovina') + ' - google.ba',
  'google.be': i18n.t('Belgium') + ' - google.be',
  'google.bf': i18n.t('Burkina Faso') + ' - google.bf',
  'google.bg': i18n.t('Bulgaria') + ' - google.bg',
  'google.bi': i18n.t('Burundi') + ' - google.bi',
  'google.bj': i18n.t('Benin') + ' - google.bj',
  'google.bs': i18n.t('Bahamas') + ' - google.bs',
  'google.bt': i18n.t('Bhutan') + ' - google.bt',
  'google.by': i18n.t('Belarus') + ' - google.by',
  'google.ca': i18n.t('Canada') + ' - google.ca',
  'google.cc': i18n.t('Cocos (Keeling) Islands') + ' - google.cc',
  'google.cd': i18n.t('Congo, the Democratic Republic of the') + ' - google.cd',
  'google.cf': i18n.t('Central African Republic') + ' - google.cf',
  'google.cg': i18n.t('Congo') + ' - google.cg',
  'google.ch': i18n.t('Switzerland') + ' - google.ch',
  'google.ci': i18n.t('Ivory Coast') + ' - google.ci',
  'google.cl': i18n.t('Chile') + ' - google.cl',
  'google.cm': i18n.t('Cameroon') + ' - google.cm',
  'google.cn': i18n.t('China') + ' - google.cn',
  'google.co.ao': i18n.t('Angola') + ' - google.co.ao',
  'google.co.bw': i18n.t('Botswana') + ' - google.co.bw',
  'google.co.ck': i18n.t('Cook Islands') + ' - google.co.ck',
  'google.co.cr': i18n.t('Costa Rica') + ' - google.co.cr',
  'google.co.id': i18n.t('Indonesia') + ' - google.co.id',
  'google.co.il': i18n.t('Israel') + ' - google.co.il',
  'google.co.in': i18n.t('India') + ' - google.co.in',
  'google.co.jp': i18n.t('Japan') + ' - google.co.jp',
  'google.co.ke': i18n.t('Kenya') + ' - google.co.ke',
  'google.co.kr': i18n.t('South Korea') + ' - google.co.kr',
  'google.co.ls': i18n.t('Lesotho') + ' - google.co.ls',
  'google.co.ma': i18n.t('Morocco') + ' - google.co.ma',
  'google.co.mz': i18n.t('Mozambique') + ' - google.co.mz',
  'google.co.nz': i18n.t('New Zealand') + ' - google.co.nz',
  'google.co.th': i18n.t('Thailand') + ' - google.co.th',
  'google.co.tz': i18n.t('Tanzania') + ' - google.co.tz',
  'google.co.ug': i18n.t('Uganda') + ' - google.co.ug',
  'google.co.uk': i18n.t('United Kingdom') + ' - google.co.uk',
  'google.co.uz': i18n.t('Uzbekistan') + ' - google.co.uz',
  'google.co.ve': i18n.t('Venezuela') + ' - google.co.ve',
  'google.co.vi': i18n.t('Virgin Islands, U.S.') + ' - google.co.vi',
  'google.co.za': i18n.t('South Africa') + ' - google.co.za',
  'google.co.zm': i18n.t('Zambia') + ' - google.co.zm',
  'google.co.zw': i18n.t('Zimbabwe') + ' - google.co.zw',
  'google.com': i18n.t('United States') + ' - google.com',
  'google.com.af': i18n.t('Afghanistan') + ' - google.com.af',
  'google.com.ag': i18n.t('Antigua and Barbuda') + ' - google.com.ag',
  'google.com.ai': i18n.t('Anguilla') + ' - google.com.ai',
  'google.com.ar': i18n.t('Argentina') + ' - google.com.ar',
  'google.com.au': i18n.t('Australia') + ' - google.com.au',
  'google.com.bd': i18n.t('Bangladesh') + ' - google.com.bd',
  'google.com.bh': i18n.t('Bahrain') + ' - google.com.bh',
  'google.com.bn': i18n.t('Brunei') + ' - google.com.bn',
  'google.com.bo': i18n.t('Bolivia') + ' - google.com.bo',
  'google.com.br': i18n.t('Brazil') + ' - google.com.br',
  'google.com.bz': i18n.t('Belize') + ' - google.com.bz',
  'google.com.co': i18n.t('Colombia') + ' - google.com.co',
  'google.com.cu': i18n.t('Cuba') + ' - google.com.cu',
  'google.com.cy': i18n.t('Cyprus') + ' - google.com.cy',
  'google.com.do': i18n.t('Dominican Republic') + ' - google.com.do',
  'google.com.ec': i18n.t('Ecuador') + ' - google.com.ec',
  'google.com.eg': i18n.t('Egypt') + ' - google.com.eg',
  'google.com.et': i18n.t('Ethiopia') + ' - google.com.et',
  'google.com.fj': i18n.t('Fiji') + ' - google.com.fj',
  'google.com.gh': i18n.t('Ghana') + ' - google.com.gh',
  'google.com.gi': i18n.t('Gibraltar') + ' - google.com.gi',
  'google.com.gt': i18n.t('Guatemala') + ' - google.com.gt',
  'google.com.hk': i18n.t('Hong Kong') + ' - google.com.hk',
  'google.com.jm': i18n.t('Jamaica') + ' - google.com.jm',
  'google.com.kh': i18n.t('Cambodia') + ' - google.com.kh',
  'google.com.kw': i18n.t('Kuwait') + ' - google.com.kw',
  'google.com.lb': i18n.t('Lebanon') + ' - google.com.lb',
  'google.com.ly': i18n.t('Libya') + ' - google.com.ly',
  'google.com.mm': i18n.t('Myanmar') + ' - google.com.mm',
  'google.com.mt': i18n.t('Malta') + ' - google.com.mt',
  'google.com.mx': i18n.t('Mexico') + ' - google.com.mx',
  'google.com.my': i18n.t('Malaysia') + ' - google.com.my',
  'google.com.na': i18n.t('Namibia') + ' - google.com.na',
  'google.com.ng': i18n.t('Nigeria') + ' - google.com.ng',
  'google.com.ni': i18n.t('Nicaragua') + ' - google.com.ni',
  'google.com.np': i18n.t('Nepal') + ' - google.com.np',
  'google.com.om': i18n.t('Oman') + ' - google.com.om',
  'google.com.pa': i18n.t('Panama') + ' - google.com.pa',
  'google.com.pe': i18n.t('Peru') + ' - google.com.pe',
  'google.com.pg': i18n.t('Papua New Guinea') + ' - google.com.pg',
  'google.com.ph': i18n.t('Philippines') + ' - google.com.ph',
  'google.com.pk': i18n.t('Pakistan') + ' - google.com.pk',
  'google.com.pr': i18n.t('Puerto Rico') + ' - google.com.pr',
  'google.com.py': i18n.t('Paraguay') + ' - google.com.py',
  'google.com.qa': i18n.t('Qatar') + ' - google.com.qa',
  'google.com.sa': i18n.t('Saudi Arabia') + ' - google.com.sa',
  'google.com.sb': i18n.t('Solomon Islands') + ' - google.com.sb',
  'google.com.sg': i18n.t('Singapore') + ' - google.com.sg',
  'google.com.sl': i18n.t('Sierra Leone') + ' - google.com.sl',
  'google.com.sv': i18n.t('El Salvador') + ' - google.com.sv',
  'google.com.tj': i18n.t('Tajikistan') + ' - google.com.tj',
  'google.com.tr': i18n.t('Turkey') + ' - google.com.tr',
  'google.com.tw': i18n.t('Taiwan') + ' - google.com.tw',
  'google.com.ua': i18n.t('Ukraine') + ' - google.com.ua',
  'google.com.uy': i18n.t('Uruguay') + ' - google.com.uy',
  'google.com.vc': i18n.t('Saint Vincent and the Grenadines') + ' - google.com.vc',
  'google.com.vn': i18n.t('Vietnam') + ' - google.com.vn',
  'google.cv': i18n.t('Cape Verde') + ' - google.cv',
  'google.cx': i18n.t('Christmas Island') + ' - google.cx',
  'google.cz': i18n.t('Czech Republic') + ' - google.cz',
  'google.de': i18n.t('Germany') + ' - google.de',
  'google.dj': i18n.t('Djibouti') + ' - google.dj',
  'google.dk': i18n.t('Denmark') + ' - google.dk',
  'google.dm': i18n.t('Dominica') + ' - google.dm',
  'google.dz': i18n.t('Algeria') + ' - google.dz',
  'google.ee': i18n.t('Estonia') + ' - google.ee',
  'google.es': i18n.t('Spain') + ' - google.es',
  'google.fi': i18n.t('Finland') + ' - google.fi',
  'google.fm': i18n.t('Micronesia') + ' - google.fm',
  'google.fr': i18n.t('France') + ' - google.fr',
  'google.ga': i18n.t('Gabon') + ' - google.ga',
  'google.ge': i18n.t('Georgia') + ' - google.ge',
  'google.gf': i18n.t('French Guiana') + ' - google.gf',
  'google.gl': i18n.t('Greenland') + ' - google.gl',
  'google.gm': i18n.t('Gambia') + ' - google.gm',
  'google.gp': i18n.t('Guadeloupe') + ' - google.gp',
  'google.gr': i18n.t('Greece') + ' - google.gr',
  'google.gy': i18n.t('Guyana') + ' - google.gy',
  'google.hn': i18n.t('Honduras') + ' - google.hn',
  'google.hr': i18n.t('Croatia') + ' - google.hr',
  'google.ht': i18n.t('Haiti') + ' - google.ht',
  'google.hu': i18n.t('Hungary') + ' - google.hu',
  'google.ie': i18n.t('Ireland') + ' - google.ie',
  'google.io': i18n.t('British Indian Ocean Territory') + ' - google.io',
  'google.iq': i18n.t('Iraq') + ' - google.iq',
  'google.is': i18n.t('Iceland') + ' - google.is',
  'google.it': i18n.t('Italy') + ' - google.it',
  'google.jo': i18n.t('Jordan') + ' - google.jo',
  'google.kg': i18n.t('Kyrgyzstan') + ' - google.kg',
  'google.ki': i18n.t('Kiribati') + ' - google.ki',
  'google.kz': i18n.t('Kazakhstan') + ' - google.kz',
  'google.la': i18n.t('Laos') + ' - google.la',
  'google.li': i18n.t('Liechtenstein') + ' - google.li',
  'google.lk': i18n.t('Sri Lanka') + ' - google.lk',
  'google.lt': i18n.t('Lithuania') + ' - google.lt',
  'google.lu': i18n.t('Luxembourg') + ' - google.lu',
  'google.lv': i18n.t('Latvia') + ' - google.lv',
  'google.md': i18n.t('Moldova') + ' - google.md',
  'google.mg': i18n.t('Madagascar') + ' - google.mg',
  'google.mk': i18n.t('Macedonia') + ' - google.mk',
  'google.ml': i18n.t('Mali') + ' - google.ml',
  'google.mn': i18n.t('Mongolia') + ' - google.mn',
  'google.ms': i18n.t('Montserrat') + ' - google.ms',
  'google.mu': i18n.t('Mauritius') + ' - google.mu',
  'google.mv': i18n.t('Maldives') + ' - google.mv',
  'google.mw': i18n.t('Malawi') + ' - google.mw',
  'google.ne': i18n.t('Niger') + ' - google.ne',
  'google.nf': i18n.t('Norfolk Island') + ' - google.nf',
  'google.nl': i18n.t('Netherlands') + ' - google.nl',
  'google.no': i18n.t('Norway') + ' - google.no',
  'google.nr': i18n.t('Nauru') + ' - google.nr',
  'google.nu': i18n.t('Niue') + ' - google.nu',
  'google.pl': i18n.t('Poland') + ' - google.pl',
  'google.ps': i18n.t('Palestine') + ' - google.ps',
  'google.pt': i18n.t('Portugal') + ' - google.pt',
  'google.ro': i18n.t('Romania') + ' - google.ro',
  'google.rs': i18n.t('Serbia and Montenegro') + ' - google.rs',
  'google.ru': i18n.t('Russia') + ' - google.ru',
  'google.rw': i18n.t('Rwanda') + ' - google.rw',
  'google.sc': i18n.t('Seychelles') + ' - google.sc',
  'google.se': i18n.t('Sweden') + ' - google.se',
  'google.sh': i18n.t('Saint Helena, Ascension and Tristan da Cunha') + ' - google.sh',
  'google.si': i18n.t('Slovenia') + ' - google.si',
  'google.sk': i18n.t('Slovakia') + ' - google.sk',
  'google.sm': i18n.t('San Marino') + ' - google.sm',
  'google.sn': i18n.t('Senegal') + ' - google.sn',
  'google.so': i18n.t('Somalia') + ' - google.so',
  'google.sr': i18n.t('Suriname') + ' - google.sr',
  'google.td': i18n.t('Chad') + ' - google.td',
  'google.tg': i18n.t('Togo') + ' - google.tg',
  'google.tk': i18n.t('Tokelau') + ' - google.tk',
  'google.tl': i18n.t('Timor-Leste') + ' - google.tl',
  'google.tm': i18n.t('Turkmenistan') + ' - google.tm',
  'google.tn': i18n.t('Tunisia') + ' - google.tn',
  'google.to': i18n.t('Tonga') + ' - google.to',
  'google.tt': i18n.t('Trinidad and Tobago') + ' - google.tt',
  'google.vg': i18n.t('Virgin Islands, British') + ' - google.vg',
  'google.vu': i18n.t('Vanuatu') + ' - google.vu',
  'google.ws': i18n.t('Samoa') + ' - google.ws',
};

export { domainToCountry };

import { getFirebaseBackend } from 'helpers/firebaseHelper';

const loadTasks = (accountId, inputValue) => {
  if (!accountId) return;

  const firebaseHelper = getFirebaseBackend();

  return new Promise(async (resolve, reject) => {
    const tasks = await firebaseHelper.getAllTasks(accountId, '1');

    // Maps to React Select Object
    let selectTasks = Object.entries(tasks)?.map(([id, { name }]) => {
      return { value: id, label: name };
    });
    // Sorts Alphabetically
    selectTasks?.sort(({ label: firstLabel }, { label: secondLabel }) => {
      const normalizedA = firstLabel
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
      const normalizedB = secondLabel
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();

      return normalizedA > normalizedB ? 1 : normalizedB > normalizedA ? -1 : 0;
    });
    // Filters by input
    if (inputValue) {
      selectTasks = selectTasks?.filter(({ label }) => {
        // Normalize, remove accents and diacretics and lowercase the strings for comparison
        const normalizedTask = label
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase();
        const normalizedLabel = inputValue
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase();

        return normalizedTask.includes(normalizedLabel);
      });
    }

    resolve(selectTasks);
  });
};

export { loadTasks };

import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ButtonFilterStyle } from './styles';
import { Button } from 'reactstrap';

const ButtonFilter = ({ handleToggle, filterTotal }) => {
  const { t: translate } = useTranslation();

  return (
    <ButtonFilterStyle>
      <Button onClick={handleToggle}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M12 3.33333H14"
            stroke="#A0AEC0"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2 3.33333H9.33333"
            stroke="#A0AEC0"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.66675 7.9974H14.0001"
            stroke="#A0AEC0"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2 7.9974H4"
            stroke="#A0AEC0"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 12.6693H14"
            stroke="#A0AEC0"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2 12.6693H9.33333"
            stroke="#A0AEC0"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.27614 7.05459C6.79684 7.57529 6.79684 8.41951 6.27614 8.94021C5.75545 9.46091 4.91122 9.46091 4.39052 8.94021C3.86983 8.41951 3.86983 7.57529 4.39052 7.05459C4.91122 6.53389 5.75544 6.53389 6.27614 7.05459"
            stroke="#A0AEC0"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.6094 11.7265C12.1301 12.2472 12.1301 13.0914 11.6094 13.6121C11.0887 14.1328 10.2445 14.1328 9.72378 13.6121C9.20308 13.0914 9.20308 12.2472 9.72378 11.7265C10.2445 11.2058 11.0887 11.2058 11.6094 11.7265"
            stroke="#A0AEC0"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        {filterTotal
          ? translate('Filters') + (filterTotal() > 0 ? ` (${filterTotal()})` : '')
          : translate('Filters')}
      </Button>
    </ButtonFilterStyle>
  );
};

ButtonFilter.propTypes = {
  handleToggle: PropTypes.func.isRequired,
  filterTotal: PropTypes.func,
};

export default ButtonFilter;

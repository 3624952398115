import styled from 'styled-components';

export const GuidelinesManageFilterLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  padding: 24px 32px;
  border-radius: 6px;
  background-color: #f7fafc;
  margin-bottom: 32px;

  .menu-inline {
    display: flex;
    gap: 32px;
    align-items: flex-end;
  }
  .menu-column {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .filter-label {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #4a5568;
  }

  .filter-select {
    width: 100%;
    min-width: 240px !important;
  }

  .menu-label-date {
    position: absolute;
    left: 416px;
    top: 300px;
  }

  #input-date {
    cursor: pointer;
    caret-color: transparent;
  }

  .filter-btn {
    display: flex;
    gap: 8px;

    button {
      display: flex;
      align-items: center;
      padding: 10px 12px;
      border: none;
      height: 39.5px;
    }

    .filter-btn-apply {
      background-color: #556ee6 !important;
    }

    .filter-btn-apply:hover {
      background-color: #3b5bfc !important;
    }

    .filter-btn-clear {
      background-color: #d69e2e !important;
    }

    .filter-btn-clear:hover {
      background-color: #b7791f !important;
    }

    .filter-btn-back {
      background-color: #f56565 !important;
    }
    .filter-btn-back:hover {
      background-color: #c53030 !important;
    }
  }
`;

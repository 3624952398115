import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card } from 'reactstrap';
import md5 from 'md5';
import _ from 'lodash';

import { useAuth } from 'hooks/useAuth';
import { getFirebaseBackend } from 'helpers/firebaseHelper';
import PageStructure from 'components/Page-Structure';
import ShowComponent from 'components/Show-Component';
import Shimmer from 'components/Shimmer';
import ErrorModal from 'components/Error-Modal';
import LessonsList from './components/LessonsList';
import { copyToClipBoard } from 'utils';
import { LessonLayout, LessonMenu } from './styles';

const WHATSAPP_LINK = 'https://api.whatsapp.com/send?text=';
const FACEBOOK_LINK = 'https://www.facebook.com/sharer/sharer.php?u=';
const LINKEDIN_LINK = 'https://www.linkedin.com/sharing/share-offsite/?url=';

// const styles = StyleSheet.create({
//   page: {
//     flexDirection: 'row',
//     backgroundColor: '#E4E4E4'
//   },
//   section: {
//     margin: 10,
//     padding: 10,
//     flexGrow: 1
//   }
// })

// const MyDocument = () => (
//   <Document>
//     <Page size="A4" orientation="landscape" style={styles.page}>
//       <View style={styles.section}>
//         <Text>Section #1</Text>
//       </View>
//       <View style={styles.section}>
//         <Text>Section #2</Text>
//       </View>
//     </Page>
//   </Document>
// )

const Certificate = () => {
  const { user } = useAuth();
  const firebaseHelper = getFirebaseBackend();
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const { id: courseId } = useParams();
  const [certificate, setCertificate] = useState(null);
  const [course, setCourse] = useState(null);
  const [library, setLibrary] = useState(null);
  const [progress, setProgress] = useState([]);
  const [current, setCurrent] = useState(-1);
  const [publicLink, setPublicLink] = useState('');
  const [loading, setLoading] = useState(true);
  const [changeLoading, setChangeLoading] = useState(false);
  const [menuOpen, setMenuOpen] = useState(true);
  const [errorModal, setErrorModal] = useState(false);

  const courseProgress = (courseObj) => {
    const lessons = [];
    let index = 0;

    courseObj.modules.forEach((module) => {
      module.lessons.forEach((lesson) => {
        lessons.push({
          id: lesson.id,
          freeTrial: lesson.freeTrial,
          index,
        });
        index++;
      });
    });

    return [lessons, -1];
  };

  const progressPercent = () => {
    const progress = library?.progress;

    if (!progress) {
      return 0;
    } else {
      const currentProgress = progress.length;
      let courseLength = 0;

      Object.values(course?.modules || {}).forEach((module) => {
        module.lessons.forEach(() => {
          courseLength++;
        });
      });

      const percent = Math.floor((currentProgress / courseLength) * 100);
      return percent;
    }
  };

  const fetchCourseAndLibrary = async () => {
    const libraryObj = await firebaseHelper.getFirebaseLibraryCourse(user.id, courseId);

    if (!libraryObj) navigate('/page-404');
    setLibrary(libraryObj);

    if (!libraryObj?.account) return;

    const courseObj = await firebaseHelper.getFirebaseCourse(libraryObj.account, courseId);

    if (!courseObj) return setErrorModal(true);
    const [progress, currentIndex] = courseProgress(courseObj);

    if (libraryObj.progress.length < progress.length) navigate('/page-404');

    setProgress(progress);
    setCurrent(currentIndex);
    setCourse(courseObj);

    setChangeLoading(false);
  };

  const fetchCertificate = async () => {
    const cutCourse = courseId.substring(courseId.length - 4, courseId.length);
    const cutUser = user.id.substring(user.id.length - 4, user.id.length);
    const hash = md5(courseId + user.id);
    const cutHash = hash.substring(hash.length - 4, hash.length);
    const certificateCode = `${cutCourse}-${cutUser}-${cutHash}-`;
    const certificateObj = await firebaseHelper.getFirebaseCertificate(certificateCode);

    setCertificate(certificateObj);
    setLoading(false);
  };

  useEffect(() => {
    try {
      fetchCourseAndLibrary();
      fetchCertificate();
    } catch (error) {
      console.error(error);
      setErrorModal(true);
    }
  }, []);

  useEffect(() => {}, [library, course]);

  useEffect(() => {
    if (!certificate) return;
    setPublicLink(`https://ecto.tools/c/${certificate.code}`);
  }, [certificate]);

  return (
    <PageStructure
      metaTitle={'Certificate'}
      breadcrumbItem={'Certificate'}
      breadcrumbTitle="Certificate"
    >
      <LessonLayout open={menuOpen}>
        <ErrorModal isOpen={errorModal} />

        <ShowComponent condition={loading}>
          <Shimmer height="30rem" />
          <Shimmer height="20rem" />
        </ShowComponent>

        <ShowComponent condition={!loading}>
          <ShowComponent condition={changeLoading}>
            <Shimmer height="30rem" />
          </ShowComponent>

          <ShowComponent condition={!changeLoading}>
            <div>
              <Card className="lesson-main">
                <section>
                  <h4>{translate('Congratulations, course completed!')}</h4>
                </section>
                <section>
                  <ShowComponent condition={!certificate}>
                    <div>
                      {translate(
                        'Your course certificate will be emitted in moments. Try again later!',
                      )}
                    </div>
                  </ShowComponent>

                  <ShowComponent condition={certificate?.validBy > new Date().valueOf()}>
                    <div>
                      {translate(
                        'This course has a waiting period before emitting a certificate. Come back in a few days!',
                      )}
                    </div>
                  </ShowComponent>

                  <ShowComponent condition={certificate?.validBy < new Date().valueOf()}>
                    <div style={{ height: 'auto' }}></div>
                  </ShowComponent>
                </section>
              </Card>
            </div>
          </ShowComponent>

          <LessonMenu open={menuOpen}>
            <ShowComponent condition={!menuOpen}>
              <div className="closed-outside">
                <button className="pdf">
                  <box-icon name="download" color="#fff" size="1.7em" />
                </button>

                <button className="png">
                  <box-icon name="download" color="#fff" size="1.7em" />
                </button>

                <a href={WHATSAPP_LINK + encodeURIComponent(publicLink)} rel="noreferrer noopener">
                  <box-icon type="logo" name="whatsapp" color="#fff" size="2em" />
                </a>

                <a href={FACEBOOK_LINK + encodeURIComponent(publicLink)} rel="noreferrer noopener">
                  <box-icon type="logo" name="facebook" color="#fff" size="2em" />
                </a>

                <a href={LINKEDIN_LINK + encodeURIComponent(publicLink)} rel="noreferrer noopener">
                  <box-icon type="logo" name="linkedin" color="#fff" size="2em" />
                </a>
              </div>
            </ShowComponent>

            <ShowComponent condition={menuOpen}>
              <Card>
                <div className="open-column">
                  <h4>{translate('Certificate Download')}</h4>
                  <div className="download-menu">
                    <button className="pdf">
                      <box-icon name="download" color="#fff" size="1.7em" />
                      {translate('.pdf')}
                    </button>

                    <button className="png">
                      <box-icon name="download" color="#fff" size="1.7em" />
                      {translate('.png')}
                    </button>
                  </div>

                  <h4>{translate('Share on social media')}</h4>
                  <div className="social-menu">
                    <a href={WHATSAPP_LINK + publicLink} rel="noreferrer noopener">
                      <box-icon type="logo" name="whatsapp" color="#fff" size="2em" />
                    </a>

                    <a href={FACEBOOK_LINK + publicLink} rel="noreferrer noopener">
                      <box-icon type="logo" name="facebook" color="#fff" size="2em" />
                    </a>

                    <a href={LINKEDIN_LINK + publicLink} rel="noreferrer noopener">
                      <box-icon type="logo" name="linkedin" color="#fff" size="2em" />
                    </a>
                  </div>

                  <h4>{translate('Public Link')}</h4>
                  <div className="link-menu">
                    <input readOnly={true} value={publicLink}></input>
                    <button onClick={(event) => copyToClipBoard(event, publicLink)}>
                      <box-icon name="copy" color="#808080" size="1.3em" />
                    </button>
                  </div>
                </div>
              </Card>
            </ShowComponent>

            <Card>
              <ShowComponent condition={menuOpen}>
                <div className="open-column">
                  <div className="lesson-menu-header">
                    <h4>{translate('Course Lessons')}</h4>
                    <button onClick={() => setMenuOpen(false)}>
                      <box-icon name="chevron-right-square" color="#484848" size="1.5em" />
                    </button>
                  </div>

                  <meter
                    min="0"
                    max="100"
                    value={progressPercent()}
                    title={translate('Course Completion Percentage')}
                    className={`lesson-menu-meter ${progressPercent() === 100 ? 'complete' : ''}`}
                  />

                  <div>
                    <LessonsList
                      courseId={courseId}
                      list={progress}
                      progress={library?.progress}
                      current={current}
                      account={library?.account}
                    />
                  </div>
                </div>
              </ShowComponent>

              <ShowComponent condition={!menuOpen}>
                <div className="closed-column">
                  <button onClick={() => setMenuOpen(true)}>
                    <box-icon name="chevron-left-square" color="#C1C1C1" size="1.5em" />
                  </button>
                  <div className="sideways">{translate('See Course Lessons')}</div>
                </div>
              </ShowComponent>
            </Card>
          </LessonMenu>
        </ShowComponent>
      </LessonLayout>
    </PageStructure>
  );
};

Certificate.displayName = 'Certificate Page';

export default Certificate;

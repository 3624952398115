import React, { useEffect, useState } from 'react';
import { Badge, Collapse, FormGroup, Input, Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useAuth } from 'hooks/useAuth';
import { getFirebaseBackend } from 'helpers/firebaseHelper';

import BadgeDenied from '../Badge-Denied';
import ButtonArchive from '../Button-Archive';
import ButtonDeny from '../Button-Deny';
import ButtonDetails from '../Button-Details';
import ButtonApprove from '../Button-Approve';
import GuidelinesManageFilter from '../Guidelines-Manage-Filter';
import ButtonFilter from '../Button-Filter';

const TablePendentGuidelines = ({
  toggleModalApprove,
  toggleModalDeny,
  toggleModalArchive,
  setGuidelineDataClicked,
  guidelinesDataSelected,
  setGuidelinesDataSelected,
}) => {
  const firebaseHelper = getFirebaseBackend();
  const { t: translate } = useTranslation();
  const { user } = useAuth();
  const [detailIndexIsOpen, setDetailIndexIsOpen] = useState(-1);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isToggleFilter, setIsToggleFilter] = useState(false);
  const [filter, setFilter] = useState(null);
  const [guidelineData, setGuidelineData] = useState([]);

  const toogleDetail = (index) => {
    if (detailIndexIsOpen === index) {
      setDetailIndexIsOpen(-1);
    } else {
      setDetailIndexIsOpen(index);
    }
  };

  const toggleSelectAll = () => {
    setSelectAllChecked((prevSelectAllChecked) => {
      const newValue = !prevSelectAllChecked;

      const hasDeniedGuideline = guidelineData.some((account) =>
        account.guidelines.some((guideline) => guideline.hasOwnProperty('denied')),
      );

      const updatedData = guidelineData.map((account) => ({
        ...account,
        guidelines: account.guidelines.map((guideline) => ({
          ...guideline,
          selected: newValue,
        })),
      }));

      const updatedGuidelinesIdSelected = updatedData.map((account) => {
        return account.guidelines.map((guideline) => {
          return {
            guidelineId: guideline.id,
            accountId: account.accountId,
          };
        });
      });

      if (newValue) {
        setGuidelinesDataSelected(updatedGuidelinesIdSelected.flat());
        setIsButtonDisabled(hasDeniedGuideline);
      } else {
        setGuidelinesDataSelected([]);
        setIsButtonDisabled(true);
      }

      setGuidelineData(updatedData);

      return newValue;
    });
  };

  const toggleSelectIndividual = (accountIndex, indexList) => {
    const updatedData = [...guidelineData];

    updatedData.map((account) => {
      if (account.accountId === accountIndex) {
        const guidelineId = account.guidelines[indexList].id;

        if (account.guidelines[indexList].hasOwnProperty('selected')) {
          account.guidelines[indexList].selected = !account.guidelines[indexList].selected;
        } else {
          account.guidelines[indexList].selected = true;
        }

        if (account.guidelines[indexList].selected) {
          const updatedGuidelinesIdSelected = [
            ...guidelinesDataSelected,
            {
              guidelineId: guidelineId,
              accountId: accountIndex,
            },
          ];
          if (account.guidelines[indexList].hasOwnProperty('denied')) {
            setIsButtonDisabled(true);
          } else {
            setGuidelinesDataSelected(updatedGuidelinesIdSelected);
            setIsButtonDisabled(false);
          }
        } else {
          const updatedGuidelinesIdSelected = guidelinesDataSelected.filter(
            (guideline) => guideline.guidelineId !== guidelineId,
          );
          setGuidelinesDataSelected(updatedGuidelinesIdSelected);
          setIsButtonDisabled(true);
        }
      }
    });

    setGuidelineData(updatedData);
  };

  const filterTotal = () => {
    let count = 0;

    for (const key in filter) {
      if (filter[key] && filter[key].length > 0) {
        count++;
      }
    }

    return count;
  };

  const toggleFilter = () => {
    setIsToggleFilter(!isToggleFilter);
  };

  const toggleModalArchiveIndividual = (guidelineId, accountId) => {
    toggleModalArchive();
    setGuidelineDataClicked([
      {
        guidelineId: guidelineId,
        accountId: accountId,
      },
    ]);
  };

  const toggleModalApproveIndividual = (guidelineId, accountId, column) => {
    toggleModalApprove();
    setGuidelineDataClicked([
      {
        guidelineId: guidelineId,
        accountId: accountId,
        column,
      },
    ]);
  };

  const toggleModalDenyIndividual = (guidelineId, accountId) => {
    toggleModalDeny();
    setGuidelineDataClicked([
      {
        guidelineId: guidelineId,
        accountId: accountId,
      },
    ]);
  };

  const fetchData = async (filterValue) => {
    const guidelinesData = await Promise.all(
      user.accounts.map(async (account) => await firebaseHelper.getAllFirebaseGuidelines(account)),
    );

    const guidelinesArray = guidelinesData.map((item) => {
      return Object.values(item);
    });
    const guidelinesFiltered = guidelinesArray.map((account) =>
      account.filter((guideline) => {
        return guideline.column === 'GUIDELINE_APPROVAL' && !guideline.archived;
      }),
    );

    const accountNames = await Promise.all(
      user.accounts.map(async (account) => await firebaseHelper.accountName(account)),
    );

    const accountGuidelines = guidelinesFiltered.map((guidelines, index) => {
      const accountName = accountNames[index];
      const accountId = user.accounts[index];
      return { accountName, accountId, guidelines };
    });

    if (filterValue) {
      const { assignedTo, type, startDate, endDate } = filterValue;

      accountGuidelines.forEach((account) => {
        account.guidelines = account.guidelines.filter((guideline) => {
          const assignedToValues = assignedTo ? assignedTo.map((person) => person.value) : [];
          const typeValues = type ? type.map((t) => t.value) : [];

          const assignedToMatch =
            !assignedToValues.length ||
            guideline.assignedTo.some((person) => assignedToValues.includes(person));
          const typeMatch = !typeValues.length || typeValues.includes(guideline.type);

          const assignedToDateMatch =
            !startDate ||
            !endDate ||
            (guideline.createdAt >= startDate && guideline.createdAt <= endDate);

          return assignedToMatch && typeMatch && assignedToDateMatch;
        });
      });
    }

    setGuidelineData(accountGuidelines);
  };

  useEffect(() => {
    fetchData();
  }, [user]);

  return (
    <React.Fragment>
      <div className="header-general-information">
        <p>{translate('Guidelines pending approval')}</p>
        <div className="d-flex gap-3">
          <ButtonApprove onClickFunction={toggleModalApprove} disabled={isButtonDisabled} inBulk />
          <ButtonDeny onClickFunction={toggleModalDeny} disabled={isButtonDisabled} inBulk />
          <ButtonFilter handleToggle={toggleFilter} filterTotal={filterTotal} />
        </div>
      </div>
      {isToggleFilter && (
        <div className="container-filter">
          <GuidelinesManageFilter
            accountsId={user.accounts}
            filter={filter}
            setFilter={setFilter}
            toggleFilter={toggleFilter}
            fetchData={fetchData}
          />
        </div>
      )}
      <FormGroup>
        <Table className="table-guideline">
          <thead>
            <tr>
              <th>
                <Input type="checkbox" checked={selectAllChecked} onChange={toggleSelectAll} />
                {translate('Primary Keyword')}
              </th>
              <th style={{ width: '144px', textAlign: 'center' }}>{translate('Account')}</th>
              <th style={{ width: '144px', textAlign: 'center' }}>
                {translate('Searches')}/{translate('Month').toLowerCase()}
              </th>
              <th style={{ width: '380px' }}>{translate('Action')}</th>
            </tr>
          </thead>
          <tbody>
            {guidelineData && guidelineData.length > 0 ? (
              guidelineData?.map((account) =>
                account.guidelines.map((guideline, indexList) => (
                  <React.Fragment key={guideline.id}>
                    <tr>
                      <td>
                        <Input
                          type="checkbox"
                          checked={guideline.selected || false}
                          onChange={() => toggleSelectIndividual(account.accountId, indexList)}
                        />
                        {guideline.primaryKeyword.charAt(0).toUpperCase() +
                          guideline.primaryKeyword.slice(1)}
                      </td>
                      <td style={{ textAlign: 'center' }}>{account.accountName}</td>
                      <td style={{ textAlign: 'center' }}>
                        {guideline.searchVolume.toLocaleString()}
                      </td>
                      <td>
                        {guideline.denied ? (
                          <div className="d-flex gap-3 align-items-center">
                            <BadgeDenied />
                            <ButtonArchive
                              onClickFunction={() =>
                                toggleModalArchiveIndividual(guideline.id, account.accountId)
                              }
                            />
                          </div>
                        ) : (
                          <div className="d-flex justify-content-between">
                            <ButtonApprove
                              onClickFunction={() =>
                                toggleModalApproveIndividual(
                                  guideline.id,
                                  account.accountId,
                                  guideline.column,
                                )
                              }
                            />
                            <ButtonDeny
                              onClickFunction={() =>
                                toggleModalDenyIndividual(guideline.id, account.accountId)
                              }
                            />
                            <ButtonDetails
                              onClickFunction={toogleDetail}
                              id={guideline.id}
                              isOpen={detailIndexIsOpen === guideline.id}
                            />
                          </div>
                        )}
                      </td>
                    </tr>
                    <tr className="table-guideline-details">
                      <td colSpan={4}>
                        <Collapse isOpen={detailIndexIsOpen === guideline.id}>
                          <p>{translate('Pre-existing content structure')}:</p>
                          {guideline?.structure.map((structure, id) => (
                            <ul key={id}>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="15"
                                  viewBox="0 0 14 15"
                                  fill="none"
                                >
                                  <path
                                    d="M7.6834 7.5014L4.7959 4.6139L5.62073 3.78906L9.33307 7.5014L5.62073 11.2137L4.7959 10.3889L7.6834 7.5014Z"
                                    fill="#718096"
                                  />
                                </svg>
                                {structure.topic}
                              </li>
                            </ul>
                          ))}
                          <Badge color="light" className="badge-guideline-observation">
                            {' '}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="15"
                              viewBox="0 0 14 15"
                              fill="none"
                            >
                              <path
                                d="M6.99984 13.3307C3.77809 13.3307 1.1665 10.7191 1.1665 7.4974C1.1665 4.27565 3.77809 1.66406 6.99984 1.66406C10.2216 1.66406 12.8332 4.27565 12.8332 7.4974C12.8332 10.7191 10.2216 13.3307 6.99984 13.3307ZM6.4165 6.91406V10.4141H7.58317V6.91406H6.4165ZM6.4165 4.58073V5.7474H7.58317V4.58073H6.4165Z"
                                fill="#718096"
                              />
                            </svg>{' '}
                            {translate(
                              'Some topics may be changed during the guideline elaboration process.',
                            )}
                          </Badge>
                        </Collapse>
                      </td>
                    </tr>
                  </React.Fragment>
                )),
              )
            ) : (
              <tr className="border">
                <td colSpan={4} className="text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="45"
                    height="44"
                    viewBox="0 0 45 44"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M25.6368 16.0133L23.9032 14.2797C23.5641 13.9406 23.104 13.75 22.6243 13.75C22.1447 13.75 21.6846 13.9406 21.3454 14.2797L15.5853 20.0399C15.3171 20.308 15.1665 20.6717 15.1665 21.0509V24.2917C15.1665 24.5449 15.3717 24.7501 15.6248 24.7501H18.865C19.2446 24.7501 19.6086 24.5993 19.877 24.3309L25.6368 18.5711C25.976 18.2319 26.1666 17.7719 26.1665 17.2922C26.1665 16.8125 25.976 16.3525 25.6368 16.0133V16.0133Z"
                      stroke="#CBD5E0"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M29.8332 30.2526H15.1665"
                      stroke="#CBD5E0"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <rect
                      x="6"
                      y="5.5"
                      width="33"
                      height="33"
                      rx="16.5"
                      stroke="#CBD5E0"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <p className="mt-1" style={{ color: '#a0aec0' }}>
                    {translate('No pending guidelines')}
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </FormGroup>
    </React.Fragment>
  );
};

TablePendentGuidelines.propTypes = {
  toggleModalApprove: PropTypes.func,
  toggleModalDeny: PropTypes.func,
  toggleModalArchive: PropTypes.func,
  setGuidelineDataClicked: PropTypes.func,
  guidelinesDataSelected: PropTypes.array,
  setGuidelinesDataSelected: PropTypes.func,
};

export default TablePendentGuidelines;

import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { useTranslation } from 'react-i18next';
import { Col, Label } from 'reactstrap';

import { selectCustomStyles } from '../../utils';
import DisplayErrors from '../Display-Errors';

const SelectInput = ({
  asyncronous = false,
  autoComplete = 'off',
  controlName,
  creatable,
  disable = false,
  isMulti = false,
  key,
  mb = 0,
  label,
  onChange = null,
  placeholder = 'Select...',
  required = false,
  size = 12,
  validation = {},
  nestedWatch = false,
  minWidth = '',
  minHeigth = '',
  maxWidth = '',
  ...options
}) => {
  const { t: translate } = useTranslation();
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const validate = required ? { ...validation, required: 'Required Field!' } : validation;

  return (
    <Col
      className={`mb-${mb}`}
      lg={size}
      style={{ minWidth: minWidth, minHeight: minHeigth, maxWidth: maxWidth }}
    >
      {label && (
        <Label htmlFor={controlName} className="col-lg-12 mt-2">
          {`${translate(label)}${required ? ' *' : ''}`}
        </Label>
      )}
      <Controller
        control={control}
        name={controlName}
        rules={validate}
        render={({ field }) => {
          let SelectComponent;
          if (creatable) {
            SelectComponent = asyncronous ? AsyncCreatableSelect : CreatableSelect;
          } else {
            SelectComponent = asyncronous ? AsyncSelect : ReactSelect;
          }
          return (
            <SelectComponent
              {...field}
              value={nestedWatch ? watch(controlName) : field.value}
              key={key}
              formatCreateLabel={(value) => translate('Other (Type): ') + `"${value}"`}
              onChange={(event) => {
                field.onChange(event);
                if (onChange) onChange(event);
              }}
              noOptionsMessage={() => translate('No options')}
              isDisabled={disable}
              styles={selectCustomStyles(field.name, errors)}
              placeholder={translate(placeholder)}
              isClearable
              {...options}
              isMulti={isMulti ? true : false}
              className="basic-multi-select"
              ref={field.ref}
            />
          );
        }}
      />

      <DisplayErrors error={errors?.[controlName]?.types} />
    </Col>
  );
};

SelectInput.propTypes = {
  asyncronous: PropTypes.bool,
  autoComplete: PropTypes.string,
  controlName: PropTypes.string.isRequired,
  creatable: PropTypes.bool,
  disable: PropTypes.bool,
  mb: PropTypes.number,
  isMulti: PropTypes.bool,
  key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  size: PropTypes.number,
  nestedWatch: PropTypes.bool,
  validation: PropTypes.object,
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string,
  minHeigth: PropTypes.string,
};

export default SelectInput;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import { getFirebaseBackend } from 'helpers/firebaseHelper';
import ButtonDeny from '../Button-Deny';

const ModalDeny = ({
  modalFunction,
  modalState,
  accountId,
  guidelineId,
  dataMany,
  dataIndividual,
}) => {
  const firebaseHelper = getFirebaseBackend();
  const { t: translate } = useTranslation();
  const [reason, setReason] = useState('');
  const [customReason, setCustomReason] = useState('');
  const [error, setError] = useState(null);

  const REASONS_TO_DENY = [
    {
      label: translate('Not aligned with the strategy'),
      value: 'NOT_ALIGNED_WITH_STRATEGY',
    },
    {
      label: translate('Duplicated guideline'),
      value: 'DUPLICATED_GUIDELINE',
    },
    {
      label: translate('Conflicting guideline'),
      value: 'CONFLICTING_GUIDELINE',
    },
    {
      label: translate('Other'),
      value: 'OTHER',
    },
  ];

  const denyGuideline = async () => {
    let statusReason = reason;

    if (!reason) {
      setError('no-select');
      return;
    }

    if (reason === 'OTHER') {
      statusReason = customReason;

      if (!statusReason) {
        setError('no-text');
        return;
      }

      if (statusReason.length < 5) {
        setError('small-text');
        return;
      }
    }

    if (dataMany?.length > 0) {
      for (const guideline of dataMany) {
        await firebaseHelper.denyGuideline(
          guideline.accountId,
          guideline.guidelineId,
          statusReason,
        );
      }
    }

    if (dataIndividual?.length > 0) {
      for (const guideline of dataIndividual) {
        await firebaseHelper.denyGuideline(
          guideline.accountId,
          guideline.guidelineId,
          statusReason,
        );
      }
    }

    modalFunction();
    setError(null);
    setReason('');
    setCustomReason('');
  };

  return (
    <Modal isOpen={modalState} toggle={modalFunction} centered>
      <ModalHeader toggle={modalFunction}>{translate('Deny guideline')}</ModalHeader>
      <ModalBody>
        <Label>{translate('Provide the reason')}:</Label>
        <Input
          type="select"
          name="reason"
          value={reason}
          onChange={(e) => {
            setReason(e.target.value);
            setError(null);
          }}
        >
          <option value="">{translate('Select')}</option>
          {REASONS_TO_DENY.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Input>
        {error === 'no-select' && (
          <p className="text-danger">{translate('Please, select a reason')}</p>
        )}
        {reason === 'OTHER' && (
          <>
            <Input
              type="text"
              name="reason"
              placeholder={translate('Provide the reason')}
              value={customReason}
              onChange={(e) => {
                setCustomReason(e.target.value);
                setError(null);
              }}
              style={{ marginTop: '8px' }}
            />
            {error === 'no-text' && (
              <p className="text-danger">{translate('Please, write a reason')}</p>
            )}
            {error === 'small-text' && (
              <p className="text-danger">{translate('Write at least 5 characters')}</p>
            )}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={modalFunction}>
          {translate('Cancel')}
        </Button>
        <ButtonDeny onClickFunction={() => denyGuideline(accountId, guidelineId, reason)} />
      </ModalFooter>
    </Modal>
  );
};

ModalDeny.propTypes = {
  modalFunction: PropTypes.func.isRequired,
  modalState: PropTypes.bool.isRequired,
  accountId: PropTypes.string,
  guidelineId: PropTypes.string,
  dataMany: PropTypes.array,
  dataIndividual: PropTypes.array,
};

export default ModalDeny;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Input } from 'reactstrap';
import axios from 'axios';

import { CMSIntegrationStyled } from './styles';
import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { useAuth } from 'hooks/useAuth';
import { StrapiIcon, WordpressIcon } from 'assets/icons/Cms';

const CmsIntegration = ({ googleDocId, title, guidelineId, cmsLink, accountData }) => {
  const firebaseHelper = getFirebaseBackend();
  const { user } = useAuth();
  const currentAccountId = user?.account;
  const { t: translate } = useTranslation();
  const [isSending, setIsSending] = useState(false);
  const [cmsLinkSaved, setCmsLinkSaved] = useState(cmsLink);

  const handleCreateDraft = async () => {
    setIsSending(true);

    const body = {
      name: accountData?.name,
      id: currentAccountId,
      title: title,
      gDriveFolder: accountData?.gDriveFolders.content,
      cmsAuth: accountData?.cmsAuth,
      linkDocs: `https://docs.google.com/document/d/${googleDocId}/edit`,
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_GDOCS_WEBHOOK_CREATE_DRAFT_CMS,
        body,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_GDOCS_FLOW_KEY}`,
          },
        },
      );

      if (response) {
        await firebaseHelper.saveCmsGuidelineLink(
          currentAccountId,
          guidelineId,
          response.data.link,
        );
        setCmsLinkSaved(response.data.link);
      }

      setIsSending(false);
    } catch (error) {
      setIsSending(false);
      console.log(error);
    }
  };

  const capitalizeFirstLetter = (text) => {
    if (!text) return '';
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  const openInputLink = () => {
    const url = document.getElementById('input-link').value;
    window.open(url, '_blank');
  };

  return (
    <CMSIntegrationStyled>
      <p className="subtitle">{`${translate('Integration')} CMS`}</p>
      <div className="d-flex flex-column gap-4">
        {!cmsLinkSaved && (
          <Button className="btn-integration" onClick={handleCreateDraft}>
            {isSending ? (
              <div
                className="spinner-border text-light"
                role="status"
                style={{
                  width: '1.5rem',
                  height: '1.5rem',
                  position: 'absolute',
                }}
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <>
                {accountData?.cmsAuth?.type === 'WORDPRESS' && (
                  <WordpressIcon width="1rem" height="1rem" />
                )}
                {accountData?.cmsAuth?.type === 'STRAPI' && (
                  <StrapiIcon width="1rem" height="1rem" />
                )}
                {translate('Create draft in {{cmsType}}', {
                  cmsType: capitalizeFirstLetter(accountData?.cmsAuth?.type),
                })}
              </>
            )}
          </Button>
        )}
        {cmsLinkSaved && (
          <div className="url-input">
            <Input
              id="input-link"
              className="input-link"
              type="text"
              value={cmsLinkSaved}
              readOnly
              onClick={(e) => e.target.select()}
            />
            <Button className="open-link-button" onClick={openInputLink}>
              <i className="bx bx-link-external" />
            </Button>
          </div>
        )}
      </div>
    </CMSIntegrationStyled>
  );
};

CmsIntegration.propTypes = {
  googleDocId: PropTypes.string,
  title: PropTypes.string,
  guidelineId: PropTypes.string,
  cmsLink: PropTypes.string,
  accountData: PropTypes.object,
};

export default CmsIntegration;

import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Button, Row, Col, Card, CardBody } from 'reactstrap';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

import { useAuth } from 'hooks/useAuth';
import { getFirebaseBackend } from '../../helpers/firebaseHelper';
import '../../assets/scss/datatables.scss';

const Meetings = () => {
  const firebaseHelper = getFirebaseBackend();
  const { t: translate } = useTranslation();
  const { user: userObj } = useAuth();
  const accountId = userObj.account;
  const [userData, setUserData] = useState([]);

  useEffect(async () => {
    if (!accountId) return;
    const meetings = await firebaseHelper.getAllMeetings(accountId);

    const newUserData = meetings
      ? Object.values(meetings).map((meeting, index) => {
          return {
            id: meeting.id,
            dateValue: meeting.date ?? 0,
            date: meeting.date ? new Date(meeting.date).toLocaleDateString() : 'N/A',
            agenda: meeting.agenda,
            minutesLong: meeting.minutes,
            tasks: meeting.relatedTasks ? meeting.relatedTasks.length : '0',
            action: actionButton(meeting),
          };
        })
      : [];
    setUserData(newUserData);
  }, [accountId]);

  const actionButton = ({ id: id }) => {
    const adminsButton = (
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <div style={{ display: 'flex' }}>
          <Link to={`/edit-meeting?id=${id}&acc=${accountId}`}>
            <Button
              style={{
                border: 'none',
                backgroundColor: '#E0E0E0',
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
              }}
            >
              <i className="bx bx-pencil" style={{ color: '#495057' }} />
              <span style={{ color: 'rgba(106, 113, 135, 1)' }}>{translate('Edit')}</span>
            </Button>
          </Link>
        </div>

        <div style={{ display: 'flex' }}>
          <Link to={`/meeting?id=${id}&acc=${accountId}`}>
            <Button
              style={{
                border: 'none',
                backgroundColor: '#E0E0E0',
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
              }}
            >
              <i className="bx bx-show" style={{ color: '#495057' }} />
              <span style={{ color: 'rgba(106, 113, 135, 1)' }}>{translate('See')}</span>
            </Button>
          </Link>
        </div>
      </div>
    );
    const clientButton = (
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <Link to={`/meeting?id=${id}&acc=${accountId}`}>
          <Button
            style={{
              border: 'none',
              backgroundColor: '#E0E0E0',
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
            }}
          >
            <i className="bx bx-show" style={{ color: '#495057' }} />
            <span style={{ color: 'rgba(106, 113, 135, 1)' }}>{translate('See')}</span>
          </Button>
        </Link>
      </div>
    );

    if (userObj.adminStatus === 'Super Admin' || userObj.adminStatus === 'Admin')
      return adminsButton;

    return clientButton;
  };

  const columns = [
    {
      dataField: 'date',
      type: 'date',
      text: translate('Date'),
      sort: true,
      sortValue: (cell, row) => {
        return row.dateValue;
      },
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'agenda',
      type: 'string',
      text: translate('Agenda'),
      sort: true,
      headerStyle: () => {
        return { width: '60%' };
      },
    },
    {
      dataField: 'minutesLong',
      type: 'number',
      text: translate('Minutes Long'),
      sort: true,
      headerStyle: () => {
        return { width: '5%', textAlign: 'center' };
      },
      style: () => {
        return { textAlign: 'center' };
      },
    },
    {
      dataField: 'tasks',
      type: 'number',
      text: translate('Tasks'),
      sort: true,
      headerStyle: () => {
        return { width: '5%', textAlign: 'center' };
      },
      style: () => {
        return { textAlign: 'center' };
      },
    },
    {
      dataField: 'action',
      searchable: false,
      text: translate('Action'),
      headerStyle: () => {
        return { width: '20%', textAlign: 'center' };
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: 'date',
      order: 'desc',
    },
  ];

  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: userData.length },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: userData.length,
    custom: true,
    sizePerPageList,
  };

  const { SearchBar } = Search;

  return (
    <React.Fragment>
      <div className="page-content" style={{ minHeight: '100vh' }}>
        <MetaTags>
          <title>{translate('List Meetings')} | Ectools</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col xs="12" className="mb-4">
              <h4 className="mb-0 font-size-18">{translate('Meetings')}</h4>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={userData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider keyField="id" columns={columns} data={userData} search>
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2 d-flex justify-content-between">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      placeholder={translate('Search')}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              {(userObj.adminStatus === 'Super Admin' ||
                                userObj.adminStatus === 'Admin') && (
                                <Col md="4" className="d-flex flex-row-reverse align-items-center">
                                  <Link to="/create-meeting">
                                    <Button
                                      style={{
                                        backgroundColor: 'rgba(85, 110, 230, 1)',
                                        border: 'none',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '5px',
                                      }}
                                    >
                                      <i className="bx bx-plus-circle" />
                                      {translate('Create New Meeting')}
                                    </Button>
                                  </Link>
                                </Col>
                              )}
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    hover
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={'table align-middle table-nowrap'}
                                    headerWrapperClasses={'thead-light'}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone {...paginationProps} />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone {...paginationProps} />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Meetings;

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import Header from '../../layouts/Header';
import Sidebar from '../../layouts/Sidebar';
import Footer from '../../layouts/Footer';

import { INIT_STATE } from '../../constants';

const Layout = (props) => {
  const changeBodyAttribute = (attribute, value) => {
    if (document.body) document.body.setAttribute(attribute, value);
    return true;
  };

  const manageBodyClass = (cssClass, action = 'toggle') => {
    switch (action) {
      case 'add':
        if (document.body) document.body.classList.add(cssClass);
        break;
      case 'remove':
        if (document.body) document.body.classList.remove(cssClass);
        break;
      default:
        if (document.body) document.body.classList.toggle(cssClass);
        break;
    }

    return true;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    changeBodyAttribute('data-sidebar', INIT_STATE.leftSideBarTheme);
  }, []);

  return (
    <div id="layout-wrapper">
      <Header />
      <Sidebar />
      <div className="main-content">{props.children}</div>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.object,
};

export default Layout;

import styled from 'styled-components';

export const PublishedDataStyle = styled.div`
  width: 332px;
  height: fit-content;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  position: relative;
  padding: 16px;

  .subtitle {
    margin: 0 !important;
    margin-bottom: 16px !important;
  }

  label {
    font-weight: 400;
    color: #4a5568;
  }

  .url-input {
    display: flex;
    align-items: center;
    gap: 1em;
    position: relative;
    .input-icon {
      position: absolute;
      right: 4.2em;
    }
  }

  .open-link-button {
    background-color: transparent;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0;
    padding-top: 5px;
    aspect-ratio: 1/1;
    height: 2.8em;
    margin: 0;
  }

  border: ${(props) => (props.publishedUrl ? 'none' : '1px solid #e53e3e')};

  .error-text {
    color: #e53e3e;
  }
`;

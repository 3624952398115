import styled from 'styled-components';

export const VTimelineList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 10px;
  border-left: 3px dashed #f6f6f6;
`;

export const VTimelineItem = styled.li`
  position: relative;
  padding: 0 0 3rem 2rem;
`;

export const VTimelineDot = styled.div`
  position: absolute;
  left: -9px;
  top: 0.4rem;
  z-index: 1;
  font-size: 16px;
`;

export const VTimelineContent = styled.div`
  display: flex;
  align-items: flex-start;

  > div:nth-child(1) {
    margin-right: 1em;
  }

  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    padding-top: 0.4rem;

    > h5 {
      color: #495057;
    }

    > p {
      white-space: pre-wrap;
      color: #74788d;
      margin: 0;
      padding: 0;
    }
  }
`;

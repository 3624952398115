import React from 'react';
import PropTypes from 'prop-types';

import { StyledReactPlayer, PlayerWrapper } from './styles';

const EmbeddedVideo = ({ link }) => {
  return (
    <>
      <PlayerWrapper>
        <StyledReactPlayer
          url={link}
          width="100%"
          height="100%"
          config={{
            youtube: {
              playerVars: { rel: 0, controls: 2 },
            },
          }}
          controls={true}
        />
      </PlayerWrapper>
    </>
  );
};

EmbeddedVideo.propTypes = {
  link: PropTypes.string,
};

export default EmbeddedVideo;

import styled from 'styled-components';

export const ButtonViewStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .btn-view {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    border: none;
    background-color: #f7fafc;
    color: #4a5568 !important;
    font-weight: 500;
  }
  .btn-view:hover {
    background-color: #eff2f7 !important;
  }
`;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'boxicons';

import { useAuth } from 'hooks/useAuth';
import { getFirebaseBackend } from 'helpers/firebaseHelper';
import PageStructure from 'components/Page-Structure';
import ShowComponent from 'components/Show-Component';
import Shimmer from 'components/Shimmer';
import CoursesPaginated from './components/CoursesPaginated';

import { BreadCrumbBtn, NoCourseMessage } from './styles';

const CARDS_PER_PAGE = 12;

const CoursesLibrary = () => {
  const firebaseHelper = getFirebaseBackend();
  const { t: translate } = useTranslation();
  const { user } = useAuth();
  const accountId = user.account;
  const [courses, setCourses] = useState([]);
  const [library, setLibrary] = useState([]);
  const [loading, setLoading] = useState(true);

  const onEmptyLibrary = (
    <NoCourseMessage>
      <box-icon name="archive" color="#000" size="1.5rem" />
      <div>
        {translate('There are no courses in your library yet.')}{' '}
        <Link to="/courses">{translate('Access our courses')}</Link>{' '}
        {translate('and add your preferred courses!')}
      </div>
    </NoCourseMessage>
  );

  const fetchCourses = async (libraryArr) => {
    const courses = await Promise.all(
      Object.entries(libraryArr).map(async ([courseId, libCourseObj]) => {
        const courseObj = await firebaseHelper.getFirebaseCourse(libCourseObj.account, courseId);
        return { ...courseObj, id: courseId, account: libCourseObj.account };
      }),
    );
    setCourses(courses);
    setLoading(false);
  };

  const fetchLibrary = async () => {
    const libraryObj = await firebaseHelper.getAllFirebaseLibraryCourse(user.id);

    const newLibrary = {};
    Object.entries(libraryObj || {}).forEach(([courseId, libraryObj]) => {
      newLibrary[courseId] = libraryObj;
    });

    setLibrary(newLibrary);
    fetchCourses(newLibrary);
  };

  useEffect(() => {
    if (!accountId) return;

    fetchLibrary();
  }, [accountId]);

  return (
    <PageStructure metaTitle="Courses">
      <div className="d-flex justify-content-between">
        <h4 className="mb-0 font-size-18">{translate('My Library')}</h4>

        <BreadCrumbBtn to="/courses">
          <box-icon name="chalkboard" size="1.2rem" color="#50A5F1" />
          {translate('See Available Courses')}
        </BreadCrumbBtn>
      </div>

      <ShowComponent condition={loading}>
        {Array.from({ length: CARDS_PER_PAGE }).map((item, index) => (
          <Shimmer
            key={index}
            width="250px"
            height="300px"
            style={{ display: 'inline-block', margin: '1.5em 1em 1em 0' }}
          />
        ))}
      </ShowComponent>

      <ShowComponent condition={!loading}>
        <CoursesPaginated
          data={courses}
          updateData={fetchCourses}
          library={library}
          itemsPerPage={CARDS_PER_PAGE}
          onZero={onEmptyLibrary}
          showProgress
        />
      </ShowComponent>
    </PageStructure>
  );
};

CoursesLibrary.displayName = 'Courses Library Page';

export default CoursesLibrary;

import { Button } from 'reactstrap';
import styled from 'styled-components';

export const CardInputCMSStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  align-items: flex-start;
  gap: 0.75rem;
  align-self: stretch;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  background: #f7f7fa;
  .btn-cms {
    display: flex;
    width: 100%;
    height: 3rem;
    padding: 0.625rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.25rem;
    border: 1px solid #e2e8f0;
    background: transparent !important;
    color: #343a40 !important;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .btn-cms:hover {
    background: #e2e8f0 !important;
  }
  .btn-cms:active {
    border: 1px solid #e2e8f0;
  }
  .col-lg-11 {
    width: 100%;
    padding: 0;
  }
  .btn-go-to {
    display: flex;
    width: 2.5rem;
    padding: 0.625rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.25rem;
    border: 1px solid #b3b3b3;
    background-color: transparent !important;
    color: #484848 !important;
  }
  .btn-go-to:hover {
    background: #e2e8f0 !important;
  }
  .btn-delete {
    display: flex;
    height: 2.75rem;
    padding: 0.625rem 1rem;
    align-items: center;
    gap: 0.5rem;
    flex-shrink: 0;
    border-radius: 0.25rem;
    background-color: #c53030 !important;
    border: none;
    color: #ffffff !important;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .btn-delete:hover {
    background: #b91c1c !important;
  }
`;

export const ButtonDeleteStyled = styled(Button)`
  background-color: #f46a6a;
  border: none;
  :hover {
    background-color: #c53030;
    border: none;
  }
  :active {
    background-color: #c53030;
    border: none;
  }
`;

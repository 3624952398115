import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import MDEditor, { commands } from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';
import { Col } from 'reactstrap';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';

import { useAuth } from 'hooks/useAuth';
import { getFirebaseBackend } from '../../helpers/firebaseHelper';
import DisplayErrors from '../Display-Errors/';

const MarkdownInput = ({
  attachId,
  controlName,
  onChange = null,
  required = false,
  size = 12,
  validation = {},
}) => {
  const firebaseHelper = getFirebaseBackend();
  const validate = required ? { ...validation, required: 'Required Field!' } : validation;

  const { user } = useAuth();
  const accountId = user.account;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const MarkdownContext = createContext();

  const openFileInput = (event) => {
    event.preventDefault();
    const fileElem = document.getElementById('fileElem');
    if (fileElem) {
      fileElem.click();
    }
  };

  const attachImageExecute = (state, api) => {
    const fileElem = document.getElementById('fileElem');

    fileElem.onchange = async (event) => {
      const file = event.target.files.item(0);
      // If no file was added when opening the file explorer then return
      if (!file) return;

      let modifyText = '';
      if (state.selectedText) {
        modifyText = `${state.selectedText}\n`;
      } else {
        modifyText = '';
      }

      // Sends the image to firebase and gets its URL to copy onto the markdown editor
      await new Promise(async (resolve, reject) => {
        try {
          const snapshot = await firebaseHelper.storeSingleFile(
            file,
            `${accountId}/${attachId}/md-description`,
          );

          const fileRef = ref(getStorage(), snapshot.ref.fullPath);
          const imageURL = await getDownloadURL(fileRef);
          modifyText += `![](${imageURL})\n`;
          resolve(true);
        } catch (error) {
          reject(error);
        }
      });

      api.replaceSelection(modifyText);
    };
  };

  const attachImageCommand = {
    name: 'Attach an Image',
    keyCommand: 'attachimage',
    buttonProps: { 'aria-label': 'Attach an Image', title: 'Attach an Image' },
    icon: (
      <div>
        <input type="file" id="fileElem" accept="image/*" style={{ display: 'none' }}></input>
        <svg width="12" height="12" viewBox="0 0 520 520" onClick={(e) => openFileInput(e)}>
          <path
            fill="currentColor"
            d="M270.1595-4.3919l116.5342 142.5758c0 0 17.7309 16.9494-1.4961 16.9494s-65.6667 0-65.6667 0 0 11.4019 0 28.986c0 50.1318 0 141.3681 0 178.5625 0 0 2.608 10.1123-12.4339 10.1123-15.1632 0-81.578 0-92.4349 0-10.8366 0-10.594-8.5157-10.594-8.5157 0-36.0688 0-130.4573 0-178.9516 0-15.7212 0-25.9155 0-25.9155s-37.2408 0-60.6123 0c-23.3311 0-5.762-17.7478-5.762-17.7478s99.1471-133.2617 112.9759-147.2431C260.6168-15.712 270.1595-4.3919 270.1595-4.3919zM410.6528 316.1509v99.8438H109.016v-99.8438H51.0416v129.1934c0 16.1984 12.96 29.335 29.0304 29.335h359.5248c16.056 0 29.0304-13.122 29.0304-29.335v-129.1934H410.6528z"
          />
        </svg>
      </div>
    ),
    execute: attachImageExecute,
  };

  const title2Command = {
    ...commands.title2,
    icon: (
      <svg width="12" height="12" viewBox="0 0 520 520">
        <path
          fill="currentColor"
          d="M15.7083333,468 C7.03242448,468 0,462.030833 0,454.666667 L0,421.333333 C0,413.969167 7.03242448,408 15.7083333,408 L361.291667,408 C369.967576,408 377,413.969167 377,421.333333 L377,454.666667 C377,462.030833 369.967576,468 361.291667,468 L15.7083333,468 Z M21.6666667,366 C9.69989583,366 0,359.831861 0,352.222222 L0,317.777778 C0,310.168139 9.69989583,304 21.6666667,304 L498.333333,304 C510.300104,304 520,310.168139 520,317.777778 L520,352.222222 C520,359.831861 510.300104,366 498.333333,366 L21.6666667,366 Z M136.835938,64 L136.835937,126 L107.25,126 L107.25,251 L40.75,251 L40.75,126 L-5.68434189e-14,126 L-5.68434189e-14,64 L136.835938,64 Z M212,64 L212,251 L161.648438,251 L161.648438,64 L212,64 Z M378,64 L378,126 L343.25,126 L343.25,251 L281.75,251 L281.75,126 L238,126 L238,64 L378,64 Z M449.047619,189.550781 L520,189.550781 L520,251 L405,251 L405,64 L449.047619,64 L449.047619,189.550781 Z"
        />
      </svg>
    ),
  };

  return (
    <Col lg={size}>
      <Controller
        control={control}
        name={controlName}
        rules={validate}
        render={({ field }) => (
          <MarkdownContext.Provider value={attachId}>
            <div data-color-mode="light">
              <MDEditor
                {...field}
                previewOptions={{
                  rehypePlugins: [[rehypeSanitize]],
                }}
                className={`${errors?.[controlName] ? 'markdown-error' : ''}`}
                preview="edit"
                commands={[
                  commands.bold,
                  commands.italic,
                  commands.strikethrough,
                  commands.hr,
                  title2Command,
                  commands.divider,
                  commands.link,
                  commands.quote,
                  commands.code,
                  commands.image,
                  attachImageCommand,
                  commands.divider,
                  commands.unorderedListCommand,
                  commands.orderedListCommand,
                  commands.checkedListCommand,
                ]}
                onChange={onChange ? onChange : field.onChange}
              />
            </div>
          </MarkdownContext.Provider>
        )}
      />

      <DisplayErrors error={errors?.[controlName]?.types} />
    </Col>
  );
};

MarkdownInput.propTypes = {
  attachId: PropTypes.string,
  autoComplete: PropTypes.string,
  controlName: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  size: PropTypes.number,
  validation: PropTypes.object,
};

export default MarkdownInput;

import i18n from 'i18n';

export const contentTypeOptions = [
  { value: 1, label: i18n.t('Blog Post') },
  { value: 2, label: i18n.t('Category Text') },
  { value: 3, label: i18n.t('Landing Page') },
  { value: 4, label: i18n.t('E-book') },
  { value: 5, label: i18n.t('Infograph') },
  { value: 6, label: i18n.t('Quizzes') },
  { value: 7, label: i18n.t('Social Media Post') },
  { value: 8, label: i18n.t('Questions and Answer') },
  { value: 9, label: i18n.t('Lists') },
  { value: 10, label: i18n.t('Product Review') },
  { value: 11, label: i18n.t('Guide') },
  { value: 12, label: i18n.t('News') },
];

export const guidelineTypeOptions = [
  { value: 'BLOG_POST', label: i18n.t('Blog Post') },
  { value: 'CATEGORY', label: i18n.t('Category') },
  { value: 'PRODUCT', label: i18n.t('Product') },
];

export const guidelineDeliveryTypeOptions = [
  { value: 'PRODUCTION', label: i18n.t('Production') },
  { value: 'PRODUCTION_AND_UNIFICATION', label: i18n.t('Production and Unification') },
  { value: 'REVIEW', label: i18n.t('Review') },
  { value: 'REVIEW_AND_DIRECTIONS', label: i18n.t('Review and Directions') },
];

export const guidelineDeliveryTypeOptionsKanban = [
  { value: 'PRODUCTION', label: i18n.t('Production') },
  { value: 'PRODUCTION_AND_UNIFICATION', label: i18n.t('Unification') },
  { value: 'REVIEW', label: i18n.t('Review') },
  { value: 'REVIEW_AND_DIRECTIONS', label: i18n.t('Directions') },
];

export const toContentType = {
  1: 'Blog Post',
  2: 'Category Text',
  3: 'Landing Page',
  4: 'E-book',
  5: 'Infograph',
  6: 'Quizzes',
  7: 'Social Media Post',
  8: 'Questions and Answer',
  9: 'Lists',
  10: 'Product Review',
  11: 'Guide',
  12: 'News',
};

export const voiceToneOptions = [
  { value: 1, label: i18n.t('Friendly') },
  { value: 2, label: i18n.t('Calm') },
  { value: 3, label: i18n.t('Serious') },
  { value: 4, label: i18n.t('Casual') },
  { value: 5, label: i18n.t('Persuavise') },
  { value: 6, label: i18n.t('Empathic') },
  { value: 7, label: i18n.t('Sarcastic') },
];

export const toVoiceTone = {
  1: 'Friendly',
  2: 'Calm',
  3: 'Serious',
  4: 'Casual',
  5: 'Persuavise',
  6: 'Empathic',
  7: 'Sarcastic',
};

export const goalOptions = [
  { value: 1, label: i18n.t('Teach') },
  { value: 2, label: i18n.t('Convert') },
  { value: 3, label: i18n.t('Engage') },
];

export const toGoal = {
  1: 'Teach',
  2: 'Convert',
  3: 'Engage',
};

export const languageOptions = [
  { value: 'pt-BR', label: i18n.t('Portuguese (BR)') },
  { value: 'us-EN', label: i18n.t('English (US)') },
  { value: 'es-AR', label: i18n.t('Spanish (AR)') },
  { value: 'fr-FR', label: i18n.t('French (FR)') },
  { value: 'it-IT', label: i18n.t('Italian (IT)') },
  { value: 'pt-PT', label: i18n.t('Portuguese (PT)') },
  { value: 'es-CL', label: i18n.t('Spanish (CL)') },
  { value: 'es-CO', label: i18n.t('Spanish (CO)') },
  { value: 'es-MX', label: i18n.t('Spanish (MX)') },
  { value: 'es-ES', label: i18n.t('Spanish (ES)') },
  { value: 'en-GB', label: i18n.t('English (GB)') },
  { value: 'nl-NL', label: i18n.t('Dutch (NL)') },
];

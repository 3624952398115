import styled from 'styled-components';

export const ButtonUploadCSVLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 205px;
  gap: 8px;

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 100%;
    gap: 8px;
    padding: 10px 12px;
    border-radius: 4px;
    border: 1px solid #e2e8f0;
    background-color: transparent;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #4a5568;
    cursor: pointer;
  }
  label:hover {
    background-color: #f7fafc;
  }

  input {
    display: none;
  }

  p {
    font-size: 10px !important;
    line-height: 12px;
    margin: 0 !important;
  }
`;

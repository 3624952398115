import i18n from 'i18n';

export const monthNumberLabel = [
  { value: 1, label: i18n.t('January') },
  { value: 2, label: i18n.t('February') },
  { value: 3, label: i18n.t('March') },
  { value: 4, label: i18n.t('April') },
  { value: 5, label: i18n.t('May') },
  { value: 6, label: i18n.t('June') },
  { value: 7, label: i18n.t('July') },
  { value: 8, label: i18n.t('August') },
  { value: 9, label: i18n.t('September') },
  { value: 10, label: i18n.t('October') },
  { value: 11, label: i18n.t('November') },
  { value: 12, label: i18n.t('December') },
];

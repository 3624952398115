const coursesColorCodes = [
  '#556EE6',
  '#50A5F1',
  '#555B6D',
  '#7367F0',
  '#865400',
  '#808080',
  '#F46A6A',
];

export { coursesColorCodes };

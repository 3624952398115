import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { Col } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';

import DisplayErrors from '../Display-Errors/';

const TimeInput = ({
  autoComplete = 'off',
  controlName,
  onChange = null,
  placeholder = controlName,
  required = false,
  size = 12,
  timeIntervals = 30,
  validation = {},
  ...other
}) => {
  const { t: translate } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const validate = required ? { ...validation, required: 'Required Field!' } : validation;

  return (
    <Col lg={size}>
      <Controller
        control={control}
        name={controlName}
        rules={validate}
        render={({ field }) => (
          <DatePicker
            {...field}
            id={controlName}
            selected={field.value}
            customInputRef={field.ref}
            className={`form-control ${errors?.[controlName] ? 'error--input' : ''}`}
            placeholderText={`${translate(placeholder)}...`}
            onChange={(event) => {
              field.onChange(event);
              if (onChange) onChange(event);
            }}
            autoComplete={autoComplete}
            showTimeSelect
            showTimeSelectOnly
            timeCaption={translate('Time')}
            timeFormat={translate('h:mm aa')}
            dateFormat={translate('h:mm aa')}
            timeIntervals={timeIntervals}
            {...other}
          />
        )}
      />

      <DisplayErrors error={errors?.[controlName]?.types} />
    </Col>
  );
};

TimeInput.propTypes = {
  autoComplete: PropTypes.string,
  controlName: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  size: PropTypes.number,
  timeIntervals: PropTypes.number,
  validation: PropTypes.object,
};

export default TimeInput;

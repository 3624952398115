import styled from 'styled-components';

export const TaskSummmaryLayout = styled.div`
  display: flex;
  justify-content: space-between;

  .task-summary-guideline {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0;

    .task-summary-stats {
      display: flex;
      gap: 24px;
    }

    .task-summary-stat {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 70px;
      margin: 0;
    }

    .task-summary-title {
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      color: #4a5568;
    }

    .task-summary-number {
      font-size: 18px;
      line-height: 28px;
      font-weight: 500;
      color: #2d3748;
    }

    .task-summary-btns {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 16px;
    }

    button {
      height: 40px;
      width: fit-content;
      padding: 10px 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: flex-end;
      gap: 8px;
      border-radius: 4px;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    }

    .btn-guideline-create {
      border: none;
      background-color: #556ee6;
      color: #fff;
    }
    .btn-guideline-create:hover {
      background-color: #3b5bfc;
    }
  }

  .btn-download-csv {
    min-width: 10.2rem;
    display: flex;
    align-items: center;
    gap: 8px;
    border: 1px solid #e2e8f0;
    background-color: transparent;
    color: #2d3748 !important;
    font-size: 13px;
    font-weight: 500;
    i {
      color: #a0aec0;
    }
  }
  .btn-download-csv:hover {
    border: 1px solid #e2e8f0;
    background-color: #f7fafc;
  }
`;

import i18n from 'i18n';

const codeToCountry = {
  af: i18n.t('Afghanistan'),
  al: i18n.t('Albania'),
  dz: i18n.t('Algeria'),
  as: i18n.t('American Samoa'),
  ad: i18n.t('Andorra'),
  ao: i18n.t('Angola'),
  ai: i18n.t('Anguilla'),
  aq: i18n.t('Antarctica'),
  ag: i18n.t('Antigua and Barbuda'),
  ar: i18n.t('Argentina'),
  am: i18n.t('Armenia'),
  aw: i18n.t('Aruba'),
  au: i18n.t('Australia'),
  at: i18n.t('Austria'),
  az: i18n.t('Azerbaijan'),
  bs: i18n.t('Bahamas'),
  bh: i18n.t('Bahrain'),
  bd: i18n.t('Bangladesh'),
  bb: i18n.t('Barbados'),
  by: i18n.t('Belarus'),
  be: i18n.t('Belgium'),
  bz: i18n.t('Belize'),
  bj: i18n.t('Benin'),
  bm: i18n.t('Bermuda'),
  bt: i18n.t('Bhutan'),
  bo: i18n.t('Bolivia'),
  ba: i18n.t('Bosnia and Herzegovina'),
  bw: i18n.t('Botswana'),
  bv: i18n.t('Bouvet Island'),
  br: i18n.t('Brazil'),
  io: i18n.t('British Indian Ocean Territory'),
  bn: i18n.t('Brunei Darussalam'),
  bg: i18n.t('Bulgaria'),
  bf: i18n.t('Burkina Faso'),
  bi: i18n.t('Burundi'),
  kh: i18n.t('Cambodia'),
  cm: i18n.t('Cameroon'),
  ca: i18n.t('Canada'),
  cv: i18n.t('Cape Verde'),
  ky: i18n.t('Cayman Islands'),
  cf: i18n.t('Central African Republic'),
  td: i18n.t('Chad'),
  cl: i18n.t('Chile'),
  cn: i18n.t('China'),
  cx: i18n.t('Christmas Island'),
  cc: i18n.t('Cocos (Keeling) Islands'),
  co: i18n.t('Colombia'),
  km: i18n.t('Comoros'),
  cg: i18n.t('Congo'),
  cd: i18n.t('Congo, the Democratic Republic of the'),
  ck: i18n.t('Cook Islands'),
  cr: i18n.t('Costa Rica'),
  ci: i18n.t('Ivory Coast'),
  hr: i18n.t('Croatia'),
  cu: i18n.t('Cuba'),
  cy: i18n.t('Cyprus'),
  cz: i18n.t('Czech Republic'),
  dk: i18n.t('Denmark'),
  dj: i18n.t('Djibouti'),
  dm: i18n.t('Dominica'),
  do: i18n.t('Dominican Republic'),
  ec: i18n.t('Ecuador'),
  eg: i18n.t('Egypt'),
  sv: i18n.t('El Salvador'),
  gq: i18n.t('Equatorial Guinea'),
  er: i18n.t('Eritrea'),
  ee: i18n.t('Estonia'),
  et: i18n.t('Ethiopia'),
  fk: i18n.t('Falkland Islands (Malvinas)'),
  fo: i18n.t('Faroe Islands'),
  fj: i18n.t('Fiji'),
  fi: i18n.t('Finland'),
  fr: i18n.t('France'),
  gf: i18n.t('French Guiana'),
  pf: i18n.t('French Polynesia'),
  tf: i18n.t('French Southern Territories'),
  ga: i18n.t('Gabon'),
  gm: i18n.t('Gambia'),
  ge: i18n.t('Georgia'),
  de: i18n.t('Germany'),
  gh: i18n.t('Ghana'),
  gi: i18n.t('Gibraltar'),
  gr: i18n.t('Greece'),
  gl: i18n.t('Greenland'),
  gd: i18n.t('Grenada'),
  gp: i18n.t('Guadeloupe'),
  gu: i18n.t('Guam'),
  gt: i18n.t('Guatemala'),
  gn: i18n.t('Guinea'),
  gw: i18n.t('Guinea-Bissau'),
  gy: i18n.t('Guyana'),
  ht: i18n.t('Haiti'),
  hm: i18n.t('Heard Island and Mcdonald Islands'),
  va: i18n.t('Holy See (Vatican City State)'),
  hn: i18n.t('Honduras'),
  hk: i18n.t('Hong Kong'),
  hu: i18n.t('Hungary'),
  is: i18n.t('Iceland'),
  in: i18n.t('India'),
  id: i18n.t('Indonesia'),
  ir: i18n.t('Iran'),
  iq: i18n.t('Iraq'),
  ie: i18n.t('Ireland'),
  il: i18n.t('Israel'),
  it: i18n.t('Italy'),
  jm: i18n.t('Jamaica'),
  jp: i18n.t('Japan'),
  jo: i18n.t('Jordan'),
  kz: i18n.t('Kazakhstan'),
  ke: i18n.t('Kenya'),
  ki: i18n.t('Kiribati'),
  kp: i18n.t('North Korea'),
  kr: i18n.t('South Korea'),
  kw: i18n.t('Kuwait'),
  kg: i18n.t('Kyrgyzstan'),
  la: i18n.t('Laos'),
  lv: i18n.t('Latvia'),
  lb: i18n.t('Lebanon'),
  ls: i18n.t('Lesotho'),
  lr: i18n.t('Liberia'),
  ly: i18n.t('Libya'),
  li: i18n.t('Liechtenstein'),
  lt: i18n.t('Lithuania'),
  lu: i18n.t('Luxembourg'),
  mo: i18n.t('Macao'),
  mk: i18n.t('Macedonia'),
  mg: i18n.t('Madagascar'),
  mw: i18n.t('Malawi'),
  my: i18n.t('Malaysia'),
  mv: i18n.t('Maldives'),
  ml: i18n.t('Mali'),
  mt: i18n.t('Malta'),
  mh: i18n.t('Marshall Islands'),
  mq: i18n.t('Martinique'),
  mr: i18n.t('Mauritania'),
  mu: i18n.t('Mauritius'),
  yt: i18n.t('Mayotte'),
  mx: i18n.t('Mexico'),
  fm: i18n.t('Micronesia'),
  md: i18n.t('Moldova'),
  mc: i18n.t('Monaco'),
  mn: i18n.t('Mongolia'),
  ms: i18n.t('Montserrat'),
  ma: i18n.t('Morocco'),
  mz: i18n.t('Mozambique'),
  mm: i18n.t('Myanmar'),
  na: i18n.t('Namibia'),
  nr: i18n.t('Nauru'),
  np: i18n.t('Nepal'),
  nl: i18n.t('Netherlands'),
  an: i18n.t('Netherlands Antilles'),
  nc: i18n.t('New Caledonia'),
  nz: i18n.t('New Zealand'),
  ni: i18n.t('Nicaragua'),
  ne: i18n.t('Niger'),
  ng: i18n.t('Nigeria'),
  nu: i18n.t('Niue'),
  nf: i18n.t('Norfolk Island'),
  mp: i18n.t('Northern Mariana Islands'),
  no: i18n.t('Norway'),
  om: i18n.t('Oman'),
  pk: i18n.t('Pakistan'),
  pw: i18n.t('Palau'),
  ps: i18n.t('Palestine'),
  pa: i18n.t('Panama'),
  pg: i18n.t('Papua New Guinea'),
  py: i18n.t('Paraguay'),
  pe: i18n.t('Peru'),
  ph: i18n.t('Philippines'),
  pn: i18n.t('Pitcairn'),
  pl: i18n.t('Poland'),
  pt: i18n.t('Portugal'),
  pr: i18n.t('Puerto Rico'),
  qa: i18n.t('Qatar'),
  re: i18n.t('Reunion'),
  ro: i18n.t('Romania'),
  ru: i18n.t('Russia'),
  rw: i18n.t('Rwanda'),
  sh: i18n.t('Saint Helena, Ascension and Tristan da Cunha'),
  kn: i18n.t('Saint Kitts and Nevis'),
  lc: i18n.t('Saint Lucia'),
  pm: i18n.t('Saint Pierre and Miquelon'),
  vc: i18n.t('Saint Vincent and the Grenadines'),
  ws: i18n.t('Samoa'),
  sm: i18n.t('San Marino'),
  st: i18n.t('Sao Tome and Principe'),
  sa: i18n.t('Saudi Arabia'),
  sn: i18n.t('Senegal'),
  rs: i18n.t('Serbia and Montenegro'),
  sc: i18n.t('Seychelles'),
  sl: i18n.t('Sierra Leone'),
  sg: i18n.t('Singapore'),
  sk: i18n.t('Slovakia'),
  si: i18n.t('Slovenia'),
  sb: i18n.t('Solomon Islands'),
  so: i18n.t('Somalia'),
  za: i18n.t('South Africa'),
  gs: i18n.t('South Georgia and the South Sandwich Islands'),
  es: i18n.t('Spain'),
  lk: i18n.t('Sri Lanka'),
  sd: i18n.t('Sudan'),
  sr: i18n.t('Suriname'),
  sj: i18n.t('Svalbard and Jan Mayen'),
  sz: i18n.t('Swaziland'),
  se: i18n.t('Sweden'),
  ch: i18n.t('Switzerland'),
  sy: i18n.t('Syria'),
  tw: i18n.t('Taiwan'),
  tj: i18n.t('Tajikistan'),
  tz: i18n.t('Tanzania'),
  th: i18n.t('Thailand'),
  tl: i18n.t('Timor-Leste'),
  tg: i18n.t('Togo'),
  tk: i18n.t('Tokelau'),
  to: i18n.t('Tonga'),
  tt: i18n.t('Trinidad and Tobago'),
  tn: i18n.t('Tunisia'),
  tr: i18n.t('Turkey'),
  tm: i18n.t('Turkmenistan'),
  tc: i18n.t('Turks and Caicos Islands'),
  tv: i18n.t('Tuvalu'),
  ug: i18n.t('Uganda'),
  ua: i18n.t('Ukraine'),
  ae: i18n.t('United Arab Emirates'),
  uk: i18n.t('United Kingdom'),
  gb: i18n.t('United Kingdom'),
  us: i18n.t('United States'),
  um: i18n.t('United States Minor Outlying Islands'),
  uy: i18n.t('Uruguay'),
  uz: i18n.t('Uzbekistan'),
  vu: i18n.t('Vanuatu'),
  ve: i18n.t('Venezuela'),
  vn: i18n.t('Vietnam'),
  vg: i18n.t('Virgin Islands, British'),
  vi: i18n.t('Virgin Islands, U.S.'),
  wf: i18n.t('Wallis and Futuna'),
  eh: i18n.t('Western Sahara'),
  ye: i18n.t('Yemen'),
  zm: i18n.t('Zambia'),
  zw: i18n.t('Zimbabwe'),
};

export { codeToCountry };

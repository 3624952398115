import i18n from 'i18n';

const serpGoogleDomains = [
  {
    value: 'google.ad',
    label: i18n.t('Andorra') + ' - google.ad',
  },
  {
    value: 'google.ae',
    label: i18n.t('United Arab Emirates') + ' - google.ae',
  },
  {
    value: 'google.al',
    label: i18n.t('Albania') + ' - google.al',
  },
  {
    value: 'google.am',
    label: i18n.t('Armenia') + ' - google.am',
  },
  {
    value: 'google.as',
    label: i18n.t('American Samoa') + ' - google.as',
  },
  {
    value: 'google.at',
    label: i18n.t('Austria') + ' - google.at',
  },
  {
    value: 'google.az',
    label: i18n.t('Azerbaijan') + ' - google.az',
  },
  {
    value: 'google.ba',
    label: i18n.t('Bosnia and Herzegovina') + ' - google.ba',
  },
  {
    value: 'google.be',
    label: i18n.t('Belgium') + ' - google.be',
  },
  {
    value: 'google.bf',
    label: i18n.t('Burkina Faso') + ' - google.bf',
  },
  {
    value: 'google.bg',
    label: i18n.t('Bulgaria') + ' - google.bg',
  },
  {
    value: 'google.bi',
    label: i18n.t('Burundi') + ' - google.bi',
  },
  {
    value: 'google.bj',
    label: i18n.t('Benin') + ' - google.bj',
  },
  {
    value: 'google.bs',
    label: i18n.t('Bahamas') + ' - google.bs',
  },
  {
    value: 'google.bt',
    label: i18n.t('Bhutan') + ' - google.bt',
  },
  {
    value: 'google.by',
    label: i18n.t('Belarus') + ' - google.by',
  },
  {
    value: 'google.ca',
    label: i18n.t('Canada') + ' - google.ca',
  },
  {
    value: 'google.cc',
    label: i18n.t('Cocos (Keeling) Islands') + ' - google.cc',
  },
  {
    value: 'google.cd',
    label: i18n.t('Congo, the Democratic Republic of the') + ' - google.cd',
  },
  {
    value: 'google.cf',
    label: i18n.t('Central African Republic') + ' - google.cf',
  },
  {
    value: 'google.cg',
    label: i18n.t('Congo') + ' - google.cg',
  },
  {
    value: 'google.ch',
    label: i18n.t('Switzerland') + ' - google.ch',
  },
  {
    value: 'google.ci',
    label: i18n.t('Ivory Coast') + ' - google.ci',
  },
  {
    value: 'google.cl',
    label: i18n.t('Chile') + ' - google.cl',
  },
  {
    value: 'google.cm',
    label: i18n.t('Cameroon') + ' - google.cm',
  },
  {
    value: 'google.cn',
    label: i18n.t('China') + ' - google.cn',
  },
  {
    value: 'google.co.ao',
    label: i18n.t('Angola') + ' - google.co.ao',
  },
  {
    value: 'google.co.bw',
    label: i18n.t('Botswana') + ' - google.co.bw',
  },
  {
    value: 'google.co.ck',
    label: i18n.t('Cook Islands') + ' - google.co.ck',
  },
  {
    value: 'google.co.cr',
    label: i18n.t('Costa Rica') + ' - google.co.cr',
  },
  {
    value: 'google.co.id',
    label: i18n.t('Indonesia') + ' - google.co.id',
  },
  {
    value: 'google.co.il',
    label: i18n.t('Israel') + ' - google.co.il',
  },
  {
    value: 'google.co.in',
    label: i18n.t('India') + ' - google.co.in',
  },
  {
    value: 'google.co.jp',
    label: i18n.t('Japan') + ' - google.co.jp',
  },
  {
    value: 'google.co.ke',
    label: i18n.t('Kenya') + ' - google.co.ke',
  },
  {
    value: 'google.co.kr',
    label: i18n.t('South Korea') + ' - google.co.kr',
  },
  {
    value: 'google.co.ls',
    label: i18n.t('Lesotho') + ' - google.co.ls',
  },
  {
    value: 'google.co.ma',
    label: i18n.t('Morocco') + ' - google.co.ma',
  },
  {
    value: 'google.co.mz',
    label: i18n.t('Mozambique') + ' - google.co.mz',
  },
  {
    value: 'google.co.nz',
    label: i18n.t('New Zealand') + ' - google.co.nz',
  },
  {
    value: 'google.co.th',
    label: i18n.t('Thailand') + ' - google.co.th',
  },
  {
    value: 'google.co.tz',
    label: i18n.t('Tanzania') + ' - google.co.tz',
  },
  {
    value: 'google.co.ug',
    label: i18n.t('Uganda') + ' - google.co.ug',
  },
  {
    value: 'google.co.uk',
    label: i18n.t('United Kingdom') + ' - google.co.uk',
  },
  {
    value: 'google.co.uz',
    label: i18n.t('Uzbekistan') + ' - google.co.uz',
  },
  {
    value: 'google.co.ve',
    label: i18n.t('Venezuela') + ' - google.co.ve',
  },
  {
    value: 'google.co.vi',
    label: i18n.t('Virgin Islands, U.S.') + ' - google.co.vi',
  },
  {
    value: 'google.co.za',
    label: i18n.t('South Africa') + ' - google.co.za',
  },
  {
    value: 'google.co.zm',
    label: i18n.t('Zambia') + ' - google.co.zm',
  },
  {
    value: 'google.co.zw',
    label: i18n.t('Zimbabwe') + ' - google.co.zw',
  },
  {
    value: 'google.com',
    label: i18n.t('United States') + ' - google.com',
  },
  {
    value: 'google.com.af',
    label: i18n.t('Afghanistan') + ' - google.com.af',
  },
  {
    value: 'google.com.ag',
    label: i18n.t('Antigua and Barbuda') + ' - google.com.ag',
  },
  {
    value: 'google.com.ai',
    label: i18n.t('Anguilla') + ' - google.com.ai',
  },
  {
    value: 'google.com.ar',
    label: i18n.t('Argentina') + ' - google.com.ar',
  },
  {
    value: 'google.com.au',
    label: i18n.t('Australia') + ' - google.com.au',
  },
  {
    value: 'google.com.bd',
    label: i18n.t('Bangladesh') + ' - google.com.bd',
  },
  {
    value: 'google.com.bh',
    label: i18n.t('Bahrain') + ' - google.com.bh',
  },
  {
    value: 'google.com.bn',
    label: i18n.t('Brunei') + ' - google.com.bn',
  },
  {
    value: 'google.com.bo',
    label: i18n.t('Bolivia') + ' - google.com.bo',
  },
  {
    value: 'google.com.br',
    label: i18n.t('Brazil') + ' - google.com.br',
  },
  {
    value: 'google.com.bz',
    label: i18n.t('Belize') + ' - google.com.bz',
  },
  {
    value: 'google.com.co',
    label: i18n.t('Colombia') + ' - google.com.co',
  },
  {
    value: 'google.com.cu',
    label: i18n.t('Cuba') + ' - google.com.cu',
  },
  {
    value: 'google.com.cy',
    label: i18n.t('Cyprus') + ' - google.com.cy',
  },
  {
    value: 'google.com.do',
    label: i18n.t('Dominican Republic') + ' - google.com.do',
  },
  {
    value: 'google.com.ec',
    label: i18n.t('Ecuador') + ' - google.com.ec',
  },
  {
    value: 'google.com.eg',
    label: i18n.t('Egypt') + ' - google.com.eg',
  },
  {
    value: 'google.com.et',
    label: i18n.t('Ethiopia') + ' - google.com.et',
  },
  {
    value: 'google.com.fj',
    label: i18n.t('Fiji') + ' - google.com.fj',
  },
  {
    value: 'google.com.gh',
    label: i18n.t('Ghana') + ' - google.com.gh',
  },
  {
    value: 'google.com.gi',
    label: i18n.t('Gibraltar') + ' - google.com.gi',
  },
  {
    value: 'google.com.gt',
    label: i18n.t('Guatemala') + ' - google.com.gt',
  },
  {
    value: 'google.com.hk',
    label: i18n.t('Hong Kong') + ' - google.com.hk',
  },
  {
    value: 'google.com.jm',
    label: i18n.t('Jamaica') + ' - google.com.jm',
  },
  {
    value: 'google.com.kh',
    label: i18n.t('Cambodia') + ' - google.com.kh',
  },
  {
    value: 'google.com.kw',
    label: i18n.t('Kuwait') + ' - google.com.kw',
  },
  {
    value: 'google.com.lb',
    label: i18n.t('Lebanon') + ' - google.com.lb',
  },
  {
    value: 'google.com.ly',
    label: i18n.t('Libya') + ' - google.com.ly',
  },
  {
    value: 'google.com.mm',
    label: i18n.t('Myanmar') + ' - google.com.mm',
  },
  {
    value: 'google.com.mt',
    label: i18n.t('Malta') + ' - google.com.mt',
  },
  {
    value: 'google.com.mx',
    label: i18n.t('Mexico') + ' - google.com.mx',
  },
  {
    value: 'google.com.my',
    label: i18n.t('Malaysia') + ' - google.com.my',
  },
  {
    value: 'google.com.na',
    label: i18n.t('Namibia') + ' - google.com.na',
  },
  {
    value: 'google.com.ng',
    label: i18n.t('Nigeria') + ' - google.com.ng',
  },
  {
    value: 'google.com.ni',
    label: i18n.t('Nicaragua') + ' - google.com.ni',
  },
  {
    value: 'google.com.np',
    label: i18n.t('Nepal') + ' - google.com.np',
  },
  {
    value: 'google.com.om',
    label: i18n.t('Oman') + ' - google.com.om',
  },
  {
    value: 'google.com.pa',
    label: i18n.t('Panama') + ' - google.com.pa',
  },
  {
    value: 'google.com.pe',
    label: i18n.t('Peru') + ' - google.com.pe',
  },
  {
    value: 'google.com.pg',
    label: i18n.t('Papua New Guinea') + ' - google.com.pg',
  },
  {
    value: 'google.com.ph',
    label: i18n.t('Philippines') + ' - google.com.ph',
  },
  {
    value: 'google.com.pk',
    label: i18n.t('Pakistan') + ' - google.com.pk',
  },
  {
    value: 'google.com.pr',
    label: i18n.t('Puerto Rico') + ' - google.com.pr',
  },
  {
    value: 'google.com.py',
    label: i18n.t('Paraguay') + ' - google.com.py',
  },
  {
    value: 'google.com.qa',
    label: i18n.t('Qatar') + ' - google.com.qa',
  },
  {
    value: 'google.com.sa',
    label: i18n.t('Saudi Arabia') + ' - google.com.sa',
  },
  {
    value: 'google.com.sb',
    label: i18n.t('Solomon Islands') + ' - google.com.sb',
  },
  {
    value: 'google.com.sg',
    label: i18n.t('Singapore') + ' - google.com.sg',
  },
  {
    value: 'google.com.sl',
    label: i18n.t('Sierra Leone') + ' - google.com.sl',
  },
  {
    value: 'google.com.sv',
    label: i18n.t('El Salvador') + ' - google.com.sv',
  },
  {
    value: 'google.com.tj',
    label: i18n.t('Tajikistan') + ' - google.com.tj',
  },
  {
    value: 'google.com.tr',
    label: i18n.t('Turkey') + ' - google.com.tr',
  },
  {
    value: 'google.com.tw',
    label: i18n.t('Taiwan') + ' - google.com.tw',
  },
  {
    value: 'google.com.ua',
    label: i18n.t('Ukraine') + ' - google.com.ua',
  },
  {
    value: 'google.com.uy',
    label: i18n.t('Uruguay') + ' - google.com.uy',
  },
  {
    value: 'google.com.vc',
    label: i18n.t('Saint Vincent and the Grenadines') + ' - google.com.vc',
  },
  {
    value: 'google.com.vn',
    label: i18n.t('Vietnam') + ' - google.com.vn',
  },
  {
    value: 'google.cv',
    label: i18n.t('Cape Verde') + ' - google.cv',
  },
  {
    value: 'google.cx',
    label: i18n.t('Christmas Island') + ' - google.cx',
  },
  {
    value: 'google.cz',
    label: i18n.t('Czech Republic') + ' - google.cz',
  },
  {
    value: 'google.de',
    label: i18n.t('Germany') + ' - google.de',
  },
  {
    value: 'google.dj',
    label: i18n.t('Djibouti') + ' - google.dj',
  },
  {
    value: 'google.dk',
    label: i18n.t('Denmark') + ' - google.dk',
  },
  {
    value: 'google.dm',
    label: i18n.t('Dominica') + ' - google.dm',
  },
  {
    value: 'google.dz',
    label: i18n.t('Algeria') + ' - google.dz',
  },
  {
    value: 'google.ee',
    label: i18n.t('Estonia') + ' - google.ee',
  },
  {
    value: 'google.es',
    label: i18n.t('Spain') + ' - google.es',
  },
  {
    value: 'google.fi',
    label: i18n.t('Finland') + ' - google.fi',
  },
  {
    value: 'google.fm',
    label: i18n.t('Micronesia') + ' - google.fm',
  },
  {
    value: 'google.fr',
    label: i18n.t('France') + ' - google.fr',
  },
  {
    value: 'google.ga',
    label: i18n.t('Gabon') + ' - google.ga',
  },
  {
    value: 'google.ge',
    label: i18n.t('Georgia') + ' - google.ge',
  },
  {
    value: 'google.gf',
    label: i18n.t('French Guiana') + ' - google.gf',
  },
  {
    value: 'google.gl',
    label: i18n.t('Greenland') + ' - google.gl',
  },
  {
    value: 'google.gm',
    label: i18n.t('Gambia') + ' - google.gm',
  },
  {
    value: 'google.gp',
    label: i18n.t('Guadeloupe') + ' - google.gp',
  },
  {
    value: 'google.gr',
    label: i18n.t('Greece') + ' - google.gr',
  },
  {
    value: 'google.gy',
    label: i18n.t('Guyana') + ' - google.gy',
  },
  {
    value: 'google.hn',
    label: i18n.t('Honduras') + ' - google.hn',
  },
  {
    value: 'google.hr',
    label: i18n.t('Croatia') + ' - google.hr',
  },
  {
    value: 'google.ht',
    label: i18n.t('Haiti') + ' - google.ht',
  },
  {
    value: 'google.hu',
    label: i18n.t('Hungary') + ' - google.hu',
  },
  {
    value: 'google.ie',
    label: i18n.t('Ireland') + ' - google.ie',
  },
  {
    value: 'google.io',
    label: i18n.t('British Indian Ocean Territory') + ' - google.io',
  },
  {
    value: 'google.iq',
    label: i18n.t('Iraq') + ' - google.iq',
  },
  {
    value: 'google.is',
    label: i18n.t('Iceland') + ' - google.is',
  },
  {
    value: 'google.it',
    label: i18n.t('Italy') + ' - google.it',
  },
  {
    value: 'google.jo',
    label: i18n.t('Jordan') + ' - google.jo',
  },
  {
    value: 'google.kg',
    label: i18n.t('Kyrgyzstan') + ' - google.kg',
  },
  {
    value: 'google.ki',
    label: i18n.t('Kiribati') + ' - google.ki',
  },
  {
    value: 'google.kz',
    label: i18n.t('Kazakhstan') + ' - google.kz',
  },
  {
    value: 'google.la',
    label: i18n.t('Laos') + ' - google.la',
  },
  {
    value: 'google.li',
    label: i18n.t('Liechtenstein') + ' - google.li',
  },
  {
    value: 'google.lk',
    label: i18n.t('Sri Lanka') + ' - google.lk',
  },
  {
    value: 'google.lt',
    label: i18n.t('Lithuania') + ' - google.lt',
  },
  {
    value: 'google.lu',
    label: i18n.t('Luxembourg') + ' - google.lu',
  },
  {
    value: 'google.lv',
    label: i18n.t('Latvia') + ' - google.lv',
  },
  {
    value: 'google.md',
    label: i18n.t('Moldova') + ' - google.md',
  },
  {
    value: 'google.mg',
    label: i18n.t('Madagascar') + ' - google.mg',
  },
  {
    value: 'google.mk',
    label: i18n.t('Macedonia') + ' - google.mk',
  },
  {
    value: 'google.ml',
    label: i18n.t('Mali') + ' - google.ml',
  },
  {
    value: 'google.mn',
    label: i18n.t('Mongolia') + ' - google.mn',
  },
  {
    value: 'google.ms',
    label: i18n.t('Montserrat') + ' - google.ms',
  },
  {
    value: 'google.mu',
    label: i18n.t('Mauritius') + ' - google.mu',
  },
  {
    value: 'google.mv',
    label: i18n.t('Maldives') + ' - google.mv',
  },
  {
    value: 'google.mw',
    label: i18n.t('Malawi') + ' - google.mw',
  },
  {
    value: 'google.ne',
    label: i18n.t('Niger') + ' - google.ne',
  },
  {
    value: 'google.nf',
    label: i18n.t('Norfolk Island') + ' - google.nf',
  },
  {
    value: 'google.nl',
    label: i18n.t('Netherlands') + ' - google.nl',
  },
  {
    value: 'google.no',
    label: i18n.t('Norway') + ' - google.no',
  },
  {
    value: 'google.nr',
    label: i18n.t('Nauru') + ' - google.nr',
  },
  {
    value: 'google.nu',
    label: i18n.t('Niue') + ' - google.nu',
  },
  {
    value: 'google.pl',
    label: i18n.t('Poland') + ' - google.pl',
  },
  {
    value: 'google.ps',
    label: i18n.t('Palestine') + ' - google.ps',
  },
  {
    value: 'google.pt',
    label: i18n.t('Portugal') + ' - google.pt',
  },
  {
    value: 'google.ro',
    label: i18n.t('Romania') + ' - google.ro',
  },
  {
    value: 'google.rs',
    label: i18n.t('Serbia and Montenegro') + ' - google.rs',
  },
  {
    value: 'google.ru',
    label: i18n.t('Russia') + ' - google.ru',
  },
  {
    value: 'google.rw',
    label: i18n.t('Rwanda') + ' - google.rw',
  },
  {
    value: 'google.sc',
    label: i18n.t('Seychelles') + ' - google.sc',
  },
  {
    value: 'google.se',
    label: i18n.t('Sweden') + ' - google.se',
  },
  {
    value: 'google.sh',
    label: i18n.t('Saint Helena, Ascension and Tristan da Cunha') + ' - google.sh',
  },
  {
    value: 'google.si',
    label: i18n.t('Slovenia') + ' - google.si',
  },
  {
    value: 'google.sk',
    label: i18n.t('Slovakia') + ' - google.sk',
  },
  {
    value: 'google.sm',
    label: i18n.t('San Marino') + ' - google.sm',
  },
  {
    value: 'google.sn',
    label: i18n.t('Senegal') + ' - google.sn',
  },
  {
    value: 'google.so',
    label: i18n.t('Somalia') + ' - google.so',
  },
  {
    value: 'google.sr',
    label: i18n.t('Suriname') + ' - google.sr',
  },
  {
    value: 'google.td',
    label: i18n.t('Chad') + ' - google.td',
  },
  {
    value: 'google.tg',
    label: i18n.t('Togo') + ' - google.tg',
  },
  {
    value: 'google.tk',
    label: i18n.t('Tokelau') + ' - google.tk',
  },
  {
    value: 'google.tl',
    label: i18n.t('Timor-Leste') + ' - google.tl',
  },
  {
    value: 'google.tm',
    label: i18n.t('Turkmenistan') + ' - google.tm',
  },
  {
    value: 'google.tn',
    label: i18n.t('Tunisia') + ' - google.tn',
  },
  {
    value: 'google.to',
    label: i18n.t('Tonga') + ' - google.to',
  },
  {
    value: 'google.tt',
    label: i18n.t('Trinidad and Tobago') + ' - google.tt',
  },
  {
    value: 'google.vg',
    label: i18n.t('Virgin Islands, British') + ' - google.vg',
  },
  {
    value: 'google.vu',
    label: i18n.t('Vanuatu') + ' - google.vu',
  },
  {
    value: 'google.ws',
    label: i18n.t('Samoa') + ' - google.ws',
  },
];

export { serpGoogleDomains };

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

import { ButtonViewStyle } from './styles';

const ButtonView = ({ onClickFunction }) => {
  const { t: translate } = useTranslation();

  return (
    <ButtonViewStyle>
      <Button className="btn-view" onClick={onClickFunction}>
        {translate('View')}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
        >
          <path
            d="M9.33582 5.4915L4.31507 10.5122L3.49023 9.68742L8.5104 4.66667H4.08582V3.5H10.5025V9.91667H9.33582V5.4915Z"
            fill="#4A5568"
          />
        </svg>
      </Button>
    </ButtonViewStyle>
  );
};

ButtonView.propTypes = {
  onClickFunction: PropTypes.func.isRequired,
};

export default ButtonView;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import 'boxicons';

import { useAuth } from 'hooks/useAuth';
import { getFirebaseBackend } from 'helpers/firebaseHelper';
import PageStructure from 'components/Page-Structure';
import ShowComponent from 'components/Show-Component';
import Shimmer from 'components/Shimmer';
import CoursesPaginated from './components/CoursesPaginated';

import { BreadCrumbBtn, NoCourseMessage } from './styles';

const CARDS_PER_PAGE = 12;

const CoursesList = () => {
  const firebaseHelper = getFirebaseBackend();
  const { t: translate } = useTranslation();
  const { user } = useAuth();
  const accountId = user.account;
  const { adminStatus } = user;
  const [courses, setCourses] = useState([]);
  const [library, setLibrary] = useState([]);
  const [loading, setLoading] = useState(true);

  const onEmptyCourses = (
    <NoCourseMessage>
      <box-icon name="archive" color="#000" size="1.5rem" />
      <div>
        {translate('No courses were found for your account in the moment. Try again later!')}
      </div>
    </NoCourseMessage>
  );

  const fetchCourses = async () => {
    const coursesObj = await firebaseHelper.getAllFirebaseCourses(accountId, adminStatus);

    const coursesArr = Object.entries(coursesObj ?? {}).map(([courseId, courseObj]) => ({
      ...courseObj,
      id: courseId,
    }));

    fetchLibrary();
    setCourses(coursesArr);
    setLoading(false);
  };

  const fetchLibrary = async () => {
    const libraryObj = await firebaseHelper.getAllFirebaseLibraryCourse(user.id);

    const newLibrary = {};
    Object.entries(libraryObj || {}).forEach(([courseId, libraryObj]) => {
      newLibrary[courseId] = libraryObj;
    });

    setLibrary(newLibrary);
  };

  useEffect(() => {
    if (!accountId) return;

    fetchCourses();
  }, [accountId]);

  return (
    <PageStructure metaTitle="Courses">
      <div className="d-flex justify-content-between">
        <h4 className="mb-0 font-size-18">{translate('Courses')}</h4>

        <BreadCrumbBtn to="/library">
          <box-icon name="bookmark-heart" size="1.2rem" color="#50A5F1" />
          {translate('Go to My Library')}
        </BreadCrumbBtn>
      </div>

      <ShowComponent condition={loading}>
        {Array.from({ length: CARDS_PER_PAGE }).map((item, index) => (
          <Shimmer
            key={index}
            width="250px"
            height="300px"
            style={{ display: 'inline-block', margin: '1.5em 1em 1em 0' }}
          />
        ))}
      </ShowComponent>

      <ShowComponent condition={!loading}>
        <CoursesPaginated
          data={courses}
          updateData={fetchCourses}
          library={library}
          itemsPerPage={CARDS_PER_PAGE}
          onZero={onEmptyCourses}
        />
      </ShowComponent>
    </PageStructure>
  );
};

CoursesList.displayName = 'Courses List Page';

export default CoursesList;

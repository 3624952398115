import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { resourcesEnUS } from './locales/resourcesEnUS';
import { resourcesPtBR } from './locales/resourcesPtBR';

const resources = {
  enUS: {
    translation: resourcesEnUS,
  },
  ptBR: {
    translation: resourcesPtBR,
  },
};

const language = localStorage.getItem('I18N_LANGUAGE');
if (!language) {
  localStorage.setItem('I18N_LANGUAGE', 'ptBR');
}

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('I18N_LANGUAGE') || 'ptBR',
    fallbackLng: 'ptBR',

    keySeparator: false,
    nsSeparator: false,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

import React, { useState, useRef, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import { useAuth } from 'hooks/useAuth';
import { getFirebaseBackend } from '../../helpers/firebaseHelper';
import ModalContentContext from '../../components/Modal-Content/ModalContent.Context';
import ExperimentConfig from './ExperimentConfig';
import ExperimentGraphs from './ExperimentGraphs';
import { StyledNav } from './styles';

const ExperimentsDashboard = () => {
  const firebaseHelper = getFirebaseBackend();
  const { t: translate } = useTranslation();
  const { user } = useAuth();
  const accountId = user.account;
  const [configExperiment, setConfigExperiment] = useState(false);
  const { content } = useContext(ModalContentContext);
  const GraphRef = useRef();
  const ConfigRef = useRef();

  const handleTabs = (event) => {
    const index = event.target.getAttribute('navLink');

    const allContents = document.querySelectorAll('.tab-pane');
    const thisContent = document.querySelector(`.tab-pane[tabIndex='${index}']`);

    const allTabs = document.querySelectorAll('.nav-link');
    const thisTab = event.target;

    allTabs.forEach((tab) => {
      tab.classList.remove('active-tab');
    });
    thisTab.classList.add('active-tab');
    allContents.forEach((content) => {
      content.classList.remove('active');
    });
    thisContent.classList.add('active');
  };

  async function fetchExperiment() {
    const experiment = await firebaseHelper.getFirebaseExperiment(accountId, content.id);
    if (experiment) return setConfigExperiment(true);
    setConfigExperiment(false);
  }

  useEffect(() => {
    const url = new URL(window.location.href);
    if (configExperiment && url.searchParams.get('expGraph')) return GraphRef.current.click();
    if (url.searchParams.get('expConfig')) ConfigRef.current.click();
  }, [configExperiment]);

  useEffect(() => {
    fetchExperiment();
  }, []);

  return (
    <>
      <StyledNav tabs style={{ paddingLeft: '2rem' }}>
        <NavItem>
          <NavLink
            className="nav-link active-tab"
            onClick={handleTabs}
            navLink="1"
            innerRef={ConfigRef}
          >
            {translate('Experiment Configuration')}
          </NavLink>
        </NavItem>
        {configExperiment && (
          <NavItem>
            <NavLink className="nav-link" onClick={handleTabs} navLink="2" innerRef={GraphRef}>
              {translate('Experiment')}
            </NavLink>
          </NavItem>
        )}
      </StyledNav>

      <TabContent activeTab="1">
        <TabPane tabId="1" tabIndex="1">
          <ExperimentConfig />
        </TabPane>
        {configExperiment && (
          <TabPane tabId="2" tabIndex="2">
            <ExperimentGraphs />
          </TabPane>
        )}
      </TabContent>
    </>
  );
};

export default ExperimentsDashboard;

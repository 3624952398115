import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Button,
  UncontrolledTooltip,
  Input,
  InputGroup,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { getDatabase, ref, onValue, off } from 'firebase/database';

import { useAuth } from 'hooks/useAuth';
import ShowComponent from '../../components/Show-Component';
import ModalContentContext from '../../components/Modal-Content/ModalContent.Context';
import ModalContent from '../../components/Modal-Content/';
import TaskModalContext from './TaskModal.Context';
import ExperimentsContent from 'components/Experiments-Content';
import IconButton from '../../components/Icon-Button';
import { getFirebaseBackend } from '../../helpers/firebaseHelper';
import { formatDate, copyToClipBoard, setPFPDebounced } from '../../utils/';

import {
  statusColorClass,
  priorityColorClass,
  complexityTooltip,
  impactTooltip,
  statusNumber,
} from '../../constants/index';
import { BodyDiv, HeaderDiv, CancelButton, DeleteButton, SelectAssignedToDiv } from './styles';

const TaskModal = ({ accountId }) => {
  const firebaseHelper = getFirebaseBackend();

  const { t: translate } = useTranslation();
  const { user, changeAccount } = useAuth();
  const { adminStatus, modal, setModal, id, acc } = useContext(TaskModalContext);
  const [content, setContent] = useState({});
  const [files, setFiles] = useState([]);
  const [comments, setComments] = useState([]);
  const [checklist, setChecklist] = useState([]);
  const [timeSpent, setTimeSpent] = useState(0);
  const [timeSpentSuccess, setTimeSuccess] = useState(false);
  const [configExperiment, setConfigExperiment] = useState(false);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [showExperiments, setShowExperiments] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [teamUsers, setTeamUsers] = useState([]);

  useEffect(() => {
    if (!modal || !id) return;

    let allowChangeAccount = false;
    if (acc && acc !== accountId && adminStatus === 'Super Admin') {
      changeAccount(user.id, acc);
      allowChangeAccount = true;
    }

    const db = getDatabase();
    const taskRef = ref(db, `tasks/${allowChangeAccount ? acc : accountId}/${id}`);
    const commentRef = ref(db, `comments/${allowChangeAccount ? acc : accountId}/${id}`);
    const checklistRef = ref(db, `checklists/${allowChangeAccount ? acc : accountId}/${id}`);

    // Subscribes to the task data
    const handleTaskValueChange = async (snapshot) => {
      const data = snapshot.val();
      if (!data) {
        return setModal(false);
      }
      data.assignedToNames = await Promise.all(
        data.assignedTo.map(async (member) => await firebaseHelper.userName(member)),
      );
      data.checkStats = await firebaseHelper.getChecklistStats({
        accountId,
        projectId: '1',
        taskId: id,
      });
      const isExperimentConfigured = await firebaseHelper.getFirebaseExperiment(accountId, id);
      setTimeSpent(data.timeSpent ?? 0);
      setConfigExperiment(Boolean(isExperimentConfigured));
      setContent(() => data);
    };

    const handleCommentValueChange = async (snapshot) => {
      const data = snapshot.val();
      const commentArr = data ? Object.values(data) : null;
      if (commentArr) {
        const asyncComment = await Promise.all(
          commentArr.map(async (comment) => {
            const processedName = await firebaseHelper.userName(comment.by);
            const selfBox = comment.by === firebaseHelper.currentUser().uid;
            const dropdown = false;
            return {
              ...comment,
              byName: processedName,
              self: selfBox,
              dropdown,
            };
          }),
        );
        setComments(asyncComment);
      } else {
        setComments(null);
      }
    };

    const handleChecklistValueChange = async (snapshot) => {
      const data = snapshot.val();
      const checklistArr = data ? Object.values(data) : null;

      checklistArr?.sort((a, b) => a.position - b.position);
      const newCheckStats = await firebaseHelper.getChecklistStats({
        accountId,
        projectId: '1',
        taskId: id,
      });
      setContent((prevState) => ({ ...prevState, checkStats: newCheckStats }));
      setChecklist(checklistArr);
    };

    onValue(taskRef, handleTaskValueChange);
    onValue(commentRef, handleCommentValueChange);
    onValue(checklistRef, handleChecklistValueChange);

    // Unsubscribes from the task data
    return () => {
      off(taskRef, 'value', handleTaskValueChange);
      off(commentRef, 'value', handleCommentValueChange);
      off(checklistRef, 'value', handleChecklistValueChange);
    };
  }, [modal, id]);

  useEffect(async () => {
    if (!content?.hasFiles) return;

    const files = await firebaseHelper.listAllFiles(`${accountId}/1/${id}`);
    setFiles(files);
  }, [content]);

  useEffect(() => {
    isMarkdownOverflown();
  }, [showExperiments]);

  useEffect(() => {
    if (showExperiments) return;

    const url = new URL(window.location.href);
    const config = url.searchParams.get('expConfig');
    const graph = url.searchParams.get('expGraph');
    if (config || graph) setShowExperiments(true);
  });

  const onCardOpened = () => {
    // Changes the URL to add the card's parameter
    const url = new URL(window.location.href);
    url.searchParams.set('id', String(id));
    url.searchParams.set('acc', String(acc));
    window.history.replaceState({ path: url.toString() }, document.title, url.toString());
    isMarkdownOverflown();
  };

  const onCardClosed = () => {
    // Changes the URL to remove the card's parameter
    const url = new URL(window.location.href);
    url.searchParams.delete('id');
    url.searchParams.delete('acc');
    url.searchParams.delete('expGraph');
    url.searchParams.delete('expConfig');
    window.history.replaceState({ path: url.toString() }, document.title, url.toString());
    // Removes files and comments from state to avoid visual glitching of previous files on new card open
    setShowMoreButton(false);
    setContent(null);
    setFiles(null);
    setComments(null);
    setChecklist(null);
    setTimeSuccess(false);
    setShowExperiments(false);
  };

  const confirmDeleteTask = () => {
    setConfirmDeleteModal(true);
  };

  const deleteTask = () => {
    firebaseHelper.removeTask(accountId, content.id);
    setModal(false);
    setConfirmDeleteModal(false);
  };

  const duplicateTask = () => {
    firebaseHelper.duplicateTask(accountId, content.id, user.id);
  };

  const handleAssignedToClick = async (userId) => {
    const isAssignedTo = content?.assignedTo?.includes(userId);
    const taskPath = {
      clientId: accountId,
      projectId: '1',
      id: content.id,
      taskId: content.id,
    };

    if (isAssignedTo && content?.assignedTo?.length > 1) {
      const newAssignedTo = content?.assignedTo?.filter((member) => member !== userId);
      await firebaseHelper.updateTask({
        ...content,
        ...taskPath,
        assignedTo: newAssignedTo,
      });
      const assignedToNames = await Promise.all(
        newAssignedTo.map(async (member) => await firebaseHelper.userName(member)),
      );
      setContent({
        ...content,
        assignedTo: newAssignedTo,
        assignedToNames,
      });
    } else if (!isAssignedTo) {
      const newAssignedTo = [...content?.assignedTo, userId];
      await firebaseHelper.updateTask({
        ...content,
        ...taskPath,
        assignedTo: newAssignedTo,
      });
      const assignedToNames = await Promise.all(
        newAssignedTo.map(async (member) => await firebaseHelper.userName(member)),
      );
      setContent({
        ...content,
        assignedTo: newAssignedTo,
        assignedToNames,
      });
    }
  };

  const copyTask = (event, id) => {
    const url = new URL(window.location.href);
    url.searchParams.set('id', String(id));
    copyToClipBoard(event, url.toString());
  };

  const isMarkdownOverflown = async () => {
    const markdownElem = document.querySelector('.markdown-description');
    const fadeElem = document.querySelector('.markdown-description-fade');

    if (!markdownElem || !fadeElem) {
      setTimeout(isMarkdownOverflown, 100);
      return;
    }

    // Checks Markdown Source for image tags and wait for them to load
    // (if they are not cached already) to calculate show more fade
    const imgTags = Array.from(markdownElem.querySelectorAll('img'));
    const isCached = imgTags.every((img) => img.complete);
    if (!isCached) {
      imgTags.forEach((img) => {
        if (img.complete) return;
        img.onload = () => {
          if (markdownElem.scrollHeight > markdownElem.clientHeight) {
            fadeElem.classList.add('show-more');
          } else {
            fadeElem.classList.remove('show-more');
          }
        };
      });
      return;
    }

    // If element doesn't have images and is overflown, shows fade component to avoid
    if (markdownElem.scrollHeight > markdownElem.clientHeight) {
      fadeElem.classList.add('show-more');
    } else {
      fadeElem.classList.remove('show-more');
    }
  };

  const changeTaskColumn = async (column) => {
    const taskPath = {
      clientId: accountId,
      projectId: '1',
      id,
    };
    firebaseHelper.updateTask({
      ...content,
      ...taskPath,
      column,
      position: await firebaseHelper.getLastPosition(accountId, '1', column),
    });
  };

  const changeTaskStatus = (status) => {
    firebaseHelper.updateTask({
      ...content,
      status,
      clientId: accountId,
      projectId: '1',
      id,
    });
  };

  const registerChecklist = async (taskId) => {
    const check = {
      accountId,
      projectId: '1',
      taskId,
    };
    setContent((prevState) => ({ ...prevState, hasChecklist: true }));
    await firebaseHelper.registerChecklist(check);
  };

  const minusHandle = () => {
    if (timeSpent > 0) {
      setTimeSpent((prevState) => prevState - 1);
    }
  };

  const plusHandle = () => {
    if (timeSpent < Number.MAX_SAFE_INTEGER) {
      setTimeSpent((prevState) => prevState + 1);
    }
  };

  const timeHandle = (event) => {
    const value = event.target.value;
    setTimeSpent(Number(value));
  };

  const saveSpentTime = async () => {
    await firebaseHelper.setTaskTimeSpent(accountId, id, timeSpent);
    setTimeSuccess(true);
    setTimeout(() => {
      setTimeSuccess(false);
    }, 2500);
  };

  return (
    <Modal
      isOpen={modal}
      role="dialog"
      toggle={() => setModal(!modal)}
      size="xl"
      onClosed={onCardClosed}
      onOpened={onCardOpened}
      unmountOnClose={true}
      keyboard={true}
      autoFocus={true}
      centered={true}
      contentClassName="modal-task"
      tabIndex="-1"
    >
      <ModalHeader cssModule={{ 'modal-title': 'w-100 text-center mb-0' }}>
        <div
          className="modal--header ms-3"
          style={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          <ShowComponent condition={!showExperiments}>
            <div className="d-flex align-items-center gap-2">
              <div className="d-flex align-items-center gap-2">
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn"
                    style={{
                      backgroundColor: 'rgba(116, 120, 141, 0.2)',
                      border: 'none',
                    }}
                    caret
                  >
                    <span style={{ color: 'rgba(116, 120, 141, 1)' }}>
                      {translate(content?.column === 'Pending' ? 'Pendings' : content?.column) +
                        ' '}
                    </span>
                    <i
                      className="mdi mdi-chevron-down"
                      style={{ color: 'rgba(116, 120, 141, 1)' }}
                    />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => changeTaskColumn('Backlog')}>
                      {translate('Backlog')}
                    </DropdownItem>
                    <DropdownItem onClick={() => changeTaskColumn('In Progress')}>
                      {translate('In Progress')}
                    </DropdownItem>
                    <DropdownItem onClick={() => changeTaskColumn('Pendings')}>
                      {translate('Pendings')}
                    </DropdownItem>
                    <DropdownItem onClick={() => changeTaskColumn('Delivered')}>
                      {translate('Delivered')}
                    </DropdownItem>
                    <DropdownItem onClick={() => changeTaskColumn('Finished')}>
                      {translate('Finished')}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown>
                  <DropdownToggle
                    className={`btn ${statusColorClass[content?.status]}`}
                    style={{ border: 'none' }}
                    caret
                  >
                    <span style={{ color: 'rgba(116, 120, 141, 1)' }}>
                      {translate(statusNumber[content?.status]) + ' '}
                    </span>
                    <i
                      className="mdi mdi-chevron-down"
                      style={{ color: 'rgba(116, 120, 141, 1)' }}
                    />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => changeTaskStatus(0)}>
                      {translate('Waiting')}
                    </DropdownItem>
                    <DropdownItem onClick={() => changeTaskStatus(1)}>
                      {translate('Ready to Implement')}
                    </DropdownItem>
                    <DropdownItem onClick={() => changeTaskStatus(2)}>
                      {translate('Technical Impediment')}
                    </DropdownItem>
                    <DropdownItem onClick={() => changeTaskStatus(3)}>
                      {translate('Under Development')}
                    </DropdownItem>
                    <DropdownItem onClick={() => changeTaskStatus(4)}>
                      {translate('Implemented')}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              <div className="d-flex align-items-center gap-2">
                <UncontrolledDropdown>
                  <DropdownToggle
                    style={{
                      backgroundColor: 'transparent',
                      border: 'none',
                    }}
                    onClick={() => {
                      teamUsers?.forEach((user) => {
                        setPFPDebounced({
                          userId: user.id,
                          elementSearch: `[id*=card-${user.id}-modal-]`,
                        });
                      });
                    }}
                    caret
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="avatar-group">
                        {_.map(content?.assignedTo, (member, index) => {
                          if (!(index < 4)) return;
                          setPFPDebounced({
                            userId: member,
                            elementSearch: `[id*=card-${member}-modal-]`,
                          });
                          return (
                            <div className="avatar-group-item" key={index}>
                              <div
                                className="avatar-user"
                                alt={`${translate('Photo of')} ${content.assignedToNames[index]}`}
                                id={`card-${member}-modal-${content.id}`}
                              ></div>
                              <UncontrolledTooltip
                                defaultOpen={false}
                                flip
                                placement="right"
                                target={`card-${member}-modal-${content.id}`}
                              >
                                {content.assignedToNames[index]}
                              </UncontrolledTooltip>
                            </div>
                          );
                        })}
                        {_.size(content?.assignedTo) > 4 && (
                          <div className="avatar-group-item">
                            <div className="avatar-xs">
                              <span
                                className="avatar-title rounded-circle text-white font-size-16"
                                style={{ backgroundColor: 'black' }}
                              >
                                {`${_.size(content.assignedTo) - 4}+`}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                      <i
                        className="mdi mdi-chevron-down ms-1"
                        style={{ color: 'rgba(116, 120, 141, 1)', fontSize: '1.5em' }}
                      />
                    </div>
                  </DropdownToggle>
                  <DropdownMenu style={{ boxShadow: 'none', border: '1px solid #E2E8F0' }}>
                    {teamUsers?.map((user, index) => {
                      const isUserAssigned = content?.assignedTo?.includes(user.id);
                      setPFPDebounced({
                        userId: user.id,
                        elementSearch: `[id*=card-${user.id}-modal-]`,
                      });
                      return (
                        <DropdownItem
                          key={user.id}
                          onClick={async () => {
                            await handleAssignedToClick(user.id);
                          }}
                          toggle={false}
                        >
                          <SelectAssignedToDiv>
                            <div>
                              <div className="avatar-group-item" key={index}>
                                <div
                                  className="avatar-user"
                                  alt={`${translate('Photo of')} ${user.name}`}
                                  id={`card-${user.id}-modal-`}
                                ></div>
                              </div>
                              <div>{user.name}</div>
                            </div>
                            <div>
                              <button className={isUserAssigned ? 'remove' : 'add'} onClick>
                                {isUserAssigned ? 'Remover' : 'Adicionar'}
                              </button>
                            </div>
                          </SelectAssignedToDiv>
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              <div className="d-flex align-items-center gap-2">
                {content?.complexity ? (
                  <span
                    className={`${
                      priorityColorClass[content?.complexity]
                    } badge rounded-pill font-size-11`}
                    style={{ lineHeight: '2' }}
                  >
                    {translate(complexityTooltip[content.complexity])}
                  </span>
                ) : null}

                {content?.impact ? (
                  <span
                    className={`${
                      priorityColorClass[content?.impact]
                    } badge rounded-pill font-size-11`}
                    style={{ lineHeight: '2' }}
                  >
                    {translate(impactTooltip[content.impact])}
                  </span>
                ) : null}
              </div>
              <div className="d-flex align-items-center gap-2">
                {content?.dateImplementation ? (
                  <div className="d-flex flex-row align-items-center">
                    <i
                      className="bx bx-award"
                      style={{
                        color: '#484848',
                        fontSize: '1.5em',
                        marginRight: '.25em',
                      }}
                    ></i>
                    <div>
                      <p
                        style={{
                          color: '#ADB5BD',
                          fontSize: '.7em',
                          marginBottom: '0',
                        }}
                      >
                        {translate('Implemented on') + ':'}
                      </p>
                      <p
                        style={{
                          color: '#484848',
                          fontSize: '.8em',
                          marginBottom: '0',
                        }}
                      >
                        {formatDate(new Date(content?.dateImplementation), translate('date-enUS'))}
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div style={{ display: 'flex', gap: '.5em' }}>
              <UncontrolledDropdown>
                <DropdownToggle
                  style={{ border: 'none', display: 'flex', height: '100%' }}
                  caret
                  tag="div"
                >
                  <IconButton
                    iconProps={{
                      type: 'regular',
                      name: 'alarm',
                      color: '#495057',
                      size: '1.75em',
                    }}
                  />
                </DropdownToggle>

                <DropdownMenu className="px-4 py-3" style={{ width: '20em' }}>
                  <div>
                    <div className="mb-2">{translate('Time spent on task (minutes)')}</div>
                    <InputGroup>
                      <Button
                        className="d-flex justify-content-center align-items-center"
                        color="primary"
                        onClick={minusHandle}
                      >
                        <box-icon type="regular" name="minus" color="white" size="1rem"></box-icon>
                      </Button>

                      <Input
                        type="number"
                        className="form-control"
                        value={timeSpent}
                        onChange={(event) => timeHandle(event)}
                      />

                      <Button
                        className="d-flex justify-content-center align-items-center"
                        color="primary"
                        onClick={plusHandle}
                      >
                        <box-icon type="regular" name="plus" color="white" size="1rem"></box-icon>
                      </Button>
                    </InputGroup>
                    <button
                      onClick={saveSpentTime}
                      className="d-flex justify-content-center align-items-end mt-3 p-0"
                      style={{
                        backgroundColor: 'white',
                        border: '0',
                        gap: '.4em',
                      }}
                    >
                      <box-icon
                        name={timeSpentSuccess ? 'check' : 'save'}
                        color={timeSpentSuccess ? '#34C38F' : '#50A5F1'}
                        size="1.8em"
                      ></box-icon>
                      <span
                        style={{
                          color: timeSpentSuccess ? '#34C38F' : '#50A5F1',
                          fontSize: '1.1rem',
                          lineHeight: '1.1rem',
                          paddingBottom: '.2em',
                        }}
                      >
                        {translate('Save')}
                      </span>
                    </button>
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>

              {showExperiments ? (
                <IconButton
                  iconProps={{
                    type: 'regular',
                    name: 'calendar-plus',
                    color: '#50A5F1',
                    size: '1.8em',
                  }}
                  onClick={() => setShowExperiments((prevState) => !prevState)}
                />
              ) : (
                <IconButton
                  iconProps={{
                    type: configExperiment ? 'solid' : 'regular',
                    name: 'vial',
                    color: configExperiment ? '#34C38F' : '#495057',
                    size: '1.8em',
                  }}
                  onClick={() => setShowExperiments((prevState) => !prevState)}
                />
              )}

              <ShowComponent condition={!content?.hasChecklist}>
                <IconButton
                  iconProps={{
                    type: 'regular',
                    name: 'list-ul',
                    color: '#495057',
                    size: '2em',
                  }}
                  onClick={() => registerChecklist(content.id)}
                />
              </ShowComponent>

              <UncontrolledDropdown>
                <DropdownToggle
                  style={{ border: 'none', display: 'flex', height: '100%' }}
                  caret
                  tag="div"
                >
                  <IconButton
                    iconProps={{
                      type: 'regular',
                      name: 'dots-vertical-rounded',
                      color: '#495057',
                      size: '1.75em',
                    }}
                  />
                </DropdownToggle>

                <DropdownMenu>
                  <DropdownItem onClick={duplicateTask}>
                    <i
                      className="bx bx-duplicate"
                      style={{ fontSize: '1.25em', color: 'black' }}
                    ></i>{' '}
                    <span style={{ fontSize: '1.1em', color: 'black' }}>
                      {translate('Duplicate')}
                    </span>
                  </DropdownItem>
                  <DropdownItem onClick={(event) => copyTask(event, content.id)}>
                    <i className="bx bx-copy" style={{ fontSize: '1.25em', color: 'black' }}></i>{' '}
                    <span style={{ fontSize: '1.1em', color: 'black' }}>
                      {translate('Copy URL')}
                    </span>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to={`/edit-task?id=${content?.id}`}>
                      <i
                        className="bx bx-edit-alt"
                        style={{ fontSize: '1.25em', color: 'black' }}
                      ></i>{' '}
                      <span style={{ fontSize: '1.1em', color: 'black' }}>{translate('Edit')}</span>
                    </Link>
                  </DropdownItem>
                  <DropdownItem onClick={confirmDeleteTask}>
                    <i className="bx bx-trash" style={{ color: 'red', fontSize: '1.25em' }}></i>{' '}
                    <span style={{ color: 'red', fontSize: '1.1em' }}>{translate('Delete')}</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              <IconButton
                iconProps={{
                  type: 'regular',
                  name: 'x',
                  color: '#495057',
                  size: '2.2em',
                }}
                onClick={() => setModal(false)}
              />
            </div>
          </ShowComponent>

          <Modal
            isOpen={confirmDeleteModal}
            toggle={() => setConfirmDeleteModal(!modal)}
            size="md"
            unmountOnClose={true}
            keyboard={true}
            autoFocus={true}
            centered={true}
            tabIndex="-1"
          >
            <ModalHeader cssModule={{ 'modal-title': 'w-100 mb-0' }}>
              <HeaderDiv>
                <div>{translate('Delete Task')}</div>
                <div>
                  <button
                    onClick={() => setConfirmDeleteModal(false)}
                    style={{ border: 'none', margin: '0', backgroundColor: 'transparent' }}
                  >
                    <i className="bx bx-x" style={{ fontSize: '1.25em', color: 'black' }}></i>{' '}
                  </button>
                </div>
              </HeaderDiv>
            </ModalHeader>
            <ModalBody>
              <BodyDiv>
                {translate('By deleting this task it will not be recoverable later')}
              </BodyDiv>
            </ModalBody>
            <ModalFooter>
              <CancelButton onClick={() => setConfirmDeleteModal(false)}>
                {translate('Cancel')}
              </CancelButton>
              <DeleteButton onClick={deleteTask}>{translate('Delete')}</DeleteButton>
            </ModalFooter>
          </Modal>

          <ShowComponent condition={showExperiments}>
            <div>
              <button
                className="d-flex align-items-center gap-2 bg-transparent p-0 m-0 border-0"
                onClick={() => setShowExperiments(false)}
              >
                <box-icon
                  type="regular"
                  name="left-arrow-circle"
                  color="#50A5F1"
                  size="1.5em"
                ></box-icon>
                <span style={{ color: '#50A5F1', marginTop: '2.5px' }}>
                  {translate('Go back to task details')}
                </span>
              </button>
            </div>
            <div style={{ display: 'flex', gap: '.5em' }}>
              <IconButton
                iconProps={{
                  type: 'regular',
                  name: 'x',
                  color: '#495057',
                  size: '2.2em',
                }}
                onClick={() => setModal(false)}
              />
            </div>
          </ShowComponent>
        </div>
      </ModalHeader>

      <ModalContentContext.Provider
        value={{
          content,
          setContent,
          adminStatus,
          checklist,
          comments,
          files,
          setFiles,
          showMoreButton,
          setShowMoreButton,
        }}
      >
        <ShowComponent condition={!showExperiments}>
          <ModalContent accountId={accountId} taskId={id} />
        </ShowComponent>

        <ShowComponent condition={showExperiments}>
          <ExperimentsContent />
        </ShowComponent>
      </ModalContentContext.Provider>
    </Modal>
  );
};

TaskModal.propTypes = {
  accountId: PropTypes.string,
};

export default TaskModal;

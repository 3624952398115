import React from 'react';
import PropTypes from 'prop-types';

const ShowComponent = ({ children, condition }) => {
  if (condition) {
    return <React.Fragment>{children}</React.Fragment>;
  } else {
    return null;
  }
};

ShowComponent.propTypes = {
  children: PropTypes.node,
  condition: PropTypes.bool,
};

export default ShowComponent;

const formatBatchName = (name, accountId, taskId) => {
  const formattedName = name.replace(/[ _]/g, '-');
  const formattedAccountName = accountId.replace(/_/g, '|');
  const formattedTaskName = taskId.replace(/_/g, '|');

  const batchName = `${formattedAccountName}_${formattedTaskName}_${formattedName}`;
  return batchName;
};

export { formatBatchName };

import styled from 'styled-components';

export const SERPDataLayout = styled.div`
  .primary-keyword {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 40px;

    p {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      margin: 0 !important;
    }

    .badge {
      height: fit-content;
    }
  }

  thead {
    th {
      font-size: 12px;
      line-height: normal;
      font-weight: 600;
    }
  }

  tbody {
    td {
      font-size: 14px;
      line-height: normal;
      font-weight: 400;
      vertical-align: middle;
      max-height: 72px;
      height: 72px;
      text-align: center;
      background-color: #fff;
    }
  }

  .url {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    max-width: 90%;
    padding: 0 !important;
    margin: 0 !important;
  }

  .svg-url {
    margin-left: 18px;
    display: inline-block;
    vertical-align: middle !important;
    position: absolute;
    right: 18px;
  }

  .svg-status-200 {
    margin-right: 8px;
  }

  .table-content-details {
    border-top: none !important;
    border-left: 1px solid #eff2f7;
    border-right: 1px solid #eff2f7;
    border-bottom: none !important;
    td {
      height: auto;
      max-height: auto;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      background-color: #f7fafc !important;
    }
  }

  .table-heading {
    width: 85px;
    border: 1px solid #edf2f7;
  }

  .tag-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;

    .tag-heading {
      display: flex;
      gap: 12px;
      border: 1px solid #edf2f7;
      border-radius: 4px;
      padding: 4px 4px 4px 12px;
      background-color: #fff;

      p {
        margin: 0 !important;
      }
    }
  }

  .tag-count {
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
  }

  .tag {
    position: relative;
    z-index: 3;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: #fff;
    text-transform: uppercase;
  }

  .tag-h1 {
    background-color: #7367f0;
  }

  .tag-h2 {
    background-color: #dd6b20;
  }

  .tag-h3 {
    background-color: #3182ce;
  }

  .tag-h4 {
    background-color: #319795;
  }

  .tag-h5 {
    background-color: #2d3748;
  }

  .tag-h6 {
    background-color: #808080;
  }

  .tag-url-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    border-top: 1px solid #edf2f7 !important;
    padding: 24px;

    p {
      margin: 0 !important;
      text-align: left;
    }

    .vertical-line {
      border: 1px solid #edf2f7;
      width: 1px;
      height: 45px;
      margin: -5px 14px;
    }
  }

  .container-google-questions {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    gap: 8px;
  }

  .btn-google-questions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    border: 1px solid #edf2f7;
    color: #1a202c !important;
    padding: 12px;
  }

  .question-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 12px;
    border: 1px solid #edf2f7;
    border-radius: 4px;

    p {
      margin: 0 !important;
    }
  }
`;

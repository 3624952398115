import React from 'react';
import { Link } from 'react-router-dom';

import SidebarContent from '../../layouts/Sidebar-Content';
import smallLogo from '../../assets/images/small-logo.svg';
import logoLight from '../../assets/images/logo-light.svg';
import smallLogoLight from '../../assets/images/small-logo-light.svg';

const Sidebar = () => {
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={smallLogo} alt="" height="29" />
            </span>
          </Link>

          <Link to="/" className="logo logo-light" style={{ width: 'fit-content' }}>
            <span className="logo-sm">
              <img src={smallLogoLight} alt="" height="29" />
            </span>
            <span className="logo-lg">
              <img src={logoLight} alt="" height="35" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          <SidebarContent />
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;

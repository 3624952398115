import React, { useEffect, useState } from 'react';
import { Row, Dropdown, Col, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import 'boxicons';
import { useAuth } from 'hooks/useAuth';

import EctoLogo from '../../assets/images/logos/ecto-logo-full.svg';
import LocastyLogo from '../../assets/images/logos/locasty-logo-full.svg';
import SeodexLogo from '../../assets/images/logos/seodex-logo-full.svg';
import { DropdownMenuStyled, DropdownToggleStyled } from './styles';
import { getFirebaseBackend } from 'helpers/firebaseHelper';

const SiteMenu = () => {
  const firebaseHelper = getFirebaseBackend();
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [accountModules, setAccountModules] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  async function fetchAccountModules() {
    const accountObj = await firebaseHelper.getAccount(user?.account);
    setAccountModules(accountObj.modules);
  }

  const linkButtons = [
    {
      title: translate('Create Task'),
      icon: 'calendar-edit',
      link: '/tasks-create',
      authorization:
        (user?.modules?.tasks && accountModules?.tasks) || user.adminStatus === 'Super Admin',
    },
    {
      title: translate('Create Meeting'),
      icon: 'bell',
      link: '/create-meeting',
      authorization: user.adminStatus === 'Super Admin' || user.adminStatus === 'Admin',
    },
    {
      title: translate('Create Guideline'),
      icon: 'message-square-edit',
      link: '/create-guideline',
      authorization: user.adminStatus === 'Super Admin' || user.adminStatus === 'Admin',
    },
  ];

  const hasSomeButtonDisplayed = () => {
    const result = linkButtons.some((button) => button.authorization);
    return result;
  };

  useEffect(() => {
    if (!user?.account) return;
    fetchAccountModules();
    hasSomeButtonDisplayed();
  }, [user]);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className="d-none d-lg-inline-block ms-1">
      <DropdownToggleStyled
        isOpen={dropdownOpen}
        className="btn header-item noti-icon d-flex justify-content-center align-items-center"
        tag="button"
      >
        <box-icon type="regular" name="grid-alt" color="#343a40" size="1.875rem" />
      </DropdownToggleStyled>
      <DropdownMenuStyled className="dropdown-menu-lg dropdown-menu-end">
        <div className="d-flex w-100">
          {hasSomeButtonDisplayed() && (
            <div className="d-flex flex-column align-items-center p-3 gap-4 border-right">
              {linkButtons.map(
                (button, index) =>
                  button.authorization && (
                    <Button
                      key={`${button.icon}+${index}`}
                      className="btn-quick-menu"
                      onClick={() => {
                        navigate(button.link);
                        setDropdownOpen(false);
                      }}
                    >
                      <box-icon name={button.icon} color="#74788d" />
                      {button.title}
                    </Button>
                  ),
              )}
            </div>
          )}
          <div className="flex-col-component">
            <span>{translate('Our tools')}</span>
            <a
              className="dropdown-icon-item"
              href={translate('locasty-menu-link')}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={LocastyLogo} style={{ height: '22px' }} />
            </a>
            <a
              className="dropdown-icon-item"
              href={translate('ecto-menu-link')}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={EctoLogo} style={{ height: '15px' }} />
            </a>
            <a
              className="dropdown-icon-item"
              href={translate('seodex-menu-link')}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={SeodexLogo} style={{ height: '14px' }} />
            </a>
          </div>
        </div>
      </DropdownMenuStyled>
    </Dropdown>
  );
};

SiteMenu.propTypes = {};

export default SiteMenu;

import styled from 'styled-components';

export const ButtonSendCommentStyle = styled.div`
  button {
    width: 40px;
    height: 40px;
    border-radius: 999px;
    background-color: #7367f0;
    border: none;
  }

  button:hover {
    background-color: #aba4f6;
    border: none;
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import 'boxicons';

import { VTimelineList, VTimelineItem, VTimelineDot, VTimelineContent } from './styles';

const VerticalTimeline = ({ data }) => {
  const compareVersions = (a, b) => {
    const parseVersion = (version) => version.match(/(\d+)/g).map(Number);

    const versionA = parseVersion(a.title);
    const versionB = parseVersion(b.title);

    for (let i = 0; i < Math.max(versionA.length, versionB.length); i++) {
      const numA = versionA[i] || 0;
      const numB = versionB[i] || 0;
      if (numA !== numB) {
        return numB - numA;
      }
    }
    return 0;
  };

  const sortedData = data?.slice().sort(compareVersions);

  return (
    <VTimelineList>
      {sortedData?.map((item, index) => (
        <VTimelineItem key={index}>
          <VTimelineDot>
            <box-icon name="right-arrow-circle" size="1rem" color="#000"></box-icon>
          </VTimelineDot>

          <VTimelineContent>
            <div>
              <box-icon name={item.icon} size="2rem" color={item.iconColor} />
            </div>

            <div>
              <h5>{item.title}</h5>
              <p>{item.description}</p>
            </div>
          </VTimelineContent>
        </VTimelineItem>
      ))}
    </VTimelineList>
  );
};

VerticalTimeline.propTypes = {
  data: PropTypes.array,
};

VerticalTimeline.displayName = 'Vertical Timeline Component';

export default VerticalTimeline;

/* eslint react/no-unknown-property: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { SwitchDescription } from './styles';

const SwitchInput = ({
  controlName,
  onChange = null,
  label,
  description,
  onLabel = 'Yes',
  offLabel = 'No',
  size = 12,
  round = false,
  required = false,
  validation = {},
  ...other
}) => {
  const { t: translate } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const validate = required ? { ...validation, required: 'Required Field!' } : validation;

  return (
    <Col lg={size} className="d-flex align-items-end">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
        }}
      >
        <div className={round ? 'round-switch' : 'square-switch'}>
          <Controller
            control={control}
            name={controlName}
            rules={validate}
            render={({ field }) => (
              <>
                <input
                  {...field}
                  type="checkbox"
                  id={controlName}
                  switch="none"
                  checked={field.value}
                  onChange={(event) => {
                    field.onChange(event);
                    if (onChange) onChange(event);
                  }}
                  {...other}
                />
                <label
                  className="m-0"
                  htmlFor={controlName}
                  data-on-label={translate(onLabel)}
                  data-off-label={translate(offLabel)}
                />
              </>
            )}
          />
        </div>

        <div>
          {label && <span className="mt-1 mx-4">{translate(label)}</span>}

          {description && (
            <SwitchDescription className="mt-1 mx-4">
              {_.isString(description) ? translate(description) : description}
            </SwitchDescription>
          )}
        </div>
      </div>
    </Col>
  );
};

SwitchInput.propTypes = {
  controlName: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onLabel: PropTypes.string,
  offLabel: PropTypes.string,
  size: PropTypes.number,
  round: PropTypes.bool,
  required: PropTypes.bool,
  validation: PropTypes.object,
};

export default SwitchInput;

import React, { useEffect, useState } from 'react';
import { FormGroup, Input, Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useAuth } from 'hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { getFirebaseBackend } from 'helpers/firebaseHelper';

import ButtonApprove from '../Button-Approve';
import GuidelinesManageFilter from '../Guidelines-Manage-Filter';
import ButtonFilter from '../Button-Filter';
import ButtonView from '../Button-View';

const TablePendentContents = ({
  toggleModalApprove,
  setGuidelineDataClicked,
  guidelinesDataSelected,
  setGuidelinesDataSelected,
}) => {
  const firebaseHelper = getFirebaseBackend();
  const { t: translate } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isToggleFilter, setIsToggleFilter] = useState(false);
  const [filter, setFilter] = useState(null);
  const [contentData, setContentData] = useState([]);

  const toggleSelectAll = () => {
    setSelectAllChecked((prevSelectAllChecked) => {
      const newValue = !prevSelectAllChecked;

      const hasDeniedGuideline = contentData.some((account) =>
        account.guidelines.some((guideline) => guideline.hasOwnProperty('denied')),
      );

      const updatedData = contentData.map((account) => ({
        ...account,
        guidelines: account.guidelines.map((guideline) => ({
          ...guideline,
          selected: newValue,
        })),
      }));

      const updatedGuidelinesIdSelected = updatedData.map((account) => {
        return account.guidelines.map((guideline) => {
          return {
            guidelineId: guideline.id,
            accountId: account.accountId,
          };
        });
      });

      if (newValue) {
        setGuidelinesDataSelected(updatedGuidelinesIdSelected.flat());
        setIsButtonDisabled(hasDeniedGuideline);
      } else {
        setGuidelinesDataSelected([]);
        setIsButtonDisabled(true);
      }

      setContentData(updatedData);

      return newValue;
    });
  };

  const toggleSelectIndividual = (accountIndex, indexList) => {
    const updatedData = [...contentData];

    updatedData.map((account) => {
      if (account.accountId === accountIndex) {
        const guidelineId = account.guidelines[indexList].id;

        if (account.guidelines[indexList].hasOwnProperty('selected')) {
          account.guidelines[indexList].selected = !account.guidelines[indexList].selected;
        } else {
          account.guidelines[indexList].selected = true;
        }

        if (account.guidelines[indexList].selected) {
          const updatedGuidelinesIdSelected = [
            ...guidelinesDataSelected,
            {
              guidelineId: guidelineId,
              accountId: accountIndex,
            },
          ];
          if (account.guidelines[indexList].hasOwnProperty('denied')) {
            setIsButtonDisabled(true);
          } else {
            setGuidelinesDataSelected(updatedGuidelinesIdSelected);
            setIsButtonDisabled(false);
          }
        } else {
          const updatedGuidelinesIdSelected = guidelinesDataSelected.filter(
            (guideline) => guideline.guidelineId !== guidelineId,
          );
          setGuidelinesDataSelected(updatedGuidelinesIdSelected);
          setIsButtonDisabled(true);
        }
      }
    });

    setContentData(updatedData);
  };

  const filterTotal = () => {
    let count = 0;

    for (const key in filter) {
      if (filter[key] && filter[key].length > 0) {
        count++;
      }
    }

    return count;
  };

  const toggleFilter = () => {
    setIsToggleFilter(!isToggleFilter);
  };

  const toggleModalApproveIndividual = (guidelineId, accountId, column) => {
    toggleModalApprove();
    setGuidelineDataClicked([
      {
        guidelineId: guidelineId,
        accountId: accountId,
        column,
      },
    ]);
  };

  const openContentApprovePage = (guidelineId) => {
    navigate(`/content/${guidelineId}/content-approval`);
  };

  const fetchData = async (filterValue) => {
    const guidelinesData = await Promise.all(
      user.accounts.map(async (account) => await firebaseHelper.getAllFirebaseGuidelines(account)),
    );

    const guidelinesArray = guidelinesData.map((item) => {
      return Object.values(item);
    });
    const guidelinesFiltered = guidelinesArray.map((account) =>
      account.filter((guideline) => {
        return guideline.column === 'CONTENT_APPROVAL' && !guideline.archived;
      }),
    );

    const accountNames = await Promise.all(
      user.accounts.map(async (account) => await firebaseHelper.accountName(account)),
    );

    const accountGuidelines = guidelinesFiltered.map((guidelines, index) => {
      const accountName = accountNames[index];
      const accountId = user.accounts[index];
      return { accountName, accountId, guidelines };
    });

    if (filterValue) {
      const { assignedTo, type, startDate, endDate } = filterValue;

      accountGuidelines.forEach((account) => {
        account.guidelines = account.guidelines.filter((guideline) => {
          const assignedToValues = assignedTo ? assignedTo.map((person) => person.value) : [];
          const typeValues = type ? type.map((t) => t.value) : [];

          const assignedToMatch =
            !assignedToValues.length ||
            guideline.assignedTo.some((person) => assignedToValues.includes(person));
          const typeMatch = !typeValues.length || typeValues.includes(guideline.type);

          const assignedToDateMatch =
            !startDate ||
            !endDate ||
            (guideline.createdAt >= startDate && guideline.createdAt <= endDate);

          return assignedToMatch && typeMatch && assignedToDateMatch;
        });
      });
    }

    setContentData(accountGuidelines);
  };

  useEffect(() => {
    fetchData();
  }, [user]);

  return (
    <React.Fragment>
      <div className="header-general-information">
        <p>{translate('Content pending approval')}</p>
        <div className="d-flex gap-3">
          <ButtonApprove onClickFunction={toggleModalApprove} disabled={isButtonDisabled} inBulk />
          <ButtonFilter handleToggle={toggleFilter} filterTotal={filterTotal} />
        </div>
      </div>
      {isToggleFilter && (
        <div className="container-filter">
          <GuidelinesManageFilter
            accountsId={user.accounts}
            filter={filter}
            setFilter={setFilter}
            toggleFilter={toggleFilter}
            fetchData={fetchData}
          />
        </div>
      )}
      <FormGroup>
        <Table className="table-guideline">
          <thead>
            <tr>
              <th>
                <Input type="checkbox" checked={selectAllChecked} onChange={toggleSelectAll} />
                {translate('Primary Keyword')}
              </th>
              <th style={{ width: '144px', textAlign: 'center' }}>{translate('Account')}</th>
              <th style={{ width: '144px', textAlign: 'center' }}>
                {translate('Searches')}/{translate('Month').toLowerCase()}
              </th>
              <th style={{ width: '380px' }}>{translate('Action')}</th>
            </tr>
          </thead>
          <tbody>
            {contentData && contentData.length > 0 ? (
              contentData?.map((account) =>
                account.guidelines.map((guideline, indexList) => (
                  <React.Fragment key={guideline.id}>
                    <tr>
                      <td>
                        <Input
                          type="checkbox"
                          checked={guideline.selected || false}
                          onChange={() => toggleSelectIndividual(account.accountId, indexList)}
                        />
                        {guideline.primaryKeyword.charAt(0).toUpperCase() +
                          guideline.primaryKeyword.slice(1)}
                      </td>
                      <td style={{ textAlign: 'center' }}>{account.accountName}</td>
                      <td style={{ textAlign: 'center' }}>
                        {guideline.searchVolume.toLocaleString()}
                      </td>
                      <td>
                        <div className="d-flex justify-content-end gap-3">
                          <ButtonApprove
                            onClickFunction={() =>
                              toggleModalApproveIndividual(
                                guideline.id,
                                account.accountId,
                                guideline.column,
                              )
                            }
                          />
                          <ButtonView
                            onClickFunction={() => openContentApprovePage(guideline.id)}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr className="table-guideline-details" />
                  </React.Fragment>
                )),
              )
            ) : (
              <tr className="border">
                <td colSpan={4} className="text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="45"
                    height="44"
                    viewBox="0 0 45 44"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M25.6368 16.0133L23.9032 14.2797C23.5641 13.9406 23.104 13.75 22.6243 13.75C22.1447 13.75 21.6846 13.9406 21.3454 14.2797L15.5853 20.0399C15.3171 20.308 15.1665 20.6717 15.1665 21.0509V24.2917C15.1665 24.5449 15.3717 24.7501 15.6248 24.7501H18.865C19.2446 24.7501 19.6086 24.5993 19.877 24.3309L25.6368 18.5711C25.976 18.2319 26.1666 17.7719 26.1665 17.2922C26.1665 16.8125 25.976 16.3525 25.6368 16.0133V16.0133Z"
                      stroke="#CBD5E0"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M29.8332 30.2526H15.1665"
                      stroke="#CBD5E0"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <rect
                      x="6"
                      y="5.5"
                      width="33"
                      height="33"
                      rx="16.5"
                      stroke="#CBD5E0"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <p className="mt-1" style={{ color: '#a0aec0' }}>
                    {translate('No pending content')}
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </FormGroup>
    </React.Fragment>
  );
};

TablePendentContents.propTypes = {
  toggleModalApprove: PropTypes.func,
  setGuidelineDataClicked: PropTypes.func,
  guidelinesDataSelected: PropTypes.array,
  setGuidelinesDataSelected: PropTypes.func,
};

export default TablePendentContents;

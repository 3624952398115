import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  UncontrolledAccordion,
  AccordionItem,
  Row,
  Col,
  Button,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import 'boxicons';
import styled from 'styled-components';

import { useAuth } from 'hooks/useAuth';
import { getFirebaseBackend } from '../../helpers/firebaseHelper';
import { formatDateTimestampNumber, setPFPDebounced } from '../../utils';
import { AccordionTitle, AccordionTable, ActionWrapper, AccordionNameRow } from './styles';
import ContainerDatePicker from '../Container-Date-Picker';

import {
  statusColorClass,
  statusNumber,
  priorityColorClass,
  priorityLetter,
  priorityNumber,
} from '../../constants';

const AccordionList = ({ column, data }) => {
  const firebaseHelper = getFirebaseBackend();

  const { t: translate } = useTranslation();
  const { user } = useAuth();
  const accountId = user?.account;
  const { adminStatus } = user;
  const [tasks, setTasks] = useState([]);

  const deliveryDateFormatted = (dateDelivery) =>
    dateDelivery
      ? formatDateTimestampNumber(dateDelivery, translate('date-enUS'))
      : translate('Add');

  const implementedDateFormatted = (dateImplementation) =>
    dateImplementation
      ? formatDateTimestampNumber(dateImplementation, translate('date-enUS'))
      : '-';

  const statusElem = (meetingStatus) => (
    <span className={`status ${statusColorClass[meetingStatus]} badge rounded-pill font-size-12`}>
      {translate(statusNumber[meetingStatus])}
    </span>
  );

  const complexityElem = (meetingComplexity) => (
    <div
      className={`${priorityColorClass[meetingComplexity]} badge rounded-pill font-size-14 px-3 py-1`}
    >
      {translate(priorityLetter[meetingComplexity])}
    </div>
  );

  const impactElem = (meetingImpact) => (
    <div
      className={`${priorityColorClass[meetingImpact]} badge rounded-pill font-size-14 px-3 py-1`}
    >
      {translate(priorityLetter[meetingImpact])}
    </div>
  );

  const dateDeliveryElem = (meetingDateDelivery, meetingId) => (
    <div className="d-flex justify-content-between position-relative">
      <UncontrolledDropdownStyled direction="down">
        <DropdownToggle data-toggle="dropdown" tag="span">
          <Input
            value={deliveryDateFormatted(meetingDateDelivery)}
            placeholder={translate('Add')}
            readOnly
            style={{ cursor: 'pointer' }}
          />
        </DropdownToggle>
        <DropdownMenu
          className="m-0 p-0"
          style={{ backgroundColor: 'transparent', height: '100%' }}
        >
          <ContainerDatePicker date={meetingDateDelivery} id={meetingId} isDateDelivery anyDate />
        </DropdownMenu>
      </UncontrolledDropdownStyled>
    </div>
  );

  const dateImplementationElem = (meetingDateImplementation, meetingId) => (
    <div className="d-flex justify-content-between position-relative">
      <UncontrolledDropdownStyled direction="down">
        <DropdownToggle data-toggle="dropdown" tag="span">
          <Input
            value={deliveryDateFormatted(meetingDateImplementation)}
            placeholder={translate('Add')}
            readOnly
            style={{ cursor: 'pointer' }}
          />
        </DropdownToggle>
        <DropdownMenu
          className="m-0 p-0"
          style={{ backgroundColor: 'transparent', height: '100%' }}
        >
          <ContainerDatePicker
            date={meetingDateImplementation}
            id={meetingId}
            isDateImplementation
            anyDate
          />
        </DropdownMenu>
      </UncontrolledDropdownStyled>
    </div>
  );

  const handleTaskRemove = (id) => {
    firebaseHelper.removeTask(accountId, id);
  };

  const actionButtons = (id) => {
    const actionElem =
      adminStatus !== 'Client' ? (
        <ActionWrapper>
          <Link to={`/edit-task?id=${id}&acc=${accountId}`}>
            <Button style={{ backgroundColor: '#50A5F1' }}>
              <box-icon name="pencil" color="#FFF" size="1.2rem" />
            </Button>
          </Link>

          <Button onClick={() => handleTaskRemove(id)} style={{ backgroundColor: '#F46A6A' }}>
            <box-icon name="trash" color="#FFF" size="1.2rem" />
          </Button>
        </ActionWrapper>
      ) : (
        <ActionWrapper>
          <Link to={`/tasks?id=${id}&acc=${accountId}`}>
            <Button style={{ backgroundColor: '#50A5F1' }}>
              <box-icon name="show" color="#FFF" size="1.2rem" />
            </Button>
          </Link>
        </ActionWrapper>
      );

    return actionElem;
  };

  const avatarElem = (assignedTo) => {
    return (
      <div className="avatar-group justify-content-center">
        {_.map(assignedTo, (member, index) => {
          if (index > 2) return;
          setPFPDebounced({
            userId: member,
            elementSearch: `[id*=member-photo-${member}]`,
          });
          return (
            <div className="avatar-group-item" key={index}>
              <div className="avatar-user" id={`member-photo-${member}`} />
            </div>
          );
        })}
        {_.size(assignedTo) > 2 && (
          <div className="avatar-group-item">
            <div className="avatar-xs">
              <span
                className="avatar-title rounded-circle text-white font-size-16"
                style={{ backgroundColor: 'black' }}
              >
                {`${_.size(assignedTo) - 2}+`}
              </span>
            </div>
          </div>
        )}
      </div>
    );
  };

  const columns = [
    {
      dataField: 'name',
      type: 'string',
      text: translate('Task Name'),
      sort: true,
      sorValue: (cell, row) => row.nameValue,
      headerStyle: { backgroundColor: '#F8F9FA', width: '20%' },
    },
    {
      dataField: 'status',
      type: 'string',
      sortValue: (cell, row) => row.statusValue,
      text: translate('Status'),
      sort: true,
      headerStyle: {
        backgroundColor: '#F8F9FA',
        width: '18%',
        textAlign: 'center',
      },
      style: { textAlign: 'center' },
    },
    {
      dataField: 'assignedTo',
      type: 'string',
      sortValue: (cell, row) => row.assignedToValue,
      text: translate('Assigned To'),
      sort: true,
      headerStyle: {
        backgroundColor: '#F8F9FA',
        width: '10%',
        textAlign: 'center',
      },
    },
    {
      dataField: 'complexity',
      type: 'string',
      sortValue: (cell, row) => row.complexityValue,
      text: translate('Complexity'),
      sort: true,
      headerStyle: {
        backgroundColor: '#F8F9FA',
        width: '8%',
        textAlign: 'center',
      },
      style: { textAlign: 'center' },
    },
    {
      dataField: 'impact',
      type: 'string',
      sortValue: (cell, row) => row.impactValue,
      text: translate('Impact'),
      sort: true,
      headerStyle: {
        backgroundColor: '#F8F9FA',
        width: '8%',
        textAlign: 'center',
      },
      style: { textAlign: 'center' },
    },
    {
      dataField: 'delivery',
      type: 'date',
      sortValue: (cell, row) => row.deliveryValue,
      text: translate('Delivery'),
      sort: true,
      headerStyle: {
        backgroundColor: '#F8F9FA',
        width: '14%',
        textAlign: 'center',
      },
      style: { textAlign: 'center', overflow: 'visible' },
    },
    {
      dataField: 'implementation',
      type: 'date',
      sortValue: (cell, row) => row.implementationValue,
      text: translate('Implementation'),
      sort: true,
      headerStyle: {
        backgroundColor: '#F8F9FA',
        width: '10%',
        textAlign: 'center',
      },
      style: { textAlign: 'center', overflow: 'visible' },
    },
    {
      dataField: 'action',
      searchable: false,
      text: translate('Action'),
      headerStyle: {
        backgroundColor: '#F8F9FA',
        width: '10%',
        textAlign: 'center',
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: 'position',
      order: 'desc',
    },
  ];

  const pageOptions = {
    sizePerPage: tasks?.length ?? 0,
    totalSize: tasks?.length ?? 0,
    custom: true,
  };

  useEffect(() => {
    const tasksData = data ?? [];

    const newData = tasksData.map((meeting) => {
      return {
        position: meeting.position,
        name: (
          <AccordionNameRow to={`/tasks?id=${meeting.id}&acc=${accountId}`}>
            {meeting.name}
          </AccordionNameRow>
        ),
        nameValue: meeting.name,
        status: statusElem(meeting.status),
        statusValue: meeting.status,
        assignedTo: avatarElem(meeting.assignedTo),
        assignedToValue: meeting.assignedTo.length,
        complexity: meeting.complexity ? complexityElem(meeting.complexity) : '-',
        complexityValue: meeting.complexity ? priorityNumber[meeting.complexity] : 0,
        impact: meeting.impact ? impactElem(meeting.impact) : '-',
        impactValue: meeting.impact ? priorityNumber[meeting.impact] : 0,
        delivery: dateDeliveryElem(meeting.dateDelivery, meeting.id),
        deliveryValue: meeting.dateDelivery ?? 0,
        implementation: dateImplementationElem(meeting.dateImplementation, meeting.id),
        implementationValue: meeting.dateImplementation ?? 0,
        action: actionButtons(meeting.id),
      };
    });

    setTasks(newData);
  }, [data]);

  useEffect(() => {
    const observerFunc = (mutationList) => {
      mutationList.forEach(function (mutation) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'id') {
          const member = mutation.target.id.replace('member-photo-', '');
          setPFPDebounced({
            userId: member,
            elementSearch: `[id*=member-photo-${member}]`,
            reload: true,
            restart: true,
          });
        }
      });
    };
    const allPhotos = document.querySelectorAll('[id^=member-photo-]');
    const observer = new MutationObserver(observerFunc);
    allPhotos.forEach((node) => {
      observer.observe(node, { attributes: true });
    });

    return () => {
      observer.disconnect();
    };
  }, [tasks]);

  return (
    <UncontrolledAccordion className="mb-4 overflow-visible" defaultOpen="1" stayOpen>
      <AccordionItem>
        <AccordionTitle targetId="1">
          {`${translate(column)} (${data?.length ?? 0})`}
        </AccordionTitle>

        <AccordionTable accordionId="1">
          <Row>
            <Col lg="12" className="overflow-visible">
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="id"
                columns={columns}
                data={tasks}
              >
                {({ paginationTableProps }) => (
                  <ToolkitProvider keyField="id" columns={columns} data={tasks}>
                    {(toolkitProps) => (
                      <React.Fragment>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive overflow-visible">
                              <BootstrapTable
                                keyField="id"
                                responsive
                                hover
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                classes={'table align-middle table-nowrap'}
                                headerWrapperClasses={'thead-light'}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </Col>
          </Row>
        </AccordionTable>
      </AccordionItem>
    </UncontrolledAccordion>
  );
};

const UncontrolledDropdownStyled = styled(UncontrolledDropdown)`
  input:focus {
    border: 1px solid #555;
  }
  input:focus-within {
    border: 1px solid #555;
  }
`;

AccordionList.displayName = 'Accordion List Component';

AccordionList.propTypes = {
  column: PropTypes.string,
  data: PropTypes.array,
};

const arePropsEqual = (prevProps, nextProps) => {
  const isColumnEquals = prevProps.column === nextProps.column;
  const isDataEquals = JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data);

  return isColumnEquals && isDataEquals;
};

export default memo(AccordionList, arePropsEqual);

const countKeywords = (rawKeywordText) => {
  if (!rawKeywordText) return 0;

  const count = rawKeywordText.split('\n').filter((line) => {
    return line;
  }).length;

  return count;
};

export { countKeywords };

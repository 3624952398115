import styled from 'styled-components';
import { ModalBody, Card, AccordionBody, Nav } from 'reactstrap';

import { mediaQuery } from '../../constants/index';

export const StyledNav = styled(Nav)`
  padding-left: 2rem;
`;

export const ContentBody = styled(ModalBody)`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

export const ContentHeader = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  padding-left: 2rem;
  width: 100%;
`;

export const RequestAPIButton = styled.button`
  max-height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3em;
  background-color: transparent;
  border: 0;
  margin: 0;
  margin-left: auto;
  color: #50a5f1;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 0.9rem;
`;

export const ExperimentContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${(props) => (props.gap ? '1em' : '')};
  background-color: #f8f8f8;
  padding: ${(props) => (props.padding ? '0 2rem 2rem 2rem' : '0')};
  border-radius: 0 0 5px 5px;
  min-height: 47vh;
  max-height: 60vh;

  @media ${mediaQuery.less.mds} {
    padding: ${(props) => (props.padding ? '0 1rem 1rem 1rem' : '0')};
    height: 48vh;
  }
`;

export const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  background-color: ${(props) => props.color};
  border-radius: 50%;
  margin-top: ${(props) => (props.margin ? '5em' : '')};
`;

export const ExperimentForm = styled.form`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
  padding: 0 2rem;
  padding-bottom: 2rem;
  gap: 2em;
  box-sizing: border-box;

  @media ${mediaQuery.less.mds} {
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;

    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
      border-radius: 50px;
    }
  }
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.spaceBetween ? 'space-between' : 'flex-start')};
  flex-grow: 1;
  width: 100%;

  @media ${mediaQuery.less.mds} {
    justify-content: flex-start;
    gap: 1em;
  }
`;

export const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  gap: 1em;

  @media ${mediaQuery.less.mds} {
    flex-direction: column;
  }
`;

export const CreditsRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.1em;
`;

export const SourceConfig = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: ${(props) => {
    const pixels = props.accordionLength * 48;
    return `calc(35vh - ${pixels}px)`;
  }};
  width: 100%;
  overflow: auto;
  padding: 0 1em;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 50px;
  }

  @media ${mediaQuery.less.mds} {
    height: auto;
    overflow: hidden;
  }
`;

export const CustomAccordionBody = styled(AccordionBody)`
  > div {
    padding: 0 !important;
  }
`;

export const ChartDiv = styled(Card)`
  padding: 1em;
  width: 100%;
  height: 25em;
  margin: 0;

  @media ${mediaQuery.less.mds} {
    height: 40em;
  }
`;

export const GraphGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  grid-gap: 1em;

  @media ${mediaQuery.less.md} {
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;
  }

  @media ${mediaQuery.less.mds} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const GraphCard = styled(Card)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 5rem;
  padding: 1rem;
  margin: 0;

  @media ${mediaQuery.less.mds} {
    width: 100%;
  }
`;

export const CardTitle = styled.div`
  font-size: 0.8rem;
  color: #74788d;
`;

export const CardThen = styled.span`
  font-size: 1rem;
  color: #495057;
`;

export const CardNow = styled.span`
  font-size: 1rem;
  color: #c1c1c1;
`;

export const CardComparison = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.2em;
  font-size: 1rem;
  color: #495057;
`;

export const GraphResponsive = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2em;
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 50px;
  }

  @media ${mediaQuery.less.mds} {
    flex-direction: column;
  }
`;

export const GraphForm = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1.5em;
  margin-top: 1em;
  width: 100%;

  > div {
    width: inherit;
  }

  @media ${mediaQuery.less.sm} {
    flex-direction: column;
    justify: center;
  }
`;

export const PillBadge = styled.span`
  line-height: 1;
  color: #707070;
  background-color: ${(props) => props.bgColor ?? '#E6E6E6'};
  font-size: ${(props) => props.fsize ?? '12px'};
  border-radius: 50rem;
  padding-top: ${(props) => props.py ?? '0.25rem'};
  padding-bottom: ${(props) => props.py ?? '0.25rem'};
  padding-right: ${(props) => props.px ?? '0.5rem'};
  padding-left: ${(props) => props.px ?? '0.5rem'};
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: ${(props) => props.justify ?? 'flex-start'};
  align-items: ${(props) => props.items ?? 'stretch'};
  align-content: ${(props) => props.content ?? 'normal'};
  flex-direction: ${(props) => props.dir ?? 'row'};
  flex-wrap: ${(props) => (props.wrap ? (props.reverse ? 'wrap-reverse' : 'wrap') : 'nowrap')};
  gap: ${(props) => props.gap ?? ''};
`;

export const ErrorBox = styled.div`
  padding: 0.5rem 1rem;
  margin-top: 1em;
  background-color: #ffe6e6;
  color: #832c2c;

  strong {
    font-weight: bold;
  }
`;

import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';

import { GuidelineLayout } from './styles';
import Breadcrumbs from '../../components/Breadcrumb';
import ModalApprove from './components/Modal-Approve';
import ModalDeny from './components/Modal-Deny';
import ModalArchive from './components/Modal-Archive';
import TablePendentGuidelines from './components/Table-Pendent-Guidelines';
import TablePendentContents from './components/Table-Pendent-Contents';

const manageGuideline = () => {
  const { t: translate } = useTranslation();
  const [modalApprove, setModalApprove] = useState(false);
  const [modalDeny, setModalDeny] = useState(false);
  const [modalArchive, setModalArchive] = useState(false);
  const [guidelineDataClicked, setGuidelineDataClicked] = useState([]);
  const [guidelinesDataSelected, setGuidelinesDataSelected] = useState([]);

  const toggleModalApprove = () => setModalApprove(!modalApprove);

  const toggleModalDeny = () => setModalDeny(!modalDeny);

  const toggleModalArchive = () => setModalArchive(!modalArchive);

  return (
    <GuidelineLayout>
      <div className="page-content">
        <MetaTags>
          <title>{translate('My Activity')} | Ectools</title>
        </MetaTags>
        <Breadcrumbs title={translate('Guideline')} breadcrumbItem={translate('My Activity')} />
        <div className="container-general-information">
          <ModalApprove
            modalFunction={toggleModalApprove}
            modalState={modalApprove}
            dataMany={guidelinesDataSelected}
            dataIndividual={guidelineDataClicked}
          />
          <ModalDeny
            modalFunction={toggleModalDeny}
            modalState={modalDeny}
            dataMany={guidelinesDataSelected}
            dataIndividual={guidelineDataClicked}
          />
          <ModalArchive
            modalFunction={toggleModalArchive}
            modalState={modalArchive}
            dataIndividual={guidelineDataClicked}
          />
          <div className="flex-column">
            <TablePendentGuidelines
              toggleModalApprove={toggleModalApprove}
              toggleModalDeny={toggleModalDeny}
              toggleModalArchive={toggleModalArchive}
              setGuidelineDataClicked={setGuidelineDataClicked}
              guidelinesDataSelected={guidelinesDataSelected}
              setGuidelinesDataSelected={setGuidelinesDataSelected}
            />
          </div>
        </div>
        <div className="container-general-information">
          <div className="flex-column">
            <TablePendentContents
              toggleModalApprove={toggleModalApprove}
              setGuidelineDataClicked={setGuidelineDataClicked}
              guidelinesDataSelected={guidelinesDataSelected}
              setGuidelinesDataSelected={setGuidelinesDataSelected}
            />
          </div>
        </div>
      </div>
    </GuidelineLayout>
  );
};

export default manageGuideline;

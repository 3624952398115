import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { ButtonDetailsStyle } from './styles';
import PropTypes from 'prop-types';

const ButtonDetails = ({ onClickFunction, id, isOpen }) => {
  const { t: translate } = useTranslation();

  return (
    <ButtonDetailsStyle>
      <Button className="btn-details" onClick={() => onClickFunction(id)}>
        {translate('Details')}
        {isOpen ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              d="M6.99993 6.31373L4.11243 9.20123L3.2876 8.3764L6.99993 4.66406L10.7123 8.3764L9.88743 9.20123L6.99993 6.31373Z"
              fill="#4A5568"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              d="M6.99993 7.68437L9.88743 4.79688L10.7123 5.62171L6.99993 9.33404L3.2876 5.62171L4.11243 4.79688L6.99993 7.68437Z"
              fill="#4A5568"
            />
          </svg>
        )}
      </Button>
    </ButtonDetailsStyle>
  );
};

ButtonDetails.propTypes = {
  onClickFunction: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default ButtonDetails;

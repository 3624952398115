const sortByLabel = (a, b) => {
  // Normalize, remove accents and diacretics and lowercase the strings for comparison
  const normalizedA = a.label
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
  const normalizedB = b.label
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
  if (normalizedA > normalizedB) return 1;
  if (normalizedA < normalizedB) return -1;
  return 0;
};

export { sortByLabel };

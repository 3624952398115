import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Button,
} from 'reactstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';

import { useAuth } from 'hooks/useAuth';
import Breadcrumbs from '../../components/Breadcrumb';
import SuccessModal from '../../components/Success-Modal';
import ErrorModal from '../../components/Error-Modal';
import LabelGroup from '../../components/Label-Group';
import TextInput from '../../components/Text-Input';
import MarkdownInput from '../../components/Markdown-Input';
import DateInput from '../../components/Date-Input';
import SelectInput from '../../components/Select-Input';
import { getFormValue, getEpochDate, loadTeam, loadProjects } from '../../utils';
import { getFirebaseBackend } from '../../helpers/firebaseHelper';
import '../../assets/scss/custom/components/_dragndrop.scss';
import '../../assets/scss/custom/components/_modal.scss';

import { priorityLetter, statusNumber } from '../../constants/index';

const EditTask = () => {
  const { t: translate } = useTranslation();
  const url = new URL(window.location.href);
  const taskParam = url.searchParams.get('id');
  const formConfig = {
    defaultValues: {
      id: '',
      name: '',
      description: '',
      startDate: '',
      endDate: '',
      implementationDate: '',
      assignedTo: [],
      foundations: [],
      status: [],
      project: [],
      impact: [],
      complexity: [],
      position: '',
      column: '',
    },
    shouldFocusError: true,
    criteriaMode: 'all',
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  };

  const firebaseHelper = getFirebaseBackend();

  const form = useForm(formConfig);
  const { user } = useAuth();
  const accountId = user.account;
  const [files, setFiles] = useState([]);
  const [fetchSuccess, setFetchSuccess] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [projectKey, setProjectKey] = useState(0);
  const [assignedKey, setAssignedKey] = useState(1000);

  const { formState } = form;

  useEffect(() => {
    getTask();
  }, []);

  useEffect(() => {
    setProjectKey((prevState) => prevState + 1);
    setAssignedKey((prevState) => prevState + 1);
  }, [accountId]);

  const fileHandle = (acceptedFiles) => {
    setFiles((prevState) => [...prevState, ...acceptedFiles]);
  };

  const fileRemoveHandle = (event) => {
    event.preventDefault();

    const newFiles = _.cloneDeep(files);
    newFiles.splice(event.target.id, 1);
    setFiles(newFiles);
  };

  const closeModalHandle = () => {
    setFetchSuccess(false);
    form.reset();
    setFiles([]);
    setSuccessModal(false);
    getTask();
  };

  const onSubmit = async (data) => {
    // Create task object and send it as a parameter to the updateTask function
    const newFiles = files.length > 0;
    const task = {
      id: data.id,
      clientId: accountId,
      projectId: data.project.value,
      name: data.name,
      description: data.description ? data.description : null,
      dateBegin: getEpochDate(data.startDate),
      dateDelivery: getEpochDate(data.endDate),
      dateImplementation: getEpochDate(data.implementationDate),
      assignedTo: data.assignedTo.map(getFormValue),
      foundations: data.foundations.map(getFormValue),
      status: data.status.value,
      impact: data?.impact?.value ? data.impact.value : null,
      complexity: data?.complexity?.value ? data.complexity.value : null,
      hasFiles: data?.hasFiles ? data.hasFiles : newFiles,
      hasChecklist: data.hasChecklist,
      hasExperiments: data.hasExperiments,
      position: data.position,
      column: data.column,
      timeSpent: data.timeSpent,
      createdAt: data.createdAt,
    };
    await firebaseHelper.updateTask(task);
    if (newFiles) {
      firebaseHelper.storeFiles(files, `${accountId}/1/${taskParam}`);
    }
    setSuccessModal(true);
  };

  const getTask = async () => {
    try {
      const task = await firebaseHelper.getTask({
        clientId: accountId,
        taskId: taskParam,
      });
      const processedFoundations = task.foundations.map((foundation) => {
        return { value: foundation, label: translate(foundation) };
      });
      const processedAssignedTo = await Promise.all(
        task.assignedTo.map(async (assigned) => {
          const label = await firebaseHelper.userName(assigned);
          return { value: assigned, label };
        }),
      );
      const processedProject = {
        value: 1,
        label: await firebaseHelper.projectName(accountId, 1),
      };
      form.reset({
        id: task.id,
        name: task.name,
        description: task.description,
        startDate: task.dateBegin ? new Date(task.dateBegin) : '',
        endDate: task.dateDelivery ? new Date(task.dateDelivery) : '',
        implementationDate: task.dateImplementation ? new Date(task.dateImplementation) : '',
        assignedTo: processedAssignedTo,
        foundations: processedFoundations,
        status: {
          value: task.status,
          label: translate(statusNumber[task.status]),
        },
        project: processedProject,
        complexity: task.complexity
          ? {
              value: task.complexity,
              label: translate(priorityLetter[task.complexity]),
            }
          : null,
        impact: task.impact
          ? {
              value: task.impact,
              label: translate(priorityLetter[task.impact]),
            }
          : null,
        position: task.position,
        hasFiles: task.hasFiles || false,
        hasChecklist: task.hasChecklist || false,
        hasExperiments: task.hasExperiments || false,
        column: task.column,
        timeSpent: task.timeSpent,
        createdAt: task.createdAt,
      });
      setFetchSuccess(true);
    } catch (error) {
      setErrorModal(true);
      console.error(error);
    }
  };

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>{`${translate('Edit Task')} | Ectools`}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title={translate('Tasks')} breadcrumbItem={translate('Edit Task')} />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <SuccessModal
                    isOpen={successModal}
                    verb="Updated"
                    onClose={closeModalHandle}
                    buttonText="See Tasks"
                    buttonLink="/tasks"
                  />

                  <ErrorModal isOpen={errorModal} />

                  <CardTitle className="mb-4">{translate('Edit Existing Task')}</CardTitle>

                  <FormProvider {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                      <LabelGroup htmlFor="name" label="Task Name" required>
                        <TextInput controlName="name" placeholder="Task Name" required />
                      </LabelGroup>

                      <LabelGroup htmlFor="description" label="Task Description">
                        <MarkdownInput attachId={taskParam} controlName="description" />
                      </LabelGroup>

                      <LabelGroup label="Task Date">
                        <DateInput controlName="startDate" placeholder="Start Date" size={4} />
                        <DateInput controlName="endDate" placeholder="Deadline" size={4} />
                        <DateInput
                          controlName="implementationDate"
                          placeholder="Implementation"
                          size={4}
                        />
                      </LabelGroup>

                      <LabelGroup label="Details" required>
                        <SelectInput
                          asyncronous
                          isMulti
                          label="Assigned To"
                          controlName="assignedTo"
                          key={assignedKey}
                          required
                          cacheOptions
                          defaultOptions
                          loadOptions={(inputValue) => loadTeam(accountId, inputValue)}
                          size={6}
                        />
                        <SelectInput
                          isMulti
                          label="Foundations"
                          controlName="foundations"
                          required
                          options={[
                            {
                              value: 'Technology',
                              label: translate('Technology'),
                            },
                            { value: 'Content', label: translate('Content') },
                            {
                              value: 'Link Building',
                              label: translate('Link Building'),
                            },
                            { value: 'Report', label: translate('Report') },
                            {
                              value: 'Performance',
                              label: translate('Performance'),
                            },
                            {
                              value: 'Architeture',
                              label: translate('Architeture'),
                            },
                          ]}
                          size={6}
                        />
                        <SelectInput
                          label="Status"
                          controlName="status"
                          required
                          options={[
                            { value: 0, label: translate('Waiting') },
                            {
                              value: 1,
                              label: translate('Ready to Implement'),
                            },
                            {
                              value: 2,
                              label: translate('Technical Impediment'),
                            },
                            { value: 3, label: translate('Under Development') },
                            { value: 4, label: translate('Implemented') },
                          ]}
                          size={6}
                        />
                        <SelectInput
                          asyncronous
                          label="Project"
                          controlName="project"
                          key={projectKey}
                          required
                          cacheOptions
                          defaultOptions
                          loadOptions={(inputValue) => loadProjects(accountId, inputValue)}
                          size={6}
                        />
                      </LabelGroup>

                      <LabelGroup label="Priority">
                        <SelectInput
                          label="Impact"
                          controlName="impact"
                          options={[
                            { value: 'L', label: translate('Low') },
                            { value: 'M', label: translate('Medium') },
                            { value: 'H', label: translate('High') },
                          ]}
                          size={6}
                        />
                        <SelectInput
                          label="Complexity"
                          controlName="complexity"
                          options={[
                            { value: 'L', label: translate('Low') },
                            { value: 'M', label: translate('Medium') },
                            { value: 'H', label: translate('High') },
                          ]}
                          size={6}
                        />
                      </LabelGroup>

                      <FormGroup className="mb-4" row>
                        <Label className="col-form-label col-lg-2">{translate('Files')}</Label>
                        <Col lg="10">
                          <Row>
                            <Dropzone onDrop={fileHandle}>
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div {...getRootProps()} className="drag-n-drop">
                                    <input {...getInputProps()} />
                                    <i
                                      className="fas fa-cloud-upload-alt fa-3x"
                                      style={{ marginBottom: '1%' }}
                                    ></i>
                                    <p>
                                      {translate(
                                        "Drag 'n' drop some files here, or click to select files",
                                      )}
                                    </p>
                                  </div>
                                </section>
                              )}
                            </Dropzone>

                            <div className="file-list">
                              {files.map((file, index) => {
                                return (
                                  <div key={`${file.name}-${index}`} className="file">
                                    <p>{file.name}</p>
                                    <button
                                      id={index}
                                      className="btn btn-sm btn-outline-danger"
                                      onClick={fileRemoveHandle}
                                    >
                                      X
                                    </button>
                                  </div>
                                );
                              })}
                            </div>
                          </Row>
                        </Col>
                      </FormGroup>

                      <Row className="justify-content-end">
                        <Col lg="10">
                          <Button
                            type="submit"
                            style={{ backgroundColor: '#344792' }}
                            disabled={Object.values(formState.errors).length || !fetchSuccess}
                          >
                            {translate('Edit Task')}
                          </Button>
                        </Col>
                      </Row>
                    </form>
                  </FormProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default EditTask;

import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

import Layout from '../layouts/Auth-Layout/';
import { useAuth } from 'hooks/useAuth';

export const AuthRoute = ({ children }) => {
  const { user, updateUser } = useAuth();

  updateUser();

  if (user) {
    const newAuth = user?.adminStatus;
    // Only let the user proceed if he has the new Auth Object, otherwise log him out
    return newAuth ? <Layout>{children}</Layout> : <Navigate to="/logout" />;
  } else {
    return <Navigate to="/login" />;
  }
};

AuthRoute.propTypes = {
  children: PropTypes.node,
};

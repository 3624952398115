import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, UncontrolledTooltip } from 'reactstrap';

const ButtonEdit = ({ onClickFunction }) => {
  const { t: translate } = useTranslation();

  return (
    <span>
      <Button className="btn-edit" id="btn-edit-tooltip" onClick={onClickFunction}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="13"
          viewBox="0 0 12 13"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.26049 10.5035H1.99805V8.24103C1.99805 8.00913 2.09013 7.78672 2.25415 7.62278L7.62039 2.25654C7.78432 2.0923 8.00684 2 8.2389 2C8.47095 2 8.69347 2.0923 8.8574 2.25654L10.245 3.64412C10.4092 3.80805 10.5015 4.03057 10.5015 4.26263C10.5015 4.49468 10.4092 4.71721 10.245 4.88113L4.87875 10.2474C4.71468 10.4112 4.49235 10.5033 4.26049 10.5035Z"
            stroke="#4A5568"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.5 3.5L9.00104 6.00104"
            stroke="#4A5568"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.49902 8.00063L5.99965 6.5"
            stroke="#4A5568"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Button>
      <UncontrolledTooltip placement="top" target="btn-edit-tooltip" className="tooltip">
        {translate('Edit')}
      </UncontrolledTooltip>
    </span>
  );
};

ButtonEdit.propTypes = {
  onClickFunction: PropTypes.func,
};

export default ButtonEdit;

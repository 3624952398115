import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import PropTypes from 'prop-types';
import 'boxicons';

import { PillBadge, FlexContainer } from './styles';

const KeywordTable = ({ categories, data }) => {
  const { t: translate } = useTranslation();
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState([
    {
      dataField: 'keyword',
      type: 'string',
      text: translate('Keyword'),
      sortValue: (cell, row) => row.keywordValue,
      sort: true,
      headerStyle: { backgroundColor: '#F8F9FA', width: '30%' },
      style: { backgroundColor: '#FFF' },
    },
  ]);

  const defaultSorted = [
    {
      dataField: 'keyword',
      order: 'asc',
    },
  ];

  const pageOptions = {
    sizePerPage: tableData.length,
    totalSize: tableData.length,
    custom: true,
  };

  useEffect(() => {
    if (!categories || !data) return;

    const rows = [];
    const columns = [
      {
        dataField: 'keyword',
        type: 'string',
        text: translate('Keyword'),
        sortValue: (cell, row) => row.keywordValue,
        sort: true,
        headerStyle: { backgroundColor: '#F8F9FA', width: '30%' },
        style: { backgroundColor: '#FFF' },
      },
    ];
    // Sorts data by query, then device, then date.
    // Eg:
    // | query | device | date |
    // | a     | a      | 1    |
    // | a     | a      | 2    |
    // | a     | a      | 3    |
    // | a     | b      | 1    |
    // | b     | a      | 1    |
    const sortedData = _.orderBy(data, ['query', 'device', 'date.value'], ['asc', 'asc', 'desc']);
    const floor = Math.min(5, categories.length);
    let newRow = {};
    let i = 0;
    let lastKeyword = sortedData?.[0]?.query;
    let lastDevice = sortedData?.[0]?.device;

    for (let [index, date] of categories.entries()) {
      if (index >= floor) break;
      const UTCDate = new Date(date);
      // Needs to use UTC to show graph dates correctly
      const textDate = `${UTCDate.getUTCDate()}/${
        UTCDate.getUTCMonth() + 1
      }/${UTCDate.getUTCFullYear()}`;
      columns.push({
        dataField: `date${index}`,
        type: 'number',
        text: textDate,
        sortValue: (cell, row) => row[`date${index}Value`],
        sort: true,
        headerStyle: { textAlign: 'center' },
        style: { backgroundColor: '#FFF' },
      });
    }

    // Maps the row number of matches by date and push it to begin another
    // row when the keyword or device changes
    for (let [index, rowObj] of sortedData.entries()) {
      if (rowObj.query !== lastKeyword || rowObj.device !== lastDevice) {
        i = 0;
        lastKeyword = rowObj.query;
        lastDevice = rowObj.device;
        rows.push(newRow);
        newRow = {};
      }
      if (index === sortedData.length - 1) rows.push(newRow);
      if (i >= floor) continue;
      if (i === 0) {
        const [, rawTags] = rowObj?.custom_id?.split('|');
        const tags = rawTags?.replace('t_', '')?.split('_');
        newRow.keyword = (
          <div className="d-flex flex-column align-items-start gap-1">
            <div>{rowObj.query}</div>
            <div className="d-flex gap-1">
              <PillBadge bgColor={rowObj.device === 'mobile' ? '#EAF2FF' : '#FFF7EA'}>
                {rowObj.device}
              </PillBadge>
              {tags
                ?.filter((tag) => tag)
                .map((tag, key) => (
                  <PillBadge key={key}>{tag}</PillBadge>
                ))}
            </div>
          </div>
        );
        newRow.keywordValue =
          rowObj.query
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase() +
          ' ' +
          rowObj.device;
      }
      const matchNum = rowObj.matched_searches.reduce((acc, cur) => {
        return cur.position < acc ? (acc = cur.position) : acc;
      }, Infinity);
      newRow[`date${i}Value`] = matchNum === Infinity ? 0 : matchNum;
      i++;
    }

    for (let i = 0; i < rows.length; i++) {
      for (let j = 0; j < categories.length; j++) {
        const curValue = rows[i][`date${j}Value`];
        const postValue = rows[i][`date${j + 1}Value`] ?? curValue;

        let diffNum = postValue - curValue;
        let unrankedTransition;
        if (curValue === 0 || postValue === 0) {
          unrankedTransition = true;
          diffNum = -diffNum;
        }

        rows[i][`date${j}`] = (
          <FlexContainer
            justify="space-between"
            align="center"
            style={{
              paddingLeft: '25%',
              paddingRight: unrankedTransition ? '18%' : '25%',
              fontSize: '.9rem',
            }}
          >
            <FlexContainer justify="center" align="center">
              {curValue === 0 ? '-' : curValue}
            </FlexContainer>
            <FlexContainer justify="center" align="center" gap=".25rem">
              {diffNum !== 0 && (
                <>
                  <box-icon
                    name={diffNum > 0 ? 'caret-up' : 'caret-down'}
                    size="20px"
                    color={diffNum > 0 ? '#34C38F' : '#F46A6A'}
                  ></box-icon>
                  <span
                    style={{
                      color: diffNum > 0 ? '#34C38F' : '#F46A6A',
                      fontSize: unrankedTransition ? '1.2rem' : '.9rem',
                    }}
                  >
                    {unrankedTransition ? '∞' : Math.abs(diffNum)}
                  </span>
                </>
              )}
            </FlexContainer>
          </FlexContainer>
        );
      }
    }
    setColumns(columns);
    setTableData(rows);
  }, [data, categories]);

  return (
    <>
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="id"
        columns={columns}
        data={tableData}
      >
        {({ paginationTableProps }) => (
          <ToolkitProvider keyField="id" columns={columns} data={tableData}>
            {(toolkitProps) => (
              <React.Fragment>
                <Row>
                  <Col xl="12">
                    <div className="table-responsive">
                      <BootstrapTable
                        keyField="id"
                        responsive
                        hover
                        bordered={false}
                        striped={false}
                        defaultSorted={defaultSorted}
                        classes={'table align-middle table-nowrap'}
                        headerWrapperClasses={'thead-light'}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                      />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
    </>
  );
};

KeywordTable.displayName = 'Keyword Table';

KeywordTable.propTypes = {
  categories: PropTypes.array,
  data: PropTypes.any,
};

export default KeywordTable;

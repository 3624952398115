import styled from 'styled-components';

export const ButtonArchiveStyle = styled.div`
  .btn-archive {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    border: none;
    background-color: #d69e2e;
    font-weight: 500;
  }
  .btn-archive:hover {
    background-color: #b7791f;
  }
`;

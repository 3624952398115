import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card } from 'reactstrap';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';
import _ from 'lodash';

import { useAuth } from 'hooks/useAuth';
import { getFirebaseBackend } from 'helpers/firebaseHelper';
import PageStructure from 'components/Page-Structure';
import ShowComponent from 'components/Show-Component';
import Shimmer from 'components/Shimmer';
import ErrorModal from 'components/Error-Modal';
import EmbeddedVideo from '../../components/Embedded-Video';
import FileTable from '../../components/File-Table';
import LessonComments from './components/LessonComments';
import LessonsList from './components/LessonsList';
import { LessonLayout, LessonMenu } from './styles';

const Course = () => {
  const { user } = useAuth();
  const firebaseHelper = getFirebaseBackend();
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const { id: courseId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const lessonId = searchParams.get('id');
  const [lesson, setLesson] = useState(null);
  const [course, setCourse] = useState(null);
  const [library, setLibrary] = useState(null);
  const [doneTest, setDoneTest] = useState(false);
  const [files, setFiles] = useState(null);
  const [progress, setProgress] = useState([]);
  const [current, setCurrent] = useState();
  const [changeLoading, setChangeLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [menuOpen, setMenuOpen] = useState(true);
  const [errorModal, setErrorModal] = useState(false);

  const courseProgress = (courseObj) => {
    const lastLesson = library?.lastLesson;

    const lessons = [];
    let index = 0;
    let lastLessonId = null;
    let currentLessonId = null;

    courseObj.modules.forEach((module) => {
      module.lessons.forEach((lesson) => {
        if (lesson.id === lastLesson) lastLessonId = index;
        if (lesson.id === lessonId) currentLessonId = index;
        lessons.push({
          id: lesson.id,
          freeTrial: lesson.freeTrial,
          index,
        });
        index++;
      });
    });

    if (currentLessonId > lastLessonId) {
      firebaseHelper.updateFirebaseLibraryCourse({
        userId: user.id,
        courseId,
        lastLesson: lessonId,
      });
    }

    return [lessons, currentLessonId];
  };

  const progressPercent = () => {
    const progress = library?.progress;

    if (!progress) {
      return 0;
    } else {
      const currentProgress = progress.length;
      let courseLength = 0;

      Object.values(course?.modules || {}).forEach((module) => {
        module.lessons.forEach(() => {
          courseLength++;
        });
      });

      const percent = Math.floor((currentProgress / courseLength) * 100);
      return percent;
    }
  };

  const lastProgressLesson = () => {
    const progress = library?.progress;

    if (!progress) {
      return false;
    } else {
      const currentProgress = progress.length;
      let courseLength = 0;

      Object.values(course?.modules || {}).forEach((module) => {
        module.lessons.forEach(() => {
          courseLength++;
        });
      });

      return currentProgress === courseLength - 1;
    }
  };

  const handlePrevious = () => {
    searchParams.set('id', progress[current - 1].id);
    setSearchParams(searchParams);

    setChangeLoading(true);
  };

  const handleNext = () => {
    const libProgress = library?.progress || [];
    const libCourseStart = library?.courseStart;
    if (!libProgress.includes(lessonId)) {
      libProgress.push(lessonId);
      firebaseHelper.updateFirebaseLibraryCourse({
        userId: user.id,
        courseId,
        progress: libProgress,
      });
    }

    if (!libCourseStart) {
      firebaseHelper.updateFirebaseLibraryCourse({
        userId: user.id,
        courseId,
        courseStart: new Date().valueOf(),
      });
    }

    searchParams.set('id', progress[current + 1].id);
    setSearchParams(searchParams);

    setChangeLoading(true);
  };

  const handleCertificate = () => {
    const libProgress = library?.progress || [];
    if (!libProgress.includes(lessonId)) {
      libProgress.push(lessonId);
      firebaseHelper.updateFirebaseLibraryCourse({
        userId: user.id,
        courseId,
        progress: libProgress,
      });
    }

    navigate(`/course/${courseId}/certificate`);
  };

  const handleTest = () => {
    navigate(`/course/${courseId}/test?id=${lesson.test}&lss=${lessonId}`);
  };

  const fetchFiles = async (accountId) => {
    const files = await firebaseHelper.listAllFiles(`${accountId}/lessons/${lessonId}`);
    setFiles(files);
  };

  const fetchLessonData = async (accountId) => {
    if (!accountId) return;

    const lessonObj = await firebaseHelper.getFirebaseLesson(accountId, lessonId);

    const teacherNames = await Promise.all(
      lessonObj.teachers.map(async (teacherId) => {
        const teacherName = await firebaseHelper.userName(teacherId);

        return { name: teacherName, id: teacherId };
      }),
    );

    if (library?.tests?.[lessonObj?.test]) setDoneTest(true);

    setLesson({ ...lessonObj, teachers: teacherNames });
    setChangeLoading(false);
    setLoading(false);
  };

  const fetchCourseData = async (accountId) => {
    if (!accountId) return;

    const courseObj = await firebaseHelper.getFirebaseCourse(accountId, courseId);

    if (!courseObj) return setErrorModal(true);
    const [progress, currentIndex] = courseProgress(courseObj);

    setProgress(progress);
    setCurrent(currentIndex);
    setCourse(courseObj);
  };

  const fetchCourseLibrary = async () => {
    const libraryObj = await firebaseHelper.getFirebaseLibraryCourse(user.id, courseId);

    if (!libraryObj) navigate('/page-404');
    setLibrary(libraryObj);
  };

  useEffect(() => {
    if (!lessonId) return;

    setChangeLoading(true);
    fetchCourseLibrary();
  }, [lessonId]);

  useEffect(() => {
    try {
      fetchCourseData(library?.account);
      fetchLessonData(library?.account);
      fetchFiles(library?.account);
    } catch (error) {
      console.error(error);
      setErrorModal(true);
    }
  }, [library]);

  return (
    <PageStructure
      metaTitle={lesson?.name || 'Lesson'}
      breadcrumbItem={lesson?.name || 'Lesson'}
      breadcrumbTitle="Lesson"
    >
      <LessonLayout open={menuOpen}>
        <ErrorModal isOpen={errorModal} />

        <ShowComponent condition={loading}>
          <Shimmer height="30rem" />
          <Shimmer height="20rem" />
        </ShowComponent>

        <ShowComponent condition={!loading}>
          <ShowComponent condition={changeLoading}>
            <Shimmer height="30rem" />
          </ShowComponent>

          <ShowComponent condition={!changeLoading}>
            <div>
              <Card className="lesson-main">
                <section>
                  <h1>{lesson?.name}</h1>

                  <div className="lesson-stats">
                    <box-icon type="regular" name="time" size="1.2rem" color="#484848"></box-icon>
                    <span>{`${lesson?.durationMins} ${translate('minutes of duration')}`}</span>
                  </div>

                  <div className="lesson-stats">
                    <box-icon
                      type="regular"
                      name="user-voice"
                      size="1.2rem"
                      color="#484848"
                    ></box-icon>
                    <span>
                      {_.map(lesson?.teachers, ({ name, id }, index) => {
                        let item = name;
                        const lastItem = lesson?.teachers?.length - 1;

                        if (index !== lastItem) item += ', ';

                        return <span key={id}>{item}</span>;
                      })}
                    </span>
                  </div>

                  <hr />
                </section>

                <section>
                  {lesson?.videoLink && <EmbeddedVideo link={lesson.videoLink} />}

                  <MDEditor.Markdown
                    source={lesson?.description}
                    rehypePlugins={[[rehypeSanitize]]}
                    linkTarget="_blank"
                    style={{ marginTop: '1em' }}
                  />

                  {!_.isEmpty(files) && (
                    <FileTable title="Lesson Files" data={files} setData={setFiles} />
                  )}
                </section>
              </Card>

              <ShowComponent condition={lesson?.allowQuestions}>
                <LessonComments lessonId={lessonId} accountId={library?.account} />
              </ShowComponent>
            </div>
          </ShowComponent>

          <LessonMenu open={menuOpen}>
            <Card>
              <ShowComponent condition={menuOpen}>
                <div className="open-column">
                  <div className="lesson-menu-header">
                    <h4>{translate('Course Lessons')}</h4>
                    <button onClick={() => setMenuOpen(false)}>
                      <box-icon name="chevron-right-square" color="#484848" size="1.5em" />
                    </button>
                  </div>

                  <meter
                    min="0"
                    max="100"
                    value={progressPercent()}
                    title={translate('Course Completion Percentage')}
                    className={`lesson-menu-meter ${progressPercent() === 100 ? 'complete' : ''}`}
                  />

                  <div>
                    <LessonsList
                      courseId={courseId}
                      list={progress}
                      progress={library?.progress}
                      current={current}
                      account={library?.account}
                    />
                  </div>

                  <div className="lesson-menu-buttons">
                    <ShowComponent condition={library?.status !== 'trial'}>
                      <button
                        className="previous"
                        onClick={handlePrevious}
                        disabled={current === 0}
                      >
                        <box-icon name="chevrons-left" color="#fff" size="1.2rem" />
                        {translate('Previous Lesson')}
                      </button>
                    </ShowComponent>

                    <ShowComponent
                      condition={lesson?.applyTest && (!doneTest || library?.status === 'trial')}
                    >
                      <button className="test" onClick={handleTest}>
                        {translate('Answer Test')}
                        <box-icon name="log-in-circle" color="#fff" size="1.2rem" />
                      </button>
                    </ShowComponent>

                    <ShowComponent condition={!lesson?.applyTest || doneTest}>
                      <ShowComponent
                        condition={
                          current === progress.length - 1 &&
                          lastProgressLesson() &&
                          library?.status !== 'trial'
                        }
                      >
                        <button className="next" onClick={handleCertificate}>
                          {translate('Certificate')}
                          <box-icon name="medal" color="#fff" size="1.2rem" />
                        </button>
                      </ShowComponent>

                      <ShowComponent
                        condition={current < progress.length - 1 && library?.status !== 'trial'}
                      >
                        <button className="next" onClick={handleNext}>
                          {translate('Next Lesson')}
                          <box-icon name="chevrons-right" color="#fff" size="1.2rem" />
                        </button>
                      </ShowComponent>
                    </ShowComponent>
                  </div>
                </div>
              </ShowComponent>

              <ShowComponent condition={!menuOpen}>
                <div className="closed-column">
                  <button onClick={() => setMenuOpen(true)}>
                    <box-icon name="chevron-left-square" color="#C1C1C1" size="1.5em" />
                  </button>
                  <div className="sideways">{translate('See Course Lessons')}</div>
                </div>
              </ShowComponent>
            </Card>

            <ShowComponent condition={!menuOpen}>
              <div className="closed-column">
                <div className="lesson-menu-buttons">
                  <ShowComponent condition={library?.status !== 'trial'}>
                    <button className="previous" onClick={handlePrevious} disabled={current === 0}>
                      <box-icon name="chevrons-left" color="#fff" size="1.2rem" />
                    </button>
                  </ShowComponent>

                  <ShowComponent condition={lesson?.applyTest && !doneTest}>
                    <button className="test" onClick={handleTest}>
                      <box-icon name="log-in-circle" color="#fff" size="1.2rem" />
                    </button>
                  </ShowComponent>

                  <ShowComponent condition={!lesson?.applyTest || doneTest}>
                    <ShowComponent
                      condition={
                        current === progress.length - 1 &&
                        lastProgressLesson() &&
                        library?.status !== 'trial'
                      }
                    >
                      <button className="next" onClick={handleCertificate}>
                        <box-icon name="medal" color="#fff" size="1.2rem" />
                      </button>
                    </ShowComponent>

                    <ShowComponent
                      condition={current < progress.length - 1 && library?.status !== 'trial'}
                    >
                      <button className="next" onClick={handleNext}>
                        <box-icon name="chevrons-right" color="#fff" size="1.2rem" />
                      </button>
                    </ShowComponent>
                  </ShowComponent>
                </div>
              </div>
            </ShowComponent>
          </LessonMenu>
        </ShowComponent>
      </LessonLayout>
    </PageStructure>
  );
};

Course.displayName = 'Course Page';

export default Course;

import i18n from 'i18n';

const serpLangs = [
  {
    value: 'af',
    label: i18n.t('Afrikaans'),
  },
  {
    value: 'ak',
    label: i18n.t('Akan'),
  },
  {
    value: 'sq',
    label: i18n.t('Albanian'),
  },
  {
    value: 'am',
    label: i18n.t('Amharic'),
  },
  {
    value: 'ar',
    label: i18n.t('Arabic'),
  },
  {
    value: 'hy',
    label: i18n.t('Armenian'),
  },
  {
    value: 'az',
    label: i18n.t('Azerbaijani'),
  },
  {
    value: 'eu',
    label: i18n.t('Basque'),
  },
  {
    value: 'be',
    label: i18n.t('Belarusian'),
  },
  {
    value: 'bem',
    label: i18n.t('Bemba'),
  },
  {
    value: 'bn',
    label: i18n.t('Bengali'),
  },
  {
    value: 'bh',
    label: i18n.t('Bihari'),
  },
  {
    value: 'bs',
    label: i18n.t('Bosnian'),
  },
  {
    value: 'br',
    label: i18n.t('Breton'),
  },
  {
    value: 'bg',
    label: i18n.t('Bulgarian'),
  },
  {
    value: 'km',
    label: i18n.t('Cambodian'),
  },
  {
    value: 'ca',
    label: i18n.t('Catalan'),
  },
  {
    value: 'chr',
    label: i18n.t('Cherokee'),
  },
  {
    value: 'ny',
    label: i18n.t('Chichewa'),
  },
  {
    value: 'zh-cn',
    label: i18n.t('Chinese (Simplified)'),
  },
  {
    value: 'zh-tw',
    label: i18n.t('Chinese (Traditional)'),
  },
  {
    value: 'co',
    label: i18n.t('Corsican'),
  },
  {
    value: 'hr',
    label: i18n.t('Croatian'),
  },
  {
    value: 'cs',
    label: i18n.t('Czech'),
  },
  {
    value: 'da',
    label: i18n.t('Danish'),
  },
  {
    value: 'nl',
    label: i18n.t('Dutch'),
  },
  {
    value: 'en',
    label: i18n.t('English'),
  },
  {
    value: 'eo',
    label: i18n.t('Esperanto'),
  },
  {
    value: 'et',
    label: i18n.t('Estonian'),
  },
  {
    value: 'ee',
    label: i18n.t('Ewe'),
  },
  {
    value: 'fo',
    label: i18n.t('Faroese'),
  },
  {
    value: 'tl',
    label: i18n.t('Filipino'),
  },
  {
    value: 'fi',
    label: i18n.t('Finnish'),
  },
  {
    value: 'fr',
    label: i18n.t('French'),
  },
  {
    value: 'fy',
    label: i18n.t('Frisian'),
  },
  {
    value: 'gaa',
    label: i18n.t('Ga'),
  },
  {
    value: 'gl',
    label: i18n.t('Galician'),
  },
  {
    value: 'ka',
    label: i18n.t('Georgian'),
  },
  {
    value: 'de',
    label: i18n.t('German'),
  },
  {
    value: 'el',
    label: i18n.t('Greek'),
  },
  {
    value: 'gn',
    label: i18n.t('Guarani'),
  },
  {
    value: 'gu',
    label: i18n.t('Gujarati'),
  },
  {
    value: 'ht',
    label: i18n.t('Haitian Creole'),
  },
  {
    value: 'ha',
    label: i18n.t('Hausa'),
  },
  {
    value: 'haw',
    label: i18n.t('Hawaiian'),
  },
  {
    value: 'iw',
    label: i18n.t('Hebrew'),
  },
  {
    value: 'hi',
    label: i18n.t('Hindi'),
  },
  {
    value: 'hu',
    label: i18n.t('Hungarian'),
  },
  {
    value: 'is',
    label: i18n.t('Icelandic'),
  },
  {
    value: 'ig',
    label: i18n.t('Igbo'),
  },
  {
    value: 'id',
    label: i18n.t('Indonesian'),
  },
  {
    value: 'ia',
    label: i18n.t('Interlingua'),
  },
  {
    value: 'ga',
    label: i18n.t('Irish'),
  },
  {
    value: 'it',
    label: i18n.t('Italian'),
  },
  {
    value: 'ja',
    label: i18n.t('Japanese'),
  },
  {
    value: 'jw',
    label: i18n.t('Javanese'),
  },
  {
    value: 'kn',
    label: i18n.t('Kannada'),
  },
  {
    value: 'kk',
    label: i18n.t('Kazakh'),
  },
  {
    value: 'rw',
    label: i18n.t('Kinyarwanda'),
  },
  {
    value: 'rn',
    label: i18n.t('Kirundi'),
  },
  {
    value: 'kg',
    label: i18n.t('Kongo'),
  },
  {
    value: 'ko',
    label: i18n.t('Korean'),
  },
  {
    value: 'kri',
    label: i18n.t('Krio (Sierra Leone)'),
  },
  {
    value: 'ku',
    label: i18n.t('Kurdish'),
  },
  {
    value: 'ckb',
    label: i18n.t('Kurdish (Soranî)'),
  },
  {
    value: 'ky',
    label: i18n.t('Kyrgyz'),
  },
  {
    value: 'lo',
    label: i18n.t('Laothian'),
  },
  {
    value: 'la',
    label: i18n.t('Latin'),
  },
  {
    value: 'lv',
    label: i18n.t('Latvian'),
  },
  {
    value: 'ln',
    label: i18n.t('Lingala'),
  },
  {
    value: 'lt',
    label: i18n.t('Lithuanian'),
  },
  {
    value: 'loz',
    label: i18n.t('Lozi'),
  },
  {
    value: 'lg',
    label: i18n.t('Luganda'),
  },
  {
    value: 'ach',
    label: i18n.t('Luo'),
  },
  {
    value: 'mk',
    label: i18n.t('Macedonian'),
  },
  {
    value: 'mg',
    label: i18n.t('Malagasy'),
  },
  {
    value: 'ms',
    label: i18n.t('Malay'),
  },
  {
    value: 'ml',
    label: i18n.t('Malayalam'),
  },
  {
    value: 'mt',
    label: i18n.t('Maltese'),
  },
  {
    value: 'mi',
    label: i18n.t('Maori'),
  },
  {
    value: 'mr',
    label: i18n.t('Marathi'),
  },
  {
    value: 'mfe',
    label: i18n.t('Mauritian Creole'),
  },
  {
    value: 'mo',
    label: i18n.t('Moldavian'),
  },
  {
    value: 'mn',
    label: i18n.t('Mongolian'),
  },
  {
    value: 'sr-ME',
    label: i18n.t('Montenegrin'),
  },
  {
    value: 'ne',
    label: i18n.t('Nepali'),
  },
  {
    value: 'pcm',
    label: i18n.t('Nigerian Pidgin'),
  },
  {
    value: 'nso',
    label: i18n.t('Northern Sotho'),
  },
  {
    value: 'no',
    label: i18n.t('Norwegian'),
  },
  {
    value: 'nn',
    label: i18n.t('Norwegian (Nynorsk)'),
  },
  {
    value: 'oc',
    label: i18n.t('Occitan'),
  },
  {
    value: 'or',
    label: i18n.t('Oriya'),
  },
  {
    value: 'om',
    label: i18n.t('Oromo'),
  },
  {
    value: 'ps',
    label: i18n.t('Pashto'),
  },
  {
    value: 'fa',
    label: i18n.t('Persian'),
  },
  {
    value: 'pl',
    label: i18n.t('Polish'),
  },
  {
    value: 'pt',
    label: i18n.t('Portuguese'),
  },
  {
    value: 'pt-br',
    label: i18n.t('Portuguese (Brazil)'),
  },
  {
    value: 'pt-pt',
    label: i18n.t('Portuguese (Portugal)'),
  },
  {
    value: 'pa',
    label: i18n.t('Punjabi'),
  },
  {
    value: 'qu',
    label: i18n.t('Quechua'),
  },
  {
    value: 'ro',
    label: i18n.t('Romanian'),
  },
  {
    value: 'rm',
    label: i18n.t('Romansh'),
  },
  {
    value: 'nyn',
    label: i18n.t('Runyakitara'),
  },
  {
    value: 'ru',
    label: i18n.t('Russian'),
  },
  {
    value: 'gd',
    label: i18n.t('Scots Gaelic'),
  },
  {
    value: 'sr',
    label: i18n.t('Serbian'),
  },
  {
    value: 'sh',
    label: i18n.t('Serbo-Croatian'),
  },
  {
    value: 'st',
    label: i18n.t('Sesotho'),
  },
  {
    value: 'tn',
    label: i18n.t('Setswana'),
  },
  {
    value: 'crs',
    label: i18n.t('Seychellois Creole'),
  },
  {
    value: 'sn',
    label: i18n.t('Shona'),
  },
  {
    value: 'sd',
    label: i18n.t('Sindhi'),
  },
  {
    value: 'si',
    label: i18n.t('Sinhalese'),
  },
  {
    value: 'sk',
    label: i18n.t('Slovak'),
  },
  {
    value: 'sl',
    label: i18n.t('Slovenian'),
  },
  {
    value: 'so',
    label: i18n.t('Somali'),
  },
  {
    value: 'es',
    label: i18n.t('Spanish'),
  },
  {
    value: 'es-419',
    label: i18n.t('Spanish (Latin American)'),
  },
  {
    value: 'su',
    label: i18n.t('Sundanese'),
  },
  {
    value: 'sw',
    label: i18n.t('Swahili'),
  },
  {
    value: 'sv',
    label: i18n.t('Swedish'),
  },
  {
    value: 'tg',
    label: i18n.t('Tajik'),
  },
  {
    value: 'ta',
    label: i18n.t('Tamil'),
  },
  {
    value: 'tt',
    label: i18n.t('Tatar'),
  },
  {
    value: 'te',
    label: i18n.t('Telugu'),
  },
  {
    value: 'th',
    label: i18n.t('Thai'),
  },
  {
    value: 'ti',
    label: i18n.t('Tigrinya'),
  },
  {
    value: 'to',
    label: i18n.t('Tonga'),
  },
  {
    value: 'lua',
    label: i18n.t('Tshiluba'),
  },
  {
    value: 'tum',
    label: i18n.t('Tumbuka'),
  },
  {
    value: 'tr',
    label: i18n.t('Turkish'),
  },
  {
    value: 'tk',
    label: i18n.t('Turkmen'),
  },
  {
    value: 'tw',
    label: i18n.t('Twi'),
  },
  {
    value: 'ug',
    label: i18n.t('Uighur'),
  },
  {
    value: 'uk',
    label: i18n.t('Ukrainian'),
  },
  {
    value: 'ur',
    label: i18n.t('Urdu'),
  },
  {
    value: 'uz',
    label: i18n.t('Uzbek'),
  },
  {
    value: 'vi',
    label: i18n.t('Vietnamese'),
  },
  {
    value: 'cy',
    label: i18n.t('Welsh'),
  },
  {
    value: 'wo',
    label: i18n.t('Wolof'),
  },
  {
    value: 'xh',
    label: i18n.t('Xhosa'),
  },
  {
    value: 'yi',
    label: i18n.t('Yiddish'),
  },
  {
    value: 'yo',
    label: i18n.t('Yoruba'),
  },
  {
    value: 'zu',
    label: i18n.t('Zulu'),
  },
];

export { serpLangs };

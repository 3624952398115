import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col } from 'reactstrap';
import { map } from 'lodash';

const DisplayErrors = ({ error, size = 12, style = {} }) => {
  const { t: translate } = useTranslation();

  return (
    <Col lg={size}>
      {map(Object.entries(error || {}), ([type, message]) => (
        <div
          key={type}
          className="error--message d-flex align-items-center"
          style={{ lineHeight: '1rem', marginTop: '.4em', ...style }}
        >
          <box-icon name="error" size="1rem" color="red"></box-icon>
          <span style={{ marginLeft: '.3em', overflowWrap: 'break-word', whiteSpace: 'pre-line' }}>
            {translate(message)}
          </span>
        </div>
      ))}
    </Col>
  );
};

DisplayErrors.propTypes = {
  error: PropTypes.object,
  size: PropTypes.number,
  style: PropTypes.object,
};

export default DisplayErrors;

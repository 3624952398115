const selectCustomStyles = (name, errors) => {
  return {
    control: (provided) => {
      return {
        ...provided,
        border: errors[name] ? '2px solid red' : `1px solid ${provided.borderColor}`,
        '&:hover': {
          border: errors[name] ? '2px solid red' : `1px solid ${provided['&:hover'].borderColor}`,
        },
      };
    },
  };
};

export { selectCustomStyles };

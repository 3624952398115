import styled from 'styled-components';

export const CardInputGoogleDriveStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  align-items: flex-start;
  gap: 0.75rem;
  align-self: stretch;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  background: #f7f7fa;
  div:nth-child(1) {
    width: 100%;
    margin: 0;
  }
  .col-lg-11 {
    width: 100% !important;
    padding: 0;
  }
  svg {
    margin: 0.25rem;
  }
  input {
    width: 100%;
  }
  .btn-go-to {
    display: flex;
    width: 2.5rem;
    padding: 0.625rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.25rem;
    border: 1px solid #b3b3b3;
    background-color: transparent !important;
    color: #484848 !important;
  }
  .btn-go-to:hover {
    background: #e2e8f0 !important;
  }
  .btn-new-folder {
    display: flex;
    min-width: 10rem;
    height: 2.75rem;
    padding: 0.625rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.25rem;
    border: 1px solid #e2e8f0;
    background: transparent !important;
    color: #343a40 !important;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .btn-new-folder:hover {
    background: #e2e8f0 !important;
  }
  .btn-edit {
    display: flex;
    height: 2.75rem;
    padding: 0.625rem 1rem;
    align-items: center;
    gap: 0.5rem;
    background-color: transparent !important;
    border: none;
    color: #556ee6 !important;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .btn-edit:hover {
    background: #e2e8f0 !important;
  }
`;

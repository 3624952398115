import i18next from 'i18next';

const getNextMondayDate = () => {
  const today = new Date();
  const currentDay = today.getDay();
  const currentHour = today.getHours();

  if (currentDay === 1 && currentHour >= 8) {
    const daysUntilNextMonday = 8 - currentDay;
    const nextMonday = new Date(today);
    nextMonday.setDate(today.getDate() + daysUntilNextMonday);
    return formatDate(nextMonday, i18next.t('date-enUS'));
  } else {
    const daysUntilNextMonday = currentDay === 0 ? 1 : 8 - currentDay;
    const nextMonday = new Date(today);
    nextMonday.setDate(today.getDate() + daysUntilNextMonday);
    return formatDate(nextMonday, i18next.t('date-enUS'));
  }
};

const formatDate = (date, lang) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  switch (lang) {
    case 'date-enUS':
      return `${month}/${day}/${year}`;
    case 'data-ptBR':
      return `${day}/${month}/${year}`;
    default:
      return `${month}/${day}/${year}`;
  }
};

export { getNextMondayDate };

const dayOrdinal = (day) => {
  if (day > 3) return 'th';

  if (day === 1) return 'st';

  if (day === 2) return 'nd';

  if (day === 3) return 'rd';

  return '';
};

export { dayOrdinal };

import i18n from 'i18n';

const competitorsChartConfig = {
  chart: {
    id: 'competitors-bar-chart',
    stacked: true,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: true,
      type: 'xy',
      autoScaleYaxis: true,
    },
    animation: {
      enabled: false,
    },
    defaultLocale: i18n.t('chart-lang'),
    locales: [
      {
        name: 'en',
        options: {
          months: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ],
          shortMonths: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ],
          days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
          shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          toolbar: {
            download: 'Download SVG',
            selection: 'Selection',
            selectionZoom: 'Selection Zoom',
            zoomIn: 'Zoom In',
            zoomOut: 'Zoom Out',
            pan: 'Panning',
            reset: 'Reset Zoom',
          },
        },
      },
      {
        name: 'pt-br',
        options: {
          months: [
            'Janeiro',
            'Fevereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro',
          ],
          shortMonths: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Out',
            'Nov',
            'Dez',
          ],
          days: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
          shortDays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
          toolbar: {
            download: 'Baixar SVG',
            selection: 'Seleção',
            selectionZoom: 'Seleção de Zoom',
            zoomIn: 'Aproximar',
            zoomOut: 'Afastar',
            pan: 'Mover',
            reset: 'Resetar Zoom',
          },
        },
      },
    ],
  },
  dataLabels: {
    dropShadow: {
      enabled: true,
      top: 1,
      left: 1,
      blur: 1,
      color: '#000',
      opacity: 0.45,
    },
  },
  xaxis: {
    type: 'number',
    tickPlacement: 'on',
    categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  },
  yaxis: {
    title: {
      text: i18n.t('Quantity of words'),
      style: {
        color: '#ADB5BD',
        fontSize: '0.7rem',
        fontWeigth: '300',
      },
    },
  },
  legend: {
    show: false,
  },
  subtitle: {
    text: '',
    offsetX: 40,
  },
  title: {
    text: i18n.t('Competitors Content Length'),
    align: 'left',
    offsetX: 10,
  },
  colors: ['#F46A6A', '#556EE6'],
  plotOptions: {
    bar: {
      columnWidth: '70%',
    },
  },
};

export { competitorsChartConfig };

import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { Row, Col, Alert, Card, CardBody, Container, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getAuth, applyActionCode } from 'firebase/auth';

import logo from '../../assets/images/small-logo.svg';
import LanguageDropdown from '../../components/Language-Dropdown';

function VerifyEmail() {
  const { t: translate } = useTranslation();
  const url = new URL(window.location.href);
  const code = url.searchParams.get('oobCode');

  const [alertColor, setAlertColor] = useState('warning');
  const [alertMessage, setAlertMessage] = useState('Waiting for verification...');

  useEffect(() => {
    const verifyEmail = async () => {
      const auth = getAuth();
      try {
        await applyActionCode(auth, code);
        setAlertColor('success');
        setAlertMessage('Email verified successfully!');
      } catch (error) {
        setAlertColor('danger');
        if (
          error.message === 'applyActionCode failed: First argument "code" must be a valid string.'
        ) {
          setAlertMessage("First argument 'code' must be a valid string.");
        } else {
          setAlertMessage(error.message);
        }
      }
    };

    verifyEmail();
  }, []);

  return (
    <>
      <MetaTags>
        <title>{translate('Verify Email')} | Ectools</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div style={{ backgroundColor: 'rgba(242, 242, 242, 0.22)' }}>
                  <Row>
                    <Col xs={9}>
                      <div className="text-primary p-4">
                        <h5 style={{ color: '#344792' }}>{translate('Verify Email')}</h5>
                        <p style={{ color: '#344792' }}>
                          {translate("Verify your SEO Dashboard account's email.")}
                        </p>
                      </div>
                    </Col>
                    <Col className="col-3" style={{ paddingLeft: '8%' }}>
                      <LanguageDropdown />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <img src={logo} alt={translate("Ectools' Logo")} height="36" />
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Alert
                      className="d-flex justify-content-center mb-4"
                      color={alertColor}
                      isOpen={true}
                    >
                      {translate(alertMessage)}
                    </Alert>
                    <Row>
                      <Col lg="12">
                        <Link to="/">
                          <Button className="col-lg-12" style={{ backgroundColor: '#344792' }}>
                            {translate('Back to Home')}
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} {translate('Ecto Digital')}.
                  {` ${translate('Created with')} `}
                  <i className="mdi mdi-heart text-danger" />
                  {` ${translate('in Curitiba')}`}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default VerifyEmail;

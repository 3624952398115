import styled from 'styled-components';

export const ButtonSaveStyle = styled.div`
  .btn-save {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 8px 12px;
    border: none;
    background-color: #48bb78;
    font-weight: 500;
    height: 36.5px;
    min-width: 89px;
  }
  .btn-save:hover {
    background-color: #25855a;
  }
`;

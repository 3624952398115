import styled from 'styled-components';

export const ContainerEditContentStyle = styled.div`
  .header-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    position: relative;

    p {
      margin: 0 !important;
      font-size: 24px;
      line-height: 32px;
      font-weight: 500;
      color: #1a202c;
    }
  }

  .container-relative {
    position: relative;
    z-index: 3;
    top: -15px;
    right: 0;
    height: 20px;
  }

  .btn-copy-markdown {
    min-width: fit-content;
    display: flex;
    padding: 0.625rem 0.75rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    background-color: transparent;
    color: #4a5568 !important;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
  }
  .btn-copy-markdown:hover {
    background-color: #f7fafc;
    border: 1px solid #f7fafc !important;
  }
  .btn-copy-markdown:active {
    color: #4a5568 !important;
    border: none !important;
  }

  .btn-recreate {
    min-width: fit-content;
    display: flex;
    padding: 0.625rem 0.75rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.25rem;
    border: 1px solid #f7fafc;
    background: #f7fafc;
    color: #4a5568 !important;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
  }
  .btn-recreate:hover {
    border: 1px solid #edf2f7 !important;
    background-color: #fff;
  }
  .btn-recreate:active {
    color: #4a5568 !important;
    border: none !important;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }
`;

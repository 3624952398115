import styled from 'styled-components';

export const CMSIntegrationStyled = styled.div`
  width: 332px;
  height: fit-content;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  position: relative;
  padding: 16px;

  button {
    display: flex;
    height: 2.75rem;
    padding: 0.625rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
    border-radius: 0.25rem;
    border: 1px solid #e2e8f0;
    background-color: transparent;
    color: #343a40 !important;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    :hover {
      border: 1px solid #e2e8f0;
      background-color: #e2e8f0;
      color: #343a40;
    }
  }

  .url-input {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    position: relative;
    .input-icon {
      position: absolute;
      right: 4.2em;
    }
    input {
      height: 2.75rem;
    }
  }

  .open-link-button {
    background-color: transparent;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0;
    aspect-ratio: 1/1;
    height: 2.75rem;
    margin: 0;
    i {
      width: 1rem;
      height: 1rem;
    }
  }
`;

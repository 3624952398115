import styled from 'styled-components';

export const PageLoadingStyle = styled.div`
  background-color: #fff;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  border-radius: 4px;
  box-shadow: 0px 12px 24px 0px rgba(18, 38, 63, 0.03);
  min-height: 580px;

  .card-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 32px;
    width: 384px;
    border-radius: 8px;
    border: 1px solid #edf2f7;

    p {
      margin: 0 !important;
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      color: #4a5568;
    }

    span {
      text-align: center;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: #718096;
    }
  }

  button {
    background-color: #f7fafc;
    color: #4a5568;
    border: none !important;
    width: 174px;
    height: 40px;
  }
  button:hover {
    background-color: #fff;
    border: 1px solid #e2e8f0 !important;
  }
`;

import React from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

import ModulesTab from './ModulesTab';
import DescriptionTab from './DescriptionTab';
import PriceTab from './PriceTab';
import AvailabilityTab from './AvailabilityTab';
import AppearanceTab from './AppearanceTab';

const ModulesInput = ({ mb = 0 }) => {
  const { t: translate } = useTranslation();
  const { watch } = useFormContext();

  const handleTabs = (event) => {
    const index = event.target.getAttribute('navLink');

    const allContents = document.querySelectorAll('.tab-pane');
    const thisContent = document.querySelector(`.tab-pane[tabIndex='${index}']`);

    const allTabs = document.querySelectorAll('.nav-link');
    const thisTab = event.target;

    allTabs.forEach((tab) => {
      tab.classList.remove('active-tab');
    });
    thisTab.classList.add('active-tab');
    allContents.forEach((content) => {
      content.classList.remove('active');
    });
    thisContent.classList.add('active');
  };

  return (
    <div className={`mb-${mb}`}>
      <Nav tabs>
        <NavItem>
          <NavLink className="nav-link active-tab" onClick={handleTabs} navLink="1">
            <box-icon style={{ pointerEvents: 'none' }} name="list-ul" size="1rem" />
            {translate('Modules')}
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink className="nav-link" onClick={handleTabs} navLink="2">
            <box-icon style={{ pointerEvents: 'none' }} name="align-left" size="1rem" />
            {translate('Description')}
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink className="nav-link" onClick={handleTabs} navLink="3">
            <box-icon style={{ pointerEvents: 'none' }} name="dollar" size="1rem" />
            {translate('Price')}
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink className="nav-link" onClick={handleTabs} navLink="4">
            <box-icon style={{ pointerEvents: 'none' }} name="lock-open" size="1rem" />
            {translate('Availability')}
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink className="nav-link" onClick={handleTabs} navLink="5">
            <box-icon style={{ pointerEvents: 'none' }} name="palette" size="1rem" />
            {translate('Appearance')}
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab="1">
        <TabPane tabId="1" tabIndex="1">
          <ModulesTab controlName="modules" />
        </TabPane>

        <TabPane tabId="2" tabIndex="2">
          <DescriptionTab />
        </TabPane>

        <TabPane tabId="3" tabIndex="3">
          <PriceTab />
        </TabPane>

        <TabPane tabId="4" tabIndex="4">
          <AvailabilityTab />
        </TabPane>

        <TabPane tabId="5" tabIndex="5">
          <AppearanceTab />
        </TabPane>
      </TabContent>
    </div>
  );
};

ModulesInput.displayName = 'Modules Input Component';

ModulesInput.propTypes = {
  controlName: PropTypes.string,
  mb: PropTypes.number,
};

export default ModulesInput;

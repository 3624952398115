const getDateByWeekNumber = (weekString) => {
  const [year, week] = weekString.split('-').map(Number);
  const firstDay = new Date(year, 0, 1 + (week - 1) * 7);
  const startOfWeek = new Date(firstDay);
  const dayOfWeek = startOfWeek.getDay();

  if (dayOfWeek > 4) {
    startOfWeek.setDate(firstDay.getDate() + 8 - dayOfWeek);
  } else {
    startOfWeek.setDate(firstDay.getDate() - dayOfWeek + 1);
  }

  const formattedDate = `${startOfWeek.getDate().toString().padStart(2, '0')}/${(startOfWeek.getMonth() + 1).toString().padStart(2, '0')}/${startOfWeek.getFullYear()}`;

  return formattedDate;
};

export { getDateByWeekNumber };

import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { ContainerDatePickerStyles } from './styles';

const ContainerDatePicker = ({ date, setDate, anyDate, index, propertyName }) => {
  const { t: translate } = useTranslation();
  const { setValue } = useFormContext();

  const handleDateChange = (newDate) => {
    const timestamp = newDate ? newDate.getTime() : null;
    setDate(timestamp);
    setValue(`guidelines.${index}.${propertyName}`, timestamp);
  };

  return (
    <ContainerDatePickerStyles>
      <DatePicker
        id="date"
        selected={date ? new Date(date) : null}
        inline
        locale={translate('en-US')}
        onChange={handleDateChange}
        minDate={anyDate ? '' : new Date()}
      />
    </ContainerDatePickerStyles>
  );
};

ContainerDatePicker.propTypes = {
  date: PropTypes.instanceOf(Date),
  setDate: PropTypes.func,
  anyDate: PropTypes.bool,
  index: PropTypes.number,
  propertyName: PropTypes.string,
};

export default ContainerDatePicker;

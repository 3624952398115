import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { setPFPDebounced } from 'utils';

import { SelectAssignedToDiv } from 'components/Task-Modal/styles';
import { CardAssignedDiv } from './styles';

const CardAssignedTo = ({ assignedTo, setAssignedTo, title, teamData, isLoading = true }) => {
  const { user } = useAuth();
  const { t: translate } = useTranslation();

  const handleAssignedToClick = (userId) => {
    const clickedUser = teamData.find((user) => user.value === userId);

    if (!assignedTo.some((user) => user.value === userId)) {
      const updatedAssignedTo = [...assignedTo, clickedUser];

      setAssignedTo(updatedAssignedTo);
    } else {
      const updatedAssignedTo = assignedTo.filter((user) => user.value !== userId);
      setAssignedTo(updatedAssignedTo);
    }
  };

  useEffect(() => {
    setPFPDebounced({
      reload: true,
      userId: user.id,
      elementSearch: `[id*=guideline-${user.id}]`,
    });
  }, [user.id]);

  return (
    <CardAssignedDiv className="card-assigned" hasResponsibles={assignedTo.length || isLoading}>
      <p className="subtitle">{title}</p>
      <div className="d-flex align-items-center gap-2">
        <UncontrolledDropdown>
          <div className="d-flex justify-content-center align-items-center">
            <div className="avatar-group">
              {_.map(assignedTo, (member, index) => {
                return (
                  <div className="avatar-group-item" key={index}>
                    <div
                      className="avatar-user"
                      alt={`${translate('Photo of')} ${assignedTo[index]}`}
                      id={`guideline-${member.value}`}
                    />
                    <p className="avatar-name">{member.label}</p>
                  </div>
                );
              })}
            </div>
          </div>
          {(assignedTo.length > 0 || isLoading) && (
            <>
              <DropdownToggle
                className="dropdown-include"
                style={{
                  backgroundColor: 'transparent',
                  border: 'none',
                }}
                caret
              >
                <p>{translate('Add')}</p>
                <i className="bx bx-plus" />
              </DropdownToggle>
              <DropdownMenu style={{ boxShadow: 'none', border: '1px solid #E2E8F0' }}>
                {assignedTo &&
                  teamData?.map((user, index) => {
                    const isUserAssigned = assignedTo
                      .map((user) => user.value)
                      .includes(user.value);
                    setPFPDebounced({
                      userId: user.value,
                      elementSearch: `[id*=guideline-${user.value}]`,
                    });
                    return (
                      <DropdownItem
                        key={user.value}
                        onClick={() => {
                          handleAssignedToClick(user.value);
                        }}
                        toggle={false}
                      >
                        <SelectAssignedToDiv>
                          <div>
                            <div className="avatar-group-item" key={index}>
                              <div
                                className="avatar-user"
                                alt={`${translate('Photo of')} ${user.label}`}
                                id={`guideline-${user.value}`}
                              ></div>
                            </div>
                            <div>{user.label}</div>
                          </div>
                          <div>
                            <span className={isUserAssigned ? 'remove' : 'add'}>
                              {isUserAssigned ? translate('Remover') : translate('Adicionar')}
                            </span>
                          </div>
                        </SelectAssignedToDiv>
                      </DropdownItem>
                    );
                  })}
              </DropdownMenu>
            </>
          )}
          {assignedTo.length === 0 && !isLoading && (
            <div className="error-text">
              * {translate('You must have at least one user assigned as responsible to approval')}
            </div>
          )}
        </UncontrolledDropdown>
      </div>
    </CardAssignedDiv>
  );
};

CardAssignedTo.propTypes = {
  assignedTo: PropTypes.array,
  setAssignedTo: PropTypes.func,
  title: PropTypes.string,
  teamData: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default CardAssignedTo;

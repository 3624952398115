import React, { useState, useEffect, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import 'boxicons';

import { useAuth } from 'hooks/useAuth';
import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { coursesColorCodes } from '../../../constants';
import { CourseGridItem } from './styles';

const CourseCard = ({ index, data, updateCard, library, showProgress = false }) => {
  const firebaseHelper = getFirebaseBackend();
  const { t: translate } = useTranslation();
  const { user } = useAuth();
  const { adminStatus } = user;
  const [hasProgress, setHasProgress] = useState(false);
  const [progress, setProgress] = useState(0);
  const [teachers, setTeachers] = useState('');
  const [hasBanner, setHasBanner] = useState(null);
  const courseImageRef = useRef(null);
  const bannerImageRef = useRef(null);

  const fetchTeacherNames = async () => {
    const teacherStr = await Promise.all(
      data.teachers.map(async (teacherId, index) => {
        const teacherName = await firebaseHelper.userName(teacherId);
        if (index === data.teachers.length - 1) return teacherName;
        return `${teacherName}, `;
      }, ''),
    );

    setTeachers(teacherStr.join(''));
  };

  const fetchCourse = async () => {
    const courseObj = await firebaseHelper.getFirebaseCourse(data.account, data.id);

    const newCourseTotal = courseObj.modules.reduce((acc, cur) => {
      return (acc += cur.lessons.length);
    }, 0);

    if (library[data?.id].progress) {
      const newProgress = Math.floor((library[data?.id].progress.length / newCourseTotal) * 100);
      setHasProgress(true);
      setProgress(newProgress);
    } else {
      setHasProgress(false);
      setProgress(0);
    }
  };

  const fetchCourseBanner = async () => {
    const path = `${data.account}/courses/${data.id}`;
    const bannerMeta = await firebaseHelper.listAllFiles(path);
    if (bannerMeta.length) {
      setHasBanner(true);
      const bannerFile = await firebaseHelper.getFileObj(`${path}/${bannerMeta[0].name}`);
      const bannerURL = URL.createObjectURL(bannerFile);
      if (bannerImageRef.current)
        bannerImageRef.current.style.backgroundImage = `url('${bannerURL}')`;
    } else {
      setHasBanner(false);
    }
  };

  const handleClick = async (event) => {
    event.preventDefault();

    await firebaseHelper.setFirebaseLibraryCourse(user.id, data.id, {
      account: data.account,
      status: 'owned',
      lastLesson: null,
      progress: null,
      tests: null,
    });
    updateCard();
  };

  useEffect(() => {
    if (!data) return;

    courseImageRef.current.style.backgroundColor = coursesColorCodes[index % 7];
    if (showProgress) fetchCourse();
    fetchTeacherNames();
    fetchCourseBanner();
  }, [data]);

  return (
    <CourseGridItem>
      <Link
        className="course-image"
        ref={courseImageRef}
        to={{
          pathname: '/course',
          search: `?acc=${data.account}&id=${data.id}`,
        }}
      >
        {adminStatus !== 'Client' && (
          <div className="course-edit-btn">
            <Link
              to={{
                pathname: '/edit-course',
                search: `?acc=${data.account}&id=${data.id}`,
              }}
            >
              <box-icon name="pencil" color="#000" size="1.5rem" />
            </Link>
          </div>
        )}
        {showProgress && (
          <div className="course-progress">
            {hasProgress ? (
              <>{`${progress}% ${translate('completed')}`}</>
            ) : (
              <>{translate('Not started')}</>
            )}
          </div>
        )}
        {hasBanner === false && <box-icon name="book-open" color="#E7E7E7" size="4.5rem" />}
        <div className={`banner-image ${hasBanner ? 'has-image' : ''}`} ref={bannerImageRef}></div>
      </Link>

      <div className="course-info">
        <div className="course-title">{data?.name}</div>
        <div className="course-teachers">
          <box-icon name="user" color="#808080" size="1rem" />
          <div>{teachers}</div>
        </div>
        <div className="course-description">{data?.description}</div>
        <div className="course-interact">
          <span>{translate('Free')}</span>
          {Object.keys(library).includes(data?.id) ? (
            <div>
              <box-icon name="check-double" color="#50A5F1" size="1.2rem" />
              {translate('Course in Library')}
            </div>
          ) : (
            <Button onClick={handleClick}>
              <box-icon name="folder-plus" color="#fff" size="1.2rem" />
              {translate('Add to library')}
            </Button>
          )}
        </div>
      </div>
    </CourseGridItem>
  );
};

CourseCard.displayName = 'Course Card Component';

CourseCard.propTypes = {
  index: PropTypes.number,
  data: PropTypes.object,
  updateCard: PropTypes.func,
  library: PropTypes.array,
  showProgress: PropTypes.bool,
};

const arePropsEqual = (prevProps, nextProps) => {
  const isIndexEquals = prevProps.index === nextProps.index;
  const isDataEquals = JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data);
  const isLibraryEquals = JSON.stringify(prevProps.library) === JSON.stringify(nextProps.data);

  return isDataEquals && isLibraryEquals && isIndexEquals;
};

export default memo(CourseCard, arePropsEqual);

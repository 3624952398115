import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const KanbanBoardShimmer = forwardRef(function boardShimmer(props, ref) {
  return (
    <div className="board-shimmer" ref={ref}>
      <div className="shimmer-column big-shimmer"></div>
      <div className="shimmer-column small-shimmer"></div>
      <div className="shimmer-column big-shimmer"></div>
      <div className="shimmer-column medium-shimmer"></div>
      <div className="shimmer-column big-shimmer"></div>
    </div>
  );
});

KanbanBoardShimmer.propTypes = {
  ref: PropTypes.object,
};

export default KanbanBoardShimmer;

import React from 'react';
import PropTypes from 'prop-types';
import 'boxicons';

import { BlankButton } from './styles';

const IconButton = ({ iconProps, ...props }) => {
  return (
    <BlankButton {...props}>
      <box-icon {...iconProps}></box-icon>
    </BlankButton>
  );
};

IconButton.propTypes = {
  iconProps: PropTypes.object,
};

export default IconButton;

import React from 'react';
import { BadgeDeniedStyle } from './styles';
import { useTranslation } from 'react-i18next';

const BadgeDenied = () => {
  const { t: translate } = useTranslation();

  return <BadgeDeniedStyle>{translate('Denied')}</BadgeDeniedStyle>;
};

export default BadgeDenied;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import PhotoPicker from 'components/Photo-Picker';
import { TabWrapper } from './styles';

const AppearanceTab = () => {
  const { t: translate } = useTranslation();

  return (
    <TabWrapper>
      <div className="mb-2">{translate("Customize your course's page appearance.")}</div>

      <h4>{translate('Course Banner')}</h4>

      <PhotoPicker controlName="icon" />
    </TabWrapper>
  );
};

AppearanceTab.displayName = 'Appearance Tab Component';

AppearanceTab.propTypes = {};

export default AppearanceTab;

import i18next from 'i18next';
import { monthNumber } from '../../constants/index';
import { dayOrdinal } from '../../utils/index';

const formatDate = (date, lang, time = false) => {
  if (!date) return;
  switch (lang) {
    case 'date-enUS':
      return `${i18next.t(monthNumber[date.getMonth()])} ${date.getDate()}${dayOrdinal(
        date.getDate(),
      )}, ${date.getFullYear()} ${
        time
          ? `at ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(
              2,
              '0',
            )}`
          : ''
      }`;
    case 'data-ptBR':
      return `${date.getDate()} ${i18next.t(
        monthNumber[date.getMonth()],
      )} de ${date.getFullYear()} ${
        time
          ? `às ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(
              2,
              '0',
            )}`
          : ''
      }`;
  }
};

export { formatDate };

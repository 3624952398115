import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { useParams } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import ReactMarkdownEditorLite from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import MarkdownIt from 'markdown-it';

import { ContentTextareaStyle } from './styles';
import { MoonLoader } from 'react-spinners';
import { CircularArrowsIcon } from 'assets/icons';

const countWordsInContent = (content) => {
  const words = content.split(' ');
  const filteredWords = words.filter((word) => word !== '');
  return filteredWords.length;
};

const ContentTextarea = ({
  item,
  index,
  recreateFunction,
  noIA,
  fullContent,
  isDisabled,
  wordsArray,
  setWordsArray,
}) => {
  const firebaseHelper = getFirebaseBackend();
  const { id } = useParams();
  const { user } = useAuth();
  const currentAccountId = user?.account;
  const { t: translate } = useTranslation();
  const mdParser = new MarkdownIt({});
  const editorRef = useRef(null);
  const [markdownContent, setMarkdownContent] = useState(item.content);
  const [copiedText, setCopiedText] = useState(false);
  const [isRotating, setRotating] = useState(false);
  const [countWords, setCountWords] = useState(countWordsInContent(item.content));

  const saveContentTopicToFirebase = _.debounce(async (topicIndex, content) => {
    try {
      await firebaseHelper.changeContentTopic(currentAccountId, id, topicIndex, content);
    } catch (error) {
      console.log(error);
    }
  }, 1500);

  const saveContentToFirebase = _.debounce(async (content) => {
    try {
      await firebaseHelper.changeContent(currentAccountId, id, content);
    } catch (error) {
      console.log(error);
    }
  }, 1500);

  const handleCopyContent = () => {
    if (editorRef.current) {
      const textToCopy = editorRef.current.getMdValue();
      navigator.clipboard.writeText(textToCopy);

      setCopiedText(true);

      setTimeout(() => {
        setCopiedText(false);
      }, 1500);
    }
  };

  const handleRecreateFunction = async () => {
    setRotating(true);
    await recreateFunction();
    setRotating(false);
  };

  const verifyArrayWords = () => {
    const currentTotalWords = {
      index: index,
      size: countWords,
    };

    const indexExists = wordsArray.findIndex((item) => item.index === index);
    if (indexExists !== -1) {
      const newWordsArray = [...wordsArray];
      newWordsArray.splice(indexExists, 1, currentTotalWords);
      const updatedTotalSize = newWordsArray.reduce(
        (acc, item) => (item.size ? acc + item.size : acc),
        0,
      );
      const totalIndex = newWordsArray.findIndex((item) => item.totalSize !== undefined);
      if (totalIndex !== -1) {
        newWordsArray.splice(totalIndex, 1, { totalSize: updatedTotalSize });
      } else {
        newWordsArray.push({ totalSize: updatedTotalSize });
      }
      setWordsArray(newWordsArray);
    } else {
      const newWordsArray = [...wordsArray, currentTotalWords];
      const updatedTotalSize = newWordsArray.reduce(
        (acc, item) => (item.size ? acc + item.size : acc),
        0,
      );
      const totalIndex = newWordsArray.findIndex((item) => item.totalSize !== undefined);
      if (totalIndex !== -1) {
        newWordsArray.splice(totalIndex, 1, { totalSize: updatedTotalSize });
      } else {
        newWordsArray.push({ totalSize: updatedTotalSize });
      }
      setWordsArray(newWordsArray);
    }
  };

  useEffect(() => {
    setMarkdownContent(item.content);
  }, [item.content]);

  return (
    <ContentTextareaStyle>
      <div className="header-content-markdown">
        {fullContent ? (
          <div
            className="d-flex align-items-center gap-2"
            style={{
              width: '100%',
              justifyContent: 'space-between',
              flex: '1 0 1',
            }}
          >
            <Input
              type="text"
              className="heading-h1"
              name="title"
              value="h1"
              bsSize="sm"
              readOnly
              style={{
                width: '31px',
                textTransform: 'uppercase',
                flex: '1 0 1',
              }}
            />
            <div
              style={{
                width: '100px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                flex: '1',
                whiteSpace: 'nowrap',
              }}
            >
              <p className="title-topic m-0">{item.title}</p>
            </div>
          </div>
        ) : (
          <div
            className="d-flex align-items-center gap-2"
            style={{
              width: '100%',
              justifyContent: 'space-between',
              flex: '1 0 1',
            }}
          >
            <Input
              type="text"
              className="heading-h1"
              name="title"
              value={item.heading}
              bsSize="sm"
              readOnly
              style={{
                width: '35px',
                textTransform: 'uppercase',
                flex: '1 0 1',
              }}
            />
            <div
              style={{
                width: '100px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                flex: '1',
                whiteSpace: 'nowrap',
              }}
            >
              <p className="title-topic m-0">{item.topic}</p>
            </div>

            <div className="d-flex align-items-center gap-2">
              <div className="d-flex align-items-center gap-2">
                <button
                  className="btn-copy-markdown"
                  color="secondary"
                  onClick={handleCopyContent}
                  disabled={copiedText}
                >
                  {copiedText ? <i className="bx bx-check" /> : <i className="bx bx-copy" />}
                  {copiedText ? translate('Copied') : translate('Copy topic')}
                </button>
              </div>
              {!noIA && (
                <Button
                  className="btn-recreate-markdown"
                  color="secondary"
                  onClick={handleRecreateFunction}
                >
                  {isRotating ? (
                    <MoonLoader color="#FFF" size={10} />
                  ) : (
                    <CircularArrowsIcon color="#fff" />
                  )}
                  {translate('Recreate topic')}
                </Button>
              )}
            </div>
          </div>
        )}
        {countWords && (
          <span className="tag-text-size">{`${countWords} ${translate('words')}`}</span>
        )}
      </div>
      <ReactMarkdownEditorLite
        ref={editorRef}
        className="content-item"
        plugins={[
          'header',
          'font-bold',
          'font-italic',
          'font-underline',
          'font-strike',
          'list-unordered',
          'list-ordered',
          'block-quote',
          'block-code-inline',
          'block-code-block',
          'link',
          'logger',
          'mode-toggle',
        ]}
        value={markdownContent}
        onChange={({ text }) => {
          setMarkdownContent(text);
          fullContent ? saveContentToFirebase(text) : saveContentTopicToFirebase(index, text);
          setCountWords(countWordsInContent(text));
          verifyArrayWords();
        }}
        renderHTML={(text) => mdParser.render(text)}
        view={{
          menu: true,
          md: false,
          html: true,
        }}
        readOnly={isDisabled}
        syncScrollMode={['rightFollowLeft', 'leftFollowRight']}
      />
    </ContentTextareaStyle>
  );
};

ContentTextarea.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  recreateFunction: PropTypes.func,
  noIA: PropTypes.bool,
  fullContent: PropTypes.bool,
  isDisabled: PropTypes.bool,
  wordsArray: PropTypes.array,
  setWordsArray: PropTypes.func,
};

export default ContentTextarea;

import React from 'react';
import { Button } from 'reactstrap';

const ButtonMove = () => {
  return (
    <Button className="btn-move">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M6.33149 11.6641C6.14808 11.6641 5.99802 11.8141 6.00002 11.9975C6.00002 12.1809 6.15008 12.331 6.33349 12.331C6.5169 12.331 6.66696 12.1809 6.66696 11.9975C6.66696 11.8141 6.5169 11.6641 6.33149 11.6641"
          stroke="#718096"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.33149 7.65625C6.14808 7.65625 5.99802 7.80631 6.00002 7.98972C6.00002 8.17313 6.15008 8.32319 6.33349 8.32319C6.5169 8.32319 6.66696 8.17313 6.66696 7.98972C6.66696 7.80631 6.5169 7.65625 6.33149 7.65625"
          stroke="#718096"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.33149 3.65625C6.14808 3.65625 5.99802 3.80631 6.00002 3.98972C6.00002 4.17313 6.15008 4.32319 6.33349 4.32319C6.5169 4.32319 6.66696 4.17313 6.66696 3.98972C6.66696 3.80631 6.5169 3.65625 6.33149 3.65625"
          stroke="#718096"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.99751 11.6641C9.8141 11.6641 9.66403 11.8141 9.66604 11.9975C9.66604 12.1809 9.8161 12.331 9.99951 12.331C10.1829 12.331 10.333 12.1809 10.333 11.9975C10.333 11.8141 10.1829 11.6641 9.99751 11.6641"
          stroke="#718096"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.99751 7.65625C9.8141 7.65625 9.66403 7.80631 9.66604 7.98972C9.66604 8.17313 9.8161 8.32319 9.99951 8.32319C10.1829 8.32319 10.333 8.17313 10.333 7.98972C10.333 7.80631 10.1829 7.65625 9.99751 7.65625"
          stroke="#718096"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.99751 3.65625C9.8141 3.65625 9.66403 3.80631 9.66604 3.98972C9.66604 4.17313 9.8161 4.32319 9.99951 4.32319C10.1829 4.32319 10.333 4.17313 10.333 3.98972C10.333 3.80631 10.1829 3.65625 9.99751 3.65625"
          stroke="#718096"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Button>
  );
};

export default ButtonMove;

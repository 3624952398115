import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { MoonLoader } from 'react-spinners';

import { CircularArrowsIcon } from 'assets/icons';

const ButtonRefresh = ({ onClickFunction, index }) => {
  const { t: translate } = useTranslation();
  const [isRotating, setRotating] = useState(false);

  const handleRefreshClick = async () => {
    setRotating(true);
    await onClickFunction();
    setRotating(false);
  };

  return (
    <span>
      <Button
        className="btn-refresh"
        id={`btn-refresh-tooltip-${index}`}
        onClick={handleRefreshClick}
      >
        {isRotating ? (
          <MoonLoader color="gray" size={10} />
        ) : (
          <CircularArrowsIcon strokeWidth="1" />
        )}
      </Button>
      <UncontrolledTooltip
        placement="right"
        target={`btn-refresh-tooltip-${index}`}
        className="tooltip"
      >
        {translate('Recreate topic')}
      </UncontrolledTooltip>
    </span>
  );
};

ButtonRefresh.propTypes = {
  onClickFunction: PropTypes.func,
  index: PropTypes.number,
};

export default ButtonRefresh;

import styled from 'styled-components';

export const BadgeDeniedStyle = styled.span`
  display: flex;
  height: 20px;
  width: fit-content;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: #fff5f5;
  color: #c53030;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
`;

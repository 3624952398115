import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ErrorModal = ({ isOpen }) => {
  const { t: translate } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
    >
      <ModalHeader>
        <div className="modal--header">
          <i className="bx bx-error-circle modal--icon" style={{ color: 'red' }}></i>
          <span className="modal--title">{translate('An Unexpected Error Occured!')}</span>
        </div>
      </ModalHeader>

      <ModalBody>
        <p>
          {translate(
            'If this error persists check if the link you are trying to access exists and try again later!',
          )}
        </p>
      </ModalBody>

      <ModalFooter>
        <Link to="/">
          <button className="modal--button-two">{translate('Return')}</button>
        </Link>
      </ModalFooter>
    </Modal>
  );
};

ErrorModal.propTypes = {
  isOpen: PropTypes.bool,
};

export default ErrorModal;

import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Col, Row, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'hooks/useAuth';
import { getFirebaseBackend } from '../../helpers/firebaseHelper';
import PageStructure from '../../components/Page-Structure/';
import SuccessModal from '../../components/Success-Modal';
import LabelGroup from '../../components/Label-Group';
import TextInput from '../../components/Text-Input';
import CheckboxInput from '../../components/Checkbox-Input';
import SpinnerInput from 'components/Spinner-Input';
import MarkdownInput from '../../components/Markdown-Input';
import SwitchInput from '../../components/Switch-Input';
import SelectInput from '../../components/Select-Input';
import ShowComponent from 'components/Show-Component';
import FilePicker from '../../components/File-Picker';
import { loadTeam, loadTests, getFormValue } from 'utils';

const formConfig = {
  defaultValues: {
    durationMins: 1,
    name: '',
    videoLink: '',
    public: false,
    teachers: [],
    description: '',
    material: [],
    allowQuestions: false,
    applyTest: false,
    test: '',
  },
  shouldFocusError: true,
  criteriaMode: 'all',
  mode: 'onSubmit',
  reValidateMode: 'onChange',
};

const CreateLesson = () => {
  const firebaseHelper = getFirebaseBackend();
  const { t: translate } = useTranslation();
  const form = useForm(formConfig);
  const { watch, getValues, formState } = form;
  const { user } = useAuth();
  const accountId = user?.account;
  const [lessonKey, setLessonKey] = useState(null);
  const [successModal, setSuccessModal] = useState(false);
  const [teachersKey, setTeachersKey] = useState(0);
  const [questionsKey, setQuestionsKey] = useState(1000);

  const nextLessonKey = async (accountId) => {
    const newLessonKey = await firebaseHelper.newFirebaseKey('lessons/', accountId);
    setLessonKey(newLessonKey);
  };

  const closeModalHandle = () => {
    form.reset();
    setSuccessModal(false);
  };

  const onSubmit = async (data) => {
    const lesson = {
      accountId: accountId,
      id: lessonKey,
      durationMins: data.durationMins,
      name: data?.name ?? null,
      videoLink: data?.videoLink ? data.videoLink : null,
      public: data.public,
      teachers: data.teachers.length ? data.teachers.map(getFormValue) : null,
      description: data.description,
      allowQuestions: data.allowQuestions,
      applyTest: data.applyTest,
      test: data.applyTest ? data?.test?.value : null,
    };
    firebaseHelper.setFirebaseLesson(lesson).then(() => {
      const files = getValues('material');
      if (files.length) {
        firebaseHelper.storeFiles(files, `${accountId}/lessons/${lessonKey}`);
      }
      nextLessonKey(accountId);
      setSuccessModal(true);
    });
  };

  useEffect(() => {
    nextLessonKey();

    return () => {
      firebaseHelper.removeUnusedMDFiles({
        accountId,
        id: lessonKey,
      });
    };
  }, []);

  useEffect(() => {
    setTeachersKey((prevState) => prevState + 1);
    setQuestionsKey((prevState) => prevState + 1);
  }, [accountId]);

  return (
    <PageStructure
      metaTitle="Create Lesson"
      cardTitle="Create a new Lesson"
      card
      breadcrumbItem="Create Lesson"
      breadcrumbTitle="Courses"
    >
      <SuccessModal
        isOpen={successModal}
        onClose={closeModalHandle}
        verb="Created"
        buttonText="See Lessons"
        buttonLink="/lessons"
      />

      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <LabelGroup htmlFor="durationMins" label="Lesson Information" required>
            <SpinnerInput controlName="durationMins" label="Minutes of duration" size={4} min={1} />
          </LabelGroup>

          <LabelGroup htmlFor="name" label="Lesson Name">
            <TextInput controlName="name" placeholder="Lesson Name" size={9} />

            <CheckboxInput controlName="public" label="This is a public lesson" size={3} />
          </LabelGroup>

          <LabelGroup htmlFor="videoLink" label="Video Link">
            <TextInput
              controlName="videoLink"
              placeholder="Type the Lesson Video URL (Vimeo or Youtube)"
              example="https://vimeo.com/1234567890"
              validation={{
                pattern: {
                  value:
                    /(https?:\/\/(.+?\.)?(vimeo|youtube)\.com(\/[A-Za-z0-9\-\._~:\/\?#\[\]@!$&'\(\)\*\+,;\=]*)?)/,
                  message: 'The URL needs to be from Vimeo or Youtube',
                },
              }}
            />
          </LabelGroup>

          <LabelGroup htmlFor="teachers" label="Teachers" required>
            <SelectInput
              asyncronous
              isMulti
              controlName="teachers"
              required
              key={teachersKey}
              cacheOptions
              defaultOptions
              loadOptions={(inputValue) => loadTeam(accountId, inputValue)}
            />
          </LabelGroup>

          <LabelGroup htmlFor="description" label="Description" required>
            <MarkdownInput attachId={lessonKey} controlName="description" required />
          </LabelGroup>

          <LabelGroup htmlFor="material" label="Downloadable Material">
            <FilePicker controlName="material" multiple isMulti />
          </LabelGroup>

          <LabelGroup htmlFor="allowQuestions" label="Interactions">
            <SwitchInput controlName="allowQuestions" label="Allow Questions" />
            <SwitchInput controlName="applyTest" label="Apply test after this lesson" />

            <ShowComponent condition={watch('applyTest')}>
              <LabelGroup htmlFor="test" label="Test for this lesson" column>
                <SelectInput
                  asyncronous
                  controlName="test"
                  required
                  key={questionsKey}
                  cacheOptions
                  defaultOptions
                  loadOptions={(inputValue) => loadTests(accountId, inputValue)}
                />
              </LabelGroup>
            </ShowComponent>
          </LabelGroup>

          <Row className="justify-content-end">
            <Col lg="10">
              <Button
                type="submit"
                style={{ backgroundColor: '#344792' }}
                disabled={Object.values(formState.errors).length}
              >
                {translate('Create Lesson')}
              </Button>
            </Col>
          </Row>
        </form>
      </FormProvider>
    </PageStructure>
  );
};

CreateLesson.displayName = 'Create Lesson Page';

export default CreateLesson;

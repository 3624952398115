import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import Slider from 'react-rangeslider';
import { useFormContext, Controller } from 'react-hook-form';
import 'react-rangeslider/lib/index.css';

import DisplayErrors from '../Display-Errors';
import { StyledSlider } from './styles';

const SliderInput = ({
  disable = false,
  required,
  size,
  controlName,
  onChange,
  validation = {},
  ...other
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const validate = required ? { ...validation, required: 'Required Field!' } : validation;

  if (disable) {
    onChange = () => {};
  }

  return (
    <Col lg={size}>
      <Controller
        control={control}
        name={controlName}
        rules={validate}
        render={({ field }) => (
          <StyledSlider
            {...field}
            disabled={disable}
            id={controlName}
            value={field.value}
            ref={field.ref}
            onChange={onChange ? onChange : field.onChange}
            {...other}
          />
        )}
      />

      <DisplayErrors error={errors?.[controlName]?.types} />
    </Col>
  );
};

SliderInput.propTypes = {
  disable: PropTypes.bool,
  required: PropTypes.bool,
  size: PropTypes.number,
  controlName: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  validation: PropTypes.object,
};

export default SliderInput;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import SwitchInput from 'components/Switch-Input';
import SpinnerInput from 'components/Spinner-Input';
import { TabWrapper, FreeTrialDisclaimer } from './styles';

const PriceTab = () => {
  const { t: translate } = useTranslation();
  const { watch, getValues, setValue } = useFormContext();

  useEffect(() => {
    if (!watch('freeTrial')) {
      const modules = getValues('modules');
      const newModules = modules.map((module) => {
        const newLessons = module.lessons.map((lesson) => {
          return { ...lesson, freeTrial: false };
        });
        return { ...module, lessons: newLessons };
      });

      setValue('modules', newModules);
    }
  }, [watch('freeTrial')]);

  return (
    <TabWrapper>
      <div className="mb-3">
        {translate(
          'Define what will be the value charged for your course, special conditions and discounts.',
        )}
      </div>

      <h4>{translate('Special Condition')}</h4>

      <SwitchInput
        controlName="clientFree"
        label="This Course is free for clients"
        description={
          <>
            {translate('If defined as ')}
            <strong>{translate('yes')}</strong>
            {translate(
              ", the dashboard clients can consume the course's content while their contract is active.",
            )}
          </>
        }
        round
      />

      <SwitchInput
        controlName="freeTrial"
        label="Allow free trial before purchase"
        description={
          <>
            {translate('If defined as ')}
            <strong>{translate('yes')}</strong>
            {translate(', the users will be able to use a free trial of the content.')}
          </>
        }
        round
      />

      {watch('freeTrial') && (
        <>
          <FreeTrialDisclaimer className="mt-2" style={{ marginLeft: '85px' }}>
            <SpinnerInput
              controlName="freeTrialDays"
              min={1}
              step={1}
              max={30}
              label="Days of Free Trial"
            />

            {translate('On the ')}
            <strong>{translate('modules ')}</strong>
            {translate(
              'tab, choose which lessons will be available on the free trial of this course.',
            )}
          </FreeTrialDisclaimer>
        </>
      )}
    </TabWrapper>
  );
};

PriceTab.displayName = 'Price Tab Component';

PriceTab.propTypes = {};

export default PriceTab;

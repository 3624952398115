import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, Button, Collapse, Spinner, Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { useParams } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import axios from 'axios';

import ButtonDetails from '../Button-Details';
import { SERPDataLayout } from './styles';

const SERPData = ({ data, googleQuestionsData, primaryKeyword }) => {
  const firebaseHelper = getFirebaseBackend();
  const { id } = useParams();
  const { user } = useAuth();
  const currentAccountId = user?.account;
  const isProduction = process.env.REACT_APP_BUILD_TYPE === 'production';
  const { t: translate } = useTranslation();
  const [detailIndexIsOpen, setDetailIndexIsOpen] = useState(-1);
  const [googleQuestions, setGoogleQuestions] = useState([]);
  const [googleQuestionsIsOpen, setGoogleQuestionsIsOpen] = useState(false);
  const [isLoadingQuestions, setIsLoadingQuestions] = useState(false);

  const toogleDetail = (index) => {
    if (detailIndexIsOpen === index) {
      setDetailIndexIsOpen(-1);
    } else {
      setDetailIndexIsOpen(index);
    }
  };

  const countHeadingTags = () => {
    if (Array.isArray(data)) {
      for (const item of data) {
        if (item && Array.isArray(item.headers)) {
          const tagCounts = [
            { tag: 'h1', count: 0 },
            { tag: 'h2', count: 0 },
            { tag: 'h3', count: 0 },
            { tag: 'h4', count: 0 },
            { tag: 'h5', count: 0 },
            { tag: 'h6', count: 0 },
          ];

          for (const header of item.headers) {
            const tagCount = tagCounts.find((tc) => tc.tag === header.tag);
            if (tagCount) {
              tagCount.count++;
            }
          }

          item.tagCounts = tagCounts;
        }
      }
    }
  };

  const getGoogleQuestions = async () => {
    setIsLoadingQuestions(true);

    if (googleQuestionsData && googleQuestionsData !== 'NO_QUESTIONS') {
      setGoogleQuestions(googleQuestionsData);
      setIsLoadingQuestions(false);

      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getGoogleQuestions`,
        {
          accountId: currentAccountId,
          guidelineId: id,
          development: !isProduction,
        },
        {
          headers: {
            Authorization: `Bearer ${await firebaseHelper.getIdToken()}`,
          },
        },
      );
      setGoogleQuestions(response.data.googleQuestions);
      setIsLoadingQuestions(false);
    } catch (error) {
      console.log(error);
      setIsLoadingQuestions(false);
    }
  };

  useEffect(() => {
    countHeadingTags();
    getGoogleQuestions();
  }, [data]);

  return (
    <SERPDataLayout>
      <div className="primary-keyword">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M17.12 5.856C15.733 4.698 13.948 4 12 4C7.582 4 4 7.582 4 12C4 16.418 7.582 20 12 20C16.418 20 20 16.418 20 12H13"
            stroke="#718096"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p>{primaryKeyword?.charAt(0).toUpperCase() + primaryKeyword?.slice(1)}</p>
        <Badge color="light">{translate('Primary')}</Badge>
      </div>
      <Table bordered>
        <thead>
          <tr>
            <th style={{ width: '72px', textAlign: 'center' }}>{translate('Position')}</th>
            <th>URL</th>
            <th style={{ width: '88px', textAlign: 'center' }}>{translate('Status')}</th>
            <th style={{ width: '139px', textAlign: 'center' }}>{translate('Action')}</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data?.map((site, index) => (
              <React.Fragment key={index}>
                <tr className="table-row">
                  <td>{index}</td>
                  <td style={{ textAlign: 'left', position: 'relative' }}>
                    <p className="url">{site.site}</p>
                    <a className="svg-url" href={site.site} target="_blank" rel="noreferrer">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M10.667 2H14.0003V5.33333"
                          stroke="#718096"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.33301 6.66667L13.9997 2"
                          stroke="#718096"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.6667 9.33594V12.6693C12.6667 13.4059 12.07 14.0026 11.3333 14.0026H3.33333C2.59667 14.0026 2 13.4059 2 12.6693V4.66927C2 3.9326 2.59667 3.33594 3.33333 3.33594H6.66667"
                          stroke="#718096"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </a>
                  </td>
                  <td>
                    {site?.status < 300 ? (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="8"
                          viewBox="0 0 8 8"
                          fill="none"
                          className="svg-status-200"
                        >
                          <circle cx="4" cy="4" r="4" fill="#48BB78" />
                        </svg>
                        {site.status}
                      </>
                    ) : (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="9"
                          height="8"
                          viewBox="0 0 9 8"
                          fill="none"
                        >
                          <circle cx="4.5" cy="4" r="4" fill="#E53E3E" />
                        </svg>
                        {site.status}
                      </>
                    )}
                  </td>
                  <td>
                    <ButtonDetails
                      onClickFunction={toogleDetail}
                      id={index}
                      isOpen={detailIndexIsOpen === index}
                    />
                  </td>
                </tr>
                <tr className="table-content-details">
                  <td colSpan={4}>
                    <Collapse isOpen={detailIndexIsOpen === index}>
                      <div className="tag-container">
                        {site?.tagCounts &&
                          site.tagCounts.map((tag, index) => (
                            <span key={index} className="tag-heading">
                              <p className="tag-count">{tag.count === 0 ? '-' : tag.count}</p>
                              <p className={`tag tag-${tag.tag}`}>{tag.tag}</p>
                            </span>
                          ))}
                      </div>
                      <div className="tag-url-container">
                        {site?.headers &&
                          site.headers.map((header, index) => {
                            const lineCount = parseInt(header.tag.slice(-1), 10);

                            return (
                              <div key={index} className="d-flex align-items-center gap-2">
                                {header.tag !== 'h1' &&
                                  Array.from({ length: lineCount - 1 }).map((_, index) => (
                                    <div key={index} className="vertical-line" />
                                  ))}
                                <p className={`tag tag-${header.tag}`}>{header.tag}</p>
                                <p>{header.text}</p>
                              </div>
                            );
                          })}
                      </div>
                    </Collapse>
                  </td>
                </tr>
              </React.Fragment>
            ))}
        </tbody>
      </Table>
      <div className="container-google-questions">
        {translate('People also ask')}
        <Button
          className="btn-google-questions"
          onClick={() => setGoogleQuestionsIsOpen(!googleQuestionsIsOpen)}
        >
          {isLoadingQuestions ? (
            <Spinner color="secondary" size="sm">
              {' '}
              {translate('Loading')}
            </Spinner>
          ) : (
            translate('Google questions')
          )}
          {googleQuestionsIsOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M6.99993 6.31373L4.11243 9.20123L3.2876 8.3764L6.99993 4.66406L10.7123 8.3764L9.88743 9.20123L6.99993 6.31373Z"
                fill="#1a202c"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M6.99993 7.68437L9.88743 4.79688L10.7123 5.62171L6.99993 9.33404L3.2876 5.62171L4.11243 4.79688L6.99993 7.68437Z"
                fill="#1a202c"
              />
            </svg>
          )}
        </Button>
        <Collapse isOpen={googleQuestionsIsOpen}>
          <div className="question-container">
            {googleQuestions &&
              googleQuestions?.length > 0 &&
              googleQuestions.map((question, index) => (
                <div key={index} className="d-flex align-items-center gap-2">
                  <p className="question">{question.question}</p>
                  <Badge color="light">
                    {question.type === 'primary' ? translate('Primary') : translate('Secondary')}
                  </Badge>
                </div>
              ))}
          </div>
        </Collapse>
      </div>
    </SERPDataLayout>
  );
};

SERPData.propTypes = {
  data: PropTypes.array,
  googleQuestionsData: PropTypes.array || PropTypes.string,
  primaryKeyword: PropTypes.string,
};

export default SERPData;
